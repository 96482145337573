import styled from 'styled-components'

const HorizontalMenuDropdownWrapperUi = styled.div`
  height: fit-content;
  width: fit-content;
  z-index: 50;
  padding: 15px 0;
  background-color: transparent;
`

export default HorizontalMenuDropdownWrapperUi
