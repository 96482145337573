import styled from 'styled-components'

const AffiliateBadgeUi = styled.div`
  width: 165px;
  height: 38px;
  background-image: url(${process.env
    .CLOUDFRONT_SHARED}/images/affiliate_badge_logo.png);
  background-size: cover;
  padding: 3px 0;
  position: fixed;
  bottom: 4px;
  right: 0;
  z-index: 3;
`

export default AffiliateBadgeUi
