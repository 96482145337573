import styled, { css } from 'styled-components'

type LayoutGridUiProps = {
  gridTemplateColumns?: string
  gridTemplateRows?: string
}
const LayoutGridUi = styled.div<LayoutGridUiProps>`
  display: grid;
  gap: 30px;
  ${p =>
    p.gridTemplateColumns &&
    css`
      grid-template-columns: ${p.gridTemplateColumns};
    `}
  ${p =>
    p.gridTemplateRows &&
    css`
      grid-template-rows: ${p.gridTemplateRows};
    `}
`

export default LayoutGridUi
