import styled, { css } from 'styled-components'

type BlogPostsRowUiProps = {
  gap: number
  mobileGap?: number
}

const BlogPostsRowUi = styled.div<BlogPostsRowUiProps>`
  display: grid;
  gap: ${p => p.gap}px;
  ${p => p.theme.phone} {
    ${p =>
      p.mobileGap &&
      css`
        gap: ${p.mobileGap}px;
      `};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      gap: ${p.mobileGap}px;
    `}
`

export default BlogPostsRowUi
