import styled, { css } from 'styled-components'
import { fontCss, mobileFontCss } from 'common/utils/styleUtilsNew'

interface TimerUiProps {
  id: string
  fontFamily?: string
  fontWeight?: string
  fontStyle?: string
  mobileFontFamily?: string
  mobileFontWeight?: string
  mobileFontStyle?: string
}

const TimerUi = styled.div.attrs<TimerUiProps>(p => ({
  id: p.id,
}))<TimerUiProps>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${fontCss}
  ${p => p.theme.phone} {
    ${mobileFontCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
    `}
`

export default TimerUi
