import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonAttachments from 'common/components/entities/Attachments'
import { wrapperStyles } from 'common/components/entities/Button'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { AttachmentsInterface } from 'client/pages/contact-us/entities/Attachments'
import { ContextFieldsInterface, useShowContactUsSuccess } from './ContactUs'

function Attachments({ entity }: { entity: AttachmentsInterface }) {
  const { setFields } = useShowContactUsSuccess()

  if (!entity.show) {
    return <div />
  }

  const handleFiles = (file: File) => {
    setFields((prev: ContextFieldsInterface) => {
      return {
        ...prev,
        [FieldSlugEnum.Attachments]: file,
      }
    })
  }
  return (
    <StyleWrapper
      styles={{ ...entity.margin, ...wrapperStyles }}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonAttachments
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        attrId={entity.htmlAttrId}
        color={entity.color}
        fontSize={entity.fontSize}
        mobileColor={entity.mobileColor}
        mobileFontSize={entity.mobileFontSize}
        fontFamily={entity.fontFamily}
        fontWeight={entity.fontWeight}
        fontStyle={entity.fontStyle}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontWeight={entity.mobileFontWeight}
        mobileFontStyle={entity.mobileFontStyle}
        iconClassName={entity.iconClassName}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        fileOnChange={handleFiles}
      />
    </StyleWrapper>
  )
}

export default Attachments
