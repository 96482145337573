import styled from 'styled-components'
import {
  BorderRadiusType,
  BorderType,
  FullBorderRadiusType,
} from 'common/types/styleTypes'
import {
  borderCss,
  borderRadiusCss,
  boxShadowCss,
  mobileBorderCss,
  mobileBorderRadiusCss,
  mobileBoxShadowCss,
} from 'common/utils/styleUtilsNew'

type BlogPostImageUiProps = {
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  borderRadius?: BorderRadiusType | FullBorderRadiusType
  mobileBorderRadius?: BorderRadiusType | FullBorderRadiusType
  boxShadow?: string
  mobileBoxShadow?: string
}

const BlogPostImageUi = styled.img<BlogPostImageUiProps>`
  width: 100%;
  ${borderRadiusCss};
  ${borderCss}
  ${boxShadowCss}
  ${p => p.theme.phone} {
    ${mobileBorderRadiusCss};
    ${mobileBorderCss}
    ${mobileBoxShadowCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    `
      ${mobileBorderRadiusCss};
      ${mobileBorderCss}
      ${mobileBoxShadowCss}
    `}
`

export default BlogPostImageUi
