import styled from 'styled-components'
import { BorderType, MarginType } from 'common/types/styleTypes'
import {
  borderCss,
  boxShadowCss,
  marginCss,
  mobileBorderCss,
  mobileBoxShadowCss,
  mobileMarginCss,
} from 'common/utils/styleUtilsNew'

type EmbedPlayerUiProps = {
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  boxShadow?: string
  mobileBoxShadow?: string
  skipIframeStyles?: boolean
}

export const TikTokEmbedPlayerUi = styled.div<EmbedPlayerUiProps>`
  ${borderCss};
  ${boxShadowCss};
  ${p => p.theme.phone} {
    ${mobileBorderCss};
    ${mobileBoxShadowCss};
  }
`

const EmbedPlayerUi = styled.div<EmbedPlayerUiProps>`
  position: relative;
  padding-top: 0;
  height: auto;
  overflow: hidden;

  :has(>iframe) {
    padding-bottom: 56.5%;
  }

  & iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-width: 0;
  }
  ${borderCss};
  ${boxShadowCss};
  ${p => p.theme.phone} {
    ${mobileBorderCss};
    ${mobileBoxShadowCss};
  }
`

export default EmbedPlayerUi
