import styled from 'styled-components'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { MarginType } from 'common/types/styleTypes'
import { marginCss, mobileMarginCss } from 'common/utils/styleUtilsNew'
import {
  carouselDisplayImageCss,
  carouselMobileDisplayImageCss,
} from '../uiUtils'

const BlogPostLinkImageWrapperUi = styled.a<{
  margin: MarginType
  mobileMargin: Partial<MarginType>
  imagePlacement?: ImagePlacementVariants
  mobileImagePlacement?: ImagePlacementVariants
}>`
  ${marginCss}
  ${carouselDisplayImageCss}
  ${p => p.theme.isEditorMobileMode && carouselMobileDisplayImageCss}
  ${p => p.theme.phone} {
    ${carouselMobileDisplayImageCss}
    ${mobileMarginCss};
  }
`

export default BlogPostLinkImageWrapperUi
