import styled from 'styled-components'

const SurveyQuestionTitleUi = styled.div`
  font-size: ${p => p.fontSize};
  color: ${p => p.color};
  text-align: center;
  margin-bottom: 15px;
  ${p => p.theme.phone} {
    font-size: ${p => p.mobileFontSize};
  }
`

export default SurveyQuestionTitleUi
