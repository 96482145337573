import { BoxShadowInterface } from 'tools/commands/migrate/utils/migrateUtils'
import styled, { css } from 'styled-components'
import { Background } from 'common/types/BackgroundType'
import { BorderType, PaddingType } from 'common/types/styleTypes'
import {
  backgroundColorCss,
  borderCss,
  boxShadowCss,
  fontCss,
  FontProps,
  mobileBackgroundColorCss,
  mobileBackgroundCss,
  mobileBorderCss,
  mobileBorderRadiusCss,
  mobileBoxShadowCss,
  mobilePaddingsCss,
  paddingsCss,
} from 'common/utils/styleUtilsNew'

export type CommonInlineBodyUiProps = {
  width: number
  mobileWidth: number
  maxWidth: number
  blur?: number
  padding?: PaddingType
  mobilePadding?: PaddingType
  background?: Background
  backgroundImage?: string | null
  mobileBackgroundImage?: string | null
  backgroundColor?: string
  mobileBackgroundColor?: string
  mobileBackground?: Background
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
} & FontProps &
  BoxShadowInterface

const CommonInlineBodyUi = styled.section<CommonInlineBodyUiProps>`
  margin: auto;
  width: 100%;

  ${({ maxWidth, width }) =>
    css`
      max-width: ${maxWidth || width}px;
    `}

  ${p => p.background && getBackgroundStyles(p.background)};

  ${p =>
    p.backgroundImage &&
    css`
      background-image: ${p.backgroundImage};
    `};

  ${paddingsCss}
  ${borderCss}
  ${boxShadowCss}
  ${fontCss}
  ${boxShadowCss}
  ${backgroundColorCss}

  ${({ blur = 0 }) =>
    css`
      filter: blur(${blur});
    `}

  ${p => p.theme.phone} {
    ${p => getBackgroundStyles(p.mobileBackground)};
    ${p =>
      p.mobileBackgroundImage &&
      css`
        background-image: ${p.mobileBackgroundImage};
      `};

    ${mobilePaddingsCss}
    ${mobileBorderCss}
    ${mobileBorderRadiusCss}
    ${mobileBoxShadowCss}
    ${mobileBackgroundColorCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${getBackgroundStyles(p.mobileBackground)};
      background-image: ${p.mobileBackgroundImage || 'none'};

      ${mobilePaddingsCss}
      ${mobileBorderCss}
      ${mobileBorderRadiusCss}
      ${mobileBoxShadowCss}
      ${mobileBackgroundCss}
      ${mobileBackgroundColorCss}
    `}
`

export default CommonInlineBodyUi

function getBackgroundStyles(p?: Background) {
  return (
    p &&
    css`
      ${p.backgroundSize && `background-size: ${p.backgroundSize};`}
      ${p.backgroundPosition && `background-position: ${p.backgroundPosition};`}
      ${p.backgroundRepeat && `background-repeat: ${p.backgroundRepeat};`}
      ${p.backgroundAttachment &&
      `background-attachment: ${p.backgroundAttachment};`}
    `
  )
}
