import styled, { css } from 'styled-components'
import { BorderType, MarginType } from 'common/types/styleTypes'
import {
  borderCss,
  boxShadowCss,
  marginCss,
  mobileBorderCss,
  mobileBoxShadowCss,
  mobileMarginCss,
} from 'common/utils/styleUtilsNew'

type BlogPostImageUiProps = {
  boxShadow?: string
  mobileBoxShadow?: string
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
}

const BlogPostImageUi = styled.img<BlogPostImageUiProps>`
  width: 100%;
  display: block;
  ${boxShadowCss}
  ${borderCss}
  ${p => p.theme.phone} {
    ${mobileBoxShadowCss}
    ${mobileBorderCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileBoxShadowCss}
      ${mobileBorderCss}
    `}
`

export default BlogPostImageUi
