import { css } from 'styled-components'
import { VerticalAlignmentVariants } from 'common/types/entities/BlogPostListingInterface'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'

export const imagePlacementCss = css<{
  imagePlacement?: ImagePlacementVariants
  imageSrc?: string
}>`
  ${p =>
    p.imagePlacement &&
    p.imagePlacement === ImagePlacementVariants.Left &&
    `grid-template-columns: 1fr 1fr;`}
  ${p =>
    p.imagePlacement &&
    p.imagePlacement === ImagePlacementVariants.Background &&
    p.imageSrc &&
    `
      background-image: url(${p.imageSrc});
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: initial;
    `}
  ${p =>
    p.imagePlacement &&
    p.imagePlacement === ImagePlacementVariants.Up &&
    `grid-template-columns: repeat(1,1fr);`}
`

export const mobileImagePlacementCss = css<{
  mobileImagePlacement?: ImagePlacementVariants
  imageSrc?: string
}>`
  grid-template-columns: repeat(1, 1fr);
  background-image: none;
  background-size: initial;
  background-position: initial;
  background-repeat: initial;
  background-attachment: initial;
  padding-top: 0;
  padding-bottom: 0;

  ${p =>
    p.mobileImagePlacement &&
    p.mobileImagePlacement === ImagePlacementVariants.Left &&
    `grid-template-columns: 1fr 1fr;
     background-image: none;
     background-size: initial;
     background-position: initial;
     background-repeat: initial;
     background-attachment: initial;
     padding-top: 0;
     padding-bottom: 0;
    `}
  ${p =>
    p.mobileImagePlacement &&
    p.mobileImagePlacement === ImagePlacementVariants.Background &&
    p.imageSrc &&
    `
    background-image: url(${p.imageSrc});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: initial;
    `}
  ${p =>
    p.mobileImagePlacement &&
    p.mobileImagePlacement === ImagePlacementVariants.Up &&
    `grid-template-columns: repeat(1,1fr);
     background-image: none;
     background-size: initial;
     background-position: initial;
     background-repeat: initial;
     background-attachment: initial;
     padding-top: 0;
     padding-bottom: 0;
    `}
`

export const verticalAlignmentCss = css<{
  imagePlacement?: ImagePlacementVariants
  verticalAlignment?: VerticalAlignmentVariants
}>`
  ${p =>
    (p.imagePlacement &&
      p.imagePlacement === ImagePlacementVariants.Background &&
      p.verticalAlignment === VerticalAlignmentVariants.Top &&
      `
        padding-bottom: 40%;
        padding-top: 0%;
        align-items: flex-start;
      `) ||
    (p.imagePlacement === ImagePlacementVariants.Background &&
      p.verticalAlignment == VerticalAlignmentVariants.Bottom &&
      `
        padding-top: 40%;
        padding-bottom: 0%;
        align-items: flex-end;
      `) ||
    (p.imagePlacement === ImagePlacementVariants.Background &&
      p.verticalAlignment == VerticalAlignmentVariants.Center &&
      `
        padding-top: 20%;
        padding-bottom: 20%;
        align-items: center;
      `) ||
    (p.imagePlacement === ImagePlacementVariants.Background &&
      ` 
      padding-top: 40%;
      align-items: flex-end;`)}
`

export const mobileVerticalAlignmentCss = css<{
  mobileImagePlacement?: ImagePlacementVariants
  mobileVerticalAlignment?: VerticalAlignmentVariants
}>`
  ${p =>
    (p.mobileImagePlacement &&
      p.mobileImagePlacement === ImagePlacementVariants.Background &&
      p.mobileVerticalAlignment === VerticalAlignmentVariants.Top &&
      `
        padding-bottom: 40%;
        padding-top: 0%;
        align-items: flex-start;
      `) ||
    (p.mobileImagePlacement === ImagePlacementVariants.Background &&
      p.mobileVerticalAlignment == VerticalAlignmentVariants.Bottom &&
      `
        padding-top: 40%;
        padding-bottom: 0%;
        align-items: flex-end;
      `) ||
    (p.mobileImagePlacement === ImagePlacementVariants.Background &&
      p.mobileVerticalAlignment == VerticalAlignmentVariants.Center &&
      `
        padding-top: 20%;
        padding-bottom: 20%;
        align-items: center;
      `) ||
    (p.mobileImagePlacement === ImagePlacementVariants.Background &&
      `
    padding-top: 40%;
    align-items: flex-end;
    `)}
`

export const imageLayerCss = css<{
  imagePlacement?: ImagePlacementVariants
  imageSrc?: string
  foregroundColor?: string
  mobileForegroundColor?: string
}>`
  ${p =>
    p.imagePlacement &&
    p.imagePlacement === ImagePlacementVariants.Background &&
    p.imageSrc &&
    p.foregroundColor &&
    `
      background-image: linear-gradient(${p.foregroundColor}, ${p.foregroundColor}),
        url(${p.imageSrc});
    `}
`

export const mobileImageLayerCss = css<{
  mobileImagePlacement?: ImagePlacementVariants
  imageSrc?: string
  mobileForegroundColor?: string
}>`
  ${p =>
    p.mobileImagePlacement &&
    p.mobileImagePlacement === ImagePlacementVariants.Background &&
    p.imageSrc &&
    p.mobileForegroundColor &&
    `
      background-image: linear-gradient(${p.mobileForegroundColor}, ${p.mobileForegroundColor}),
        url(${p.imageSrc});
    `}
`

export const carouselImagePlacementCss = css<{
  imagePlacement?: ImagePlacementVariants
  imageSrc?: string
}>`
  ${p =>
    p.imagePlacement &&
    p.imagePlacement === ImagePlacementVariants.Left &&
    `grid-template-columns: 1fr 1fr;`}
  ${p =>
    p.imagePlacement &&
    p.imagePlacement === ImagePlacementVariants.Background &&
    p.imageSrc &&
    `
      background-image: url(${p.imageSrc});
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: initial;
      padding-top: 50%;
    `}
`

export const carouselMobileImagePlacementCss = css<{
  mobileImagePlacement?: ImagePlacementVariants
  imageSrc?: string
}>`
  background: unset;
  padding: unset;
  grid-template-columns: unset;
  ${p =>
    p.mobileImagePlacement &&
    p.mobileImagePlacement === ImagePlacementVariants.Left &&
    `grid-template-columns: 1fr 1fr;`}
  ${p =>
    p.mobileImagePlacement &&
    p.mobileImagePlacement === ImagePlacementVariants.Background &&
    p.imageSrc &&
    `
      background-image: url(${p.imageSrc});
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: initial;
      padding-top: 50%;
    `}
`

export const carouselDisplayImageCss = css<{
  imagePlacement?: ImagePlacementVariants
}>`
  display: ${p =>
    p.imagePlacement && p.imagePlacement === ImagePlacementVariants.Background
      ? 'none'
      : 'initial'};
`

export const carouselMobileDisplayImageCss = css<{
  mobileImagePlacement?: ImagePlacementVariants
}>`
  display: ${p =>
    p.mobileImagePlacement &&
    p.mobileImagePlacement === ImagePlacementVariants.Background
      ? 'none'
      : 'initial'};
`
