import styled, { css } from 'styled-components'

type SioPricePlanListButtonProps = {
  active?: boolean
}

const PricePlanListButtonUi = styled.button<SioPricePlanListButtonProps>`
  cursor: pointer;
  flex: 1;
  background: ${p => (p.active ? '#1BA0FF' : 'transparent')};
  &:nth-child(2) {
    border-right: 2px solid #1ba0ff;
    border-left: 2px solid #1ba0ff;
  }
  border: none;
  outline: none;
  font-size: 22px;
  padding-top: 9px;
  padding-bottom: 9px;
  word-break: break-all;
  color: ${p => (p.active ? '#ffffff' : '#000033')};
  ${p => p.theme.phone} {
    font-size: 16px;
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      font-size: 16px;
    `}
`

export default PricePlanListButtonUi
