import PropTypes from 'prop-types'
import React, { useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import usePage from '../hooks/usePage'
import { getGlobalFontFamily } from '../reducers/pageReducer'
import LectureUi from './ui/LectureUi'

function LecturePage({ children }) {
  const fontFamily = usePage(getGlobalFontFamily)

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      window.parent.postMessage({
        type: 'lecture_content_height',
        height: entries[0].target.clientHeight,
      })
    })

    // start observing a DOM node
    resizeObserver.observe(document.body)
  }, [])

  return (
    <React.Fragment>
      <Helmet />
      <LectureUi styles={{ fontFamily: wrapFontFamily(fontFamily) }}>
        {children}
      </LectureUi>
    </React.Fragment>
  )
}

LecturePage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LecturePage
