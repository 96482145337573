import styled from 'styled-components'

const ContentTableHeaderUi = styled.div`
  display: flex;
  justify-content: space-between;
  //grid-gap: 10px;
  margin-bottom: 10px;
`

export default ContentTableHeaderUi
