import React from 'react'
import { useTranslation } from 'react-i18next'
import AffiliateBadgePoweredByUi from './ui/AffiliateBadgePoweredByUi'
import AffiliateBadgeUi from './ui/AffiliateBadgeUi'

type AffiliateBadgeProps = {
  affiliateLink?: string
}

const AffiliateBadge = ({ affiliateLink = '' }: AffiliateBadgeProps) => {
  const { t } = useTranslation('common')

  const Badge = () => (
    <AffiliateBadgeUi>
      <AffiliateBadgePoweredByUi>
        {t('components.affiliate_badge.made_with')}
      </AffiliateBadgePoweredByUi>
    </AffiliateBadgeUi>
  )

  return affiliateLink ? (
    <a href={affiliateLink} target="_blank" rel="noopener noreferrer">
      <Badge />
    </a>
  ) : (
    <Badge />
  )
}

export default AffiliateBadge
