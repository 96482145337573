import React from 'react'
import BurgerSpanUi from 'common/components/entities/Menu/components/Mobile/Burger/ui/BurgerSpanUi'
import BurgerUi from 'common/components/entities/Menu/components/Mobile/Burger/ui/BurgerUi'
import { AlignSelfType, PaddingType } from 'common/types/styleTypes'

type BurgerProps = {
  handleClick?: () => void
  burgerIconColor: string
  opened?: boolean
  openedColor: string
  forceVisible?: boolean
  fixed?: boolean
  padding: PaddingType
  mobilePadding: PaddingType
  burgerAlignSelf: AlignSelfType
}

function Burger({
  handleClick,
  burgerIconColor,
  opened = false,
  openedColor,
  forceVisible = false,
  fixed = false,
  padding,
  mobilePadding,
  burgerAlignSelf,
}: BurgerProps) {
  return (
    <BurgerUi
      onClick={handleClick}
      opened={opened}
      forceVisible={forceVisible}
      fixed={fixed}
      padding={padding}
      mobilePadding={mobilePadding}
      alignItems={burgerAlignSelf || 'flex-end'}
    >
      <BurgerSpanUi
        burgerIconColor={burgerIconColor}
        openedColor={openedColor}
        opened={opened}
      />
      <BurgerSpanUi
        burgerIconColor={burgerIconColor}
        openedColor={openedColor}
        opened={opened}
      />
      <BurgerSpanUi
        burgerIconColor={burgerIconColor}
        openedColor={openedColor}
        opened={opened}
      />
    </BurgerUi>
  )
}

export default Burger
