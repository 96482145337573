import React from 'react'
import {
  BlogPost,
  blogPostListingTitleInterface,
} from 'common/types/entities/BlogPostListingInterface'
import BlogPostAnnounceHeaderLinkUi from './ui/BlogPostAnnounceHeaderLinkUi'
import BlogPostAnnounceHeaderUi from './ui/BlogPostAnnounceHeaderUi'

type BlogPostsTitlesProps = {
  post: BlogPost
} & blogPostListingTitleInterface

function BlogPostsTitles({
  post,
  titleFontSize,
  mobileTitleFontSize,
  titleLineHeight,
  mobileTitleLineHeight,
  titleFontFamily,
  titleFontWeight,
  titleFontStyle,
  mobileTitleFontFamily,
  mobileTitleFontWeight,
  mobileTitleFontStyle,
  titleLetterSpacing,
  mobileTitleLetterSpacing,
  titleAlign,
  mobileTitleAlign,
  titleColor,
  mobileTitleColor,
  titleMargin,
  mobileTitleMargin,
}: BlogPostsTitlesProps) {
  return (
    <BlogPostAnnounceHeaderUi
      key={post.path}
      fontSize={titleFontSize}
      color={titleColor}
      fontFamily={titleFontFamily}
      fontWeight={titleFontWeight}
      fontStyle={titleFontStyle}
      letterSpacing={titleLetterSpacing}
      textAlign={titleAlign}
      lineHeight={titleLineHeight}
      mobileFontSize={mobileTitleFontSize}
      mobileColor={mobileTitleColor}
      mobileFontFamily={mobileTitleFontFamily}
      mobileFontWeight={mobileTitleFontWeight}
      mobileFontStyle={mobileTitleFontStyle}
      mobileLineHeight={mobileTitleLineHeight}
      mobileLetterSpacing={mobileTitleLetterSpacing}
      mobileTextAlign={mobileTitleAlign}
      margin={titleMargin}
      mobileMargin={mobileTitleMargin}
    >
      <BlogPostAnnounceHeaderLinkUi href={post.path}>
        {post.name}
      </BlogPostAnnounceHeaderLinkUi>
    </BlogPostAnnounceHeaderUi>
  )
}

export default BlogPostsTitles
