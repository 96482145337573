import styled from 'styled-components'
import { fontSizeCss, mobileFontSizeCss } from 'common/utils/styleUtilsNew'

type WebinarSessionDateTimeIconUiProps = {
  fontSize: number
  mobileFontSize?: number
}
const WebinarSessionDateTimeIconUi = styled.span<WebinarSessionDateTimeIconUiProps>`
  ${fontSizeCss}
  ${p => p.theme.phone} {
    ${mobileFontSizeCss}
  }
  ${p => p.theme.isEditorMobileMode && mobileFontSizeCss}
`

export default WebinarSessionDateTimeIconUi
