import styled, { CSSProp } from 'styled-components'

export const CountrySelectorButtonContentUi = styled.div<{
  styles?: CSSProp
}>`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ styles }) => styles}
`
