import styled from 'styled-components'

const AffiliateBadgePoweredByUi = styled.span`
  position: absolute;
  left: 40px;
  top: 5px;
  font-size: 11px;
  color: #ffffff;
  font-family: Arial, sans-serif;
`

export default AffiliateBadgePoweredByUi
