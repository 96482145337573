import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import Meta from '../../components/core/Meta'
import useWebinarSession, {
  selectors as webinarSessionSelectors,
} from '../../hooks/useWebinarSession'
import { isDateValid } from '../../utils/dateUtils'
import { dispatchWebinarStartedCustomEvent } from '../../utils/webinarUtils'
import OnExitPopup from '../components/OnExitPopup'
import OnloadPopups from '../components/OnloadPopups'
import TriggeredPopup from '../components/TriggeredPopup'
import PageUi from '../ui/PageUi'
import WebinarCountdown from './components/WebinarCountdown'

function WebinarSessionPage({ children }) {
  const globalTextFontProperties = usePage(
    pageSelectors.getGlobalFontProperties,
  )
  let sessionDateTime = useWebinarSession(webinarSessionSelectors.getDateTime)
  let showSession = false
  if (isDateValid(sessionDateTime)) {
    const diff = sessionDateTime.getTime() - Date.now()
    showSession = diff < 0
  } else {
    // fallback date
    sessionDateTime = new Date()
  }
  const [visible, setVisible] = useState(showSession)

  const isServer = typeof window === 'undefined'

  useEffect(() => {
    if (!isServer && visible) {
      dispatchWebinarStartedCustomEvent()
    }
  }, [isServer, visible])

  if (isServer) {
    return null
  }

  return (
    <PageUi
      textFontFamily={globalTextFontProperties.fontFamily}
      textFontWeight={globalTextFontProperties.fontWeight}
      textFontStyle={globalTextFontProperties.fontStyle}
    >
      {visible ? (
        <React.Fragment>
          <Meta noIndex />
          {children}
          <TriggeredPopup />
          <OnExitPopup />
          <OnloadPopups />
        </React.Fragment>
      ) : (
        <WebinarCountdown
          date={sessionDateTime}
          onTimeEndHandler={() => setVisible(true)}
        />
      )}
    </PageUi>
  )
}

WebinarSessionPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WebinarSessionPage
