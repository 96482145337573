import { css } from 'styled-components'

export const textLi = () => css`
  list-style: none !important;
  margin-left: 0 !important;
  display: flex;
  align-items: baseline;
`
export const textUl = () => css`
  margin-left: 25px;
  padding-left: 0;
`
export const textFlexBasis = flexBasis => css`
  flex-basis: ${flexBasis};
`

export const textLineHeight = lineHeight =>
  css`
    line-height: ${lineHeight};
  `
export const textFontStyle = fontStyle =>
  css`
    font-style: ${fontStyle};
  `
export const textFontSize = fontSize =>
  css`
    font-size: ${fontSize};
  `
export const textColor = color =>
  css`
    color: ${color};
  `
export const textFontFamily = fontFamily =>
  css`
    font-family: ${fontFamily};
  `
export const textLinkColor = linkColor =>
  css`
    & a {
      color: ${linkColor};
    }
    & a > span {
      color: ${linkColor};
    }
  `

export const leftToRightDisplay = textAlign =>
  css`
    direction: ${textAlign === 'right' ? 'rtl' : 'unset'};
  `

export const headingFontWeight = fontWeight =>
  css`
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: ${fontWeight} !important;
    }
  `
export const headingFontStyle = fontStyle =>
  css`
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-style: ${fontStyle} !important;
    }
  `
export const headingFontFamily = fontFamily =>
  css`
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: ${fontFamily} !important;
    }
  `
export const headingFontSize = fontSize =>
  css`
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: ${fontSize};
    }
  `
//to prevent margins from user agent stylesheet
export const headingMargins = () =>
  css`
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  `
