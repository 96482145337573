import styled from 'styled-components'

const LanguageSwitcherItemsLinkUi = styled.a`
  display: block;
  font-size: 15px;
  text-decoration: none;
  color: #6b7c93;
  padding: 5px 0;
  &:hover {
    color: #149efc;
  }
`

export default LanguageSwitcherItemsLinkUi
