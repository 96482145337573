import styled from 'styled-components'

const LanguageSwitcherUi = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default LanguageSwitcherUi
