import styled, { css } from 'styled-components'
import { TimerItemStylesInterface } from 'common/components/entities/Timer/types/TimerItemStylesInterface'
import { fontCss, mobileFontCss } from 'common/utils/styleUtilsNew'

const TimerItemDelimiterUi = styled.section<TimerItemStylesInterface>`
  ${fontCss}
  ${p => p.theme.phone} {
    ${mobileFontCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
    `}
`

export default TimerItemDelimiterUi
