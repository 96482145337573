import { isDateValid } from 'publisher/utils/dateUtils'

const webinarSessionLinkPlaceholder = '%WEBINAR_SESSION_LINK%'
const webinarSessionTimeStampPlaceholder = '%WEBINAR_SESSION_TIMESTAMP%'

const defaultState = {
  timeStamp: webinarSessionTimeStampPlaceholder,
  url: webinarSessionLinkPlaceholder,
}

const currentTimestamp = Date.now()

export default function(state = defaultState) {
  return state
}

export const getSessionStartDateTime = ({ timeStamp }) =>
  new Date(Number(timeStamp) * 1000)

export const getSessionDelayInSeconds = state => {
  const sessionStartDateTime = getSessionStartDateTime(state)
  return isDateValid(sessionStartDateTime)
    ? (sessionStartDateTime.getTime() - new Date().getTime()) / 1000
    : 0
}

export const getWebinarTimestamp = state => {
  // we shouldn't pass placeholder on SSR
  if (typeof window === 'undefined') {
    return null
  }

  if (state.timeStamp === webinarSessionTimeStampPlaceholder) {
    return currentTimestamp
  }

  return Number(state.timeStamp) * 1000
}

export const getUrl = state => {
  // we shouldn't pass placeholder on SSR
  if (typeof window === 'undefined') {
    return ''
  }

  if (state.url === webinarSessionLinkPlaceholder) {
    return 'https://yoursubdomain.systeme.io/webinar/name/show/client-hash'
  }

  return state.url
}

export const selectors = {
  getSessionStartDateTime,
  getWebinarTimestamp,
  getSessionDelayInSeconds,
  getUrl,
}
