import React from 'react'
import CommonAudio from '../../../common/components/entities/Audio/AudioNew'
import { AudioInterface } from '../../../common/types/entities/AudioInterface'
import filesSelectors from '../../store/files/filesSelectors'
import { useFiles } from '../../store/index'
import StyleWrapper from '../core/StyleWrapper'

function AudioNew({ entity }: { entity: AudioInterface }) {
  const file = useFiles(files => filesSelectors.getFile(files, entity.fileId))
  const src = entity.fileId ? (file && file.path) || '' : entity.url

  return (
    <StyleWrapper
      mobileMargin={entity.mobileMargin}
      margin={entity.margin}
      appearance={entity.appearance}
    >
      <CommonAudio htmlAttrId={entity.htmlAttrId} src={src} />
    </StyleWrapper>
  )
}

export default AudioNew
