import React from 'react'
import PropTypes from 'prop-types'
import TwitterTweetButtonUi from './ui/TwitterTweetButtonUi'
import TwitterTweetButtonIconUi from './ui/TwitterTweetButtonIconUi'

function TwitterTweetButtonOld({ text, styles, mobileStyles }) {
  return (
    <TwitterTweetButtonUi
      styles={styles}
      mobileStyles={mobileStyles}
      target="_blank"
      href={`https://twitter.com/intent/tweet?text=${text}`}
    >
      <TwitterTweetButtonIconUi />
      <span>Tweet</span>
    </TwitterTweetButtonUi>
  )
}

TwitterTweetButtonOld.propTypes = {
  text: PropTypes.string,
}

export default TwitterTweetButtonOld