import styled from 'styled-components'
import {
  InactivePaginationArrowCss,
  MobileInactivePaginationArrowCss,
} from 'common/utils/styleUtilsNew'

type PaginationLinkUiProps = {
  paginationActiveColor: string
  mobilePaginationActiveColor?: string
  paginationActiveBackgroundColor: string
  mobilePaginationActiveBackgroundColor?: string
  paginationInactiveColor: string
  mobilePaginationInactiveColor?: string
  paginationInactiveBackgroundColor: string
  mobilePaginationInactiveBackgroundColor?: string
}
const PaginationLinkUi = styled.a<PaginationLinkUiProps>`
  font-size: 14px;
  text-decoration: none;
  padding: 5px 10px;
  text-align: center;
  border-radius: 50%;
  padding: 0em 0.5em;
  height: 40px;
  line-height: 40px;
  width: 40px;
  margin-left: 8px;
  margin-right: 8px;
  ${InactivePaginationArrowCss}
  ${p => p.theme.isEditorMobileMode && MobileInactivePaginationArrowCss};
  ${p => p.theme.phone} {
    ${MobileInactivePaginationArrowCss}
    margin-top: 5px;
  }
`

export default PaginationLinkUi
