import React from 'react'
import { Translation } from 'react-i18next'
import styled from 'styled-components'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'
import { BlogContentPlaceholderInterface } from '../../../common/types/entities/BlogContentPlaceholderInterface'
import { PaddingType } from '../../../common/types/styleTypes'
import {
  mobilePaddingsCss,
  paddingsCss,
} from '../../../common/utils/styleUtilsNew'

const BlogContentPlaceholderUi = styled.div`
  color: #acc9de;
  font-size: 80px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`

type BlogContentPlaceholderContainerUiProps = {
  padding: PaddingType
  mobilePadding: PaddingType
}
const BlogContentPlaceholderContainerUi = styled.div<BlogContentPlaceholderContainerUiProps>`
  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
  }
  ${paddingsCss}
`

const BlogContentPlaceholderNew = ({
  entity,
}: {
  entity: BlogContentPlaceholderInterface
}) =>
  entity.childIds.length > 0 ? (
    <BlogContentPlaceholderContainerUi
      padding={entity.padding}
      mobilePadding={entity.mobilePadding}
    >
      <BaseNodeChildren childIds={entity.childIds} />
    </BlogContentPlaceholderContainerUi>
  ) : (
    <BlogContentPlaceholderUi id="blog-post-content-placeholder">
      <Translation>
        {t =>
          t('components.entities.blog_post_content_placeholder.placeholder')
        }
      </Translation>
    </BlogContentPlaceholderUi>
  )

export default BlogContentPlaceholderNew
