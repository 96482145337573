import styled from 'styled-components'

type LanguageSwitcherItemsUiProps = {
  isOpened: boolean
}

const LanguageSwitcherItemsUi = styled.div<LanguageSwitcherItemsUiProps>`
  box-shadow: 0 1px 4px 0 #d5e0e8;
  z-index: 1;
  border-radius: 3px;
  background-color: #fff;
  padding: 22px 25px;
`

export default LanguageSwitcherItemsUi
