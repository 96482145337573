import styled, { css } from 'styled-components'
import { BulletListInterface } from 'common/types/entities/BulletListInterface'
import {
  bulletListIconCss,
  mobileBulletListIconCss,
  mobileVerticalSpacing,
  verticalSpacing,
} from 'common/utils/styleUtilsNew'

interface BulletListUiProps
  extends Pick<
    BulletListInterface,
    | 'verticalSpacing'
    | 'mobileVerticalSpacing'
    | 'listItemIconColor'
    | 'listItemIconSize'
    | 'mobileListItemIconColor'
  > {
  isEditing: boolean
}

const BulletListUi = styled.div<BulletListUiProps>`
  width: 100%;

  list-style-type: none;
  list-style: none;

  ul {
    margin-left: 25px;
    padding-left: 0;
  }

  li {
    ${verticalSpacing};
    list-style-type: none !important;
    list-style: none !important;
    margin-left: 0 !important;
    display: flex;
    align-items: baseline;

    ${p => p.theme.phone} {
      ${mobileVerticalSpacing}
    }

    ${p =>
      p.theme.isEditorMobileMode &&
      css`
        ${mobileVerticalSpacing}
      `}
  }

  & li:last-of-type {
    margin-bottom: 0;
  }

  & li div {
    display: inline;
    position: relative;
  }

  & li > i {
    margin-left: -25px;
    margin-right: 10px;

    ${bulletListIconCss}

    ${p => p.theme.phone} {
      ${mobileBulletListIconCss}
    }

    ${p =>
      p.theme.isEditorMobileMode &&
      css`
        ${mobileBulletListIconCss}
      `}
  }
`

export default BulletListUi
