export function validator(message, fun) {
  const f = (...args) => fun.apply(fun, args)

  f.message = message

  return f
}

export function checker(...validators) {
  return (...args) =>
    validators.reduce((error, check) => {
      if (check.apply(check, args)) {
        return error
      }

      error = [check.message] // show errors by one

      return error
    }, [])
}
