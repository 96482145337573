import { useEffect, useRef, useState } from 'react'
import { getTimeRemaining } from 'common/components/entities/Countdown/utils/get-time-remaining'

interface CountdownProps {
  time?: number | null
  children: ({
    days,
    hours,
    minutes,
    seconds,
  }: {
    days: number
    hours: number
    minutes: number
    seconds: number
  }) => JSX.Element
  onTimeEndHandler?: () => void
}

const Countdown = ({
  time = null,
  children,
  onTimeEndHandler,
}: CountdownProps) => {
  const [state, setState] = useState(getTimeRemaining(time))
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    const updateClock = () => {
      const state = getTimeRemaining(time)
      if (state.total < 0 && timerRef.current) {
        clearInterval(timerRef.current)
        onTimeEndHandler && onTimeEndHandler()
      } else {
        setState(state)
      }
    }
    if (time) {
      timerRef.current = setInterval(updateClock, 1000)
    }
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [onTimeEndHandler, time])
  return children(state)
}

export default Countdown
