import React from 'react'
import CommonWebinarSessionDateTime from '../../../../common/components/entities/WebinarSessionDateTime/WebinarSessionDateTimeNew'
import WebinarSessionDateTimeInterface from '../../../../common/types/entities/WebinarSessionDateTimeInterface'
import StyleWrapper from '../../../components/core/StyleWrapper'
import useWebinar, {
  selectors as webinarSelectors,
} from '../../../hooks/useWebinar'
import { managementSelectors, useManagement } from '../../../store/index'

function WebinarSessionDateTime({
  entity,
}: {
  entity: WebinarSessionDateTimeInterface
}) {
  let timestamp = useWebinar(webinarSelectors.getWebinarTimestamp)
  const isScreenshotMode = useManagement(managementSelectors.isScreenshotMode)

  if (isScreenshotMode) {
    timestamp = new Date(Date.now() + 10 * 1000)
  }
  return (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      <CommonWebinarSessionDateTime
        attrId={entity.htmlAttrId}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        color={entity.color}
        mobileColor={entity.mobileColor}
        fontSize={entity.fontSize}
        iconFontSize={entity.iconFontSize}
        mobileFontSize={entity.mobileFontSize}
        mobileIconFontSize={entity.mobileIconFontSize}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        fontFamily={entity.fontFamily}
        fontWeight={entity.fontWeight}
        fontStyle={entity.fontStyle}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontWeight={entity.mobileFontWeight}
        mobileFontStyle={entity.mobileFontStyle}
        timestamp={timestamp}
      />
    </StyleWrapper>
  )
}

export default WebinarSessionDateTime
