import styled, { css } from 'styled-components'
import { AlignType, PaddingType } from 'common/types/styleTypes'
import {
  fontCss,
  FontProps,
  lineHeightCss,
  mobileFontCss,
  mobileLineHeightCss,
} from 'common/utils/styleUtilsNew'

type BlogPostTitleUiNewProps = {
  lineHeight?: number
  mobileLineHeight?: number
  backgroundColor?: string
  mobileBackgroundColor?: string
  alignSelf: AlignType
  mobileAlignSelf?: AlignType
  padding?: PaddingType
  mobilePadding?: PaddingType
} & FontProps

const BlogPostTitleUiNew = styled.h1<BlogPostTitleUiNewProps>`
  text-align: ${p => p.alignSelf || 'center'};
  ${fontCss}
  ${p => p.fontFamily && `font-family: ${p.fontFamily} !important;`}
  ${lineHeightCss}

${p => p.theme.phone} {
    text-align: ${p => p.mobileAlignSelf || 'center'};
    ${mobileFontCss}
    ${p =>
      p.mobileFontFamily && `font-family: ${p.mobileFontFamily} !important;`}
    ${mobileLineHeightCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
      ${p.mobileFontFamily && `font-family: ${p.mobileFontFamily} !important;`}
      ${mobileLineHeightCss}
      text-align: ${p.mobileAlignSelf || 'center'};
    `}
`

export default BlogPostTitleUiNew
