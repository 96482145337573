import React from 'react'
import CommonWebinarSessionLink from '../../../../common/components/entities/WebinarSessionLink/WebinarSessionLinkNew'
import WebinarSessionLinkInterface from '../../../../common/types/entities/WebinarSessionLinkInterface'
import StyleWrapper from '../../../components/core/StyleWrapper'
import useWebinar, { selectors } from '../../../hooks/useWebinar'

function WebinarSessionLinkNew({
  entity,
}: {
  entity: WebinarSessionLinkInterface
}) {
  const url = useWebinar(selectors.getUrl)

  return (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
      textAlign="center"
    >
      {url && (
        <CommonWebinarSessionLink
          htmlAttrId={entity.htmlAttrId}
          text={entity.text}
          redirectUrl={url}
          color={entity.color}
          mobileColor={entity.mobileColor}
          fontSize={entity.fontSize}
          mobileFontSize={entity.mobileFontSize}
          textAlign={entity.textAlign}
          mobileTextAlign={entity.mobileTextAlign}
        />
      )}
    </StyleWrapper>
  )
}

export default WebinarSessionLinkNew
