import React from 'react'
import { Skeleton } from '../../ui/skeleton'

export const EventInfoSkeleton = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        width: '100%',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
      </div>
      <div style={{ display: 'flex' }}>
        <Skeleton height={40} />
      </div>
    </div>
  )
}
