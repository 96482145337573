import styled from 'styled-components'


const WebinarSessionDateTimeUi = styled.div.attrs(p => ({
  id: p.attrId,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default WebinarSessionDateTimeUi
