import React from 'react'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import { useDelay } from 'publisher/hooks/useDelay'
import { managementSelectors, useManagement } from 'publisher/store/index'
import StyleWrapper from '../../core/StyleWrapper'
import EmbedPlayer from './EmbedPlayer'
import FilePlayer from './FilePlayer'
import SourcePlayer from './SourcePlayer'
import VideoScreenshotPlaceholderUi from './ui/VideoScreenshotPlaceholderUi'

function Video({ entity }: { entity: VideoInterface }) {
  const isVisible = useDelay(entity.delay)
  const isScreenshotMode = useManagement(managementSelectors.isScreenshotMode)
  if (isScreenshotMode) {
    return (
      <VideoScreenshotPlaceholderUi
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
      />
    )
  }

  let Player
  if (entity.url) {
    Player = SourcePlayer
  } else if (entity.fileId) {
    Player = FilePlayer
  } else {
    Player = EmbedPlayer
  }

  if (!isVisible) {
    return (
      <StyleWrapper
        mobileMargin={entity.mobileMargin}
        margin={entity.margin}
        appearance={{ desktop: false, mobile: false }}
      />
    )
  }

  return (
    <StyleWrapper
      mobileMargin={entity.mobileMargin}
      margin={entity.margin}
      appearance={entity.appearance}
    >
      <Player entity={entity} />
    </StyleWrapper>
  )
}

export default Video
