import styled, { css } from 'styled-components'

interface SectionContainerProps {
  width: string
  backgroundColor?: string
  mobileBackgroundColor?: string
}

const SectionContainerUi = styled.div<SectionContainerProps>`
  max-width: ${p => p.width};
  width: 100%;
  margin: auto;
  background-color: ${({ backgroundColor = 'transparent' }) => backgroundColor};
  ${p => p.theme.phone} {
    max-width: initial;
    background-color: ${({ mobileBackgroundColor = 'transparent' }) =>
      mobileBackgroundColor};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      max-width: initial;
      background-color: ${p.mobileBackgroundColor};
    `}
`

export default SectionContainerUi
