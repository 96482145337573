import styled, { css } from 'styled-components'
import { PaddingType } from 'common/types/styleTypes'
import { mobilePaddingsCss, paddingsCss } from 'common/utils/styleUtilsNew'

interface ContentBoxInnerUiProps {
  padding?: PaddingType
  mobilePadding?: PaddingType
}

const ContentBoxInnerUi = styled.div<ContentBoxInnerUiProps>`
  ${paddingsCss}
  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobilePaddingsCss}
    `}
`

export default ContentBoxInnerUi
