import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonSection from 'common/components/entities/Section/SectionOld'
import * as types from 'common/types'
import { splitMarginsAndRestStyles } from 'common/utils/styleUtils'
import useFiles, { selectors as filesSelectors } from 'publisher/hooks/useFiles'
import { useDelay } from '../../../hooks/useDelay'
import BaseNodeChildren from '../../ChildrenEntities'

const SectionOld = ({ entity }) => {
  const { options, childIds, mobileOptions } = entity
  const isVisible = useDelay(entity.options.delay)
  const backgroundFile = useFiles(files =>
    filesSelectors.getFile(files, options.backgroundFileId),
  )
  const mobileBackgroundFile = useFiles(files =>
    filesSelectors.getFile(files, mobileOptions.backgroundFileId),
  )
  const calculatedMobileBackgroundFile =
    typeof mobileOptions.backgroundFileId === 'undefined'
      ? backgroundFile
      : mobileBackgroundFile
  const [margins, styles] = splitMarginsAndRestStyles(entity.styles)
  const [mobileMargins, mobileStyles] = splitMarginsAndRestStyles(
    entity.mobileStyles,
  )
  const {
    position: mobilePosition,
    top: mobileTop,
    bottom: mobileBottom,
    zIndex: mobileZIndex,
    ...restMobileStyles
  } = mobileStyles
  const { position, top, bottom, zIndex, ...restStyles } = styles
  const { appearance = {} } = options
  const { desktop = true, mobile = true } = appearance
  // const stretchStyles = restProps.options.stretch ? { height: '100%' } : {}
  return isVisible ? (
    <StyleWrapper
      mobile={mobile}
      desktop={desktop}
      styles={{
        position,
        top,
        bottom,
        zIndex: zIndex,
        ...margins,
        // ...stretchStyles,
      }}
      mobileStyles={{
        position: mobilePosition,
        top: mobileTop,
        bottom: mobileBottom,
        zIndex: mobileZIndex,
        ...mobileMargins,
      }}
      id={entity.options.attrId}
    >
      <CommonSection
        options={options}
        mobileOptions={mobileOptions}
        styles={restStyles}
        backgroundFile={backgroundFile}
        mobileBackgroundFile={calculatedMobileBackgroundFile}
        mobileStyles={restMobileStyles}
      >
        <BaseNodeChildren childIds={childIds} />
      </CommonSection>
    </StyleWrapper>
  ) : (
    <div />
  )
}

SectionOld.propTypes = {
  entity: types.entity.isRequired,
}

export default SectionOld
