import {
  FINISH_SURVEY,
  SUBMIT_RECAPTCHA,
  OPT_IN_RECAPTCHA_VALIDATION_FAILED,
} from 'publisher/actionTypes'
import {
  OptInRecaptchaErrorInterface,
  SubmitRecaptchaPayloadInterface,
  SurveyResult,
} from './optInStateInterface'

export function finishSurvey(results: Record<string, SurveyResult>) {
  return {
    type: FINISH_SURVEY,
    payload: results,
  }
}

export function submitRecaptcha(token: SubmitRecaptchaPayloadInterface) {
  return {
    type: SUBMIT_RECAPTCHA,
    payload: token,
  }
}

export function optInRecaptchaValidationFailed(
  error: OptInRecaptchaErrorInterface,
) {
  return {
    type: OPT_IN_RECAPTCHA_VALIDATION_FAILED,
    payload: error,
  }
}
