import PropTypes from 'prop-types'
import React from 'react'
import CommonLanguageSwitcher from 'common/components/entities/LanguageSwitcher'
import usePage, { selectors } from '../../hooks/usePage'

function LanguageSwitcher({ entity }) {
  const locale = usePage(selectors.getLocale)

  return (
    <CommonLanguageSwitcher
      currentLanguage={locale}
      styles={entity.styles}
      mobileStyles={entity.mobileStyles}
      redirectPath={entity.options.redirectPath}
      excludedLanguages={entity.options.excludedLanguages}
    />
  )
}

LanguageSwitcher.propTypes = {
  entity: PropTypes.shape({
    options: PropTypes.shape({
      redirectPath: PropTypes.string,
      excludedLanguages: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    styles: PropTypes.objectOf(PropTypes.string),
    mobileStyles: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default LanguageSwitcher
