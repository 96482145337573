import styled, { css } from 'styled-components'
import { AlignSelfType } from 'common/types/styleTypes'
import { alignSelfCss, mobileAlignSelfCss } from 'common/utils/styleUtilsNew'

type CarouselSlideUiProps = {
  active: boolean
  alignSelf?: AlignSelfType
  mobileAlignSelf?: AlignSelfType
}

const CarouselSlideUi = styled.div<CarouselSlideUiProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  //in this case we make sure that slide is take 100% of the screen width and not more
  width: 0;
  min-width: 100%;
  scroll-snap-align: start;
  //to prevent trigger isIntersecting in IntersectionObserver with blank slides
  min-height: 1px;
  ${alignSelfCss}
  ${p => p.theme.phone} {
    ${mobileAlignSelfCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileAlignSelfCss};
    `}
`

export default CarouselSlideUi
