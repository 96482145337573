import React, { useEffect, useState } from 'react'
import Alert from 'common/components/core/Alert'
import {
  getNotificationCookie,
  removeNotificationCookie,
} from 'publisher/utils/cookie'

function Notification() {
  const [notification, setNotification] = useState('')

  useEffect(() => {
    const message = getNotificationCookie()
    if (message) {
      setNotification(message)
    }
    removeNotificationCookie()
  }, [])

  return notification ? (
    <Alert type="error" onClose={removeNotificationCookie}>
      {notification}
    </Alert>
  ) : null
}

export default Notification
