import PropTypes from 'prop-types'
import React from 'react'
import { useDelay } from 'publisher/hooks/useDelay'
import useWebinar, { selectors } from 'publisher/hooks/useWebinar'
import BaseButton from './BaseButton'

export const TEST_ID = 'webinar-call-to-action'

function WebinarCallToAction({ entity }) {
  const { styles, mobileStyles, options, mobileOptions } = entity
  const sessionDelayInSeconds = useWebinar(selectors.getSessionDelayInSeconds)
  const isVisible = useDelay(
    (options.delay ? +options.delay : 0) + sessionDelayInSeconds,
  )
  const handleClick = () => {
    if (options.url) {
      window.location.assign(options.url)
    }
  }

  return (
    isVisible && (
      <BaseButton
        dataTestId={TEST_ID}
        attrId={options.attrId}
        handleClick={handleClick}
        styles={styles}
        mobileStyles={mobileStyles}
        borderType={options.borderType}
        text={options.text}
        mobileBorderType={mobileOptions.borderType}
        appearance={options.appearance}
      />
    )
  )
}

WebinarCallToAction.propTypes = {
  entity: PropTypes.shape({
    options: PropTypes.shape({
      attrId: PropTypes.string.isRequired,
      delay: PropTypes.number,
      borderType: PropTypes.string.isRequired,
      url: PropTypes.string,
      text: PropTypes.string.isRequired,
      appearance: PropTypes.shape({
        desktop: PropTypes.bool.isRequired,
        mobile: PropTypes.bool.isRequired,
      }).isRequired,
    }),
    mobileOptions: PropTypes.shape({
      borderType: PropTypes.string,
    }),
    mobileStyles: PropTypes.objectOf(PropTypes.string),
    styles: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default WebinarCallToAction
