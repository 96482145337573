export enum ButtonActionTypeEnum {
  SendForm = 'sendForm',
  ShowPopup = 'showPopup',
  OpenUrl = 'openUrl',
  NextStepRedirection = 'nextStepRedirection',
  LectureCompleteAndRedirect = 'lectureCompleteAndRedirect',
  ContactUs = 'contactUs',
  Login = 'login',
  ResetPassword = 'resetPassword',
  ResetRequest = 'resetRequest',
  ConfirmRegistration = 'confirmRegistration',
}
