import styled from 'styled-components'
import { colorCss, mobileColorCss } from 'common/utils/styleUtilsNew'

const BlogPostCategoryLinkNewUi = styled.a<{
  color: string
  mobileColor?: string
}>`
  text-decoration: none;
  ${colorCss};
  ${p => p.theme.isEditorMobileMode && mobileColorCss}
  ${p => p.theme.phone} {
    ${mobileColorCss}
  }
`

export default BlogPostCategoryLinkNewUi
