import React, { useState } from 'react'
import { wrapperStyles } from 'common/components/entities/Button'
import { MobileMenuTypesEnum } from 'common/components/entities/Menu/enums/MobileMenuTypesEnum'
import MenuInterface from 'common/components/entities/Menu/types/MenuInterface'
import StyleWrapper from '../../../common/components/StyleWrapper'
import * as CommonMenu from '../../../common/components/entities/Menu'

function Menu({ entity }: { entity: MenuInterface }) {
  const [isOpened, setOpened] = useState(false)

  const isSideMenu = entity.mobileMenuType === MobileMenuTypesEnum.side
  const isDropdownMenu = entity.mobileMenuType === MobileMenuTypesEnum.dropDown

  return (
    <StyleWrapper
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
      styles={{ ...entity.margin, ...wrapperStyles }}
      mobileStyles={entity.mobileMargin}
    >
      <CommonMenu.Horizontal
        attrId={entity.htmlAttrId}
        items={entity.menuItems}
        isVisibleOnMobile={!entity.burger}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        spacing={entity.spacing}
        mobileSpacing={entity.mobileSpacing}
        activeSubmenuColor={entity.activeSubmenuColor}
        subMenuSpacing={entity.subMenuSpacing}
        fontSize={entity.fontSize}
        fontFamily={entity.fontFamily}
        fontWeight={entity.fontWeight}
        fontStyle={entity.fontStyle}
        mobileFontSize={entity.mobileFontSize}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontWeight={entity.mobileFontWeight}
        mobileFontStyle={entity.mobileFontStyle}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        color={entity.color}
        mobileColor={entity.mobileColor}
        subMenuColor={entity.subMenuColor}
        activeItemColor={entity.activeColor}
        mobileActiveItemColor={entity.mobileActiveColor}
        itemUnderlineColor={entity.itemUnderlineColor}
        mobileItemUnderlineColor={entity.mobileItemUnderlineColor}
      />
      {entity.burger && (
        <CommonMenu.Burger
          burgerIconColor={entity.burgerIconColor}
          burgerAlignSelf={entity.burgerAlignSelf}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          opened={isOpened}
          handleClick={() => setOpened(!isOpened)}
          openedColor={entity.mobileColor || entity.color}
          fixed={isSideMenu && isOpened}
        />
      )}
      {isSideMenu && (
        <CommonMenu.Side
          attrId={entity.htmlAttrId}
          menuAttrId={`side-${entity.htmlAttrId}`}
          items={entity.menuItems}
          opened={isOpened}
          alignSelf={entity.alignSelf}
          mobileAlignSelf={entity.mobileAlignSelf}
          sideMenuBackgroundColor={entity.sideMenuBackgroundColor}
          itemUnderlineColor={entity.itemUnderlineColor}
          mobileItemUnderlineColor={entity.mobileItemUnderlineColor}
          fontSize={entity.fontSize}
          mobileFontSize={entity.mobileFontSize}
          fontFamily={entity.fontFamily}
          fontWeight={entity.fontWeight}
          fontStyle={entity.fontStyle}
          mobileFontFamily={entity.mobileFontFamily}
          mobileFontWeight={entity.mobileFontWeight}
          mobileFontStyle={entity.mobileFontStyle}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          color={entity.color}
          spacing={entity.mobileSpacing}
          subMenuSpacing={entity.mobileSubMenuSpacing}
          mobileColor={entity.mobileColor}
          activeColor={entity.activeColor}
          mobileActiveColor={entity.mobileActiveColor}
        />
      )}
      {isDropdownMenu && isOpened && (
        <CommonMenu.DropDown
          attrId={entity.htmlAttrId}
          items={entity.menuItems}
          alignSelf={entity.alignSelf}
          mobileAlignSelf={entity.mobileAlignSelf}
          itemUnderlineColor={entity.itemUnderlineColor}
          mobileItemUnderlineColor={entity.mobileItemUnderlineColor}
          fontSize={entity.fontSize}
          mobileFontSize={entity.mobileFontSize}
          fontFamily={entity.fontFamily}
          fontWeight={entity.fontWeight}
          fontStyle={entity.fontStyle}
          spacing={entity.mobileSpacing}
          subMenuSpacing={entity.mobileSubMenuSpacing}
          mobileFontFamily={entity.mobileFontFamily}
          mobileFontWeight={entity.mobileFontWeight}
          mobileFontStyle={entity.mobileFontStyle}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          color={entity.color}
          mobileColor={entity.mobileColor}
          activeColor={entity.activeColor}
          mobileActiveColor={entity.mobileActiveColor}
        />
      )}
    </StyleWrapper>
  )
}

export default Menu
