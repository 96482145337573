import React from 'react'
import { BorderType } from '../../../types/styleTypes'
import SurveyAnswerLabelUi from './ui/SurveyAnswerLabelUi'
import SurveyAnswerRadioButtonUi from './ui/SurveyAnswerRadioButtonUi'
import SurveyAnswerTextUi from './ui/SurveyAnswerTextUi'
import SurveyAnswerUi from './ui/SurveyAnswerUi'

type SurveyAnswerProps = {
  answer: {
    title: string
    id: string
  }
  fontSize: number
  mobileFontSize?: number
  color: string
  select: () => void
  backgroundColor: string
  outlineColor: string
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
}

function SurveyAnswer({
  answer,
  fontSize,
  mobileFontSize,
  color,
  select,
  backgroundColor,
  outlineColor,
  border,
  mobileBorder,
}: SurveyAnswerProps) {
  return (
    <SurveyAnswerUi
      fontSize={fontSize}
      mobileFontSize={mobileFontSize}
      color={color}
      backgroundColor={backgroundColor}
      borderColor={outlineColor}
      border={border}
      mobileBorder={mobileBorder}
      onClick={select}
    >
      <SurveyAnswerLabelUi htmlFor={`answer-${answer.id}`}>
        <SurveyAnswerTextUi>{answer.title}</SurveyAnswerTextUi>
        <SurveyAnswerRadioButtonUi
          className="answer-radio"
          outlineColor={outlineColor}
        />
      </SurveyAnswerLabelUi>
    </SurveyAnswerUi>
  )
}

export default SurveyAnswer
