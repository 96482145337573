import styled from 'styled-components'

const TooltipIconUi = styled.i`
  color: #9ba9b3;
  margin-left: 5px;
  transition: color .1s ease;
  cursor: pointer;
  &:hover {
    color: #149efc;
  }
`

export default TooltipIconUi
