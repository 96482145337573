import { useEffect, useState } from 'react'
import { optInSelectors, useTypedSelector } from 'publisher/store'
import { useTranslation } from 'react-i18next';

export const useCalendarErrors = () => {
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [isErrorsVisible, setIsErrorsVisible] = useState(false)
  const selectedDate = useTypedSelector(state =>
    optInSelectors.getBookingFieldValue(state.optIn, 'date'),
  )
  const selectedTimeSlot = useTypedSelector(state =>
    optInSelectors.getBookingFieldValue(state.optIn, 'timeSlot'),
  )
  const { t } = useTranslation()

  const validate = () => {
    let updatedErrors: typeof errors = {}
    if (!selectedDate && !(selectedDate in errors)) {
      updatedErrors = {
        selectedDate: t('entities.calendar.date_field_not_empty'),
      }
    }
    if (
      typeof selectedTimeSlot !== 'number' &&
      !(selectedTimeSlot in errors)
    ) {
      updatedErrors = {
        ...updatedErrors,
        timeSlot: t('entities.calendar.time_slot_field_not_empty'),
      }
    }
    setErrors(updatedErrors)
  }

  useEffect(() => {
    validate()
  }, [selectedDate, selectedTimeSlot])

  return { errors: Object.values(errors), isErrorsVisible, setIsErrorsVisible }
}
