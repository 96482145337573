import styled, { css } from 'styled-components'
import { AlignType } from 'common/types/styleTypes'
import {
  colorCss,
  fontCss,
  lineHeightCss,
  mobileColorCss,
  mobileFontCss,
  mobileLineHeightCss,
  mobileTextAlignCss,
  mobileTextCss,
  textAlignCss,
  textCss,
} from 'common/utils/styleUtilsNew'

export type CheckboxUiProps = {
  textAlign: AlignType
  mobileAlignSelf?: AlignType
}

const CheckboxUi = styled.div<CheckboxUiProps>`
${textCss}
${fontCss}
${colorCss}
${lineHeightCss}
${textAlignCss}

${p => p.theme.phone} {
  ${mobileTextCss}
  ${mobileFontCss}
  ${mobileColorCss}
  ${mobileLineHeightCss}
  ${mobileTextAlignCss}
}

${p =>
  p.theme.isEditorMobileMode &&
  css`
  ${mobileTextCss}
  ${mobileFontCss}
  ${mobileColorCss}
  ${mobileLineHeightCss}
  ${mobileTextAlignCss}
`}
`

export default CheckboxUi
