export enum BackgroundTypeEnum {
  Default = 'Default',
  Fixed = 'Fixed',
  Repeat = 'Repeat',
  Width100 = 'Width100',
  Width100Height100 = 'Width100Height100',
  ScalesWithoutCropping = 'ScalesWithoutCropping',
}

export enum BackgroundPositionTypeEnum {
  TopLeft = 'TopLeft',
  TopCenter = 'TopCenter',
  TopRight = 'TopRight',
  CenterLeft = 'CenterLeft',
  CenterCenter = 'CenterCenter',
  CenterRight = 'CenterRight',
  BottomLeft = 'BottomLeft',
  BottomCenter = 'BottomCenter',
  BottomRight = 'BottomRight',
}
