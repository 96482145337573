import { AxiosResponse } from 'axios'

type BadRequestResponseData = {
  errors: ServerErrors
}

export type ServerErrors = {
  common: string[]
  fields: Record<string, string[]>
}

export class BadRequest extends Error {
  public response: AxiosResponse<BadRequestResponseData>
  constructor(response: AxiosResponse<BadRequestResponseData>) {
    super()
    this.response = response
    this.name = this.constructor.name
  }
}
