import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import Countdown from 'common/components/entities/Countdown'
import Timer from 'common/components/entities/Timer/TimerOld'
import * as types from 'common/types'
import { change } from 'publisher/actions/optInActions'
import {
  getWebinarStartTimeCookie,
  setWebinarStartTimeCookie,
} from 'publisher/utils/cookie'

class StartTimer extends PureComponent {
  constructor(props) {
    super(props)
    const {
      pageId,
      entity: {
        options: { hours = 0, minutes = 0, seconds = 0 },
      },
    } = props
    const time =
      this.getNotExpiredStoredStartTime(pageId, hours, minutes, seconds) ||
      this.storeStartTime(pageId, hours, minutes, seconds)

    this.state = { time }
  }

  getNotExpiredStoredStartTime = (pageId, hours, minutes, seconds) => {
    const startTime = getWebinarStartTimeCookie(
      pageId,
      `${hours}_${minutes}_${seconds}`,
    )
    return startTime > Date.now() && +startTime
  }

  generateStartTime = (hours, minutes, seconds) => {
    const startDate = new Date()
    startDate.setHours(startDate.getHours() + Number(hours))
    startDate.setMinutes(startDate.getMinutes() + Number(minutes))
    startDate.setSeconds(startDate.getSeconds() + Number(seconds))

    return startDate.getTime()
  }

  storeStartTime = (pageId, hours, minutes, seconds) => {
    const time = this.generateStartTime(hours, minutes, seconds)
    setWebinarStartTimeCookie(pageId, time, `${hours}_${minutes}_${seconds}`)
    this.props.change('timerDatetime', time)
    return time
  }

  restart = (pageId, hours, minutes, seconds) => () => {
    const time = this.storeStartTime(pageId, hours, minutes, seconds)
    this.setState({ time })
  }

  render() {
    const {
      pageId,
      entity: {
        options: {
          hours: initHours,
          minutes: initMinutes,
          seconds: initSeconds,
          labelStyles,
          timeStyles,
          appearance,
        },
        mobileOptions: {
          labelStyles: mobileLabelStyles,
          timeStyles: mobileTimeStyles,
        },
        styles,
      },
    } = this.props
    const { time } = this.state
    if (!time) {
      return <div />
    }

    return (
      <StyleWrapper desktop={appearance.desktop} mobile={appearance.mobile}>
        <Countdown
          time={time}
          onTimeEndHandler={this.restart(
            pageId,
            initHours,
            initMinutes,
            initSeconds,
          )}
        >
          {({ hours, minutes, seconds }) => (
            <Timer
              wrapperStyles={styles}
              labelStyles={labelStyles}
              mobileLabelStyles={mobileLabelStyles}
              timeStyles={timeStyles}
              mobileTimeStyles={mobileTimeStyles}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
            />
          )}
        </Countdown>
      </StyleWrapper>
    )
  }
}

StartTimer.propTypes = {
  entity: types.entity.isRequired,
  pageId: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
}

const mapDispatchToProps = { change }

const mapStateToProps = ({ page: { id: pageId } }) => ({
  pageId,
})

export default connect(mapStateToProps, mapDispatchToProps)(StartTimer)
