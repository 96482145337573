import axios from 'axios'
import {
  createRequestInterceptor,
  createResponseInterceptor,
} from 'common/utils/registerAxiosInterceptors'

export function initialiseAxiosInterceptors() {
  createRequestInterceptor(axios)
  createResponseInterceptor(axios)
}
