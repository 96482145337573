import React from 'react'
import { useTranslation } from 'react-i18next'
import { AvailabilitySlot } from '../../calendar-interface'
import { isDatesEqual, formatDate, secondsToHours } from '../../utils'
import { TimeSlotListUi } from './ui/time-slot-list-ui'
import { TimeSlotButtonUi } from './ui/time-slots-button-ui'
import { TimeSlotsWrapperUi } from './ui/time-slots-wrapper-ui'

interface TimeSlotsProps {
  availabilitySlots: AvailabilitySlot[]
  selectedTimeSlot?: number
  onTimeSlotSelect?: (timeSlot: number) => void
  selectedSlotBackgroundColor: string
  selectedDate: Date
  titlesColor: string
}

export function TimeSlots({
  availabilitySlots,
  selectedTimeSlot,
  onTimeSlotSelect,
  selectedSlotBackgroundColor,
  selectedDate,
  titlesColor,
}: TimeSlotsProps) {
  const { t } = useTranslation()
  const selectedAvailability = availabilitySlots.find(({ date }) =>
    isDatesEqual(date, selectedDate),
  )

  return (
    <TimeSlotsWrapperUi>
      <span style={{ color: titlesColor }}>{formatDate(selectedDate, t)}</span>
      <TimeSlotListUi>
        {selectedAvailability?.spots.map(({ startTime }) => (
          <TimeSlotButtonUi
            key={startTime}
            selectedBackgroundColor={selectedSlotBackgroundColor}
            onClick={() => onTimeSlotSelect?.(startTime)}
            disabled={!onTimeSlotSelect}
            isSelected={
              !!selectedAvailability &&
              isDatesEqual(selectedDate, selectedAvailability?.date) &&
              selectedTimeSlot === startTime
            }
            type="button"
          >
            {secondsToHours(startTime)}
          </TimeSlotButtonUi>
        ))}
      </TimeSlotListUi>
    </TimeSlotsWrapperUi>
  )
}
