import React from 'react'
import ReactPlayer from 'react-player/lazy'
import BasePlayer from 'publisher/components/entities/Video/BasePlayer'
import { filesSelectors, useFiles } from 'publisher/store/index'
import { VideoInterface } from '../../../../common/types/entities/VideoInterface'
import StyleWrapper from '../../../components/core/StyleWrapper'
import VideoScreenshotPlaceholderUi from '../../../components/entities/Video/ui/VideoScreenshotPlaceholderUi'
import { useDelay } from '../../../hooks/useDelay'
import useWebinarSession, {
  selectors as webinarSessionSelectors,
} from '../../../hooks/useWebinarSession'
import { managementSelectors, useManagement } from '../../../store/index'

function WebinarSessionVideoNew({ entity }: { entity: VideoInterface }) {
  const isVisible = useDelay(entity.delay)
  const isScreenshotMode = useManagement(managementSelectors.isScreenshotMode)
  const sessionDateTime = useWebinarSession(webinarSessionSelectors.getDateTime)
  const file = useFiles(files => filesSelectors.getFile(files, entity.fileId))
  const startSeconds = Math.round(
    (Date.now() - sessionDateTime.getTime()) / 1000,
  )
  const replay =
    typeof document === 'undefined'
      ? false
      : Boolean(new URLSearchParams(document.location.search).get('replay'))

  if (isScreenshotMode) {
    return (
      <VideoScreenshotPlaceholderUi
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
      />
    )
  }

  const onReady = (seconds: number) => (player: ReactPlayer) => {
    const internalPlayer = player.getInternalPlayer()
    const isPlaying =
      player.getCurrentTime() > 0 &&
      !internalPlayer.paused &&
      !internalPlayer.ended &&
      internalPlayer.readyState > 2
    if (!replay && !isPlaying) {
      player.seekTo(seconds, 'seconds')
    }
  }

  let Player
  if (entity.url) {
    Player = (
      <BasePlayer
        attrId={entity.htmlAttrId}
        autoplay
        url={entity.url}
        controls={replay}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        onReady={onReady(startSeconds)}
      />
    )
  } else if (entity.fileId && file) {
    Player = (
      <BasePlayer
        attrId={entity.htmlAttrId}
        autoplay
        url={file.path}
        controls={replay}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        onReady={onReady(startSeconds)}
      />
    )
  } else {
    Player = <div />
  }

  if (!isVisible) {
    return (
      <StyleWrapper
        mobileMargin={entity.mobileMargin}
        margin={entity.margin}
        appearance={{ desktop: false, mobile: false }}
      />
    )
  }

  return Player
}

export default WebinarSessionVideoNew
