import styled from 'styled-components'
import { BorderType, PaddingType } from 'common/types/styleTypes'
import {
  containerCss,
  fontCss,
  mobileFontCss,
} from 'common/utils/styleUtilsNew'

type WebinarSessionDateTimeUiProps = {
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
  fontSize: number
  boxShadow?: string
  padding: PaddingType
  backgroundColor?: string
  color?: string
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  mobileColor?: string
  mobileBackgroundColor?: string
  mobilePadding: PaddingType
  mobileBoxShadow?: string
  mobileFontSize?: number
  mobileFontFamily?: string
  mobileFontStyle?: string
  mobileFontWeight?: string
}
const WebinarSessionDateTimeUi = styled.div<WebinarSessionDateTimeUiProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${containerCss}
  ${fontCss}
  ${p => p.theme.phone} {
    ${mobileFontCss}
  }
  ${p => p.theme.isEditorMobileMode && mobileFontCss}
`

export default WebinarSessionDateTimeUi
