import styled from 'styled-components'

// we need it when the text is multiline
const addTextAlignBaseOnJustifyContent = styles => {
  let textAlign
  if (styles.justifyContent) {
    switch (styles.justifyContent) {
      case 'flex-start':
        textAlign = 'left'
        break
      case 'flex-end':
        textAlign = 'right'
        break
      case 'center':
        textAlign = 'center'
        break
      default:
        textAlign = 'left'
    }
  }

  return {
    ...styles,
    textAlign,
  }
}

const ContentBoxHeaderOldUi = styled.div`
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  min-height: 50px;
  //to prevent spacing between parent and this block while zooming in the browsers
  margin: -1px;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => addTextAlignBaseOnJustifyContent(mobileStyles)}
  }
  ${({ styles = {} }) => addTextAlignBaseOnJustifyContent(styles)}
`

export default ContentBoxHeaderOldUi
