import React from 'react'
import Pagination from 'common/components/core/PaginationNew'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import {
  BlogPost,
  CommonBlogPostListingProps,
} from 'common/types/entities/BlogPostListingInterface'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { LayoutType } from 'client/components/core/Sidebar/components/Settings/components/PostListingLayoutSetting/utils'
import BlogPosts from '../BlogPostsNew'
import BlogPostListingUiNew from './ui/BlogPostListingUiNew'

type BlogPostListingsProps = {
  blogPath?: string
  htmlAttrId: string
  posts: BlogPost[]
  locale: PageLocaleEnum
  paginationCurrent: number
  paginationTotal: number
  imagePlacement?: ImagePlacementVariants
  mobileImagePlacement?: ImagePlacementVariants
  layoutType?: LayoutType
  isMobile: boolean
} & CommonBlogPostListingProps

function BlogPostListings(props: BlogPostListingsProps) {
  return (
    <BlogPostListingUiNew
      id={props.htmlAttrId}
      padding={props.padding}
      mobilePadding={props.mobilePadding}
    >
      <BlogPosts
        imagePlacement={props.imagePlacement}
        mobileImagePlacement={props.mobileImagePlacement}
        isMobile={props.isMobile}
        imageMargin={props.imageMargin}
        layoutType={props.layoutType}
        mobileImageMargin={props.mobileImageMargin}
        titleMargin={props.titleMargin}
        mobileTitleMargin={props.mobileTitleMargin}
        categoriesMargin={props.categoriesMargin}
        mobileCategoriesMargin={props.mobileCategoriesMargin}
        descriptionMargin={props.descriptionMargin}
        mobileDescriptionMargin={props.mobileDescriptionMargin}
        dateMargin={props.dateMargin}
        mobileDateMargin={props.mobileDateMargin}
        displayHorizontally={props.displayHorizontally}
        showDate={props.showDate}
        imageBorder={props.imageBorder}
        mobileImageBorder={props.mobileImageBorder}
        imageBoxShadow={props.imageBoxShadow}
        mobileImageBoxShadow={props.mobileImageBoxShadow}
        titleFontSize={props.titleFontSize}
        mobileTitleFontSize={props.mobileTitleFontSize}
        titleLineHeight={props.titleLineHeight}
        mobileTitleLineHeight={props.mobileTitleLineHeight}
        titleFontFamily={props.titleFontFamily}
        titleFontWeight={props.titleFontWeight}
        titleFontStyle={props.titleFontStyle}
        mobileTitleFontFamily={props.mobileTitleFontFamily}
        mobileTitleFontWeight={props.mobileTitleFontWeight}
        mobileTitleFontStyle={props.mobileTitleFontStyle}
        titleLetterSpacing={props.titleLetterSpacing}
        mobileTitleLetterSpacing={props.mobileTitleLetterSpacing}
        titleAlign={props.titleAlign}
        mobileTitleAlign={props.mobileTitleAlign}
        titleColor={props.titleColor}
        mobileTitleColor={props.mobileTitleColor}
        descriptionFontSize={props.descriptionFontSize}
        mobileDescriptionFontSize={props.mobileDescriptionFontSize}
        descriptionLineHeight={props.descriptionLineHeight}
        mobileDescriptionLineHeight={props.mobileDescriptionLineHeight}
        descriptionFontFamily={props.descriptionFontFamily}
        descriptionFontWeight={props.descriptionFontWeight}
        descriptionFontStyle={props.descriptionFontStyle}
        mobileDescriptionFontFamily={props.mobileDescriptionFontFamily}
        mobileDescriptionFontWeight={props.mobileDescriptionFontWeight}
        mobileDescriptionFontStyle={props.mobileDescriptionFontStyle}
        descriptionAlign={props.descriptionAlign}
        mobileDescriptionAlign={props.mobileDescriptionAlign}
        descriptionColor={props.descriptionColor}
        mobileDescriptionColor={props.mobileDescriptionColor}
        categoriesFontSize={props.categoriesFontSize}
        mobileCategoriesFontSize={props.mobileCategoriesFontSize}
        categoriesFontFamily={props.categoriesFontFamily}
        categoriesFontWeight={props.categoriesFontWeight}
        categoriesFontStyle={props.categoriesFontStyle}
        mobileCategoriesFontFamily={props.mobileCategoriesFontFamily}
        mobileCategoriesFontWeight={props.mobileCategoriesFontWeight}
        mobileCategoriesFontStyle={props.mobileCategoriesFontStyle}
        categoriesAlign={props.categoriesAlign}
        mobileCategoriesAlign={props.mobileCategoriesAlign}
        categoriesColor={props.categoriesColor}
        mobileCategoriesColor={props.mobileCategoriesColor}
        dateFontSize={props.dateFontSize}
        mobileDateFontSize={props.mobileDateFontSize}
        dateFontFamily={props.dateFontFamily}
        dateFontWeight={props.dateFontWeight}
        dateFontStyle={props.dateFontStyle}
        mobileDateFontFamily={props.mobileDateFontFamily}
        mobileDateFontWeight={props.mobileDateFontWeight}
        mobileDateFontStyle={props.mobileDateFontStyle}
        dateAlign={props.dateAlign}
        mobileDateAlign={props.mobileDateAlign}
        dateColor={props.dateColor}
        mobileDateColor={props.mobileDateColor}
        posts={props.posts}
        rowSize={props.maxPostsPerRow}
        blogPath={props.blogPath}
        locale={props.locale}
        rowsGap={props.rowsGap}
        columnsGap={props.columnsGap}
        postCard={props.postCard}
      />
      <Pagination
        current={props.paginationCurrent}
        total={props.paginationTotal}
        paginationActiveColor={props.paginationActiveColor}
        mobilePaginationActiveColor={props.mobilePaginationActiveColor}
        paginationActiveBackgroundColor={props.paginationActiveBackgroundColor}
        mobilePaginationActiveBackgroundColor={
          props.mobilePaginationActiveBackgroundColor
        }
        paginationInactiveColor={props.paginationInactiveColor}
        mobilePaginationInactiveColor={props.mobilePaginationInactiveColor}
        paginationInactiveBackgroundColor={
          props.paginationInactiveBackgroundColor
        }
        mobilePaginationInactiveBackgroundColor={
          props.mobilePaginationInactiveBackgroundColor
        }
      />
    </BlogPostListingUiNew>
  )
}

export default BlogPostListings
