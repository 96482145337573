import React from 'react'
import { useDispatch } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonExplicitConsent from 'common/components/entities/ExplicitConsent'
import CommonText from 'common/components/entities/Text'
import * as types from 'common/types'
import { splitMarginsAndPaddingAndRestStyles } from 'common/utils/styleUtils'
import { toggleExplicitConsent } from 'publisher/actionsManagement'
import useManagement, {
  selectors as managementSelectors,
} from 'publisher/hooks/useManagement'
import usePage, { selectors as pageSelectors } from '../../hooks/usePage'
import { usePopupOpenerRef } from '../../hooks/usePopupOpenerRef'

function ExplicitConsent({ entity }) {
  const ref = usePopupOpenerRef()
  const linkColor = usePage(pageSelectors.getGlobalLinkColor)
  const textColor = usePage(pageSelectors.getGlobalTextColor)
  const textFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const mobileTextFontSize = usePage(pageSelectors.getGlobalMobileTextFontSize)
  const textLineHeight = usePage(pageSelectors.getGlobalTextLineHeight)
  const mobileTextLineHeight = usePage(
    pageSelectors.getGlobalMobileTextLineHeight,
  )
  const dispatch = useDispatch()
  const isConfirmed = useManagement(m =>
    managementSelectors.isExplicitConsentConfirmed(m, entity.id),
  )

  const toggleConfirmed = () => dispatch(toggleExplicitConsent(entity.id))
  const { options, styles, mobileStyles } = entity
  const [marginsAndPadding, restStyles] =
    splitMarginsAndPaddingAndRestStyles(styles)
  const [mobileMarginsAndPadding, mobileRestStyles] =
    splitMarginsAndPaddingAndRestStyles(mobileStyles)
  const {
    appearance: { desktop, mobile },
  } = options
  return (
    <StyleWrapper
      desktop={desktop}
      mobile={mobile}
      styles={marginsAndPadding}
      mobileStyles={mobileMarginsAndPadding}
    >
      <CommonExplicitConsent
        options={options}
        styles={restStyles}
        mobileStyles={mobileRestStyles}
        checked={isConfirmed}
        onChangeHandler={toggleConfirmed}
      >
        <CommonText
          htmlAttrId={options.attrId}
          styles={restStyles}
          mobileStyles={mobileRestStyles}
          linkColor={linkColor}
          fontSize={textFontSize}
          mobileFontSize={mobileTextFontSize}
          lineHeight={textLineHeight}
          mobileLineHeight={mobileTextLineHeight}
          textColor={textColor}
          flexBasis="100%"
        >
          <div dangerouslySetInnerHTML={{ __html: options.html }} ref={ref} />
        </CommonText>
      </CommonExplicitConsent>
    </StyleWrapper>
  )
}

ExplicitConsent.propTypes = {
  entity: types.entity.isRequired,
}

export default ExplicitConsent
