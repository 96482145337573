import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonBlogPostDate from 'common/components/entities/BlogPostDate/BlogPostDateNew'
import { pageTypes } from 'common/constants/pageTypes'
import { BlogPostDateInterface } from 'common/types/entities/BlogPostDateInterface'
import useBlog, { selectors as blogSelectors } from 'publisher/hooks/useBlog'
import useManagement, {
  selectors as managementSelectors,
} from 'publisher/hooks/useManagement'
import { usePage, typedPageSelectors } from 'publisher/store/index'

function BlogPostDateNew({ entity }: { entity: BlogPostDateInterface }) {
  const locale = usePage(typedPageSelectors.getLocale)
  const blogPostDate = useBlog(blogSelectors.getBlogPostDate)
  const isPreview = useManagement(managementSelectors.isPreviewMode)
  const pageType = usePage(typedPageSelectors.getPageType)
  const isScreenshotMode = useManagement(managementSelectors.isScreenshotMode)

  return (
    <StyleWrapper
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonBlogPostDate
        //render current date in post layout preview/screenshot and post date in post preview
        date={
          (isPreview && pageType === pageTypes.blogPostLayout) ||
          isScreenshotMode
            ? new Date()
            : (blogPostDate as Date)
        }
        locale={locale}
        attrId={entity.htmlAttrId}
        fontSize={entity.fontSize}
        fontFamily={entity.fontFamily}
        fontStyle={entity.fontStyle}
        fontWeight={entity.fontWeight}
        mobileFontSize={entity.mobileFontSize}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontWeight={entity.mobileFontWeight}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        color={entity.color}
        mobileColor={entity.mobileColor}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
      />
    </StyleWrapper>
  )
}

export default BlogPostDateNew
