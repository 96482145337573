import React from 'react'
import { BorderType, PaddingType } from 'common/types/styleTypes'
import TextAreaUi from './ui/TextAreaUi'

export default TextAreaUi

// type TextAreaProps = {
//   text: string
//   placeholder?: string
//   height: number
//   attrId: string
//   border: Partial<BorderType>
//   mobileBorder?: Partial<BorderType>
//   padding: PaddingType
//   mobilePadding?: PaddingType
//   backgroundColor: string
//   mobileBackgroundColor?: string
//   boxShadow?: string
//   mobileBoxShadow?: string
//   fontSize?: number
//   mobileFontSize?: number
// }
//
// function TextArea({
//   text,
//   placeholder,
//   height,
//   attrId,
//   border,
//   mobileBorder,
//   padding,
//   mobilePadding,
//   backgroundColor,
//   mobileBackgroundColor,
//   boxShadow,
//   mobileBoxShadow,
//   fontSize,
//   mobileFontSize,
// }: TextAreaProps) {
//   return (
//     <TextAreaUi
//       id={attrId}
//       fontSize={fontSize}
//       mobileFontSize={mobileFontSize}
//       height={height}
//       placeholder={placeholder}
//       border={border}
//       mobileBorder={mobileBorder}
//       padding={padding}
//       mobilePadding={mobilePadding}
//       backgroundColor={backgroundColor}
//       mobileBackgroundColor={mobileBackgroundColor}
//       boxShadow={boxShadow}
//       mobileBoxShadow={mobileBoxShadow}
//     >
//       {text}
//     </TextAreaUi>
//   )
// }
//
// export default TextArea
