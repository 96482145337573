import styled from 'styled-components'
import { textCss, mobileTextCss, TextProps } from 'common/utils/styleUtilsNew'

const LanguageSwitcherCurrentLangUi = styled.div<TextProps>`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  ${textCss};
  ${p => p.theme.isEditorMobileMode && mobileTextCss}
  ${p => p.theme.phone} {
    ${mobileTextCss}
  }
  &:after {
    content: '⌄';
    vertical-align: 60%;
    padding: 0 5px 8px 5px;
    cursor: pointer;
  }
`

export default LanguageSwitcherCurrentLangUi
