import PropTypes from 'prop-types'
import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonLink from 'common/components/entities/Button/Link'
import { buttonLinkTypeValues } from 'common/constants/ButtonLinkTypes'
import { borderTypeKeys } from 'common/constants/settings'
import { splitMarginsAndRestStyles } from 'common/utils/styleUtils'
import { pageSelectors, usePage } from 'publisher/store'
import { pageTypes } from '../../../../common/constants/pageTypes'

export const TEST_ID = 'open-url-button'

function OpenUrlButton({ entity }) {
  const { options, mobileOptions, styles, mobileStyles } = entity
  const [margins, restStyles] = splitMarginsAndRestStyles(styles)
  const [mobileMargins, mobileRestStyles] =
    splitMarginsAndRestStyles(mobileStyles)
  const pageType = usePage(pageSelectors.getPageType)

  let target = buttonLinkTypeValues[entity.options.linkTarget]
  if (
    pageType === pageTypes.lecture &&
    target === buttonLinkTypeValues.openInSameWindow
  ) {
    target = '_parent'
  }

  return (
    <StyleWrapper
      styles={{ ...margins, ...wrapperStyles }}
      mobileStyles={mobileMargins}
      desktop={options.appearance.desktop}
      mobile={options.appearance.mobile}
    >
      <CommonLink
        dataTestId={TEST_ID}
        attrId={options.attrId}
        styles={restStyles}
        mobileStyles={mobileRestStyles}
        borderType={options.borderType}
        mobileBorderType={mobileOptions.borderType}
        subText={options.subText}
        hoverStyles={{
          textColor: options?.hover?.textColor,
          backgroundColor: options?.hover?.backgroundColor,
          subTextColor: options?.hover?.subTextColor,
          transform: options?.hover?.transform,
          offset: options?.hover?.offset,
        }}
        hoverMobileStyles={{
          textColor: mobileOptions?.hover?.textColor,
          backgroundColor: mobileOptions?.hover?.backgroundColor,
          subTextColor: mobileOptions?.hover?.subTextColor,
          transform: mobileOptions?.hover?.transform,
          offset: mobileOptions?.hover?.offset,
        }}
        subTextStyles={{
          color: options.subTextColor,
          fontSize: options.subTextFontSize,
          fontFamily: options.subTextFontFamily,
          fontStyle: options.subTextFontStyle,
          fontWeight: options.subTextFontWeight,
        }}
        subTextMobileStyles={{
          color: mobileOptions.subTextColor,
          fontSize: mobileOptions.subTextFontSize,
          fontFamily: mobileOptions.subTextFontFamily,
          fontStyle: mobileOptions.subTextFontStyle,
          fontWeight: mobileOptions.subTextFontWeight,
        }}
        href={entity.options.linkUrl}
        target={target}
        rel={entity.options.rel}
        iconClassNameBefore={entity.options.iconClassNameBefore}
        iconClassNameAfter={entity.options.iconClassNameAfter}
        asLink
      >
        {entity.options.text}
      </CommonLink>
    </StyleWrapper>
  )
}

OpenUrlButton.propTypes = {
  entity: PropTypes.shape({
    options: PropTypes.shape({
      attrId: PropTypes.string,
      text: PropTypes.string,
      linkUrl: PropTypes.string,
      borderType: PropTypes.oneOf(Object.values(borderTypeKeys)),
      linkTarget: PropTypes.oneOf(Object.keys(buttonLinkTypeValues)),
      rel: PropTypes.string,
      appearance: PropTypes.shape({
        desktop: PropTypes.bool.isRequired,
        mobile: PropTypes.bool.isRequired,
      }).isRequired,
      subText: PropTypes.string,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      iconClassNameBefore: PropTypes.string,
      iconClassNameAfter: PropTypes.string,
    }),
    mobileOptions: PropTypes.shape({
      borderType: PropTypes.string,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      subTextFontWeight: PropTypes.string,
    }),
    mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
    styles: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  dataTestId: PropTypes.string,
}

OpenUrlButton.defaultProps = {
  dataTestId: '',
}

export default OpenUrlButton
