import styled from 'styled-components'

const WebinarCountDownBackground = styled.div`
  height: 100vh;
  width: 100%;
  padding-top: 10%;
  background: rgb(241, 244, 248);
  ${p => p.theme.phone} {
    padding-top: 5%;
  }
`

export default WebinarCountDownBackground
