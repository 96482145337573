import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CommonSurvey, {
  QuestionPropType,
  actions,
} from 'common/components/entities/Survey'
import { finishSurvey } from 'publisher/store/optIn/optInActions'
import { addUrlSchemaToTheLink } from '../../../../common/utils/urlUtils'
import { showPopup } from '../../../actionsManagement'
import BaseNodeChildren from '../../ChildrenEntities'

function Survey({ entity }) {
  const dispatch = useDispatch()
  const [answersIds, setAnswersIds] = useState([])
  const [questionIndex, setQuestionIndex] = useState(0)
  const [showResult, setShowResult] = useState(false)

  function chooseAnswer(answerId) {
    const updatedAnswersIds = [
      ...answersIds.slice(0, questionIndex),
      answerId,
      ...answersIds.slice(questionIndex),
    ]
    setAnswersIds(updatedAnswersIds)
    if (questionIndex + 1 === entity.options.questions.length) {
      setShowResult(true)
      const questionsAnswers = updatedAnswersIds.reduce(
        (acc, answerId, index) => [
          ...acc,
          {
            id: entity.options.questions[index].id,
            answersIds: [answerId],
          },
        ],
        [],
      )
      dispatch(
        finishSurvey({
          [entity.id]: {
            surveyEntityId: entity.id,
            questions: questionsAnswers,
          },
        }),
      )
      if (entity.options.action === actions.openPopup) {
        dispatch(showPopup(entity.options.popupId))
      } else if (entity.options.action === actions.redirect) {
        window.location.assign(
          addUrlSchemaToTheLink(entity.options.redirectUrl),
        )
      }
    } else {
      setQuestionIndex(questionIndex + 1)
    }
  }

  return (
    <CommonSurvey
      styles={entity.styles}
      mobileStyles={entity.mobileStyles}
      question={entity.options.questions[questionIndex]}
      questionTitleFontSize={entity.options.questionTitleFontSize}
      mobileQuestionTitleFontSize={entity.mobileOptions.questionTitleFontSize}
      questionSubTitleFontSize={entity.options.questionSubTitleFontSize}
      mobileQuestionSubTitleFontSize={
        entity.mobileOptions.questionSubTitleFontSize
      }
      answerFontSize={entity.options.answerFontSize}
      mobileAnswerFontSize={entity.mobileOptions.answerFontSize}
      answerColor={entity.options.answerColor}
      answerOutlineColor={entity.options.answerOutlineColor}
      answerBackgroundColor={entity.options.answerBackgroundColor}
      questionTitleColor={entity.options.questionTitleColor}
      questionSubTitleColor={entity.options.questionSubTitleColor}
      answerBorderRadius={entity.options.answerBorderRadius}
      mobileAnswerBorderRadius={entity.mobileOptions.answerBorderRadius}
      showResult={showResult}
      choose={chooseAnswer}
    >
      <BaseNodeChildren childIds={entity.childIds} />
    </CommonSurvey>
  )
}

Survey.propTypes = {
  entity: {
    options: PropTypes.shape({
      questions: PropTypes.arrayOf(QuestionPropType).isRequired,
      questionTitleFontSize: PropTypes.string.isRequired,
      questionSubTitleFontSize: PropTypes.string.isRequired,
      answerFontSize: PropTypes.string.isRequired,
      action: PropTypes.oneOf(Object.keys(actions)).isRequired,
      redirectUrl: PropTypes.string,
      popupId: PropTypes.string,
    }).isRequired,
    styles: PropTypes.shape({
      fontFamily: PropTypes.string,
      padding: PropTypes.string.isRequired,
      marginTop: PropTypes.string.isRequired,
      marginRight: PropTypes.string.isRequired,
      marginBottom: PropTypes.string.isRequired,
      marginLeft: PropTypes.string.isRequired,
    }).isRequired,
    childIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  },
}

export default Survey
