import { PageLocaleEnum } from './PageLocaleEnum'

// ISO 639-1
export enum LanguageCodeEnum {
  FRENCH = 'fr',
  ENGLISH = 'en',
  SPANISH = 'es',
  ITALIAN = 'it',
  PORTUGUESE = 'pt',
  DEUTSCH = 'de',
  DUTCH = 'nl',
  RUSSIAN = 'ru',
  JAPANESE = 'ja',
  ARABIC = 'ar',
  TURKISH = 'tr',
  CHINESE = 'zh',
  SWEDISH = 'sv',
  ROMANIAN = 'ro',
  CZECH = 'cs',
  HUNGARIAN = 'hu',
  SLOVAK = 'sk',
  DANISH = 'da',
  INDONESIAN = 'id',
  POLISH = 'pl',
  GREEK = 'el',
  SERBIAN = 'sr',
  NORWEGIAN = 'nb',
  THAI = 'th',
  SLOVENIAN = 'sl',
  UKRAINIAN = 'uk',
  ALBANIA = 'sq',
  HINDI = 'hi',
}

export function getLanguageCodeByLocale(locale: PageLocaleEnum) {
  const locoKey = (Object.keys(PageLocaleEnum) as Array<keyof typeof PageLocaleEnum>).find(key => PageLocaleEnum[key] === locale)
  return locoKey ? LanguageCodeEnum[locoKey] : LanguageCodeEnum.ENGLISH;
}
