import React from 'react'
import { TwitterTweetButtonInterface } from 'common/types/entities/TwitterTweetButtonInterface'
import TwitterTweetButtonIconUi from './ui/TwitterTweetButtonIconUi'
import TwitterTweetButtonUi from './ui/TwitterTweetButtonUi'
import TwitterTweetButtonWrapperUi from './ui/TwitterTweetButtonWrapperUi'

function TwitterTweetButton({
  text,
  ...restProps
}: Pick<
  TwitterTweetButtonInterface,
  'text' | 'alignSelf' | 'mobileAlignSelf'
>) {
  return (
    <TwitterTweetButtonWrapperUi>
      <TwitterTweetButtonUi
        target="_blank"
        href={`https://twitter.com/intent/tweet?text=${text}`}
        {...restProps}
      >
        <TwitterTweetButtonIconUi />
        <span>Tweet</span>
      </TwitterTweetButtonUi>
    </TwitterTweetButtonWrapperUi>
  )
}

export default TwitterTweetButton
