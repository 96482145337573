import PropTypes from 'prop-types'
import React from 'react'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import Meta from '../../components/core/Meta'
import PageUi from '../ui/PageUi'
import BlogOnExitPopup from './BlogOnExitPopup'
import BlogOnloadPopups from './BlogOnloadPopups'
import BlogTriggeredPopup from './BlogTriggeredPopup'

function BlogPage({ children }) {
  const globalTextFontProperties = usePage(
    pageSelectors.getGlobalFontProperties,
  )
  return (
    <PageUi
      textFontFamily={globalTextFontProperties.fontFamily}
      textFontWeight={globalTextFontProperties.fontWeight}
      textFontStyle={globalTextFontProperties.fontStyle}
    >
      <Meta />
      {children}
      <BlogTriggeredPopup />
      <BlogOnExitPopup />
      <BlogOnloadPopups />
    </PageUi>
  )
}

BlogPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlogPage
