import React from 'react'
import { useDispatch } from 'react-redux'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import CommonRecaptcha from 'publisher/components/core/CommonRecaptcha'
import { useOptIn } from 'publisher/store/'
import { submitRecaptcha } from 'publisher/store/optIn/optInActions'
import { getOptInRecaptchaError } from 'publisher/store/optIn/optInSelectors'

type RecaptchaProps = {
  entity: RecaptchaInterface
}

function OptInRecaptcha({ entity }: RecaptchaProps) {
  const optInRecaptchaError = useOptIn(getOptInRecaptchaError)
  const errors =
    optInRecaptchaError.id === entity.id ? [optInRecaptchaError.error] : []
  const dispatch = useDispatch()

  async function onSubmit(token: string) {
    dispatch(
      submitRecaptcha({
        id: entity.id,
        token: token,
      }),
    )
  }

  return <CommonRecaptcha entity={entity} errors={errors} onSubmit={onSubmit} />
}

export default OptInRecaptcha
