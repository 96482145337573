import styled, { css } from 'styled-components'
import { BorderType } from 'common/types/styleTypes'
import { borderCss, mobileBorderCss } from 'common/utils/styleUtilsNew'

type HorizontalLineUiProps = {
  border: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
}

const HorizontalLineUi = styled.hr<HorizontalLineUiProps>`
  ${borderCss}
  ${p => p.theme.phone} {
    ${mobileBorderCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileBorderCss}
    `}
`

export default HorizontalLineUi
