import axios from 'axios'
import {
  BookingFields,
  SurveysResults,
} from '../store/optIn/optInStateInterface'

type OptInBookingFields = Omit<
  BookingFields,
  'date' | 'endDate' | 'startDate' | 'timeZone' | 'timeSlot'
> & {
  timeSlot: string
}

interface OptInInterface {
  fields: Record<string, any>
  datetime?: string
  timeZone: string
  popupId: string
  isDesktop: boolean
  surveysResults: SurveysResults
  entityId: string
  checkBoxIds: string[]
  captcha?: string
  booking?: OptInBookingFields
  bookingCalendarEntityId?: string
}

export interface OptInArgs {
  fields: Record<string, any>
  datetime?: string
  timeZone: string
  popupId: string
  isDesktop: boolean
  surveysResults: SurveysResults
  entityId: string
  checkBoxIds: string[]
  captcha?: string
  booking?: OptInBookingFields
  bookingCalendarEntityId?: string
}

export const optIn = ({
  fields,
  datetime,
  timeZone,
  popupId,
  isDesktop,
  surveysResults,
  entityId,
  checkBoxIds,
  captcha,
  booking,
  bookingCalendarEntityId,
}: OptInArgs) => {
  const optin: OptInInterface = {
    fields,
    timeZone,
    popupId,
    isDesktop,
    surveysResults,
    entityId,
    checkBoxIds,
  }

  // only in the webinar page
  if (datetime) optin.datetime = datetime

  // only if enabled on the page
  if (captcha) optin.captcha = captcha

  if (booking) {
    optin.booking = { ...booking }
    optin.bookingCalendarEntityId = bookingCalendarEntityId
  }

  return axios.post(location.href, {
    optin,
  })
}
