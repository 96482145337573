import React, { memo } from 'react'
import CommonBulletList from 'common/components/entities/BulletList'
import CommonText from 'common/components/entities/TextNew/ui/TextUi'
import { BulletListInterface } from 'common/types/entities/BulletListInterface'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { usePopupOpenerRef } from 'publisher/hooks/usePopupOpenerRef'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import { useDelay } from '../../hooks/useDelay'

interface NewBulletListProps {
  entity: BulletListInterface
}

function NewBulletList({ entity }: NewBulletListProps) {
  const ref = usePopupOpenerRef()
  const isVisible = useDelay(entity.delay)

  const globalFontFamily = usePage(pageSelectors.getGlobalTextFontFamily)
  const globalLinkColor = usePage(pageSelectors.getGlobalLinkColor)
  const globalTextColor = usePage(pageSelectors.getGlobalTextColor)
  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const mobileGlobalFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalTextLineHeight = usePage(pageSelectors.getGlobalTextLineHeight)
  const globalMobileTextLineHeight = usePage(
    pageSelectors.getGlobalMobileTextLineHeight,
  )

  const fontProperties = usePage(pageSelectors.getGlobalFontProperties)

  const fontFamily = entity.fontFamily || fontProperties.fontFamily

  const mobileFontFamily = entity.mobileFontFamily || fontFamily

  return isVisible ? (
    <StyleWrapper
      mobileMargin={entity.mobileMargin}
      margin={entity.margin}
      appearance={entity.appearance}
    >
      <CommonBulletList
        verticalSpacing={entity.verticalSpacing}
        mobileVerticalSpacing={entity.mobileVerticalSpacing}
        listItemIconColor={entity.listItemIconColor}
        mobileListItemIconColor={entity.mobileListItemIconColor}
        listItemIconSize={entity.listItemIconSize}
        isEditing={false}
      >
        <CommonText
          id={entity.htmlAttrId}
          linkColor={globalLinkColor}
          fontSize={entity.fontSize || globalFontSize}
          fontFamily={fontFamily || globalFontFamily}
          mobileFontFamily={mobileFontFamily}
          mobileFontSize={entity.mobileFontSize || mobileGlobalFontSize}
          lineHeight={entity.lineHeight || globalTextLineHeight}
          mobileLineHeight={
            entity.mobileLineHeight || globalMobileTextLineHeight
          }
          color={entity.color || globalTextColor}
          padding={entity.padding}
          fontStyle={entity.fontStyle}
          mobileFontStyle={entity.mobileFontStyle}
          fontWeight={entity.fontWeight || fontProperties.fontWeight}
          mobileFontWeight={entity.mobileFontWeight}
          mobilePadding={entity.mobilePadding}
          textAlign={entity.textAlign}
          mobileTextAlign={entity.mobileTextAlign}
          backgroundColor={entity.backgroundColor}
          mobileBackgroundColor={entity.mobileBackgroundColor}
        >
          <div
            dangerouslySetInnerHTML={{ __html: entity.html || '' }}
            ref={ref}
          />
        </CommonText>
      </CommonBulletList>
    </StyleWrapper>
  ) : (
    <div />
  )
}

export default memo(NewBulletList)
