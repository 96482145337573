import React from 'react'
import PropTypes from 'prop-types'
import usePage from '../hooks/usePage'
import { getGlobalSettings } from '../reducers/pageReducer'

const InlineForm = ({ children }) => {
  const globalSettings = usePage(getGlobalSettings)
  return (
    <React.Fragment>
      {React.cloneElement(children, { globalSettings })}
    </React.Fragment>
  )
}

InlineForm.propTypes = {
  children: PropTypes.node.isRequired,
}

export default InlineForm
