export const getTimeRemaining = (endTime: number | null) => {
  const total = (endTime || Date.now()) - Date.now()
  const seconds = Math.ceil((total / 1000) % 60)
  const minutes = Math.floor((total / 1000 / 60) % 60)
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
  const days = Math.floor(total / (1000 * 60 * 60 * 24))

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  }
}
