import styled from 'styled-components'

type WebinarRegistrationSelectUiProps = {
  dataTestId?: string
}

const WebinarRegistrationSelectUi = styled.select.attrs<
  WebinarRegistrationSelectUiProps
>(p => ({
  ...(process.env.NODE_ENV === 'test' ? { 'data-testid': p.dataTestId } : {}),
}))<WebinarRegistrationSelectUiProps>`
  height: 42px;
  font-size: 16px;
  text-indent: 10px;
  outline: none;
  border: 1px solid #b0baca;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
`

export default WebinarRegistrationSelectUi
