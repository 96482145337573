import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonBlogPostImage from 'common/components/entities/BlogPostImage'
import { BlogPostImageInterface } from 'common/types/entities/BlogPostImageInterface'
import useBlog, { selectors } from 'publisher/hooks/useBlog'

type BlogPostImageProps = {
  entity: BlogPostImageInterface
}

function BlogPostImage({ entity }: BlogPostImageProps) {
  const imageUrl = useBlog(selectors.getBlogPostImageUrl)
  return (
    <StyleWrapper
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
    >
      <CommonBlogPostImage
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        src={imageUrl}
        background={entity.background}
        mobileBackground={entity.mobileBackground}
      />
    </StyleWrapper>
  )
}

export default BlogPostImage
