import styled from 'styled-components'


const PopupWrapper = styled.div`
  position: fixed;
  left: 0; /*ie11 hack*/
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  ${p => p.theme.phone} {
    left: 0;
  }
`

export default PopupWrapper
