import React from 'react'
import Select, { OnChangeValue, PropsValue, StylesConfig } from 'react-select'
import ErrorUi from 'client/components/core/Error/ui/ErrorUi'
import OptionWrapper from '../../styleComponents/OptionWrapper'

type OptionValue = string | number | null | boolean | undefined

export type OptionType<T extends OptionValue> = {
  value: T
  label: string
}

export type ChangeOptionValue<
  T extends OptionValue,
  IsMulti extends boolean = false,
> = OnChangeValue<OptionType<T>, IsMulti>

export type ChangeOptionWithStringValue<T extends OptionValue = string> =
  ChangeOptionValue<T>

export type ChangeOptionWithBooleanValue = ChangeOptionValue<boolean>

interface ReactSelectProps<
  T extends OptionValue,
  IsMulti extends boolean = false,
> {
  selectedOption: PropsValue<OptionType<T>>
  update: (option: ChangeOptionValue<T, IsMulti>) => void
  labelText?: string
  labelIcon?: JSX.Element
  helpMessage?: string
  needTranslate?: boolean
  options: OptionType<T>[]
  isMulti: IsMulti
  placeholder?: string
  withoutWrapper?: boolean
  styles?: StylesConfig<OptionType<T>, IsMulti>
  itemStyles?: React.CSSProperties
  menuPortalTarget?: HTMLElement | null
  isLoading?: boolean
  isDisabled?: boolean
  error?: string
  onOpen?: () => void
}

function ReactSelect<T extends OptionValue, IsMulti extends boolean = false>({
  selectedOption,
  update,
  labelText,
  labelIcon,
  helpMessage,
  needTranslate = true,
  placeholder,
  options,
  isMulti,
  withoutWrapper = false,
  styles,
  menuPortalTarget,
  itemStyles,
  isLoading = false,
  isDisabled = false,
  error,
  onOpen,
}: ReactSelectProps<T, IsMulti>) {
  const select = (
    <Select<OptionType<T>, IsMulti>
      placeholder={placeholder}
      onChange={update}
      value={selectedOption}
      options={options}
      getOptionLabel={option => option.label}
      isMulti={isMulti}
      isDisabled={isDisabled}
      menuPortalTarget={menuPortalTarget}
      styles={{
        ...styles,
        menu: provided => ({ ...provided, zIndex: 2 }),
        menuPortal: provided => ({ ...provided, zIndex: 101 }),
      }}
      onMenuOpen={() => {
        if (onOpen) {
          onOpen()
        }
      }}
      isLoading={isLoading}
    />
  )
  if (withoutWrapper) {
    return select
  }

  return (
    <OptionWrapper
      helpMessage={helpMessage}
      labelText={labelText}
      labelIcon={labelIcon}
      itemStyles={itemStyles}
      needTranslate={needTranslate}
    >
      {select}
      {error ? <ErrorUi dangerouslySetInnerHTML={{ __html: error }} /> : null}
    </OptionWrapper>
  )
}

export const buildOption = <T,>(value: T, label: string) => ({
  value,
  label,
})

export default ReactSelect
