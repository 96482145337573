import React from 'react'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import {
  BlogPost,
  blogPostListingCategoriesInterface,
  blogPostListingDateInterface,
  blogPostListingDescriptionInterface,
  blogPostListingImageInterface,
  blogPostListingTitleInterface,
  PostCardInterface,
} from 'common/types/entities/BlogPostListingInterface'
import { CommonContainerInterface } from 'common/types/entities/EntityInterface'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { LayoutType } from 'client/components/core/Sidebar/components/Settings/components/PostListingLayoutSetting/utils'
import BlogPostCard from './BlogPostCard'
import OneBackgroundThreeSideLayout from './OneBackgroundThreeSideLayout'
import OneBackgroundThreeUnderLayout from './OneBackgroundThreeUnderLayout'
import ThreeTwoPostsPerRowLayout from './ThreeTwoPostsPerRowLayout'
import TwoThreePostsPerRowLayout, {
  RowsAlignment,
} from './TwoThreePostsPerRowLayout'
import BlogPostsPostItemRowUi from './ui/BlogPostsPostItemRowUi'
import BlogPostsRowUi from './ui/BlogPostsRowUi'

const ROW_SIZE = 3

export type BlogPostCardProps = {
  blogPath?: string
  rowSize?: number
  mobileRowsGap?: number
  locale: PageLocaleEnum
  showDate: boolean
  postCard?: CommonContainerInterface
  imagePlacement?: ImagePlacementVariants
  mobileImagePlacement?: ImagePlacementVariants
  layoutType?: number
  isCategoryPage: boolean
  displayHorizontally: boolean
} & blogPostListingImageInterface &
  blogPostListingTitleInterface &
  blogPostListingDescriptionInterface &
  blogPostListingCategoriesInterface &
  blogPostListingDateInterface

type BlogPostsProps = {
  blogPath?: string
  posts: BlogPost[]
  displayHorizontally: boolean
  rowSize?: number
  rowsGap: number
  mobileRowsGap?: number
  columnsGap: number
  locale: PageLocaleEnum
  showDate: boolean
  postCard?: PostCardInterface
  layoutType?: LayoutType
  imagePlacement?: ImagePlacementVariants
  mobileImagePlacement?: ImagePlacementVariants
  isMobile?: boolean
} & blogPostListingImageInterface &
  blogPostListingTitleInterface &
  blogPostListingDescriptionInterface &
  blogPostListingCategoriesInterface &
  blogPostListingDateInterface

function BlogPosts({
  blogPath,
  posts,
  displayHorizontally,
  rowSize = ROW_SIZE,
  locale,
  imageBorder,
  mobileImageBorder,
  imageBoxShadow,
  mobileImageBoxShadow,
  titleFontSize,
  mobileTitleFontSize,
  titleLineHeight,
  mobileTitleLineHeight,
  titleFontFamily,
  titleFontWeight,
  titleFontStyle,
  mobileTitleFontFamily,
  mobileTitleFontWeight,
  mobileTitleFontStyle,
  titleLetterSpacing,
  mobileTitleLetterSpacing,
  titleAlign,
  mobileTitleAlign,
  titleColor,
  mobileTitleColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionFontFamily,
  descriptionFontWeight,
  descriptionFontStyle,
  mobileDescriptionFontFamily,
  mobileDescriptionFontWeight,
  mobileDescriptionFontStyle,
  descriptionAlign,
  mobileDescriptionAlign,
  descriptionColor,
  mobileDescriptionColor,
  categoriesFontSize,
  mobileCategoriesFontSize,
  categoriesFontFamily,
  categoriesFontWeight,
  categoriesFontStyle,
  mobileCategoriesFontFamily,
  mobileCategoriesFontWeight,
  mobileCategoriesFontStyle,
  categoriesAlign,
  mobileCategoriesAlign,
  categoriesColor,
  mobileCategoriesColor,
  showDate,
  dateFontSize,
  mobileDateFontSize,
  dateFontFamily,
  dateFontWeight,
  dateFontStyle,
  mobileDateFontFamily,
  mobileDateFontWeight,
  mobileDateFontStyle,
  dateAlign,
  mobileDateAlign,
  dateColor,
  mobileDateColor,
  rowsGap,
  mobileRowsGap,
  columnsGap,
  imageMargin,
  mobileImageMargin,
  titleMargin,
  mobileTitleMargin,
  categoriesMargin,
  mobileCategoriesMargin,
  descriptionMargin,
  mobileDescriptionMargin,
  dateMargin,
  mobileDateMargin,
  postCard,
  imagePlacement,
  mobileImagePlacement,
  layoutType,
  isMobile,
}: BlogPostsProps) {
  const calculatedRowSize = displayHorizontally ? rowSize : 1

  let isCategoryPage = false
  if (typeof window !== 'undefined') {
    isCategoryPage = /\/category\/.*/.test(window.location.href)
  }
  const blogPostCardProps = {
    isMobile: isMobile,
    imagePlacement: imagePlacement,
    mobileImagePlacement: mobileImagePlacement,
    postCard: postCard,
    imageBorder: imageBorder,
    mobileImageBorder: mobileImageBorder,
    imageBoxShadow: imageBoxShadow,
    mobileImageBoxShadow: mobileImageBoxShadow,
    imageMargin: imageMargin,
    mobileImageMargin: mobileImageMargin,
    isCategoryPage: isCategoryPage,
    categoriesFontSize: categoriesFontSize,
    mobileCategoriesFontSize: mobileCategoriesFontSize,
    categoriesFontFamily: categoriesFontFamily,
    categoriesFontWeight: categoriesFontWeight,
    categoriesFontStyle: categoriesFontStyle,
    mobileCategoriesFontFamily: mobileCategoriesFontFamily,
    mobileCategoriesFontWeight: mobileCategoriesFontWeight,
    mobileCategoriesFontStyle: mobileCategoriesFontStyle,
    categoriesAlign: categoriesAlign,
    mobileCategoriesAlign: mobileCategoriesAlign,
    categoriesColor: categoriesColor,
    mobileCategoriesColor: mobileCategoriesColor,
    categoriesMargin: categoriesMargin,
    mobileCategoriesMargin: mobileCategoriesMargin,
    blogPath: blogPath,
    titleFontSize: titleFontSize,
    mobileTitleFontSize: mobileTitleFontSize,
    titleLineHeight: titleLineHeight,
    mobileTitleLineHeight: mobileTitleLineHeight,
    titleFontFamily: titleFontFamily,
    titleFontWeight: titleFontWeight,
    titleFontStyle: titleFontStyle,
    mobileTitleFontFamily: mobileTitleFontFamily,
    mobileTitleFontWeight: mobileTitleFontWeight,
    mobileTitleFontStyle: mobileTitleFontStyle,
    titleLetterSpacing: titleLetterSpacing,
    mobileTitleLetterSpacing: mobileTitleLetterSpacing,
    titleAlign: titleAlign,
    mobileTitleAlign: mobileTitleAlign,
    titleColor: titleColor,
    mobileTitleColor: mobileTitleColor,
    titleMargin: titleMargin,
    mobileTitleMargin: mobileTitleMargin,
    showDate: showDate,
    dateFontSize: dateFontSize,
    mobileDateFontSize: mobileDateFontSize,
    dateFontFamily: dateFontFamily,
    dateFontWeight: dateFontWeight,
    dateFontStyle: dateFontStyle,
    mobileDateFontFamily: mobileDateFontFamily,
    mobileDateFontWeight: mobileDateFontWeight,
    mobileDateFontStyle: mobileDateFontStyle,
    dateAlign: dateAlign,
    mobileDateAlign: mobileDateAlign,
    dateColor: dateColor,
    mobileDateColor: mobileDateColor,
    dateMargin: dateMargin,
    mobileDateMargin: mobileDateMargin,
    locale: locale,
    descriptionFontSize: descriptionFontSize,
    mobileDescriptionFontSize: mobileDescriptionFontSize,
    descriptionLineHeight: descriptionLineHeight,
    mobileDescriptionLineHeight: mobileDescriptionLineHeight,
    descriptionFontFamily: descriptionFontFamily,
    descriptionFontWeight: descriptionFontWeight,
    descriptionFontStyle: descriptionFontStyle,
    mobileDescriptionFontFamily: mobileDescriptionFontFamily,
    mobileDescriptionFontWeight: mobileDescriptionFontWeight,
    mobileDescriptionFontStyle: mobileDescriptionFontStyle,
    descriptionAlign: descriptionAlign,
    mobileDescriptionAlign: mobileDescriptionAlign,
    descriptionColor: descriptionColor,
    mobileDescriptionColor: mobileDescriptionColor,
    descriptionMargin: descriptionMargin,
    mobileDescriptionMargin: mobileDescriptionMargin,
    displayHorizontally: displayHorizontally,
  }

  const renderPostsGrid = (posts: BlogPost[], rowSize: number) => (
    <BlogPostsPostItemRowUi columnsCount={rowSize} gap={columnsGap}>
      {posts.map((post: BlogPost, index: number) => (
        <BlogPostCard key={index} post={post} {...blogPostCardProps} />
      ))}
    </BlogPostsPostItemRowUi>
  )

  const mobileOneColumnPostsRendering = () => {
    return (
      <BlogPostsRowUi gap={rowsGap} mobileGap={mobileRowsGap}>
        {posts
          .reduce((acc, post, index) => {
            index % calculatedRowSize === 0 && acc.push([])
            acc[acc.length - 1].push(post)
            return acc
          }, [] as BlogPost[][])
          .map(post => renderPostsGrid(post, calculatedRowSize))}
      </BlogPostsRowUi>
    )
  }

  switch (layoutType) {
    case LayoutType.GridLongShort3ShortLong: {
      const pattern = [2, 3, 2]
      let rowLengthPatternIndex = 0
      const sortedPosts = []
      const currentPosts = [...posts]
      while (currentPosts.length > 0) {
        const postsRow = currentPosts.splice(0, pattern[rowLengthPatternIndex])
        const alignment: RowsAlignment =
          (rowLengthPatternIndex === 0 && RowsAlignment.doubleLongShort) ||
          (rowLengthPatternIndex === 2 && RowsAlignment.shortDoubleLong) ||
          RowsAlignment.default

        sortedPosts.push({ posts: postsRow, alignment })
        rowLengthPatternIndex = pattern[rowLengthPatternIndex + 1]
          ? rowLengthPatternIndex + 1
          : 0
      }
      return (
        <>
          {displayHorizontally ? (
            <BlogPostsRowUi gap={rowsGap} mobileGap={mobileRowsGap}>
              {sortedPosts.map((rowPosts, index) => (
                <TwoThreePostsPerRowLayout
                  key={index}
                  posts={rowPosts.posts}
                  alignment={rowPosts.alignment}
                  blogPostCardProps={blogPostCardProps}
                />
              ))}
            </BlogPostsRowUi>
          ) : (
            mobileOneColumnPostsRendering()
          )}
        </>
      )
    }
    case LayoutType.Grid1Big3Under: {
      const rowPattern = [1, 3]
      const sortedPosts = getSortedPosts(posts, rowPattern)
      return (
        <>
          {displayHorizontally ? (
            <BlogPostsRowUi gap={rowsGap} mobileGap={mobileRowsGap}>
              {sortedPosts.map((rowPosts, index) => (
                <OneBackgroundThreeUnderLayout
                  key={index}
                  posts={rowPosts}
                  oneElementInRow={index === 0 || index % 2 === 0}
                  blogPostCardProps={blogPostCardProps}
                />
              ))}
            </BlogPostsRowUi>
          ) : (
            mobileOneColumnPostsRendering()
          )}
        </>
      )
    }
    case LayoutType.GridRows3x2x3: {
      const rowPattern = [3, 2]
      const sortedPosts = getSortedPosts(posts, rowPattern)
      return (
        <>
          {displayHorizontally ? (
            <BlogPostsRowUi gap={rowsGap} mobileGap={mobileRowsGap}>
              {sortedPosts.map((rowPosts, index) => (
                <ThreeTwoPostsPerRowLayout
                  key={index}
                  threeElementsInRow={index === 0 || index % 2 === 0}
                  posts={rowPosts}
                  blogPostCardProps={blogPostCardProps}
                />
              ))}
            </BlogPostsRowUi>
          ) : (
            mobileOneColumnPostsRendering()
          )}
        </>
      )
    }
    case LayoutType.Grid1Big3Side: {
      const rowSize = 4
      const sortedPosts = posts.reduce((acc, post, index) => {
        index % rowSize === 0 && acc.push([])
        acc[acc.length - 1].push(post)
        return acc
      }, [] as BlogPost[][])

      return (
        <>
          {displayHorizontally ? (
            <BlogPostsRowUi gap={rowsGap} mobileGap={mobileRowsGap}>
              {sortedPosts.map((rowPosts, index) => (
                <OneBackgroundThreeSideLayout
                  key={index}
                  posts={rowPosts}
                  oneElementInColumn={index === 0 || index % 2 === 0}
                  blogPostCardProps={blogPostCardProps}
                />
              ))}
            </BlogPostsRowUi>
          ) : (
            mobileOneColumnPostsRendering()
          )}
        </>
      )
    }
    default:
      return (
        <BlogPostsRowUi gap={rowsGap} mobileGap={mobileRowsGap}>
          {posts
            .reduce((acc, post, index) => {
              index % calculatedRowSize === 0 && acc.push([])
              acc[acc.length - 1].push(post)
              return acc
            }, [] as BlogPost[][])
            .map(post => renderPostsGrid(post, calculatedRowSize))}
        </BlogPostsRowUi>
      )
  }
}

const getSortedPosts = (posts: BlogPost[], pattern: number[]) => {
  const sortedPosts = []
  const currentPosts = [...posts]
  let rowLengthPatternIndex = 0
  while (currentPosts.length > 0) {
    const postsRow = currentPosts.splice(0, pattern[rowLengthPatternIndex])
    sortedPosts.push(postsRow)
    rowLengthPatternIndex = pattern[rowLengthPatternIndex + 1]
      ? rowLengthPatternIndex + 1
      : 0
  }

  return sortedPosts
}

export default BlogPosts
