import styled from 'styled-components'
import {
  mobileBackgroundColorCss,
  backgroundColorCss,
} from 'common/utils/styleUtilsNew'

const BlogPostContentContainerUi = styled.div<{
  backgroundColor?: string
  mobileBackgroundColor?: string
}>`
  margin-right: 1px;
  ${backgroundColorCss}
  ${p => p.theme.isEditorMobileMode && mobileBackgroundColorCss}
  ${p => p.theme.phone} {
    ${mobileBackgroundColorCss}
  }
`

export default BlogPostContentContainerUi
