import React, { MouseEvent, useState } from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonFaq, { FaqItem } from 'common/components/entities/Faq'
import FaqInterface, {
  FaqItemInterface,
} from 'common/types/entities/FaqInterface'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'
import { pageSelectors, usePage } from 'publisher/store'

type FaqProps = {
  entity: FaqInterface
}

const Faq = ({ entity }: FaqProps) => {
  const [activeIndexes, setActiveIndexes] = useState<Record<number, boolean>>(
    {},
  )
  const faqItems = usePage(page =>
    pageSelectors.getChildrenEntities(page, entity.childIds),
  ) as FaqItemInterface[]

  const toggleCollapse = (index: number) => (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    setActiveIndexes(prevIndexes => ({
      ...prevIndexes,
      [index]: !prevIndexes[index],
    }))
  }

  return (
    <StyleWrapper
      mobile={entity.appearance.mobile}
      desktop={entity.appearance.desktop}
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
    >
      <CommonFaq
        mobilePadding={entity.mobilePadding}
        padding={entity.padding}
        htmlAttrId={entity.htmlAttrId}
        spaceBetweenItems={entity.spaceBetweenItems}
      >
        {faqItems.map((faqItem, index) => (
          <FaqItem
            key={faqItem.id}
            showIcons={entity.showIcons}
            mobileShowIcons={entity.mobileShowIcons}
            itemBorderRadius={entity.itemBorderRadius}
            itemPadding={entity.itemPadding}
            mobileItemPadding={entity.mobileItemPadding}
            backgroundColor={entity.backgroundColor}
            mobileBackgroundColor={entity.mobileBackgroundColor}
            titleColor={entity.titleColor}
            mobileTitleColor={entity.mobileTitleColor}
            fontSize={entity.titleFontSize}
            mobileFontSize={entity.mobileTitleFontSize}
            fontFamily={entity.titleFontFamily}
            fontStyle={entity.titleFontStyle}
            fontWeight={entity.titleFontWeight}
            title={faqItem.title}
            iconClassName={entity.iconClassName}
            closedIconClassName={entity.closedIconClassName}
            iconColor={entity.iconColor}
            mobileIconColor={entity.mobileIconColor}
            boxShadow={entity.boxShadow}
            mobileBoxShadow={entity.mobileBoxShadow}
            toggleCollapse={toggleCollapse(index)}
            expanded={activeIndexes[index]}
          >
            <BaseNodeChildren childIds={faqItem.childIds} />
          </FaqItem>
        ))}
      </CommonFaq>
    </StyleWrapper>
  )
}

export default Faq
