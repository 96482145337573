import styled, { css } from 'styled-components'

export const EventInfoWrapperUi = styled.div<{ titlesColor?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1;
  width: 100%;

  ${p =>
    p.color &&
    css`
      & p {
        color: ${p.color};
      }
    `}

  ${p =>
    p.titlesColor &&
    css`
      & span {
        color: ${p.titlesColor};
      }
    `}
`
