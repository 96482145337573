import styled, { css } from 'styled-components'
import { PaddingType } from 'common/types/styleTypes'
import { paddingsCss, mobilePaddingsCss } from 'common/utils/styleUtilsNew'

type CarouselUiProps = {
  padding: PaddingType
  mobilePadding: PaddingType
}

const CarouselUi = styled.div<CarouselUiProps>`
  position: relative;
  ${paddingsCss}
  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobilePaddingsCss}
    `}
`

export default CarouselUi
