import PropTypes from 'prop-types'
import React from 'react'
import useOptIn from 'publisher/hooks/useOptIn'
import { getNextStepUrl } from 'publisher/reducers/optInReducer'
import BaseButton from '../BaseButton'

function NextStepRedirectionButton({ entity }) {
  const nextStepUrl = useOptIn(getNextStepUrl)

  return (
    <BaseButton
      dataTestId="next-step-redirection-button"
      asLink={true}
      href={nextStepUrl}
      attrId={entity.options.attrId}
      text={entity.options.text}
      styles={entity.styles}
      mobileStyles={entity.mobileStyles}
      borderType={entity.options.borderType}
      mobileBorderType={entity.mobileOptions.borderType}
      subText={entity.options.subText}
      subTextColor={entity.options.subTextColor}
      subTextFontSize={entity.options.subTextFontSize}
      subTextFontWeight={entity.options.subTextFontWeight}
      subTextFontStyle={entity.options.subTextFontStyle}
      subTextFontFamily={entity.options.subTextFontFamily}
      mobileSubTextColor={entity.mobileOptions.subTextColor}
      mobileSubTextFontWeight={entity.mobileOptions.subTextFontWeight}
      mobileSubTextFontStyle={entity.mobileOptions.subTextFontStyle}
      mobileSubTextFontSize={entity.mobileOptions.subTextFontSize}
      mobileSubTextFontFamily={entity.mobileOptions.subTextFontFamily}
      iconClassNameBefore={entity.options.iconClassNameBefore}
      iconClassNameAfter={entity.options.iconClassNameAfter}
      appearance={entity.options.appearance}
      hoverStyles={entity.options?.hover}
      hoverMobileStyles={entity.mobileOptions?.hover}
    />
  )
}

NextStepRedirectionButton.propTypes = {
  entity: PropTypes.shape({
    options: PropTypes.shape({
      attrId: PropTypes.string,
      text: PropTypes.string,
      subText: PropTypes.string,
      borderType: PropTypes.string,
      appearance: PropTypes.shape({
        desktop: PropTypes.bool.isRequired,
        mobile: PropTypes.bool.isRequired,
      }).isRequired,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      iconClassNameBefore: PropTypes.string,
      iconClassNameAfter: PropTypes.string,
      hover: PropTypes.shape({
        textColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        subTextColor: PropTypes.string,
        transform: PropTypes.string,
        offset: PropTypes.number,
      }),
    }),
    mobileOptions: PropTypes.shape({
      borderType: PropTypes.string,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      hover: PropTypes.shape({
        textColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        subTextColor: PropTypes.string,
        transform: PropTypes.string,
        offset: PropTypes.number,
      }),
    }),
    mobileStyles: PropTypes.objectOf(PropTypes.string),
    styles: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default NextStepRedirectionButton
