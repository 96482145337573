import React from 'react'
import { blogPostListingPaginationInterface } from 'common/types/entities/BlogPostListingInterface'
import PaginationLinkUi from './ui/PaginationLinkUi'

type PaginationArrowProps = {
  href: string
  left?: boolean
  right?: boolean
} & blogPostListingPaginationInterface

const leftArrowClassName = 'fas fa-chevron-left'
const rightArrowClassName = 'fas fa-chevron-right'

function PaginationArrow({
  href,
  left,
  right,
  paginationActiveColor,
  mobilePaginationActiveColor,
  paginationActiveBackgroundColor,
  mobilePaginationActiveBackgroundColor,
  paginationInactiveColor,
  mobilePaginationInactiveColor,
  paginationInactiveBackgroundColor,
  mobilePaginationInactiveBackgroundColor,
}: PaginationArrowProps) {
  return (
    <>
      {left && (
        <PaginationLinkUi
          href={href}
          paginationActiveColor={paginationActiveColor}
          mobilePaginationActiveColor={mobilePaginationActiveColor}
          paginationActiveBackgroundColor={paginationActiveBackgroundColor}
          mobilePaginationActiveBackgroundColor={
            mobilePaginationActiveBackgroundColor
          }
          paginationInactiveColor={paginationInactiveColor}
          mobilePaginationInactiveColor={mobilePaginationInactiveColor}
          paginationInactiveBackgroundColor={paginationInactiveBackgroundColor}
          mobilePaginationInactiveBackgroundColor={
            mobilePaginationInactiveBackgroundColor
          }
        >
          <i className={leftArrowClassName} />
        </PaginationLinkUi>
      )}
      {right && (
        <PaginationLinkUi
          href={href}
          paginationActiveColor={paginationActiveColor}
          mobilePaginationActiveColor={mobilePaginationActiveColor}
          paginationActiveBackgroundColor={paginationActiveBackgroundColor}
          mobilePaginationActiveBackgroundColor={
            mobilePaginationActiveBackgroundColor
          }
          paginationInactiveColor={paginationInactiveColor}
          mobilePaginationInactiveColor={mobilePaginationInactiveColor}
          paginationInactiveBackgroundColor={paginationInactiveBackgroundColor}
          mobilePaginationInactiveBackgroundColor={
            mobilePaginationInactiveBackgroundColor
          }
        >
          <i className={rightArrowClassName} />
        </PaginationLinkUi>
      )}
    </>
  )
}

export default PaginationArrow
