import React from 'react'
import {
  BlogPost,
  blogPostListingDescriptionInterface,
} from 'common/types/entities/BlogPostListingInterface'
import BlogPostAnnounceDescriptionUi from './ui/BlogPostAnnounceDescriptionUi'

type BlogPostsDescriptionsProps = {
  post: BlogPost
} & blogPostListingDescriptionInterface

function BlogPostsDescriptions({
  post,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionFontFamily,
  descriptionFontWeight,
  descriptionFontStyle,
  mobileDescriptionFontFamily,
  mobileDescriptionFontWeight,
  mobileDescriptionFontStyle,
  descriptionAlign,
  mobileDescriptionAlign,
  descriptionColor,
  mobileDescriptionColor,
  descriptionMargin,
  mobileDescriptionMargin,
}: BlogPostsDescriptionsProps) {
  return (
    <BlogPostAnnounceDescriptionUi
      key={post.path}
      fontSize={descriptionFontSize}
      color={descriptionColor}
      fontFamily={descriptionFontFamily}
      fontWeight={descriptionFontWeight}
      fontStyle={descriptionFontStyle}
      textAlign={descriptionAlign}
      lineHeight={descriptionLineHeight}
      mobileLineHeight={mobileDescriptionLineHeight}
      mobileFontSize={mobileDescriptionFontSize}
      mobileColor={mobileDescriptionColor}
      mobileFontFamily={mobileDescriptionFontFamily}
      mobileFontWeight={mobileDescriptionFontWeight}
      mobileFontStyle={mobileDescriptionFontStyle}
      mobileTextAlign={mobileDescriptionAlign}
      margin={descriptionMargin}
      mobileMargin={mobileDescriptionMargin}
    >
      {post.description}
    </BlogPostAnnounceDescriptionUi>
  )
}

export default BlogPostsDescriptions
