import React from 'react'
import { Translation } from 'react-i18next'
import styled from 'styled-components'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'

const BlogContentPlaceholderUi = styled.div`
  color: #acc9de;
  font-size: 80px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const BlogPostContentPlaceholder = ({ entity }) =>
  entity.childIds && entity.childIds.length > 0 ? (
    <BaseNodeChildren childIds={entity.childIds} />
  ) : (
    <BlogContentPlaceholderUi id="blog-post-content-placeholder">
      <Translation>
        {t =>
          t('components.entities.blog_post_content_placeholder.placeholder')
        }
      </Translation>
    </BlogContentPlaceholderUi>
  )

export default BlogPostContentPlaceholder
