import React from 'react'
import CommonContentBox from 'common/components/entities/ContentBox'
import { ContentBoxInterface } from 'common/types/entities/ContentBoxInterface'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { useDelay } from 'publisher/hooks/useDelay'

interface ContentBoxProps {
  entity: ContentBoxInterface
}

const ContentBox = ({ entity }: ContentBoxProps) => {
  const isVisible = useDelay(entity.delay)
  return isVisible ? (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      <CommonContentBox entity={entity}>
        <BaseNodeChildren childIds={entity.childIds} />
      </CommonContentBox>
    </StyleWrapper>
  ) : (
    <div />
  )
}

export default ContentBox
