import styled from 'styled-components'

export const EventTimeZoneSelectWrapper = styled.div`
  max-width: 240px;
  width: 100%;

  & > div {
    padding: 0;
  }
  & > div:last-of-type {
    margin: 0;
  }
`
