import styled from 'styled-components'

type ButtonIconUiProps = {
  placed: string
}

const ButtonIconUi = styled.i<ButtonIconUiProps>`
  margin-left: ${p => (p.placed === 'left' ? 0 : '15px')};
  margin-right: ${p => (p.placed === 'right' ? 0 : '15px')};
`

export default ButtonIconUi
