import styled from 'styled-components'

const PricePlanActionPriceUi = styled.div`
  font-size: 30px;
  color: #000033;
  font-weight: bold;
  margin-bottom: 15px;
  small {
    font-size: 16px;
    font-weight: normal;
  }
`

export default PricePlanActionPriceUi
