const BLOG_CATEGORY_URL_PLACEHOLDER = '%BLOG_CATEGORY_URL_ %'
const BLOG_PAGE_URL_PLACEHOLDER = '%BLOG_PAGE_URL_ %'
const BLOG_POST_URL_PLACEHOLDER = '%BLOG_POST_URL_ %'
export function getBlogPageReplacement(pageId: number) {
  return BLOG_PAGE_URL_PLACEHOLDER.replace(' ', String(pageId))
}

export function getBlogCategoryReplacement(categoryId: number) {
  return BLOG_CATEGORY_URL_PLACEHOLDER.replace(' ', String(categoryId))
}

export function getBlogPostReplacement(pageId: number) {
  return BLOG_POST_URL_PLACEHOLDER.replace(' ', String(pageId))
}

export function isBlogPageUrlPlaceholder(placeholder: string) {
  return placeholder === BLOG_PAGE_URL_PLACEHOLDER
}

export function isBlogPostUrlPlaceholder(placeholder: string) {
  return placeholder === BLOG_POST_URL_PLACEHOLDER
}

export function isBlogCategoryUrlPlaceholder(placeholder: string) {
  return placeholder === BLOG_CATEGORY_URL_PLACEHOLDER
}

export function isBlogReplacementPlaceholder(value: string) {
  return (
    isBlogCategoryUrlPlaceholder(value) ||
    isBlogPageUrlPlaceholder(value) ||
    isBlogPostUrlPlaceholder(value)
  )
}
