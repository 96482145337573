import React from 'react'

interface AgreementProps {
  text: string
}

const NewAgreement = ({ text }: AgreementProps) => {
  return <span dangerouslySetInnerHTML={{ __html: text }} />
}

export default NewAgreement
