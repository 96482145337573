import styled, { css } from 'styled-components'
import { AlignSelfType } from 'common/types/styleTypes'

type DropDownUiProps = {
  forceVisible: boolean
  alignSelf: AlignSelfType
  mobileAlignSelf?: AlignSelfType
}
const DropDownUi = styled.div<DropDownUiProps>`
  justify-content: ${p => p.alignSelf || 'center'};
  ${p => p.theme.phone} {
    display: flex;
    justify-content: ${p => p.mobileAlignSelf || 'center'};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      display: flex;
      justify-content: ${p.mobileAlignSelf || 'center'};
    `}
  display: ${p => (p.forceVisible ? 'flex' : 'none')};
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  min-height: 20px;
  align-items: center;
`

export default DropDownUi
