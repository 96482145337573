import styled from 'styled-components'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import {
  carouselImagePlacementCss,
  carouselMobileImagePlacementCss,
} from '../uiUtils'

type CarouselBlogPostsRowUiProps = {
  imagePlacement: ImagePlacementVariants
  mobileImagePlacement?: ImagePlacementVariants
  imageSrc?: string
}

const CarouselBlogPostsRowUi = styled.div<CarouselBlogPostsRowUiProps>`
  display: grid;
  ${carouselImagePlacementCss}
  ${p => p.theme.isEditorMobileMode && carouselMobileImagePlacementCss}

  ${p => p.theme.phone} {
    ${carouselMobileImagePlacementCss}
  }
`

export default CarouselBlogPostsRowUi
