import React from 'react'
import PropTypes from 'prop-types'
import { borderTypeValues } from 'common/constants/settings'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import InlineUi from './ui/Inline'
// exclude maxWidth as deprecated
const Inline = ({ children, mobileStyles, styles, options }) => {
  return (
    <InlineUi
      styles={{
        ...styles,
        ...borderTypeValues[options.borderType],
      }}
      mobileStyles={mobileStyles}
    >
      {children}
    </InlineUi>
  )
}

Inline.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.objectOf(PropTypes.string),
  mobileStyles: PropTypes.objectOf(PropTypes.string),
  options: PropTypes.objectOf(PropTypes.any),
}

Inline.defaultProps = {
  styles: {},
  mobileStyles: {},
  options: {},
}

export default provideMobileStyles(Inline)
