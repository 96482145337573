import React from 'react'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import filesSelectors from 'publisher/store/files/filesSelectors'
import { useFiles } from 'publisher/store/index'
import BasePlayer from './BasePlayer'
import useCalculateAutoPlay from './useCalculateAutoPlay'

function FilePlayer({ entity }: { entity: VideoInterface }) {
  const file = useFiles(files => filesSelectors.getFile(files, entity.fileId))
  const posterFile = useFiles(files =>
    filesSelectors.getFile(files, entity.posterFileId),
  )

  const autoplay = useCalculateAutoPlay(entity)

  if (!file) {
    return <div />
  }

  return (
    <BasePlayer
      attrId={entity.htmlAttrId}
      autoplay={autoplay}
      url={file.path}
      posterUrl={posterFile?.path}
      controls={entity.controls}
      boxShadow={entity.boxShadow}
      mobileBoxShadow={entity.mobileBoxShadow}
      border={entity.border}
      mobileBorder={entity.mobileBorder}
    />
  )
}

export default FilePlayer
