import { BackgroundInterface } from 'common/types/BackgroundType'
import { getMobileBackgroundFileId } from 'common/utils/getMobileBackgroundFileId'
import { useFiles } from 'publisher/store'
import { getFile } from 'publisher/store/file/fileSelectors'

interface UseBackgroundImageInterface {
  backgroundFileId: BackgroundInterface['backgroundFileId']
  mobileBackgroundFileId: BackgroundInterface['backgroundFileId']
}

export const useBackgroundImage = ({
  backgroundFileId,
  mobileBackgroundFileId,
}: UseBackgroundImageInterface) => {
  const backgroundFile = useFiles(state => getFile(state, backgroundFileId))

  const mobileBackgroundFile = useFiles(state =>
    getFile(
      state,
      getMobileBackgroundFileId(backgroundFileId, mobileBackgroundFileId),
    ),
  )

  const backgroundImage = backgroundFile
    ? `url("${backgroundFile.path}")`
    : 'none'

  const mobileBackgroundImage = mobileBackgroundFile?.path
    ? `url("${mobileBackgroundFile.path}")`
    : 'none'

  return { backgroundImage, mobileBackgroundImage }
}
