export function formatDate(date: Date, locale: string) {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions

  return new Intl.DateTimeFormat(locale, options).format(date)
}

export function getFormattedTimeObject(
  hours: number,
  minutes = 0,
): TimeOptions {
  return {
    hours,
    minutes,
  }
}

export function get12HoursFormatLabel(hour: number) {
  const suffix = hour >= 12 ? 'PM' : 'AM'
  let formattedHour = hour > 12 ? hour - 12 : hour
  //if 00 then it is 12 am
  formattedHour = formattedHour === 0 ? 12 : formattedHour
  return { hour: formattedHour, suffix }
}

export function getOptionsByDateAndTimes(
  date: Date,
  times: TimeOptions[],
  timeFormat = 24,
) {
  date.setSeconds(0, 0)
  return times.reduce((prev, time) => {
    date.setHours(time.hours)
    date.setMinutes(time.minutes)
    if (timeFormat === 24) {
      return {
        ...prev,
        [date.getTime()]: `${date.getHours()} : ${
          (date.getMinutes() < 10 ? 0 : '') + String(date.getMinutes())
        }`,
      }
    } else {
      const label = get12HoursFormatLabel(date.getHours())
      return {
        ...prev,
        [date.getTime()]: `${label.hour} : ${
          (date.getMinutes() < 10 ? 0 : '') + String(date.getMinutes())
        } ${label.suffix}`,
      }
    }
  }, {})
}

export type TimeOptions = {
  hours: number
  minutes: number
}

export function getTomorrowDate() {
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)
  tomorrow.setHours(0, 0, 0, 0)
  return tomorrow
}

export function getCurrentDate() {
  const date = new Date()
  date.setMinutes(0, 0, 0)
  return date
}
