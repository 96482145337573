import styled from 'styled-components'

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${({ zIndex }) => zIndex || 'auto'};
  background-color: ${({ dark }) => (dark ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
`

export default Overlay
