import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import TooltipWrapperUi from './ui/TooltipWrapperUi'
import TooltipIconUi from './ui/TooltipIconUi'
import TooltipMessageUi from './ui/TooltipMessageUi'
import TooltipArrowUi from './ui/TooltipArrowUi'
import TooltipOverlayUi from './ui/TooltipOverlayUi'

const defaultMessageMaxWidth = 350
const defaultMinLeft = 5

class Tooltip extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      top: 0,
      left: 0,
      arrowLeft: '50%',
    }

    this.tooltipIcon = createRef()
    this.tooltipMessage = createRef()
  }

  hideMessage = () => this.setState({ show: false })

  showMessage = () => {
    const {
      y,
      x,
      width: iconWidth,
      height: iconHeight,
    } = this.tooltipIcon.current.getBoundingClientRect()
    const { height } = this.tooltipMessage.current.getBoundingClientRect()
    const top = y - height - iconHeight / 2
    const left =
      x < defaultMessageMaxWidth / 2 + iconWidth / 2
        ? defaultMinLeft
        : defaultMessageMaxWidth / 2 + iconWidth / 2

    this.setState({
      show: true,
      top,
      left,
      arrowLeft: this.props.arrowCenter ? defaultMessageMaxWidth / 2 : x, // todo fix and remove hack
    })
  }

  render() {
    const { children, message, flex } = this.props
    const { top, left, show, arrowLeft } = this.state
    return (
      <TooltipWrapperUi flex={flex}>
        {children}
        <TooltipMessageUi
          ref={this.tooltipMessage}
          top={top}
          left={left}
          show={show}
          maxWidth={defaultMessageMaxWidth}
        >
          {message}
          <TooltipArrowUi left={arrowLeft} />
        </TooltipMessageUi>
        <TooltipIconUi
          ref={this.tooltipIcon}
          className="fa fa-question-circle"
          onClick={this.showMessage}
        />
        {show && <TooltipOverlayUi onClick={this.hideMessage} />}
      </TooltipWrapperUi>
    )
  }
}

Tooltip.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string.isRequired,
  flex: PropTypes.bool,
}

Tooltip.defaultProps = {
  children: '',
  flex: false,
}

export default Tooltip
