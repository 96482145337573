import styled, { css } from 'styled-components'

const SectionOldUi = styled.section.attrs(p => ({
  id: p.attrId,
}))`
  position: static;
  display: flex;
  ${p => p.theme.phone} {
    ${props =>
      props.styles &&
      props.styles.backgroundAttachment === 'fixed' &&
      props.styles.backgroundImage &&
      css`
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        background-position: center center;
      `}
    width: 100%;
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
  ${p =>
    p.theme.isEditor &&
    css`
      position: static;
    `}
`

export default SectionOldUi
