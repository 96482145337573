import styled, { CSSProp } from 'styled-components'

export const DropDownListUi = styled.ul<{
  styles?: CSSProp
}>`
  font-size: 14px;
  list-style: none;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  max-height: 14em;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;

  ${({ styles }) => styles}
`
