import { borderTypeKeys, borderTypeValues } from 'common/constants/settings'

export const overwriteBorderType = ({
  styles,
  targetBorderType,
  overwriteBorderType,
}) => {
  if (!targetBorderType) {
    return borderTypeValues[overwriteBorderType]
  }

  if (
    targetBorderType === borderTypeKeys.none ||
    targetBorderType === overwriteBorderType
  ) {
    return borderTypeValues[targetBorderType]
  }

  const overwriteBorderStyle = borderTypeValues[overwriteBorderType]
  const targetBorderStyles = { ...borderTypeValues[targetBorderType] }

  Object.keys(overwriteBorderStyle).forEach(key => {
    targetBorderStyles[key] = styles.borderWidth
  })

  return targetBorderStyles
}
