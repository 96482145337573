import React from 'react'
import CommonAffiliateBadge from 'common/components/entities/AffiliateBadge'
import useManagement, { selectors } from '../hooks/useManagement'

function AffiliateBadge() {
  const affiliateLink = useManagement(selectors.getAffiliateLink)
  return <CommonAffiliateBadge affiliateLink={affiliateLink} />
}

export default AffiliateBadge
