import React from 'react'
import PropTypes from 'prop-types'
import SurveyAnswer, { AnswerPropType } from './SurveyAnswer'
import SurveyQuestionSubTitleUi from './ui/SurveyQuestionSubTitleUi'
import SurveyQuestionTitleUi from './ui/SurveyQuestionTitleUi'
import SurveyQuestionUi from './ui/SurveyQuestionUi'

function SurveyQuestion({
  question,
  questionTitleFontSize,
  mobileQuestionTitleFontSize,
  questionSubTitleFontSize,
  mobileQuestionSubTitleFontSize,
  answerFontSize,
  mobileAnswerFontSize,
  answerColor,
  answerOutlineColor,
  answerBackgroundColor,
  questionTitleColor,
  questionSubTitleColor,
  answerBorderRadius,
  mobileAnswerBorderRadius,
  choose,
}) {
  return (
    <SurveyQuestionUi>
      <SurveyQuestionTitleUi
        fontSize={questionTitleFontSize}
        mobileFontSize={mobileQuestionTitleFontSize}
        color={questionTitleColor}
      >
        {question.title}
      </SurveyQuestionTitleUi>
      {question.subTitle && (
        <SurveyQuestionSubTitleUi
          fontSize={questionSubTitleFontSize}
          mobileFontSize={mobileQuestionSubTitleFontSize}
          color={questionSubTitleColor}
        >
          {question.subTitle}
        </SurveyQuestionSubTitleUi>
      )}
      {question.answers.map(answer => (
        <SurveyAnswer
          key={answer.id}
          answer={answer}
          fontSize={answerFontSize}
          mobileFontSize={mobileAnswerFontSize}
          color={answerColor}
          select={() => choose(answer.id)}
          backgroundColor={answerBackgroundColor}
          outlineColor={answerOutlineColor}
          borderRadius={answerBorderRadius}
          mobileBorderRadius={mobileAnswerBorderRadius}
        />
      ))}
    </SurveyQuestionUi>
  )
}

export const QuestionPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  answers: PropTypes.arrayOf(AnswerPropType),
}).isRequired

SurveyQuestion.propTypes = {
  question: QuestionPropType,
  mobileQuestionTitleFontSize: PropTypes.string,
  questionSubTitleFontSize: PropTypes.string.isRequired,
  mobileQuestionSubTitleFontSize: PropTypes.string,
  answerFontSize: PropTypes.string.isRequired,
  mobileAnswerFontSize: PropTypes.string,
  questionTitleColor: PropTypes.string.isRequired,
  questionSubTitleColor: PropTypes.string.isRequired,
  answerColor: PropTypes.string.isRequired,
  answerOutlineColor: PropTypes.string,
  answerBackgroundColor: PropTypes.string,
  choose: PropTypes.func.isRequired,
  answerBorderRadius: PropTypes.shape({
    borderTopLeftRadius: PropTypes.number,
    borderTopRightRadius: PropTypes.number,
    borderBottomRightRadius: PropTypes.number,
    borderBottomLeftRadius: PropTypes.number,
  }).isRequired,
  mobileAnswerBorderRadius: PropTypes.shape({
    borderTopLeftRadius: PropTypes.number,
    borderTopRightRadius: PropTypes.number,
    borderBottomRightRadius: PropTypes.number,
    borderBottomLeftRadius: PropTypes.number,
  }),
}

export default SurveyQuestion
