import styled from 'styled-components'

const LanguageSwitcherCurrentLangUi = styled.div`
  cursor: pointer;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default LanguageSwitcherCurrentLangUi
