import { LogArgument } from 'rollbar'
import { useRollbar } from '@rollbar/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { joinDateWithTimeSlot } from 'common/components/entities/Calendar/utils'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import * as exceptions from 'common/errors'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { getLocalTimeZone } from 'common/utils/date-utils'
import { optInFail, optInSuccess } from 'publisher/actions/optInActions'
import { optIn } from 'publisher/api/optInApi'
import { Query, QueryKeys } from 'publisher/components/core/MatchMedia'
import useManagement, {
  selectors as managementSel,
} from 'publisher/hooks/useManagement'
import useOptIn, { selectors as optInSelectors } from 'publisher/hooks/useOptIn'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import { useRecaptchaValidation } from 'publisher/hooks/useRecaptchaValidation'
import { getFieldsBySlugs } from 'publisher/reducers/optInReducer'
import { typedPageSelectors, usePage as useTypedPage } from 'publisher/store'
import { PageState } from 'publisher/store/page/PageStateInterface'
import { validateEmptyOptInFields } from 'publisher/utils/fieldValidation'
import { redirectTo } from 'publisher/utils/redirect'

interface UseBookingCalendarOptInArgs {
  optInButtonEntity: BookingCalendarInterface
  closePopup?: () => void
}

export const useBookingCalendarOptIn = ({
  optInButtonEntity,
  closePopup,
}: UseBookingCalendarOptInArgs) => {
  const rollbar = useRollbar()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])

  const bookingCalendarEntity = useTypedPage((page: PageState) =>
    typedPageSelectors.getFirstEntityByType(
      page,
      EntityTypeEnum.BookingCalendar,
    ),
  )
  const funnelStepId = useManagement(managementSel.getFunnelStepId)
  const confirmedCheckboxesIds = useManagement(
    managementSel.getConfirmedExplicitConsentsIds,
  )
  const popup = usePage((p: PageState) =>
    pageSelectors.getAscendantPopup(p, optInButtonEntity),
  )
  const popupId = popup ? popup.id : null
  const isDesktop = useManagement(managementSel.isDesktop)

  const fieldsSlugs = usePage((page: PageState) => {
    return pageSelectors.findEntityDescendantsFieldsSlugs(
      page,
      bookingCalendarEntity,
    )
  })
  const fields = useOptIn((state: { fields: Record<FieldSlugEnum, string> }) =>
    getFieldsBySlugs(state, fieldsSlugs),
  )
  const visibleOptInFieldProperties = useTypedPage(p =>
    typedPageSelectors.getVisibleOptInFieldProperties(
      p,
      isDesktop,
      fields,
      popupId,
    ),
  )
  const visibleOptInFields = usePage((p: PageState) =>
    pageSelectors.getVisibleOptInFields(p, isDesktop, popupId, fields),
  )

  const bookingFields = useOptIn(optInSelectors.getBookingOptInFields)

  const mandatoryCheckboxes = useTypedPage(p =>
    typedPageSelectors.getVisibleMandatoryCheckboxes(p, isDesktop, popupId),
  )

  const { validateRecaptchas } = useRecaptchaValidation(optInButtonEntity)

  const isTemplate = usePage(pageSelectors.isTemplate)
  const isPreview = useManagement(managementSel.isPreviewMode)

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (isTemplate || isPreview) {
      return
    }

    const checkboxesErrors = mandatoryCheckboxes.reduce((errors, checkbox) => {
      return confirmedCheckboxesIds.includes(checkbox.id)
        ? errors
        : [
            ...errors,
            'options' in checkbox
              ? checkbox.options.errorText
              : checkbox.errorText,
          ]
    }, [] as string[])

    if (checkboxesErrors.length > 0) {
      setErrors(checkboxesErrors)
      return
    } else setErrors([])

    const emptyFieldErrors = validateEmptyOptInFields(
      visibleOptInFieldProperties,
      t,
    )

    if (Object.keys(emptyFieldErrors).length) {
      dispatch(
        optInFail({
          common: [],
          fields: emptyFieldErrors,
        }),
      )
      return
    }
    const { recaptchaToken, error } = validateRecaptchas()

    if (error) return

    setLoading(true)
    try {
      const { data } = await optIn({
        booking: {
          eventId: bookingFields.eventId,
          ownerId: bookingFields.ownerId,
          eventLocationId: bookingFields.eventLocationId,
          timeSlot: joinDateWithTimeSlot(
            bookingFields.date,
            bookingFields.timeSlot,
          ),
        },
        bookingCalendarEntityId: bookingCalendarEntity?.id,
        fields: visibleOptInFields,
        timeZone: bookingFields?.timeZone || getLocalTimeZone(),
        popupId,
        isDesktop: window.matchMedia(Query[QueryKeys.DESKTOP]).matches,
        surveysResults: {},
        entityId: optInButtonEntity.id,
        checkBoxIds: confirmedCheckboxesIds,
        captcha: recaptchaToken,
      })

      if (popup && closePopup) {
        closePopup()
      }
      dispatch(optInSuccess())
      if (data.redirect) {
        redirectTo(data.redirect)
      }
      window.parent.postMessage(
        {
          type: `funnel_step_${funnelStepId}_form_submit_success`,
        },
        '*',
      )
    } catch (error) {
      if (error instanceof exceptions.BadRequest) {
        setErrors(error.response.data.errors.common)
        dispatch(
          optInFail({
            fields: error.response.data.errors.fields,
          }),
        )
        window.dispatchEvent(new Event('form_updated'))
      } else if (error instanceof exceptions.NetworkError) {
        setErrors([t('core.errors.no_connection')])
      } else {
        console.log(`--error--`, error)
        rollbar.error(`Opt-in error`, error as LogArgument)
      }
    }
    setLoading(false)
  }

  return { isLoading, errors, submit }
}
