import React from 'react'
import PropTypes from 'prop-types'

export default function(ComposedComponent) {
  const WithMobileStyles = ({ mobileStyles, mobileOptions, ...restProps }) => {
    const calculatedMobileStyles = {
      ...restProps.styles,
      ...mobileStyles,
    }

    const calculatedMobileOptions = {
      ...restProps.options,
      ...mobileOptions,
    }

    if (restProps.options && restProps.options.headerStyles) {
      calculatedMobileOptions.headerStyles = {
        ...restProps.options.headerStyles,
        ...mobileOptions.headerStyles,
      }
    }

    return (
      <ComposedComponent
        {...restProps}
        mobileStyles={calculatedMobileStyles}
        mobileOptions={calculatedMobileOptions}
      />
    )
  }

  const composedComponentName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component'

  WithMobileStyles.propTypes = {
    mobileStyles: PropTypes.objectOf(PropTypes.any),
    mobileOptions: PropTypes.objectOf(PropTypes.any),
  }

  WithMobileStyles.defaultProps = {
    mobileStyles: {},
    mobileOptions: {},
  }

  WithMobileStyles.displayName = `WithMobileStyles${composedComponentName}`

  return WithMobileStyles
}
