import React, { useState } from 'react'
import {
  CommonSurveyQuestionProps,
  SurveyQuestionType,
} from 'common/types/entities/SurveyInterface'
import SurveyAnswer from './SurveyAnswer'
import SurveyTextArea from './SurveyTextArea'
import SurveyQuestionSubTitleUi from './ui/SurveyQuestionSubTitleUi'
import SurveyQuestionTitleUi from './ui/SurveyQuestionTitleUi'
import SurveyQuestionUi from './ui/SurveyQuestionUi'

interface SurveyQuestionProps extends CommonSurveyQuestionProps {
  choose: (id: string, openTextAnswer?: string) => void
  question: SurveyQuestionType
  isEdit?: boolean
}

function SurveyQuestion(props: SurveyQuestionProps) {
  const [openAnswer, setOpenAnswer] = useState<string>('')

  return (
    <SurveyQuestionUi>
      <SurveyQuestionTitleUi
        fontSize={props.questionTitleFontSize}
        mobileFontSize={props.mobileQuestionTitleFontSize}
        color={props.questionTitleColor}
      >
        {props.question.title}
      </SurveyQuestionTitleUi>
      {props.question.subTitle && (
        <SurveyQuestionSubTitleUi
          fontSize={props.questionSubTitleFontSize}
          mobileFontSize={props.mobileQuestionSubTitleFontSize}
          color={props.questionSubTitleColor}
        >
          {props.question.subTitle}
        </SurveyQuestionSubTitleUi>
      )}
      {props.question.answers.map(answer =>
        answer.isOpen ? (
          <SurveyTextArea
            isEdit={props.isEdit}
            answer={answer}
            fontSize={props.answerFontSize}
            mobileFontSize={props.mobileAnswerFontSize}
            color={props.answerColor}
            select={() => props.choose(answer.id, openAnswer)}
            backgroundColor={props.answerBackgroundColor}
            outlineColor={props.answerOutlineColor}
            border={props.answerBorder}
            mobileBorder={props.mobileAnswerBorder}
            setOpenAnswer={setOpenAnswer}
            openAnswer={openAnswer}
          />
        ) : (
          <SurveyAnswer
            key={answer.id}
            answer={answer}
            fontSize={props.answerFontSize}
            mobileFontSize={props.mobileAnswerFontSize}
            color={props.answerColor}
            select={() => props.choose(answer.id)}
            backgroundColor={props.answerBackgroundColor}
            outlineColor={props.answerOutlineColor}
            border={props.answerBorder}
            mobileBorder={props.mobileAnswerBorder}
          />
        ),
      )}
    </SurveyQuestionUi>
  )
}

export default SurveyQuestion
