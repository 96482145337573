import styled, { css } from 'styled-components'
import { SectionContainerType } from 'common/components/entities/Section/types/SectionInterface'
import { containerCss } from 'common/utils/styleUtilsNew'

interface SectionUiProps extends SectionContainerType {
  htmlAttrId: string
  backgroundImage: string
  mobileBackgroundImage: string
}

const SectionUi = styled.section.attrs<SectionUiProps>(p => ({
  id: p.htmlAttrId,
}))<SectionUiProps>`
  display: flex;
  ${containerCss}
  background-image:${p => p.backgroundImage};
  ${p => p.theme.phone} {
    background-image: ${p => p.mobileBackgroundImage};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      background-image: ${p.mobileBackgroundImage};
    `}
`

export default SectionUi
