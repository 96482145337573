export default function (date, action) {
  const currentDate = new Date()
  const calculatedDelay = date.getTime() - currentDate.getTime()

  if (calculatedDelay > 0) {
    setTimeout(action, calculatedDelay)
  } else {
    action()
  }
}
