import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AlignSelfType,
  PaddingType,
  BorderType,
} from '../../../types/styleTypes'
import AttachmentsContentUi from './ui/AttachmentsContentUi'
import AttachmentsFileNameUi from './ui/AttachmentsFileNameUi'
import AttachmentsIconUi from './ui/AttachmentsIconUi'
import AttachmentsUi from './ui/AttachmentsUi'

type AttachmentsProps = {
  disabled?: boolean
  attrId: string
  padding: PaddingType
  mobilePadding?: PaddingType
  alignSelf: AlignSelfType
  mobileAlignSelf?: AlignSelfType
  fontSize: number
  mobileFontSize?: number
  fontFamily?: string
  fontWeight?: string
  fontStyle?: string
  mobileFontFamily?: string
  mobileFontWeight?: string
  mobileFontStyle?: string
  color: string
  mobileColor?: string
  backgroundColor: string
  mobileBackgroundColor?: string
  iconClassName: string
  fileOnChange?: (value: File) => void
  border: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  boxShadow?: string
  mobileBoxShadow?: string
}

const getFileNameString = (fileName: string) => {
  if (fileName.length > 13) {
    return fileName.slice(0, 13) + '...'
  }
  return fileName.slice(0, 13)
}

function Attachments({
  disabled,
  attrId,
  padding,
  mobilePadding,
  alignSelf,
  mobileAlignSelf,
  fontSize,
  mobileFontSize,
  fontFamily,
  fontWeight,
  fontStyle,
  mobileFontFamily,
  mobileFontWeight,
  mobileFontStyle,
  color,
  mobileColor,
  backgroundColor,
  mobileBackgroundColor,
  iconClassName,
  fileOnChange,
  border,
  mobileBorder,
  boxShadow,
  mobileBoxShadow,
}: AttachmentsProps) {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const [fileName, setFileName] = useState('')

  const openFileSelection = () => {
    if (inputRef.current && !disabled) {
      inputRef.current.click()
    }
  }
  const handleFileChange = () => {
    if (
      inputRef.current &&
      inputRef.current.files &&
      fileOnChange &&
      !disabled
    ) {
      fileOnChange(inputRef.current.files[0])
      setFileName(inputRef.current.files[0].name)
    }
  }

  return (
    <>
      <AttachmentsUi
        id={attrId}
        color={color}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
        fontStyle={fontStyle}
        fontSize={fontSize}
        mobileFontSize={mobileFontSize}
        mobileColor={mobileColor}
        mobileFontFamily={mobileFontFamily}
        mobileFontWeight={mobileFontWeight}
        mobileFontStyle={mobileFontStyle}
        backgroundColor={backgroundColor}
        mobileBackgroundColor={mobileBackgroundColor}
        padding={padding}
        mobilePadding={mobilePadding}
        alignSelf={alignSelf}
        mobileAlignSelf={mobileAlignSelf}
        border={border}
        mobileBorder={mobileBorder}
        boxShadow={boxShadow}
        mobileBoxShadow={mobileBoxShadow}
        onClick={openFileSelection}
      >
        <AttachmentsContentUi>
          {t('components.attachments.text_label')}
          <AttachmentsIconUi className={iconClassName} />
          <input
            style={{ display: 'none' }}
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
          />
        </AttachmentsContentUi>
      </AttachmentsUi>
      <AttachmentsFileNameUi
        alignSelf={alignSelf}
        mobileAlignSelf={mobileAlignSelf}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
        fontStyle={fontStyle}
        fontSize={fontSize}
        mobileFontFamily={mobileFontFamily}
        mobileFontWeight={mobileFontWeight}
        mobileFontStyle={mobileFontStyle}
      >
        {fileName && getFileNameString(fileName)}
      </AttachmentsFileNameUi>
    </>
  )
}

export default Attachments
