const getSafeSessionStorage = () => {
  let storageOrUndefined: Storage | undefined = undefined

  try {
    storageOrUndefined = window.sessionStorage
  } catch (e) {
    storageOrUndefined = undefined
  }

  return new Proxy({} as Storage, {
    get:
      (_, prop: string) =>
      (...argument: string[]) => {
        if (!storageOrUndefined) return null

        try {
          return storageOrUndefined[prop](...argument)
        } catch (e) {
          return null
        }
      },
  })
}

const safeSessionStorage = getSafeSessionStorage()

export default safeSessionStorage
