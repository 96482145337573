import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonCountdown from 'common/components/entities/Countdown'
import ClockItemUi from './ui/ClockItemUi'
import ClockUi from './ui/ClockUi'
import LabelUi from './ui/LabelUi'
import NumberUi from './ui/NumberUi'
import WebinarCountdownBackgroundUi from './ui/WebinarCountdownBackgroundUi'

const WebinarCountdown = ({ date, onTimeEndHandler }) => {
  const { t } = useTranslation()
  return (
    <WebinarCountdownBackgroundUi>
      <CommonCountdown
        time={Date.parse(date)}
        onTimeEndHandler={onTimeEndHandler}
      >
        {({ days, hours, minutes, seconds }) => (
          <ClockUi>
            <ClockItemUi>
              <NumberUi>{days}</NumberUi>
              <LabelUi>{t('webinar_session.countdown.days')}</LabelUi>
            </ClockItemUi>
            <ClockItemUi>
              <NumberUi>{hours}</NumberUi>
              <LabelUi>{t('webinar_session.countdown.hours')}</LabelUi>
            </ClockItemUi>
            <ClockItemUi>
              <NumberUi>{minutes}</NumberUi>
              <LabelUi>{t('webinar_session.countdown.minutes')}</LabelUi>
            </ClockItemUi>
            <ClockItemUi>
              <NumberUi>{seconds}</NumberUi>
              <LabelUi>{t('countdown.seconds')}</LabelUi>
            </ClockItemUi>
          </ClockUi>
        )}
      </CommonCountdown>
    </WebinarCountdownBackgroundUi>
  )
}

WebinarCountdown.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onTimeEndHandler: PropTypes.func.isRequired,
}

export default WebinarCountdown
