import styled from 'styled-components'

const SurveyAnswerLabelUi = styled.div`
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
`

export default SurveyAnswerLabelUi
