import styled, { css } from 'styled-components'
import { fontSizeCss, mobileFontSizeCss } from 'common/utils/styleUtilsNew'

const SurveyQuestionSubTitleUi = styled.div<{
  fontSize: number
  mobileFontSize?: number
}>`
  ${fontSizeCss};
  color: ${p => p.color};
  text-align: center;
  margin-top: 20px;
  margin-bottom: 25px;
  ${p => p.theme.phone} {
    ${mobileFontSizeCss}
  }
  ${p => p.theme.isEditorMobileMode && p.mobileFontSize && mobileFontSizeCss}
`

export default SurveyQuestionSubTitleUi
