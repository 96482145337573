export enum CommonWidthEnum {
  fullPage = 'fullPage',
  wide = 'wide',
  medium = 'medium',
  small = 'small',
  extraSmall = 'extraSmall',
}

export const commonWidthLocoKeys: Record<CommonWidthEnum, string> = {
  fullPage: 'settings_styles.content_width.full_page',
  wide: 'settings_styles.content_width.wide',
  medium: 'settings_styles.content_width.medium',
  small: 'settings_styles.content_width.small',
  extraSmall: 'settings_styles.content_width.extra_small',
}

export const commonWidthValues: Record<CommonWidthEnum, string> = {
  fullPage: '100%',
  wide: '1120px',
  medium: '960px',
  small: '720px',
  extraSmall: '550px',
}
