import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showPopup } from '../actionsManagement'
import { closeCookieExists } from '../utils/cookie'
import { dispatchPopupOpenedCustomEvent } from '../utils/popupUtils'
import useManagement, { selectors } from './useManagement'
import usePage, { selectors as pageSelectors } from './usePage'

function useOnLoadVisibility(pageId, popupId, delay) {
  const [count, setCount] = useState(0)
  const activePopupId = useManagement(selectors.getActivePopup)
  const popupEntity = usePage(page =>
    pageSelectors.getEntityById(page, popupId),
  )
  const isAnyPopupOpened = Boolean(activePopupId)
  const dispatch = useDispatch()
  const hasCloseCookie = closeCookieExists(pageId, popupId)

  useEffect(() => {
    if (hasCloseCookie) {
      return
    }
    if (count >= delay) {
      if (isAnyPopupOpened) {
        return
      } else {
        dispatch(showPopup(popupId))
        // use small timeout in order to render popup
        dispatchPopupOpenedCustomEvent(popupEntity.options.attrId)
      }
    }

    const timeout = setTimeout(() => {
      setCount(count => count + 1)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [count, isAnyPopupOpened, delay, dispatch, hasCloseCookie, popupId])
}

export default useOnLoadVisibility
