import React from 'react'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import EmbedPlayerUi, { TikTokEmbedPlayerUi } from './ui/EmbedPlayerUi'

function EmbedPlayer({ entity }: { entity: VideoInterface }) {
  if (!entity.embedCode) {
    return <div />
  }
  // we don't need to apply iframe styles to TikTok embeds
  const isTikTokCode = entity.embedCode.includes('tiktok.com')

  if (isTikTokCode) {
    return (
      <TikTokEmbedPlayerUi
        id={entity.htmlAttrId}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        dangerouslySetInnerHTML={{ __html: entity.embedCode }}
      />
    )
  }

  return (
    <EmbedPlayerUi
      id={entity.htmlAttrId}
      border={entity.border}
      mobileBorder={entity.mobileBorder}
      boxShadow={entity.boxShadow}
      mobileBoxShadow={entity.mobileBoxShadow}
      dangerouslySetInnerHTML={{ __html: entity.embedCode }}
    />
  )
}

export default EmbedPlayer
