import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import blog from 'publisher/reducers/blogReducer'
import files from 'publisher/reducers/filesReducer'
import lecture from 'publisher/reducers/lectureReducer'
import management from 'publisher/reducers/managementReducer'
import optIn from 'publisher/reducers/optInReducer'
import page from 'publisher/reducers/pageReducer'
import payment from 'publisher/reducers/paymentReducer'
import webinar from 'publisher/reducers/webinarReducer'
import webinarSession from 'publisher/reducers/webinarSession'

const configureStore = preloadedState => {
  // eslint-disable-next-line no-underscore-dangle

  const composeEnhancers =
    (typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose
  return createStore(
    combineReducers({
      page,
      files,
      optIn,
      management,
      payment,
      blog,
      webinar,
      webinarSession,
      lecture,
    }),
    preloadedState,
    composeEnhancers(applyMiddleware(thunk)),
  )
}

export default configureStore
