import React, { useEffect, useState } from 'react'
import CommonAgreement from 'common/components/entities/Agreement/new-agreement'
import { AgreementInterface } from 'common/types/entities/AgreementInterface'
import { getAgreement } from 'publisher/api/agreementApi'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import usePage from 'publisher/hooks/usePage'
import { selectors as pageSelectors } from 'publisher/reducers/pageReducer'

interface NewAgreementProps {
  entity: AgreementInterface
}

function NewAgreement({ entity }: NewAgreementProps) {
  const pageId = usePage(pageSelectors.getPageId)

  const [agreementText, setAgreementText] = useState<string>('')

  useEffect(() => {
    getAgreement(pageId).then(res => {
      // remove escape characters
      const sanitizedText = res.data.replace(/\\"/g, '"')
      setAgreementText(sanitizedText)
    })
  }, [pageId])

  if (!agreementText) {
    return null
  }

  return (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      <CommonAgreement text={agreementText} />
    </StyleWrapper>
  )
}

export default NewAgreement
