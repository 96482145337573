import styled, { CSSProp } from 'styled-components'
import { CountrySelectorButtonUi } from '../../CountrySelector/ui/CountrySelectorUi'

export const PhoneInputWrapperUi = styled.div<{
  styles?: CSSProp
}>`
  display: flex;

  ${CountrySelectorButtonUi} {
    border-radius: 4px;
    margin-right: -1px; /* for border overlap */
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  ${({ styles }) => styles}
`
