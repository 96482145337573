import styled from 'styled-components'

const TooltipMessageUi = styled.div`
  min-width: 100px;
  max-width: ${props => `${props.maxWidth}px`};
  position: fixed;
  z-index: 11; // more than TooltipOverlayUi
  padding: 10px;
  top: ${props => `${props.top}px`};
  left: ${props => `${props.left}px`};
  background: white;
  text-transform: none;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  ${({ styles }) => styles}
`

export default TooltipMessageUi
