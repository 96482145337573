import styled from 'styled-components'

const ButtonLoaderUi = styled.span.attrs(props => ({
  'data-test-element': props.dataTestElement || '',
}))`
  float: left;
  margin-right: 8px;
  margin-top: 2px;
  display: inline-block;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid ${p => p.color};
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

ButtonLoaderUi.defaultProps = {
  color: 'rgba(20, 158, 252, 1)',
}

export default ButtonLoaderUi
