import PropTypes from 'prop-types'
import URLSearchParams from 'url-search-params'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeField } from 'publisher/actions/optInActions'
import useOptIn from 'publisher/hooks/useOptIn'
import { getFieldValue } from 'publisher/reducers/optInReducer'
import { getPageType } from 'publisher/reducers/pageReducer'
import { usePage } from 'publisher/store'
import { PageTypeEnum } from '../../../../common/enums/PageTypeEnum'
import BaseField from './BaseField'

function EmailField({ entity }) {
  const dispatch = useDispatch()
  const [isPredefined, setPredefined] = useState(false)
  const value = useOptIn(optIn => getFieldValue(optIn, entity.options.slug))
  const pageType = usePage(getPageType)

  const handleChange = value =>
    dispatch(changeField(entity.options.slug, value))

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const email = searchParams.get('email')
    if (email) {
      handleChange(email)
      // email field always predefined for offer inner pages
      // so, the check below needs to prevent predefining on other pages
    } else if (value && pageType === PageTypeEnum.OfferInner) {
      // when value isPredefined it is a customer value
      // customer email must be the same as registered with
      setPredefined(true)
    }
  }, [])

  return (
    <BaseField
      entity={entity}
      readOnly={isPredefined}
      disabled={isPredefined}
      needInitialise={false}
      type="email"
    />
  )
}

EmailField.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      slug: PropTypes.string,
      placeholder: PropTypes.string,
      placeholderColor: PropTypes.string,
    }).isRequired,
    styles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    mobileStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    type: PropTypes.string.isRequired,
  }),
}

export default EmailField
