import React, { createContext, useContext, PropsWithChildren } from 'react'
import {
  BaseEntityInterface,
  EntityInnerItemInterface,
} from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'

type CreateEntityElementType = (
  entity: BaseEntityInterface | OldEntityInterface | EntityInnerItemInterface,
  additionalProps?: Record<string, any>,
) => JSX.Element

interface CreateEntitiesContextInterface {
  createEntityElement: CreateEntityElementType
}

const CreateEntityElementContext =
  createContext<CreateEntitiesContextInterface | null>(null)

export const useCreateEntityElement = () => {
  const createEntityElementContext = useContext(CreateEntityElementContext)
  if (!createEntityElementContext) {
    throw new Error(
      'useCreateEntity must be used within a CreateEntitiesProvider',
    )
  }

  return createEntityElementContext.createEntityElement
}

export function CreateEntityElementProvider({
  createEntityElement,
  children,
}: PropsWithChildren<CreateEntitiesContextInterface>) {
  return (
    <CreateEntityElementContext.Provider value={{ createEntityElement }}>
      {children}
    </CreateEntityElementContext.Provider>
  )
}
