import React, { memo } from 'react'
import PropTypes from 'prop-types'
import CommonText from 'common/components/entities/Text'
import StyleWrapper from 'common/components/StyleWrapper'
import { usePopupOpenerRef } from '../../hooks/usePopupOpenerRef'
import { useDelay } from '../../hooks/useDelay'
import usePage, { selectors } from '../../hooks/usePage'

function Text({ entity }) {
  const ref = usePopupOpenerRef()
  const isVisible = useDelay(entity.options.delay)
  const linkColor = usePage(selectors.getGlobalLinkColor)
  const textColor = usePage(selectors.getGlobalTextColor)
  const textFontSize = usePage(selectors.getGlobalTextFontSize)
  const mobileTextFontSize = usePage(selectors.getGlobalMobileTextFontSize)
  const textLineHeight = usePage(selectors.getGlobalTextLineHeight)
  const mobileTextLineHeight = usePage(selectors.getGlobalMobileTextLineHeight)
  const { options, styles, mobileStyles } = entity

  return isVisible ? (
    <StyleWrapper
      textAlign={styles.textAlign}
      desktop={options.appearance.desktop}
      mobile={options.appearance.mobile}
    >
      <CommonText
        htmlAttrId={options.attrId}
        styles={styles}
        mobileStyles={mobileStyles}
        linkColor={linkColor}
        fontSize={textFontSize}
        mobileFontSize={mobileTextFontSize}
        lineHeight={textLineHeight}
        mobileLineHeight={mobileTextLineHeight}
        textColor={textColor}
      >
        <div dangerouslySetInnerHTML={{ __html: options.html }} ref={ref} />
      </CommonText>
    </StyleWrapper>
  ) : (
    <div />
  )
}

Text.propTypes = {
  entity: PropTypes.objectOf(PropTypes.any),
}

export default memo(Text)
