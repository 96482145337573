import React from 'react'
import WebinarRegistrationSelectUi from './uiNew/WebinarRegistrationSelectUi'

type WebinarRegistrationTimeOptionsProps = {
  value?: string
  options?: Record<string, string>
  handleChange: (val: string) => void
  dataTestId?: string
}

function WebinarRegistrationTimeOptions({
  options,
  value,
  handleChange,
  dataTestId,
}: WebinarRegistrationTimeOptionsProps) {
  const isServer = typeof window === 'undefined'
  return (
    <WebinarRegistrationSelectUi
      dataTestId={dataTestId}
      value={value}
      onChange={e => handleChange(e.target.value)}
    >
      {options &&
        !isServer &&
        Object.keys(options).map(key => (
          <option key={key} value={key}>
            {options[key]}
          </option>
        ))}
    </WebinarRegistrationSelectUi>
  )
}

export default WebinarRegistrationTimeOptions
