import React, { useEffect, useRef } from 'react'
import { PaddingType } from 'common/types/styleTypes'
import CarouselArrowLeftUi from './ui/CarouselArrowLeftUi'
import CarouselArrowRightUi from './ui/CarouselArrowRightUi'
import CarouselNavigationItemUi from './ui/CarouselNavigationItemUi'
import CarouselNavigationItemsUi from './ui/CarouselNavigationItemsUi'
import CarouselSlideUi from './ui/CarouselSlideUi'
import CarouselSliderUi from './ui/CarouselSliderUi'
import CarouselUi from './ui/CarouselUi'

type CarouselProps = {
  children: React.ReactElement[] | React.ReactElement // todo should be ReactElement
  padding: PaddingType
  mobilePadding: PaddingType
  totalItems: number
  htmlAttrId: string
  switchActiveSlideNumber?: (index: number) => void
  disableOverflow?: boolean
  showArrows: boolean
  mobileShowArrows?: boolean
  activeSlideIndex: number
  controlsColor: string
  arrowsColor: string
  carouselRef?: React.RefObject<HTMLDivElement>
}

function Carousel({
  controlsColor,
  arrowsColor,
  children,
  padding,
  mobilePadding,
  totalItems,
  htmlAttrId,
  switchActiveSlideNumber,
  disableOverflow = false,
  activeSlideIndex,
  showArrows,
  mobileShowArrows,
  carouselRef,
}: CarouselProps) {
  function handleNavigationClick(index: number) {
    return function (event: React.SyntheticEvent) {
      event.preventDefault()
      event.stopPropagation()
      if (switchActiveSlideNumber) {
        switchActiveSlideNumber(index)
      }
    }
  }

  const nextSlide = activeSlideIndex + 1
  const prevSlide = activeSlideIndex - 1

  const calculatedMobileShowArrows =
    typeof mobileShowArrows === 'undefined' ? showArrows : mobileShowArrows

  return (
    <CarouselUi
      padding={padding}
      mobilePadding={mobilePadding}
      id={htmlAttrId}
      className="carousel-ui"
      ref={carouselRef}
    >
      {prevSlide >= 0 && (
        <CarouselArrowLeftUi
          showArrows={showArrows}
          mobileShowArrows={calculatedMobileShowArrows}
          color={arrowsColor}
          className="fas fa-chevron-left"
          onClick={handleNavigationClick(prevSlide)}
        />
      )}
      {nextSlide < totalItems && (
        <CarouselArrowRightUi
          showArrows={showArrows}
          mobileShowArrows={calculatedMobileShowArrows}
          color={arrowsColor}
          className="fas fa-chevron-right"
          onClick={handleNavigationClick(nextSlide)}
        />
      )}
      <CarouselSliderUi
        className="carousel-slider-ui"
        disableOverflow={disableOverflow}
      >
        {children}
      </CarouselSliderUi>
      <CarouselNavigationItemsUi>
        {Array(totalItems)
          .fill(0)
          .map((_, index) => (
            <CarouselNavigationItemUi
              color={controlsColor}
              active={activeSlideIndex === index}
              key={`slide-${index}`}
              onClick={handleNavigationClick(index)}
            />
          ))}
      </CarouselNavigationItemsUi>
    </CarouselUi>
  )
}

export { CarouselSlideUi }

export default Carousel
