import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledCounterItemTime = styled.section`
  font-size: 20px;
  font-weight: bold;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

const StyledCounterItemLabel = styled.section`
  color: #ccc;
  text-transform: uppercase;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

const StyledCounterItem = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
`

const TimerItemUi = ({
  label,
  time,
  timeStyles,
  mobileTimeStyles,
  labelStyles,
  mobileLabelStyles,
  forwardedRef,
}) => (
  <StyledCounterItem ref={forwardedRef}>
    <StyledCounterItemTime
      styles={timeStyles}
      mobileStyles={mobileTimeStyles}
      ref={forwardedRef}
    >
      {time < 10 ? `0${time}` : time}
    </StyledCounterItemTime>
    <StyledCounterItemLabel
      styles={labelStyles}
      mobileStyles={mobileLabelStyles}
      ref={forwardedRef}
    >
      {label}
    </StyledCounterItemLabel>
  </StyledCounterItem>
)

TimerItemUi.propTypes = {
  forwardedRef: PropTypes.func,
  timeStyles: PropTypes.objectOf(PropTypes.any),
  mobileTimeStyles: PropTypes.objectOf(PropTypes.any),
  labelStyles: PropTypes.objectOf(PropTypes.any),
  mobileLabelStyles: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
}

TimerItemUi.defaultProps = {
  forwardedRef: () => {},
  timeStyles: {},
  mobileTimeStyles: {},
  labelStyles: {},
  mobileLabelStyles: {},
}

export default TimerItemUi
