import styled, { css } from 'styled-components'

interface MobileSubMenuChevronUiProps {
  opened: boolean
  position: 'relative' | 'absolute'
  spacing?: number
}

const MobileSubMenuWrapperUi = styled.div<MobileSubMenuChevronUiProps>`
  margin-left: 20px;
  display: flex;
  position: ${p => p.position};
  flex-direction: column;
  ${p =>
    p.spacing &&
    css`
      gap: ${p.spacing}px;
    `}
  opacity: ${p => (p.opened ? 1 : 0)};
  visibility: ${p => (p.opened ? 'visible' : 'hidden')};
  transition: visibility 0.2s ease-in-out, transform 0.2s, opacity 0.2s;
  transform: ${p => (p.opened ? 'translateY(0)' : 'translateY(-10px)')};
`

export default MobileSubMenuWrapperUi
