import { autoUpdate, flip, shift, useFloating } from '@floating-ui/react-dom'
import React from 'react'
import { HorizontalMenuProps } from 'common/components/entities/Menu/components/Desktop/Horizontal/index'
import HorizontalMenuDropdownUi from 'common/components/entities/Menu/components/Desktop/Horizontal/ui/HorizontalMenuDropdownUi'
import HorizontalMenuDropdownWrapperUi from 'common/components/entities/Menu/components/Desktop/Horizontal/ui/HorizontalMenuDropdownWrapperUi'
import HorizontalMenuSpacerUi from 'common/components/entities/Menu/components/Desktop/Horizontal/ui/HorizontalMenuSpacerUi'
import MenuItemUi from 'common/components/entities/Menu/components/ui/MenuItemUi'
import useActiveMenuItem from 'common/components/entities/Menu/hooks/useActiveMenuItem'
import { MenuItemInterface } from 'common/components/entities/Menu/types/MenuInterface'
import { hasSubMenu } from 'common/components/entities/Menu/utils/hasSubMenu'
import { useFloatHover } from 'common/hooks/useFloatHover'
import { isBlogReplacementPlaceholder } from 'common/utils/blogReplacementsUtils'

interface HorizontalMenuItemProps
  extends Omit<
    HorizontalMenuProps,
    | 'alignSelf'
    | 'mobileAlignSelf'
    | 'disablePointerEvents'
    | 'forceHide'
    | 'padding'
    | 'mobilePadding'
    | 'isVisibleOnMobile'
  > {
  item: MenuItemInterface
  index: number
}

const HorizontalMenuItem = ({
  items,
  activeItemColor,
  activeSubmenuColor,
  mobileActiveItemColor,
  itemUnderlineColor,
  mobileItemUnderlineColor,
  subMenuColor,
  subMenuSpacing,
  mobileSpacing,
  spacing,
  color,
  item,
  mobileColor,
  index,
  mobileLetterSpacing,
  attrId,
  letterSpacing,
  isEditing,
  ...fontProps
}: HorizontalMenuItemProps) => {
  const { show, delayOpen, delayClose } = useFloatHover()

  const isOpenInEditMode = isEditing && item.subMenuOpen

  const { refs, floatingStyles } = useFloating<HTMLButtonElement>({
    placement: 'bottom',
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
    open: show,
    strategy: 'fixed',
  })

  const { setHoveredItemIndex, isItemActive, activeSubitems } = useActiveMenuItem(item, index)

  return (
    <React.Fragment
      key={`${attrId}-horizontal-item-${index}-${item.link || item.text}`}
    >
      <MenuItemUi
        ref={refs.setReference}
        as={hasSubMenu(item) ? 'button' : 'a'}
        href={item.link}
        target={item.target}
        isActive={isItemActive}
        underlineColor={itemUnderlineColor}
        mobileUnderlineColor={mobileItemUnderlineColor}
        letterSpacing={letterSpacing}
        mobileLetterSpacing={mobileLetterSpacing}
        onMouseEnter={() => {
          delayOpen()
          setHoveredItemIndex(index)
        }}
        onMouseLeave={() => {
          delayClose()
          setHoveredItemIndex(null)
        }}
        color={color}
        mobileColor={mobileColor}
        activeColor={activeItemColor}
        mobileActiveColor={mobileActiveItemColor}
        {...fontProps}
      >
        {item.text}
      </MenuItemUi>
      {hasSubMenu(item) && (show || isOpenInEditMode) && (
        <HorizontalMenuDropdownWrapperUi
          ref={refs.setFloating}
          onMouseEnter={() => {
            delayOpen()
            setHoveredItemIndex(index)
          }}
          onMouseLeave={() => {
            delayClose()
            setHoveredItemIndex(null)
          }}
          style={floatingStyles}
        >
          <HorizontalMenuDropdownUi spacing={subMenuSpacing}>
            {item.subMenuItems
              ?.filter(subItem => !isBlogReplacementPlaceholder(subItem.link))
              .map((subItem, subIndex) => (
                <MenuItemUi
                  as={'a'}
                  key={`${attrId}-horizontal-sub-item-${index}-${subIndex}-${
                    subItem.link || subItem.text
                  }`}
                  href={subItem.link}
                  target={subItem.target}
                  isActive={activeSubitems.includes(subIndex)}
                  color={subMenuColor}
                  activeColor={activeSubmenuColor}
                  mobileActiveColor={mobileActiveItemColor}
                  underlineColor={itemUnderlineColor}
                  mobileUnderlineColor={mobileItemUnderlineColor}
                  letterSpacing={letterSpacing}
                  mobileLetterSpacing={mobileLetterSpacing}
                  {...fontProps}
                >
                  {subItem.text}
                </MenuItemUi>
              ))}
          </HorizontalMenuDropdownUi>
        </HorizontalMenuDropdownWrapperUi>
      )}
      {items.length >= 2 && items.length !== index + 1 && (
        <HorizontalMenuSpacerUi
          maxWidth={spacing}
          mobileMaxWidth={mobileSpacing}
        />
      )}
    </React.Fragment>
  )
}

export default HorizontalMenuItem
