import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonSurvey from 'common/components/entities/SurveyNew'
import { SurveyInterface } from 'common/types/entities/SurveyInterface'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'
import { finishSurvey } from 'publisher/store/optIn/optInActions'
import {
  Answer,
  QuestionAnswer,
} from 'publisher/store/optIn/optInStateInterface'
import { addUrlSchemaToTheLink } from '../../../../common/utils/urlUtils'
import { showPopup } from '../../../actionsManagement'

function SurveyNew({ entity }: { entity: SurveyInterface }) {
  const dispatch = useDispatch()
  const [answersIds, setAnswersIds] = useState<Answer[]>([])
  const [questionIndex, setQuestionIndex] = useState(0)
  const [showResult, setShowResult] = useState(false)

  function chooseAnswer(answerId: string, openTextAnswer?: string) {
    const updatedAnswers: Answer[] = [
      ...answersIds.slice(0, questionIndex),
      openTextAnswer
        ? {
            id: answerId,
            openAnswer: openTextAnswer,
          }
        : {
            id: answerId,
          },
      ...answersIds.slice(questionIndex),
    ]
    setAnswersIds(updatedAnswers)
    if (questionIndex + 1 === entity.questions.length) {
      if (entity.popupId) {
        dispatch(showPopup(entity.popupId))
      } else if (entity.redirectUrl) {
        window.location.assign(addUrlSchemaToTheLink(entity.redirectUrl))
      } else {
        setShowResult(true)
        const questionsAnswers = updatedAnswers.reduce(
          (acc, answer, index) => [
            ...acc,
            {
              id: entity.questions[index].id,
              answers: [answer],
            },
          ],
          [] as QuestionAnswer[],
        )
        dispatch(
          finishSurvey({
            [entity.id]: {
              surveyEntityId: entity.id,
              questions: questionsAnswers,
            },
          }),
        )
      }
    } else {
      setQuestionIndex(questionIndex + 1)
    }
  }

  return (
    <StyleWrapper
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonSurvey
        question={entity.questions[questionIndex]}
        questionTitleFontSize={entity.questionTitleFontSize}
        mobileQuestionTitleFontSize={entity.mobileQuestionTitleFontSize}
        questionSubTitleFontSize={entity.questionSubTitleFontSize}
        mobileQuestionSubTitleFontSize={entity.mobileQuestionSubTitleFontSize}
        answerFontSize={entity.answerFontSize}
        mobileAnswerFontSize={entity.mobileAnswerFontSize}
        fontFamily={entity.fontFamily}
        mobileFontFamily={entity.mobileFontFamily}
        fontStyle={entity.fontStyle}
        fontWeight={entity.fontWeight}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontWeight={entity.mobileFontWeight}
        answerColor={entity.answerColor}
        answerOutlineColor={entity.answerOutlineColor}
        answerBackgroundColor={entity.answerBackgroundColor}
        questionTitleColor={entity.questionTitleColor}
        questionSubTitleColor={entity.questionSubTitleColor}
        answerBorder={entity.answerBorder}
        mobileAnswerBorder={entity.answerBorder}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        showResult={showResult}
        choose={chooseAnswer}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
      >
        <BaseNodeChildren childIds={entity.childIds} />
      </CommonSurvey>
    </StyleWrapper>
  )
}

export default SurveyNew
