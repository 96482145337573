import styled from 'styled-components'

type BlogPostContentItemUiProps = {}

const ContentItemUi = styled.a<BlogPostContentItemUiProps>`
  color: ${p => p.color};
  &:hover {
    box-shadow: inset 0 -1px 0;
  }
`

export default ContentItemUi
