import styled, { css } from 'styled-components'
import { BorderType, PaddingType } from 'common/types/styleTypes'
import {
  backgroundColorCss,
  borderCss,
  boxShadowCss,
  fontCss,
  colorCss,
  mobileColorCss,
  FontProps,
  mobileBackgroundColorCss,
  mobileBorderCss,
  mobileBoxShadowCss,
  mobileFontCss,
  mobilePaddingsCss,
  paddingsCss,
} from 'common/utils/styleUtilsNew'

type TextAreaUiProps = {
  height: number
  border: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  padding: PaddingType
  mobilePadding?: PaddingType
  backgroundColor: string
  mobileBackgroundColor?: string
  boxShadow?: string
  mobileBoxShadow?: string
} & FontProps

const TextAreaUi = styled.textarea<TextAreaUiProps>`
  height: ${p => p.height}px;
  width: 100%;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  appearance: none;
  &::placeholder {
    ${colorCss}
  }
  ${fontCss}
  ${backgroundColorCss}
  ${borderCss}
  ${boxShadowCss}
  ${paddingsCss}
  ${p => p.theme.phone} {
    ${mobileFontCss}
    ${mobileBorderCss}
    ${mobileBackgroundColorCss}
    ${mobileBoxShadowCss}
    ${mobilePaddingsCss}
    &::placeholder {
      ${mobileColorCss}
    }
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
      ${mobileBackgroundColorCss}
      ${mobileBorderCss}
      ${mobileBoxShadowCss}
      ${mobilePaddingsCss}
      &::placeholder {
        ${mobileColorCss}
      }
    `}
`

export default TextAreaUi
