import React from 'react'
import {
  CommonSurveyProps,
  SurveyQuestionType,
} from 'common/types/entities/SurveyInterface'
import SurveyQuestion from './SurveyQuestion'
import SurveyUi from './ui/SurveyUi'

interface SurveyProps extends CommonSurveyProps {
  showResult: boolean
  children: React.ReactChildren | React.ReactElement
  question: SurveyQuestionType
  choose: (id: string, openTextAnswer?: string) => void
  isEdit?: boolean
}

function Survey(props: SurveyProps) {
  return (
    <SurveyUi
      padding={props.padding}
      mobilePadding={props.mobilePadding}
      fontFamily={props.fontFamily}
      fontStyle={props.fontStyle}
      fontWeight={props.fontWeight}
      mobileFontFamily={props.mobileFontFamily}
      mobileFontStyle={props.mobileFontStyle}
      mobileFontWeight={props.mobileFontWeight}
      backgroundColor={props.backgroundColor}
      mobileBackgroundColor={props.mobileBackgroundColor}
      border={props.border}
      mobileBorder={props.mobileBorder}
      boxShadow={props.boxShadow}
      mobileBoxShadow={props.mobileBoxShadow}
    >
      {props.showResult ? (
        props.children
      ) : (
        <SurveyQuestion
          key={props.question.id}
          question={props.question}
          questionTitleFontSize={props.questionTitleFontSize}
          mobileQuestionTitleFontSize={props.mobileQuestionTitleFontSize}
          questionSubTitleFontSize={props.questionSubTitleFontSize}
          mobileQuestionSubTitleFontSize={props.mobileQuestionSubTitleFontSize}
          answerFontSize={props.answerFontSize}
          mobileAnswerFontSize={props.mobileAnswerFontSize}
          answerBorder={props.answerBorder}
          mobileAnswerBorder={props.mobileAnswerBorder}
          answerColor={props.answerColor}
          answerOutlineColor={props.answerOutlineColor}
          answerBackgroundColor={props.answerBackgroundColor}
          questionTitleColor={props.questionTitleColor}
          questionSubTitleColor={props.questionSubTitleColor}
          choose={props.choose}
          isEdit={props.isEdit}
        />
      )}
    </SurveyUi>
  )
}

export enum SurveyActions {
  Redirect = 'redirect',
  Nothing = 'nothing',
  OpenPopup = 'openPopup',
}

export const actions = {
  redirect: 'redirect',
  nothing: 'nothing',
  openPopup: 'openPopup',
}

export const actionsNames = {
  [actions.redirect]: 'components.survey.actions.redirect',
  [actions.openPopup]: 'components.survey.actions.open_popup',
  [actions.nothing]: 'components.survey.actions.nothing',
}

export default Survey
