import React from 'react'
import { AlignType } from 'common/types/styleTypes'
import { FontProps } from 'common/utils/styleUtilsNew'
import BlogPostDateUi from './ui/BlogPostDateUiNew'

const formatDate = (date: Date, locale: string) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  return new Intl.DateTimeFormat(locale, options).format(date)
}

type BlogPostDateProps = {
  date: Date
  locale: string
  attrId: string
  mobileLineHeight?: number
  lineHeight?: number
  alignSelf: AlignType
  mobileAlignSelf?: AlignType
} & FontProps

function BlogPostDateNew({
  date,
  locale,
  attrId,
  fontSize,
  fontFamily,
  fontStyle,
  fontWeight,
  mobileLineHeight,
  mobileFontSize,
  mobileFontFamily,
  mobileFontStyle,
  mobileFontWeight,
  lineHeight,
  color,
  mobileColor,
  alignSelf,
  mobileAlignSelf,
}: BlogPostDateProps) {
  if (typeof window === 'undefined' || !(date instanceof Date)) {
    return (
      <BlogPostDateUi
        id={attrId}
        fontSize={fontSize}
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        mobileFontSize={mobileFontSize}
        mobileFontFamily={mobileFontFamily}
        mobileFontStyle={mobileFontStyle}
        mobileFontWeight={mobileFontWeight}
        lineHeight={lineHeight}
        mobileLineHeight={mobileLineHeight}
        color={color}
        mobileColor={mobileColor}
        alignSelf={alignSelf}
        mobileAlignSelf={mobileAlignSelf}
      />
    )
  }

  return (
    <BlogPostDateUi
      id={attrId}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      mobileFontSize={mobileFontSize}
      mobileFontFamily={mobileFontFamily}
      mobileFontStyle={mobileFontStyle}
      mobileFontWeight={mobileFontWeight}
      lineHeight={lineHeight}
      mobileLineHeight={mobileLineHeight}
      color={color}
      mobileColor={mobileColor}
      alignSelf={alignSelf}
      mobileAlignSelf={mobileAlignSelf}
    >
      {formatDate(date, locale)}
    </BlogPostDateUi>
  )
}

export default BlogPostDateNew
