import styled, { CSSProp } from 'styled-components'
import { FlagEmoji } from '../../FlagEmoji/FlagEmoji'

export const CountrySelectorFlagEmojiUi = styled(FlagEmoji)<{
  disabled?: boolean
  selectedCountry?: boolean
  styles?: CSSProp
}>`
  margin: 0 4px;

  ${({ selectedCountry }) =>
    `visibility: ${selectedCountry ? 'visible' : 'hidden'}`}

  ${({ disabled }) => (disabled ? `opacity: 0.75;` : '')}
  ${({ styles }) => styles}
`
