import styled, { css } from 'styled-components'

type LayoutGridColumnUiProps = {
  columnStart?: number
  columnEnd?: number
}

const LayoutGridColumnUi = styled.div<LayoutGridColumnUiProps>`
  display: grid;
  ${p =>
    p.columnStart &&
    css`
      grid-column-start: ${p.columnStart};
    `}
  ${p =>
    p.columnEnd &&
    css`
      grid-column-end: ${p.columnEnd};
    `}
`

export default LayoutGridColumnUi
