import styled, { css } from 'styled-components'

const SideMenuNavUi = styled.ul<{ spacing?: number }>`
  & li {
    ${({ spacing }) =>
      spacing &&
      css`
        margin-bottom: ${spacing}px;
      `}
  }
  & li:last-of-type {
    margin-bottom: 0;
  }
  margin-top: 50px;
  margin-right: 10px;
  margin-left: 10px;
  list-style: none;
  padding: 0;
`

export default SideMenuNavUi
