import styled, { css } from 'styled-components'
import { CommonContainerInterface } from 'common/types/entities/EntityInterface'
import { containerCss } from 'common/utils/styleUtilsNew'

interface ContentBoxWrapperUiProps extends CommonContainerInterface {
  htmlAttrId: string
}

const ContentBoxWrapperUi = styled.div.attrs<ContentBoxWrapperUiProps>(p => ({
  id: p.htmlAttrId,
}))<ContentBoxWrapperUiProps>`
  min-height: 100px;
  width: 100%;
  overflow-x: auto;
  ${containerCss}
  ${p => p.theme.phone} {
    ${containerCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${containerCss}
    `}
`

export default ContentBoxWrapperUi
