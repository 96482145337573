import styled from 'styled-components'

const MobileMenuItemWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  cursor: pointer;
`

export default MobileMenuItemWrapper
