import React from 'react'
import styled, { css } from 'styled-components'
import { TimerItemStylesInterface } from 'common/components/entities/Timer/types/TimerItemStylesInterface'
import { fontCss, mobileFontCss } from 'common/utils/styleUtilsNew'

const StyledCounterItemTime = styled.section<TimerItemStylesInterface>`
  font-weight: bold;
  ${fontCss}
  ${p => p.theme.phone} {
    ${mobileFontCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
    `}
`

const StyledCounterItemLabel = styled.section<TimerItemStylesInterface>`
  text-transform: uppercase;
  ${fontCss}
  ${p => p.theme.phone} {
    ${mobileFontCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
    `}
`

const StyledCounterItem = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
`

interface TimerItemUiProps {
  label: string
  time: number
  labelStyles: TimerItemStylesInterface
  timeStyles: TimerItemStylesInterface
}

const TimerItemUi = ({
  label,
  time,
  timeStyles,
  labelStyles,
}: TimerItemUiProps) => (
  <StyledCounterItem>
    <StyledCounterItemTime
      color={timeStyles.color}
      mobileColor={timeStyles.mobileColor}
      fontSize={timeStyles.fontSize}
      mobileFontSize={timeStyles.mobileFontSize}
    >
      {time < 10 ? `0${time}` : time}
    </StyledCounterItemTime>
    <StyledCounterItemLabel
      color={labelStyles.color}
      mobileColor={labelStyles.mobileColor}
      fontSize={labelStyles.fontSize}
      mobileFontSize={labelStyles.mobileFontSize}
    >
      {label}
    </StyledCounterItemLabel>
  </StyledCounterItem>
)

export default TimerItemUi
