import React, { memo } from 'react'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import FieldErrors from 'publisher/components/FieldErrors'
import BaseButton from 'publisher/components/entities/BaseButton'
import { useClosePopup } from 'publisher/hooks/use-close-popup'
import useBlog, { selectors as blogSelectors } from 'publisher/hooks/useBlog'
import useOptInSubmit from 'publisher/hooks/useOptInSubmit'

interface SendFormButtonProps {
  entity: OldEntityInterface
}

function SendFormButton({ entity }: SendFormButtonProps) {
  const { styles, mobileStyles, options, mobileOptions } = entity
  const blogLayoutPageId = useBlog(blogSelectors.getBlogLayoutPageId)
  const closePopup = useClosePopup(entity, blogLayoutPageId)

  const [isLoading, errors, submit] = useOptInSubmit({
    optInButtonEntity: entity,
    closePopup,
  })

  return (
    <React.Fragment>
      <BaseButton
        // @ts-ignore
        handleClick={submit}
        // @ts-ignore
        disabled={isLoading}
        styles={styles}
        mobileStyles={mobileStyles}
        attrId={options.attrId}
        text={options.text}
        subText={options.subText}
        subTextColor={options.subTextColor}
        subTextFontSize={options.subTextFontSize}
        subTextFontWeight={options.subTextFontWeight}
        subTextFontStyle={options.subTextFontStyle}
        subTextFontFamily={options.subTextFontFamily}
        mobileSubTextColor={mobileOptions.subTextColor}
        mobileSubTextFontSize={mobileOptions.subTextFontSize}
        mobileSubTextFontWeight={mobileOptions.subTextFontWeight}
        mobileSubTextFontStyle={mobileOptions.subTextFontStyle}
        mobileSubTextFontFamily={mobileOptions.subTextFontFamily}
        iconClassNameBefore={entity.options.iconClassNameBefore}
        iconClassNameAfter={entity.options.iconClassNameAfter}
        borderType={options.borderType}
        mobileBorderType={mobileOptions.borderType}
        appearance={options.appearance}
        hoverStyles={options?.hover}
        hoverMobileStyles={mobileOptions?.hover}
      />
      {/* @ts-ignore */}
      <FieldErrors errors={errors} align="center" />
    </React.Fragment>
  )
}

export default memo(SendFormButton)
