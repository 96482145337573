export const getDailyTime = ({
  hours,
  minutes,
  seconds,
}: {
  hours: number
  minutes: number
  seconds: number
}) => {
  const currentDate = new Date()
  const endDate = new Date()
  endDate.setHours(Number(hours))
  endDate.setMinutes(Number(minutes))
  endDate.setSeconds(Number(seconds))

  // for example current hour is 22 and end date hour is 20, so we need to set +1 day
  if (currentDate.getTime() > endDate.getTime()) {
    endDate.setDate(currentDate.getDate() + 1)
  }
  if (endDate.getDate() === currentDate.getDate()) {
    return endDate.getTime()
  } else {
    return null
  }
}
