import styled from 'styled-components'

export const Skeleton = styled.span<{ width?: number; height: number }>`
  animation: shimmer 1.2s ease-in-out 0s infinite;
  width: ${({ width }) => `${width}px` || '100%'};
  height: ${({ height }) => `${height}px`};
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;

  @keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }
`
