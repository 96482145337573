import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonButton from 'common/components/entities/Button/Button'
import { WebinarCallToActionInterface } from 'common/types/entities/WebinarCallToActionInterface'
import { useDelay } from 'publisher/hooks/useDelay'
import { useWebinar } from 'publisher/store'
import webinarSelectors from 'publisher/store/webinar/webinarSelectors'
import { addUrlSchemaToTheLink } from '../../../../common/utils/urlUtils'

export type WebinarCallToActionPropsType = {
  entity: WebinarCallToActionInterface
}

function WebinarCallToActionNew({ entity }: WebinarCallToActionPropsType) {
  const sessionDelayInSeconds = useWebinar(
    webinarSelectors.getSessionDelayInSeconds,
  )
  const isVisible = useDelay(
    (entity.delay ? +entity.delay : 0) + sessionDelayInSeconds,
  )

  const handleClick = () => {
    if (entity.url) {
      window.location.assign(addUrlSchemaToTheLink(entity.url))
    }
  }

  return (
    <StyleWrapper
      styles={{ ...entity.margin, ...wrapperStyles }}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      {isVisible && (
        <CommonButton
          text={entity.text}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          subText={entity.subText}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          alignSelf={entity.alignSelf}
          backgroundColor={entity.backgroundColor}
          mobileBackgroundColor={entity.mobileBackgroundColor}
          attrId={entity.htmlAttrId}
          width={entity.width}
          mobileWidth={entity.mobileWidth}
          textColor={entity.textColor}
          subTextColor={entity.subTextColor}
          mobileTextColor={entity.mobileTextColor}
          mobileSubTextColor={entity.mobileSubTextColor}
          textFontSize={entity.textFontSize}
          mobileTextFontSize={entity.mobileTextFontSize}
          subTextFontSize={entity.subTextFontSize}
          mobileSubTextFontSize={entity.mobileSubTextFontSize}
          textFontFamily={entity.textFontFamily}
          textFontWeight={entity.textFontWeight}
          textFontStyle={entity.textFontStyle}
          subTextFontFamily={entity.subTextFontFamily}
          subTextFontWeight={entity.subTextFontWeight}
          subTextFontStyle={entity.subTextFontStyle}
          mobileTextFontFamily={entity.mobileTextFontFamily}
          mobileTextFontWeight={entity.mobileTextFontWeight}
          mobileTextFontStyle={entity.mobileTextFontStyle}
          mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
          mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
          mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          iconClassNameBefore={entity.iconClassNameBefore}
          iconClassNameAfter={entity.iconClassNameAfter}
          onClick={handleClick}
          hover={entity.hover}
          mobileHover={entity.mobileHover}
        />
      )}
    </StyleWrapper>
  )
}

export default WebinarCallToActionNew
