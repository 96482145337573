import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonLatestBlogPosts from 'common/components/entities/BlogLatestPosts/LatestBlogPostsNew'
import {
  publisherImagePlaceholderSrc,
  publisherBlogPostTs,
  rangePosts,
} from 'common/components/entities/BlogPostsNew/utils'
import { BlogPost } from 'common/types/entities/BlogPostListingInterface'
import { LatestBlogPostsInterface } from 'common/types/entities/LatestBlogPostsInterface'
import useBlog, { selectors as blogSelectors } from 'publisher/hooks/useBlog'
import { managementSelectors, useManagement } from 'publisher/store'
import { usePage, typedPageSelectors } from 'publisher/store/index'

function parsePosts(postsReplacement: string) {
  try {
    return JSON.parse(postsReplacement) as BlogPost[]
  } catch (e) {
    return []
  }
}

function LatestBlogPostsNew({ entity }: { entity: LatestBlogPostsInterface }) {
  const blogPath = useBlog(blogSelectors.getBlogPath)
  const locale = usePage(typedPageSelectors.getLocale)
  const isPreview = useManagement(managementSelectors.isPreview)
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const latestPosts = parsePosts(entity.postsReplacement)
  let calculatedDisplayHorizontally = entity.displayHorizontally

  if (typeof window !== 'undefined' && !isDesktop) {
    calculatedDisplayHorizontally = false
  }
  const posts = isPreview
    ? rangePosts(
        entity.maxPosts,
        publisherBlogPostTs,
        publisherImagePlaceholderSrc,
      )
    : latestPosts

  return (
    <StyleWrapper
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonLatestBlogPosts
        htmlAttrId={entity.htmlAttrId}
        displayHorizontally={calculatedDisplayHorizontally}
        maxPosts={entity.maxPosts}
        locale={locale}
        blogPath={blogPath}
        posts={posts}
        imageMargin={entity.imageMargin}
        mobileImageMargin={entity.mobileImageMargin}
        titleMargin={entity.titleMargin}
        mobileTitleMargin={entity.mobileTitleMargin}
        categoriesMargin={entity.categoriesMargin}
        mobileCategoriesMargin={entity.mobileCategoriesMargin}
        descriptionMargin={entity.descriptionMargin}
        mobileDescriptionMargin={entity.mobileDescriptionMargin}
        dateMargin={entity.dateMargin}
        mobileDateMargin={entity.mobileDateMargin}
        maxPostsPerRow={entity.maxPostsPerRow}
        showDate={entity.showDate}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        imageBorder={entity.imageBorder}
        mobileImageBorder={entity.mobileImageBorder}
        imageBoxShadow={entity.imageBoxShadow}
        mobileImageBoxShadow={entity.mobileImageBoxShadow}
        titleFontSize={entity.titleFontSize}
        mobileTitleFontSize={entity.mobileTitleFontSize}
        titleLineHeight={entity.titleLineHeight}
        mobileTitleLineHeight={entity.mobileTitleLineHeight}
        titleFontFamily={entity.titleFontFamily}
        titleFontWeight={entity.titleFontWeight}
        titleFontStyle={entity.titleFontStyle}
        mobileTitleFontFamily={entity.mobileTitleFontFamily}
        mobileTitleFontWeight={entity.mobileTitleFontWeight}
        mobileTitleFontStyle={entity.mobileTitleFontStyle}
        titleLetterSpacing={entity.titleLetterSpacing}
        mobileTitleLetterSpacing={entity.mobileTitleLetterSpacing}
        titleAlign={entity.titleAlign}
        mobileTitleAlign={entity.mobileTitleAlign}
        titleColor={entity.titleColor}
        mobileTitleColor={entity.mobileTitleColor}
        descriptionFontSize={entity.descriptionFontSize}
        mobileDescriptionFontSize={entity.mobileDescriptionFontSize}
        descriptionLineHeight={entity.descriptionLineHeight}
        mobileDescriptionLineHeight={entity.mobileDescriptionLineHeight}
        descriptionFontFamily={entity.descriptionFontFamily}
        descriptionFontWeight={entity.descriptionFontWeight}
        descriptionFontStyle={entity.descriptionFontStyle}
        mobileDescriptionFontFamily={entity.mobileDescriptionFontFamily}
        mobileDescriptionFontWeight={entity.mobileDescriptionFontWeight}
        mobileDescriptionFontStyle={entity.mobileDescriptionFontStyle}
        descriptionAlign={entity.descriptionAlign}
        mobileDescriptionAlign={entity.mobileDescriptionAlign}
        descriptionColor={entity.descriptionColor}
        mobileDescriptionColor={entity.mobileDescriptionColor}
        categoriesFontSize={entity.categoriesFontSize}
        mobileCategoriesFontSize={entity.mobileCategoriesFontSize}
        categoriesFontFamily={entity.categoriesFontFamily}
        categoriesFontWeight={entity.categoriesFontWeight}
        categoriesFontStyle={entity.categoriesFontStyle}
        mobileCategoriesFontFamily={entity.mobileCategoriesFontFamily}
        mobileCategoriesFontWeight={entity.mobileCategoriesFontWeight}
        mobileCategoriesFontStyle={entity.mobileCategoriesFontStyle}
        categoriesAlign={entity.categoriesAlign}
        mobileCategoriesAlign={entity.mobileCategoriesAlign}
        categoriesColor={entity.categoriesColor}
        mobileCategoriesColor={entity.mobileCategoriesColor}
        dateFontSize={entity.dateFontSize}
        mobileDateFontSize={entity.mobileDateFontSize}
        dateFontFamily={entity.dateFontFamily}
        dateFontWeight={entity.dateFontWeight}
        dateFontStyle={entity.dateFontStyle}
        mobileDateFontFamily={entity.mobileDateFontFamily}
        mobileDateFontWeight={entity.mobileDateFontWeight}
        mobileDateFontStyle={entity.mobileDateFontStyle}
        dateAlign={entity.dateAlign}
        mobileDateAlign={entity.mobileDateAlign}
        dateColor={entity.dateColor}
        mobileDateColor={entity.mobileDateColor}
        rowsGap={entity.rowsGap}
        columnsGap={entity.columnsGap}
      />
    </StyleWrapper>
  )
}

export default LatestBlogPostsNew
