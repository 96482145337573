import styled from 'styled-components'

const PricePlanListUi = styled.div`
  display: flex;
  border: 2px solid #1ba0ff;
  border-radius: 5px;
  margin-bottom: 30px;
`

export default PricePlanListUi
