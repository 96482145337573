import React from 'react'
import { BlogPost } from 'common/types/entities/BlogPostListingInterface'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { BlogPostCardProps } from '.'
import BlogPostCard from './BlogPostCard'
import LayoutGridUi from './ui/LayoutGridUi'

type ThreeTwoPostsPerRowLayoutProps = {
  blogPostCardProps: BlogPostCardProps
  posts: BlogPost[]
  threeElementsInRow: boolean
}

function ThreeTwoPostsPerRowLayout({
  posts,
  blogPostCardProps,
  threeElementsInRow,
}: ThreeTwoPostsPerRowLayoutProps) {
  return (
    <>
      {threeElementsInRow ? (
        <LayoutGridUi gridTemplateColumns="1fr 1fr 1fr">
          {posts.map((post: BlogPost, index: number) => (
            <BlogPostCard
              key={index}
              post={post}
              {...blogPostCardProps}
              imagePlacement={ImagePlacementVariants.Background}
            />
          ))}
        </LayoutGridUi>
      ) : (
        <LayoutGridUi gridTemplateColumns="1fr 1fr">
          {posts.map((post: BlogPost, index: number) => (
            <BlogPostCard
              key={index}
              post={post}
              {...blogPostCardProps}
              imagePlacement={ImagePlacementVariants.Background}
            />
          ))}
        </LayoutGridUi>
      )}
    </>
  )
}

export default ThreeTwoPostsPerRowLayout
