import styled from 'styled-components'

type FaqItemContentUiProps = {
  expanded: boolean
}

const FaqItemContentUi = styled.div<FaqItemContentUiProps>`
  height: ${p => (p.expanded ? 'auto' : 0)};
  overflow: ${p => (p.expanded ? 'visible' : 'hidden')};
`

export default FaqItemContentUi
