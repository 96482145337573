import styled, { CSSProp } from 'styled-components'

export const DialCodePreviewUi = styled.div<{
  disabled?: boolean
  styles?: CSSProp
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.57em;
  border: 1px solid gainsboro;
  margin-right: -1px; /* for border overlap */
  background-color: white;
  color: #222;
  font-size: 14px;

  ${({ disabled }) =>
    disabled &&
    `background-color: whitesmoke;
    color: #666;`}
  ${({ styles }) => styles}
`
