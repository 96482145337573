import styled, { css } from 'styled-components'

const BreadcrumbsUi = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  /* To prevent overflow of the mobile editor with white-space:nowrap */
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      max-width: 290px;
    `}
`

export default BreadcrumbsUi
