import React from 'react'
import { useTranslation } from 'react-i18next'
import { BorderType, PaddingType } from 'common/types/styleTypes'
import WebinarSessionDateTimeIconNewUi from './ui/WebinarSessionDateTimeIconNewUi'
import WebinarSessionDateTimeNewUi from './ui/WebinarSessionDateTimeNewUi'

type WebinarSessionDateTimeNewProps = {
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
  fontSize: number
  iconFontSize: number
  boxShadow?: string
  padding: PaddingType
  backgroundColor?: string
  color?: string
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  mobileColor?: string
  mobileBackgroundColor?: string
  mobilePadding: PaddingType
  mobileBoxShadow?: string
  mobileIconFontSize?: number
  mobileFontSize?: number
  mobileFontFamily?: string
  mobileFontStyle?: string
  mobileFontWeight?: string
  timestamp?: number
  attrId: string
}
const WebinarSessionDateTimeNew = ({
  fontFamily,
  fontStyle,
  fontWeight,
  fontSize,
  iconFontSize,
  boxShadow,
  padding,
  backgroundColor,
  color,
  border,
  mobileBorder,
  mobileColor,
  mobileBackgroundColor,
  mobilePadding,
  mobileBoxShadow,
  mobileIconFontSize,
  mobileFontSize,
  mobileFontFamily,
  mobileFontStyle,
  mobileFontWeight,
  timestamp,
  attrId,
}: WebinarSessionDateTimeNewProps) => {
  const { t, i18n } = useTranslation('common')
  const date = new Intl.DateTimeFormat(i18n.language, {
    //@ts-ignore
    timeStyle: 'short',
    dateStyle: 'long',
  })
  const dateTimeString = date.format(timestamp ? timestamp : Date.now())
  return (
    <WebinarSessionDateTimeNewUi
      id={attrId}
      fontFamily={fontFamily}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      fontSize={fontSize}
      boxShadow={boxShadow}
      padding={padding}
      backgroundColor={backgroundColor}
      color={color}
      border={border}
      mobileBorder={mobileBorder}
      mobileColor={mobileColor}
      mobileBackgroundColor={mobileBackgroundColor}
      mobilePadding={mobilePadding}
      mobileBoxShadow={mobileBoxShadow}
      mobileFontSize={mobileFontSize}
      mobileFontFamily={mobileFontFamily}
      mobileFontStyle={mobileFontStyle}
      mobileFontWeight={mobileFontWeight}
    >
      <WebinarSessionDateTimeIconNewUi
        className="far fa-clock"
        fontSize={iconFontSize}
        mobileFontSize={mobileIconFontSize}
      />
      <span>
        {t('components.webinar_registration_date_time.schedule_training')}
      </span>
      <span>{dateTimeString}</span>
    </WebinarSessionDateTimeNewUi>
  )
}

export default WebinarSessionDateTimeNew
