import styled from 'styled-components'

const Value = styled.div`
  width: ${({ directionRow }) => (directionRow ? '150px' : '100%')};
  position: relative;
  float: right;
  height: auto;
`

export default Value
