import styled from 'styled-components'

type BurgerSpanUiProps = {
  opened: boolean
  openedColor: string
  burgerIconColor: string
}
const BurgerSpanUi = styled.span<BurgerSpanUiProps>`
  display: block;
  cursor: pointer;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
    background-color 0.3s 0.15s ease-in;
  background-color: ${p =>
    p.opened && p.openedColor ? p.openedColor : p.burgerIconColor};
`

export default BurgerSpanUi
