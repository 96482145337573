import styled from 'styled-components'
import { fontCss, mobileFontCss, FontProps } from 'common/utils/styleUtilsNew'

const SubTextUiNew = styled.small<FontProps>`
  ${fontCss}
  ${p => p.theme.phone} {
    ${mobileFontCss}
  }
  ${p => p.theme.isEditorMobileMode && mobileFontCss}
`

export default SubTextUiNew
