import PropTypes from 'prop-types'

// eslint-disable-next-line import/prefer-default-export
export const entity = PropTypes.shape({
  id: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  styles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  mobileStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  childIds: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string.isRequired,
})

export const file = PropTypes.shape({
  id: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  filenameWithoutHash: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
})

export const offerSubscriptionPlan = PropTypes.shape({
  trialPeriod: PropTypes.number,
  amount: PropTypes.number,
  interval: PropTypes.string,
  limitOfPayments: PropTypes.number,
})

export const offerPricePlan = PropTypes.shape({
  limitedSubscriptionPlan: PropTypes.bool,
  currency: PropTypes.string,
  subscriptionPlan: offerSubscriptionPlan,
  directChargeAmount: PropTypes.number,
})

export const offer = PropTypes.shape({
  id: PropTypes.number,
  offerFunnelId: PropTypes.number,
  pricePlans: PropTypes.arrayOf(offerSubscriptionPlan),
})

export const deviceMode = PropTypes.string

export const physicalProduct = PropTypes.shape({
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          value: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
})
