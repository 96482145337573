import styled from 'styled-components'

export const InputRadio = styled.input.attrs({ type: 'radio' })<{
  checkedColor: string
}>`
  margin-right: 5px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;

  background-clip: content-box;
  border: 1px solid #dae1ea;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;

  &:checked {
    background-color: ${p => p.checkedColor};
  }
`
