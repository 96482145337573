import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import WebinarSessionDateTimeUi from './ui/WebinarSessionDateTimeUi'
import WebinarSessionDateTimeIconUi from './ui/WebinarSessionDateTimeIconUi'

const WebinarSessionDateTime = ({
  options,
  styles,
  mobileStyles,
  timestamp,
}) => {
  const { t, i18n } = useTranslation('common')
  let date = new Intl.DateTimeFormat(i18n.language, {
    timeStyle: 'short',
    dateStyle: 'long',
  })
  const dateTimeString = date.format(timestamp)
  return (
    <WebinarSessionDateTimeUi
      attrId={options.attrId}
      styles={styles}
      mobileStyles={mobileStyles}
    >
      <WebinarSessionDateTimeIconUi
        className="far fa-clock"
        styles={options.iconStyles}
      />
      <span>
        {t('components.webinar_registration_date_time.schedule_training')}
      </span>
      <span>{dateTimeString}</span>
    </WebinarSessionDateTimeUi>
  )
}

WebinarSessionDateTime.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
  timestamp: PropTypes.number.isRequired,
}

export default provideMobileStyles(WebinarSessionDateTime)
