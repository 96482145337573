import styled from 'styled-components'
import { BorderType, PaddingType } from 'common/types/styleTypes'
import {
  borderCss,
  colorCss,
  FontProps,
  mobileBorderCss,
} from 'common/utils/styleUtilsNew'

type TextAreaUiProps = {
  height?: number
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  padding?: PaddingType
  mobilePadding?: PaddingType
  backgroundColor: string
  mobileBackgroundColor?: string
  borderColor?: string
  boxShadow?: string
  mobileBoxShadow?: string
  isEdit?: boolean
} & FontProps

const SurveyTextAreaUi = styled.textarea<TextAreaUiProps>`
  font-size: ${p => p.fontSize}px;
  width: 100%;
  background-color: ${p => p.backgroundColor};
  border: 1px solid transparent;
  ${p => (p.isEdit ? 'resize: none' : 'resize: vertical')};
  outline-color: red;
  ${p => p.borderColor && `outline-color: ${p.borderColor};`};
  padding: 10px 26px 10px 10px;
  margin-bottom: 10px;
  ${borderCss}
  &:hover {
    border: 1px solid ${p => p.borderColor};
  }
  ${p => p.theme.phone} {
    ${mobileBorderCss};
    ${p => p.mobileFontSize && `font-size: ${p.mobileFontSize}px;`};
  }
  ${p => p.theme.isEditorMobileMode && mobileBorderCss}
  ${p => p.theme.isEditorMobileMode && `font-size: ${p.mobileFontSize}px;`}
  &::placeholder {
    ${colorCss}
  }
`

export default SurveyTextAreaUi
