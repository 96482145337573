import styled from 'styled-components'

type BlogPostContentTableHeaderTitleUiProps = {
  color: string
  fontSize: number
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
}

const ContentTableHeaderTitleUi = styled.span<BlogPostContentTableHeaderTitleUiProps>`
  color: ${p => p.color};
  font-size: ${p => p.fontSize}px;
  ${p => p.fontFamily && `font-family: ${p.fontFamily}`};
  ${p => p.fontStyle && `font-style: ${p.fontStyle}`};
  ${p => p.fontWeight && `font-weight: ${p.fontWeight}`};
`

export default ContentTableHeaderTitleUi
