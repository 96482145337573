import React from 'react'
import { useDispatch } from 'react-redux'
import Overlay from 'common/components/core/Overlay'
import structureTypes from 'common/constants/structureTypes'
import { entity } from 'common/types'
import { closePopup } from 'publisher/actionsManagement'
import PopupWrapper from 'publisher/pages/ui/PopupWrapper'
import { PopupVisibilityUi } from 'publisher/pages/ui/popup-visibility-controller-ui'
import { getEntitiesByType, getPageId } from 'publisher/reducers/pageReducer'
import Popup from '../../components/entities/Popup'
import useManagement, {
  selectors as managementSelectors,
} from '../../hooks/useManagement'
import usePage from '../../hooks/usePage'
import { setCloseCookie } from '../../utils/cookie'

const TriggeredPopup = () => {
  const triggeredPopupId = useManagement(
    managementSelectors.getTriggeredPopupId,
  )
  const popups = usePage(getEntitiesByType(structureTypes.POPUP))
  const pageId = usePage(getPageId)
  const dispatch = useDispatch()

  const handleClose = (popupId, closedDays) => {
    setCloseCookie(pageId, popupId, closedDays)
    dispatch(closePopup)
  }

  return (
    <>
      {popups?.map(popup => (
        <PopupVisibilityUi
          key={popup.id}
          isVisible={triggeredPopupId === popup.id}
        >
          <PopupWrapper>
            <Popup
              entity={popup}
              overflow="auto"
              closePopup={() => handleClose(popup.id, popup.options.closedDays)}
            />
          </PopupWrapper>
          <Overlay dark />
        </PopupVisibilityUi>
      ))}
    </>
  )
}

TriggeredPopup.propTypes = {
  activePopup: entity,
}

TriggeredPopup.defaultProps = {
  activePopup: null,
}

export default TriggeredPopup
