import {
  SUBMIT_FAIL,
  SUBMIT_SUCCESS,
  FIELD_CHANGE,
  BOOKING_FIELD_CHANGE,
} from 'publisher/actionTypes'

export const optInSuccess = () => ({
  type: SUBMIT_SUCCESS,
})

export const optInFail = errors => ({
  type: SUBMIT_FAIL,
  payload: errors,
})

export const changeField = (slug, value) => ({
  type: FIELD_CHANGE,
  payload: {
    slug,
    value,
  },
})

export const changeBookingField = (slug, value) => ({
  type: BOOKING_FIELD_CHANGE,
  payload: {
    slug,
    value,
  },
})

/**
 * @deprecated
 * @param slug
 * @param value
 * @returns {function(...[*]=)}
 */
export const change = (slug, value) => dispatch => {
  dispatch({
    type: FIELD_CHANGE,
    payload: { slug, value },
  })
}
