import styled, { CSSProp } from 'styled-components'
import { CountrySelectorStyleProps } from '..'

export const CountrySelectorButtonUi = styled.button<
  CountrySelectorStyleProps & {
    hideDropdown?: boolean
    styles?: CSSProp
  }
>`
  display: flex;
  height: 36px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid gainsboro;
  margin: 0;
  // for old safari compatibility
  appearance: button;
  /* stylelint-disable-next-line */
  -webkit-appearance: button;
  background-color: white;
  cursor: pointer;
  text-transform: none;
  user-select: none;

  &:hover {
    background-color: whitesmoke;
  }

  ${({ hideDropdown }) =>
    hideDropdown &&
    `
    cursor: auto;

    &:hover {
      background-color: transparent;
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
      background-color: whitesmoke;

      &:hover {
        background-color: whitesmoke;
      }

      cursor: auto;
  `}
  ${({ styles }) => styles}
`
