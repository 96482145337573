import styled from 'styled-components'

const BodyUi = styled.div`
  position: relative;
  width: 100%;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }

  ${({ styles = {}, theme, mobileStyles = {} }) =>
    theme.isEditorMobileMode ? mobileStyles : styles}
`

export default BodyUi
