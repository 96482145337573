import styled, { css } from 'styled-components'

const BlogPostsPostItemRowUi = styled.div<{
  columnsCount: number
  centerVertically?: boolean
  gap: number
}>`
  display: grid;
  grid-template-columns: repeat(${p => p.columnsCount}, 1fr);
  gap: ${p => p.gap}px;
  ${p =>
    p.centerVertically &&
    css`
      align-items: center;
    `}
`

export default BlogPostsPostItemRowUi
