import styled, { css } from 'styled-components'

const TwitterTweetButtonUi = styled.a`
  display: flex;
  align-items: center;
  background-color: #1b95e0;
  color: #ffffff;
  padding: 5px 8px 5px 6px;
  border-radius: 3px;
  font-size: 13px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  > span {
    margin-left: 4px;
  }
  &:hover {
    background-color: #0c7abf;
  }
  ${p => p.theme.phone} {
    width: 100%;
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default TwitterTweetButtonUi
