import React from 'react'
import PropTypes from 'prop-types'
import SurveyAnswerRadioButtonUi from './ui/SurveyAnswerRadioButtonUi'
import SurveyAnswerLabelUi from './ui/SurveyAnswerLabelUi'
import SurveyAnswerUi from './ui/SurveyAnswerUi'
import SurveyAnswerTextUi from './ui/SurveyAnswerTextUi'

function SurveyAnswer({
  answer,
  fontSize,
  color,
  select,
  backgroundColor,
  outlineColor,
  borderRadius,
  mobileBorderRadius,
}) {
  // const [checked, setChecked] = useState(false)
  return (
    <SurveyAnswerUi
      fontSize={fontSize}
      color={color}
      backgroundColor={backgroundColor}
      borderColor={outlineColor}
      borderRadius={borderRadius}
      mobileBorderRadius={mobileBorderRadius}
      // checked={checked}
      onClick={select}
    >
      <SurveyAnswerLabelUi htmlFor={`answer-${answer.id}`}>
        <SurveyAnswerTextUi>{answer.title}</SurveyAnswerTextUi>
        <SurveyAnswerRadioButtonUi
          className="answer-radio"
          borderColor={outlineColor}
          // checked={checked}
        />
      </SurveyAnswerLabelUi>
    </SurveyAnswerUi>
  )
}

export const AnswerPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}).isRequired

SurveyAnswer.propTypes = {
  answer: AnswerPropType,
  fontSize: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  outlineColor: PropTypes.string,
  select: PropTypes.func.isRequired,
}

SurveyAnswer.defaultProps = {
  backgroundColor: 'rgba(255, 255, 255, 0)', // a new option
  outlineColor: 'rgba(255, 255, 255, 0)', // a new option
  borderRadius: PropTypes.shape({
    borderTopLeftRadius: PropTypes.number,
    borderTopRightRadius: PropTypes.number,
    borderBottomRightRadius: PropTypes.number,
    borderBottomLeftRadius: PropTypes.number,
  }).isRequired,
  mobileBorderRadius: PropTypes.shape({
    borderTopLeftRadius: PropTypes.number,
    borderTopRightRadius: PropTypes.number,
    borderBottomRightRadius: PropTypes.number,
    borderBottomLeftRadius: PropTypes.number,
  }).isRequired,
}

export default SurveyAnswer
