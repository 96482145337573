import React from 'react'
import useBlog, { selectors as blogSelectors } from '../../hooks/useBlog'
import useManagement, {
  selectors as managementSelectors,
} from '../../hooks/useManagement'
import usePage, { selectors as pageSelectors } from '../../hooks/usePage'
import { MobileOnloadPopup, OnloadPopup } from '../components/OnloadPopups'

/**
 * The main difference between this component and OnloadPopups
 * is that we could have popups from blog layout
 * and we need to create a "close cookie" with layout page id
 */
export default function BlogOnloadPopups() {
  const desktopOnloadPopups = usePage(pageSelectors.getOnloadPopups)
  // mobile onload it is a "mobile on exit" option actually
  const mobileOnloadPopups = usePage(pageSelectors.getMobileOnloadPopups)
  // we could have global popups available on all pages and posts
  const blogLayoutPageId = useBlog(blogSelectors.getBlogLayoutPageId)
  const isMobile = useManagement(managementSelectors.isMobile)

  const renderedDesktopOnLoadPopups = desktopOnloadPopups.map(popup => (
    <OnloadPopup pageId={blogLayoutPageId} key={popup.id} popup={popup} />
  ))

  if (typeof window !== 'undefined' && isMobile) {
    return mobileOnloadPopups
      .map(popup => (
        <MobileOnloadPopup
          pageId={blogLayoutPageId}
          key={popup.id}
          popup={popup}
        />
      ))
      .concat(renderedDesktopOnLoadPopups)
  } else {
    return renderedDesktopOnLoadPopups
  }
}
