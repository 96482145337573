import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import scheduleCallback from 'common/utils/scheduleCallback'
import useManagement, {
  selectors as managementSelectors,
} from '../hooks/useManagement'
import usePage, { selectors as pageSelectors } from '../hooks/usePage'
import useWebinar, { selectors as webinarSelectors } from '../hooks/useWebinar'
import CommonPage from './CommonPage'

function WebinarThankYouPage({ children }) {
  const isPreview = useManagement(managementSelectors.isPreviewMode)
  const isTemplate = usePage(pageSelectors.isTemplate)
  const sessionDateTime = useWebinar(webinarSelectors.getSessionStartDateTime)
  const redirectionUrl = useWebinar(webinarSelectors.getUrl)

  useEffect(() => {
    if (!isPreview && !isTemplate) {
      scheduleCallback(
        sessionDateTime,
        () => (window.location = redirectionUrl),
      )
    }
  }, [])

  return <CommonPage>{children}</CommonPage>
}

WebinarThankYouPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WebinarThankYouPage
