import styled, { css } from 'styled-components'

type CarouselNavigationItemUiProps = {
  active: boolean
}

const CarouselNavigationItemUi = styled.span<CarouselNavigationItemUiProps>`
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  position: relative;
  ${p =>
    p.active
      ? css`
          background: ${p.color};
        `
      : css`
          border: 2px solid ${p.color};
        `};
`

export default CarouselNavigationItemUi
