/* eslint-disable react/prop-types */
// Twemoji is used for emoji rendering
// https://twemoji.twitter.com
import React, { CSSProperties } from 'react'
import { ParsedCountry } from '../../types'
import { FlagEmojiUi } from './ui/FlagEmojiUi'

interface FlagEmojiProps extends React.HTMLAttributes<HTMLImageElement> {
  iso2?: ParsedCountry['iso2']
  size?: CSSProperties['width']
  disableLazyLoading?: boolean
}

export const FlagEmoji: React.FC<FlagEmojiProps> = ({
  iso2,
  className,
  size = '24px',
  disableLazyLoading,
  ...restProps
}) => {
  if (!iso2) {
    // render empty image to save place for flag
    return <img width={size} height={size} {...restProps} />
  }

  const src = `${process.env.CLOUDFRONT_SHARED}/images/flag-icons/${iso2}.svg`

  return (
    <FlagEmojiUi
      className={className}
      src={src}
      width={size}
      height={size}
      draggable={false}
      data-country={iso2}
      loading={disableLazyLoading ? undefined : 'lazy'}
      {...restProps}
    />
  )
}
