import styled from 'styled-components'

const Label = styled.label`
  flex-basis: ${({ directionRow }) => (directionRow ? '165px' : '100%')};
  margin-bottom: 7px;
  font-size: 13px;
  display: block;
  color: #6b7c93;
`

export default Label
