import styled from 'styled-components'

const Inline = styled.section`
  margin: auto;
  ${({ styles = {} }) => styles}
  width: 100%;
  max-width: ${p => p.styles.width || p.styles.maxWidth};

  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles};
    width: 100%;
    max-width: ${p => p.styles.width || p.styles.maxWidth};
  }

  ${p => p.theme.isEditorMobileMode && p.mobileStyles}
`

export default Inline
