export const getDelayTime = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days: number
  hours: number
  minutes: number
  seconds: number
}) => {
  const startDate = new Date()
  startDate.setDate(startDate.getDate() + days)
  startDate.setHours(startDate.getHours() + hours)
  startDate.setMinutes(startDate.getMinutes() + minutes)
  startDate.setSeconds(startDate.getSeconds() + seconds)

  return startDate.getTime()
}
