/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CSSProp } from 'styled-components'
import getCountryTranslation from 'common/utils/getCountryTranslation'
import { defaultCountries } from '../../constants/defaultCountries'
import { CountryData, CountryIso2, ParsedCountry } from '../../types'
import { parseCountry, scrollToChild } from '../../utils'
import { DropDownClearInputIconUi } from './ui/DropDownClearInputIconUi'
import { DropDownCountryNameUi } from './ui/DropDownCountryNameUi'
import { DropDownDialCodeUi } from './ui/DropDownDialCodeUi'
import { DropDownFlagEmojiUi } from './ui/DropDownFlagEmojiUi'
import { DropDownInputUi } from './ui/DropDownInputUi'
import { DropDownItemUi } from './ui/DropDownItemUi'
import { DropDownListUi } from './ui/DropDownListUi'
import { DropDownWrapperUi } from './ui/DropDownWrapperUi'

export interface CountrySelectorDropdownStyleProps {
  style?: CSSProp
  listStyle?: React.CSSProperties
  searchInputStyle?: React.CSSProperties
  clearSearchInputStyle?: React.CSSProperties
  listItemStyle?: CSSProp
  listItemFlagStyle?: CSSProp
  listItemCountryNameStyle?: CSSProp
  listItemDialCodeStyle?: CSSProp
}

export interface CountrySelectorDropdownProps
  extends CountrySelectorDropdownStyleProps {
  className?: string
  show: boolean
  dialCodePrefix?: string
  language?: Intl.Locale['language']
  searchInputPlaceholder?: string
  selectedCountry: CountryIso2
  countries?: CountryData[]
  onSelect?: (country: ParsedCountry) => void
  onClose?: () => void
}

export const CountrySelectorDropdown: React.FC<CountrySelectorDropdownProps> =
  ({
    className,
    show,
    dialCodePrefix = '+',
    searchInputPlaceholder,
    selectedCountry,
    language = 'en',
    countries = defaultCountries,
    onSelect,
    onClose,
    ...styleProps
  }) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const listRef = useRef<HTMLUListElement>(null)
    const lastScrolledCountry = useRef<CountryIso2>()

    const [query, setQuery] = useState<string>('')

    const filteredCountries = useMemo(() => {
      if (query) {
        const queryLowerCase = query.toLowerCase()
        return countries.filter(([country]) =>
          country.toLowerCase().includes(queryLowerCase),
        )
      } else {
        return countries
      }
    }, [countries, query])

    const getCountryIndex = useCallback(
      (country: CountryIso2) => {
        return filteredCountries.findIndex(
          c => parseCountry(c).iso2 === country,
        )
      },
      [filteredCountries],
    )

    const [focusedItemIndex, setFocusedItemIndex] = useState(
      getCountryIndex(selectedCountry),
    )

    const resetFocusedItemIndex = () => {
      if (lastScrolledCountry.current === selectedCountry) return
      setFocusedItemIndex(getCountryIndex(selectedCountry))
    }

    const handleCountrySelect = useCallback(
      (country: ParsedCountry) => {
        setFocusedItemIndex(getCountryIndex(country.iso2))
        onSelect?.(country)
      },
      [onSelect, getCountryIndex],
    )

    const moveFocusedItem = (to: 'prev' | 'next' | 'first' | 'last') => {
      const lastPossibleIndex = filteredCountries.length - 1

      const getNewIndex = (currentIndex: number) => {
        if (to === 'prev') return currentIndex - 1
        if (to === 'next') return currentIndex + 1
        if (to === 'last') return lastPossibleIndex
        return 0
      }

      setFocusedItemIndex(v => {
        const newIndex = getNewIndex(v)
        if (newIndex < 0) return 0
        if (newIndex > lastPossibleIndex) return lastPossibleIndex
        return newIndex
      })
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()

      if (e.key === 'Enter') {
        const focusedCountry = parseCountry(filteredCountries[focusedItemIndex])
        handleCountrySelect(focusedCountry)
        return
      }

      if (e.key === 'Escape') {
        onClose?.()
        return
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault()
        moveFocusedItem('prev')
        return
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault()
        moveFocusedItem('next')
        return
      }

      if (e.key === 'PageUp') {
        e.preventDefault()
        moveFocusedItem('first')
        return
      }

      if (e.key === 'PageDown') {
        e.preventDefault()
        moveFocusedItem('last')
        return
      }

      if (e.key === ' ') {
        // prevent scrolling with space
        e.preventDefault()
      }
    }

    const scrollToFocusedCountry = useCallback(() => {
      if (
        !listRef.current ||
        focusedItemIndex === undefined ||
        !filteredCountries[focusedItemIndex]
      )
        return

      const focusedCountry = parseCountry(
        filteredCountries[focusedItemIndex],
      ).iso2
      if (focusedCountry === lastScrolledCountry.current) return

      const element = listRef.current.querySelector(
        `[data-country="${focusedCountry}"]`,
      )
      if (!element) return
      scrollToChild(listRef.current, element as HTMLElement)

      lastScrolledCountry.current = focusedCountry
    }, [focusedItemIndex, filteredCountries])

    // Scroll to focused item on change
    useEffect(() => {
      scrollToFocusedCountry()
    }, [focusedItemIndex, scrollToFocusedCountry])

    useEffect(() => {
      if (!inputRef.current) return

      if (show) {
        // Autofocus on open dropdown
        inputRef.current.focus()
      } else {
        resetFocusedItemIndex()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    // Update focusedItemIndex on selectedCountry prop change
    useEffect(() => {
      setFocusedItemIndex(0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    return (
      <DropDownWrapperUi
        className={className}
        styles={styleProps.style}
        show={show}
        tabIndex={-1}
        aria-activedescendant={`${
          filteredCountries[focusedItemIndex] &&
          parseCountry(filteredCountries[focusedItemIndex]).iso2
        }-option`}
        onBlur={e => {
          // NOTE: we use this condition to prevent triggering onBlur, by click on child
          if (!e.currentTarget.contains(e.relatedTarget as Node)) {
            onClose?.()
          }
        }}
      >
        <DropDownInputUi
          ref={inputRef}
          placeholder={searchInputPlaceholder || ''}
          style={styleProps.searchInputStyle}
          type="text"
          value={query}
          onChange={e => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        {query && (
          <DropDownClearInputIconUi
            onClick={() => setQuery('')}
            style={styleProps.clearSearchInputStyle}
          />
        )}
        <DropDownListUi
          className={className}
          ref={listRef}
          role="listbox"
          styles={styleProps.style}
        >
          {filteredCountries.map((c, index) => {
            const country = parseCountry(c)
            const isSelected = country.iso2 === selectedCountry
            const isFocused = index === focusedItemIndex

            return (
              <DropDownItemUi
                key={country.iso2}
                data-country={country.iso2}
                role="option"
                aria-selected={isSelected}
                aria-label={`${country.name} ${dialCodePrefix}${country.dialCode}`}
                id={`${country.iso2}-option`}
                isSelected={isSelected}
                isFocused={isFocused}
                onClick={() => handleCountrySelect(country)}
                styles={styleProps.listItemStyle}
              >
                <DropDownFlagEmojiUi
                  iso2={country.iso2}
                  styles={styleProps.listItemFlagStyle}
                />
                <DropDownCountryNameUi
                  styles={styleProps.listItemCountryNameStyle}
                >
                  {getCountryTranslation(country.iso2, language)}
                </DropDownCountryNameUi>
                <DropDownDialCodeUi styles={styleProps.listItemDialCodeStyle}>
                  {dialCodePrefix}
                  {country.dialCode}
                </DropDownDialCodeUi>
              </DropDownItemUi>
            )
          })}
        </DropDownListUi>
      </DropDownWrapperUi>
    )
  }
