import React, { useEffect, useState } from 'react'
import CommonTwitterTweetButton from 'common/components/entities/TwitterTweetButton'
import { TwitterTweetButtonInterface } from 'common/types/entities/TwitterTweetButtonInterface'
import { isValidUrl } from 'common/utils/urlUtils'
import useManagement, {
  selectors as managementSelectors,
} from '../../hooks/useManagement'
import StyleWrapper from '../core/StyleWrapper'

function TwitterTweetButton({
  entity,
}: {
  entity: TwitterTweetButtonInterface
}) {
  const affiliateId = useManagement(managementSelectors.getAffiliateId)
  const [textWithAffiliateId, setTextWithAffiliateId] = useState(entity.text)

  useEffect(() => {
    if (affiliateId) {
      const textParts = entity.text ? entity.text.split(' ') : []
      const text = textParts
        .map(textPart => {
          return isValidUrl(textPart)
            ? `${textPart}?sa=${affiliateId}`
            : textPart
        })
        .join(' ')

      setTextWithAffiliateId(text)
    }
  }, [affiliateId])

  return (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      <CommonTwitterTweetButton
        text={textWithAffiliateId}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
      />
    </StyleWrapper>
  )
}

export default TwitterTweetButton
