import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { customerCountryPlaceholder } from 'publisher/reducers/optInReducer'
import { OptInState } from './optInStateInterface'

export function getFieldValueBySlug(state: OptInState, slug: FieldSlugEnum) {
  return state.fields[slug]
}

export function getFieldErrorsBySlug(state: OptInState, slug: FieldSlugEnum) {
  return state.errors.fields[slug] || []
}

export function getDateTimeField(state: OptInState) {
  return state.fields.datetime
}

export function getSurveysResults(state: OptInState) {
  return state.surveysResults
}

export function getNextStepUrl(state: OptInState) {
  return state.nextStepUrl
}

export function getCustomerCountry(state: OptInState) {
  if (state.fields[FieldSlugEnum.Country]) {
    return state.fields[FieldSlugEnum.Country]
  }

  return state.customerCountry !== customerCountryPlaceholder
    ? state.customerCountry
    : ''
}

export function getSubmittedRecaptchas(state: OptInState) {
  return state.submitedRecaptchas
}

export function getOptInRecaptchaError(state: OptInState) {
  return state.optInRecaptchaError
}
