import React from 'react'
import { AlignType } from 'common/types/styleTypes'
import { FontProps } from 'common/utils/styleUtilsNew'
import BlogPostCategoriesUiNew from './ui/BlogPostCategoriesUiNew'
import BlogPostCategoryLinkUiNew from './ui/BlogPostCategoryLinkUiNew'

type Category =
  | string
  | {
      name: string
      path: string
    }

type BlogPostCategoriesProps = {
  categories: Category[]
  blogPath?: string
  attrId: string
  mobileLineHeight?: number
  lineHeight?: number
  alignSelf: AlignType
  mobileAlignSelf?: AlignType
} & FontProps

function BlogPostCategoriesNew({
  categories = [],
  blogPath = '',
  attrId,
  fontSize,
  fontFamily,
  fontStyle,
  fontWeight,
  mobileLineHeight,
  mobileFontSize,
  mobileFontFamily,
  mobileFontStyle,
  mobileFontWeight,
  lineHeight,
  color,
  mobileColor,
  alignSelf,
  mobileAlignSelf,
}: BlogPostCategoriesProps) {
  if (!categories.length) {
    return (
      <BlogPostCategoriesUiNew
        id={attrId}
        fontSize={fontSize}
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        mobileFontSize={mobileFontSize}
        mobileFontFamily={mobileFontFamily}
        mobileFontStyle={mobileFontStyle}
        mobileFontWeight={mobileFontWeight}
        lineHeight={lineHeight}
        mobileLineHeight={mobileLineHeight}
        color={color}
        mobileColor={mobileColor}
        alignSelf={alignSelf}
        mobileAlignSelf={mobileAlignSelf}
      />
    )
  }

  return (
    <BlogPostCategoriesUiNew
      id={attrId}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      mobileFontSize={mobileFontSize}
      mobileFontFamily={mobileFontFamily}
      mobileFontStyle={mobileFontStyle}
      mobileFontWeight={mobileFontWeight}
      lineHeight={lineHeight}
      mobileLineHeight={mobileLineHeight}
      color={color}
      mobileColor={mobileColor}
      alignSelf={alignSelf}
      mobileAlignSelf={mobileAlignSelf}
    >
      {categories.map((category, index: number) =>
        typeof category === 'object' ? (
          <BlogPostCategoryLinkUiNew
            key={category.path}
            href={`${blogPath}/category/${category.path}`}
          >
            {category.name}
            {categories.length > 1 && index + 1 !== categories.length && ' |'}
          </BlogPostCategoryLinkUiNew>
        ) : (
          <BlogPostCategoryLinkUiNew
            key={category}
            href={`${blogPath}/posts?category=${category}`}
          >
            {category}
            {categories.length > 1 && index + 1 !== categories.length && ' |'}
          </BlogPostCategoryLinkUiNew>
        ),
      )}
    </BlogPostCategoriesUiNew>
  )
}

export default BlogPostCategoriesNew
