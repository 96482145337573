import styled from 'styled-components'
import { BorderType, MarginType } from 'common/types/styleTypes'
import {
  marginCss,
  mobileMarginCss,
  borderCss,
  mobileBorderCss,
  boxShadowCss,
  mobileBoxShadowCss,
} from 'common/utils/styleUtilsNew'

type VideoScreenshotPlaceholderUiProps = {
  margin: MarginType
  mobileMargin: Partial<MarginType>
  boxShadow?: string
  mobileBoxShadow?: string
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
}

const VideoScreenshotPlaceholderUi = styled.div<VideoScreenshotPlaceholderUiProps>`
  width: 240px;
  height: 120px;
  box-sizing: border-box;
  background-color: #2b2b2b;
  background-image: url(${process.env
    .CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/videoplaceholderbg.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.3);
  ${marginCss};
  ${boxShadowCss};
  ${borderCss};
  ${p => p.theme.phone} {
    ${mobileMarginCss};
    ${mobileBorderCss};
    ${mobileBoxShadowCss};
  }
`

export default VideoScreenshotPlaceholderUi
