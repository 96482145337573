import styled, { CSSProp } from 'styled-components'

export const DropDownWrapperUi = styled.div<{
  show?: boolean
  styles?: CSSProp
}>`
  font-size: 14px;
  position: absolute;
  z-index: 1;
  top: 3.14em;
  left: 0;
  display: flex;
  width: 22em;

  flex-direction: column;
  margin: 0;
  background-color: white;
  box-shadow: 2px 2px 16px rgb(0 0 0 / 25%);
  color: #222;

  ${({ show }) => `display: ${show ? 'block' : 'none'};`}

  ${({ styles }) => styles}
`
