import styled, { css } from 'styled-components'
import {
  AlignSelfType,
  BorderType,
  HoverType,
  PaddingType,
} from 'common/types/styleTypes'
import {
  fontCss,
  mobileFontCss,
  FontProps,
  paddingsCss,
  mobilePaddingsCss,
  backgroundColorCss,
  mobileBackgroundColorCss,
  borderCss,
  mobileBorderCss,
  boxShadowCss,
  mobileBoxShadowCss,
  lineHeightCss,
  mobileLineHeightCss,
  hoverCss,
  mobileHoverCss,
  hoverTransformCss,
  mobileHoverTransformCss,
} from 'common/utils/styleUtilsNew'

type ButtonUiProps = {
  backgroundColor: string
  mobileBackgroundColor?: string
  color: string
  mobileColor?: string
  padding: Partial<PaddingType>
  mobilePadding?: Partial<PaddingType>
  width?: 'auto' | '100%'
  mobileWidth?: 'auto' | '100%'
  border: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  alignSelf?: AlignSelfType
  mobileAlignSelf?: AlignSelfType
  boxShadow?: string
  mobileBoxShadow?: string
  lineHeight?: number
  mobileLineHeight?: number
  hover?: HoverType
  mobileHover?: HoverType
} & FontProps

const ButtonUi = styled.button<ButtonUiProps>`
  width: ${p => p.width || 'auto'};
  align-self: ${p => p.alignSelf || 'center'};
  opacity: ${p => (p.disabled ? '0.7' : '1')};
  cursor: pointer;
  transition: 0.3s;
  ${backgroundColorCss}
  ${paddingsCss}
  ${fontCss}
  ${borderCss}
  ${boxShadowCss}
  ${lineHeightCss}
  ${hoverCss}
  ${hoverTransformCss}
  ${p => p.theme.phone} {
    width: ${p => p.mobileWidth || '100%'};
    align-self: ${p => p.mobileAlignSelf || 'center'};
    opacity: ${p => (p.disabled ? '0.7' : '1')};
    ${mobileBackgroundColorCss}
    ${mobilePaddingsCss}
    ${mobileFontCss} 
    ${mobileBorderCss};
    ${mobileBoxShadowCss}
    ${mobileLineHeightCss}
    ${mobileHoverCss}
    ${mobileHoverTransformCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileHoverCss}
      ${mobileHoverTransformCss}
      ${mobileBackgroundColorCss}
      ${mobilePaddingsCss}
      ${mobileBorderCss}
      ${mobileFontCss}
      ${mobileBoxShadowCss}
      ${mobileLineHeightCss}
      width: ${p.mobileWidth || '100%'};
      align-self: ${p.mobileAlignSelf || 'center'};
    `}
`

export default ButtonUi
