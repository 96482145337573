import styled from 'styled-components'


const ClockItemUi = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
  height: 360px;
  background: #FFFFFF;
  padding: 25px 0 0 0;
  margin: 30px 15px 0 0;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
  border-radius: 5px;
  ${p => p.theme.phone} {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0 0 20px 0;
  }
`

export default ClockItemUi
