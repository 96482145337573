import styled from 'styled-components'
import { widthValues } from 'common/constants/settings'

const SectionContainerOldUi = styled.div`
  max-width: ${({ width }) => widthValues[width]};
  width: 100%;
  margin: auto;
  background-color: ${({ backgroundColor = 'transparent' }) => backgroundColor};
  ${p => p.theme.phone} {
    background-color: ${({ mobileBackgroundColor = 'transparent' }) =>
      mobileBackgroundColor};
  }
`

export default SectionContainerOldUi
