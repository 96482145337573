import PropTypes from 'prop-types'
import React from 'react'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import ExplicitConsentCheckboxUi from './ui/ExplicitConsentCheckboxUi'
import ExplicitConsentLabelUi from './ui/ExplicitConsentLabelUi'
import ExplicitConsentUi from './ui/ExplicitConsentUi'

const ExplicitConsent = ({
  options,
  styles,
  mobileStyles,
  onChangeHandler,
  checked,
  children,
  isCheckboxDisabled,
}) => (
  <ExplicitConsentUi
    attrId={options.attrId}
    styles={styles}
    mobileStyles={mobileStyles}
  >
    <ExplicitConsentLabelUi>
      <ExplicitConsentCheckboxUi
        onChange={onChangeHandler}
        defaultChecked={checked}
        disabled={isCheckboxDisabled}
      />
      {children}
    </ExplicitConsentLabelUi>
  </ExplicitConsentUi>
)

ExplicitConsent.propTypes = {
  mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
  styles: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.objectOf(PropTypes.any),
  onChangeHandler: PropTypes.func,
  checked: PropTypes.bool,
  isCheckboxDisabled: PropTypes.bool,
  children: PropTypes.node,
}

ExplicitConsent.defaultProps = {
  styles: {},
  options: {},
  onChangeHandler: () => {},
  checked: false,
  isCheckboxDisabled: false,
}

export default provideMobileStyles(ExplicitConsent)
