import styled, { css } from 'styled-components'
import { FontProps, fontCss, mobileFontCss } from 'common/utils/styleUtilsNew'

type BreadcrumbsItemUiProps = {
  color: string
  lastItemColor: string
} & FontProps

const BreadcrumbsItemUi = styled.li<BreadcrumbsItemUiProps>`
  color: ${p => p.color};
  font-size: 20px;

  &:not(&:first-of-type) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:after {
    content: '>';
    padding: 0 10px;
  }

  &:last-of-type&:after {
    content: '';
  }
  ${fontCss}
  ${mobileFontCss}

  a {
    color: ${p => p.color};
    padding: 5px 0;
  }

  &:last-child {
    color: ${p => p.lastItemColor};
  }

  &:last-child span {
    padding: 5px 0;
    color: ${p => p.lastItemColor};
  }

  &:first-child a {
    padding-left: 0;
  }

  ${p => p.theme.mobile} {
    ${mobileFontCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
    `}
`

export default BreadcrumbsItemUi
