import React, { PureComponent } from 'react'

export default function provideDelay(Component) {
  class Delay extends PureComponent {
    constructor(props) {
      super(props)
      const delay = this.getDelay(props)
      this.state = {
        show: delay === 0,
      }
    }

    componentDidMount() {
      const delay = this.getDelay(this.props)
      if (delay > 0) {
        setTimeout(this.show, delay * 1000)
      }
    }

    getDelay = props => {
      return props.entity.options.delay ? +props.entity.options.delay : 0
    }

    show = () => this.setState({ show: true })

    render() {
      return this.state.show && <Component {...this.props} />
    }
  }

  return Delay
}
