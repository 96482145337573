import styled from 'styled-components'
import { mobileTextAlignCss, textAlignCss } from 'common/utils/styleUtilsNew'
import { AlignType } from '../../../../types/styleTypes'

type WrapperWebinarSessionLinkUiProps = {
  textAlign?: AlignType
  mobileTextAlign?: AlignType
}
const WrapperWebinarSessionLinkUi = styled.div<WrapperWebinarSessionLinkUiProps>`
  ${textAlignCss}
  ${p => p.theme.isEditorMobileMode && mobileTextAlignCss}
  ${p => p.theme.phone} {
    ${mobileTextAlignCss}
  }
`

export default WrapperWebinarSessionLinkUi
