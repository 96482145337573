import PropTypes from 'prop-types'
import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonLink from 'common/components/entities/Button/Link'
import { borderTypeKeys } from 'common/constants/settings'
import { splitMarginsAndRestStyles } from 'common/utils/styleUtils'
import { useFiles } from '../../../store'
import { fileSelectors } from '../../../store/file/fileSelectors'

function DownloadFileButton({ entity }) {
  const { options, mobileOptions, styles, mobileStyles } = entity
  const [margins, restStyles] = splitMarginsAndRestStyles(styles)
  const [mobileMargins, mobileRestStyles] =
    splitMarginsAndRestStyles(mobileStyles)
  const file = useFiles(files =>
    fileSelectors.getFile(files, options.srcFileId),
  )

  return (
    <StyleWrapper
      styles={{ ...margins, ...wrapperStyles }}
      mobileStyles={mobileMargins}
      desktop={options.appearance.desktop}
      mobile={options.appearance.mobile}
    >
      <CommonLink
        attrId={options.attrId}
        styles={restStyles}
        mobileStyles={mobileRestStyles}
        borderType={options.borderType}
        mobileBorderType={mobileOptions.borderType}
        subText={options.subText}
        hoverStyles={{
          textColor: options?.hover?.textColor,
          backgroundColor: options?.hover?.backgroundColor,
          subTextColor: options?.hover?.subTextColor,
          transform: options?.hover?.transform,
          offset: options?.hover?.offset,
        }}
        hoverMobileStyles={{
          textColor: mobileOptions?.hover?.textColor,
          backgroundColor: mobileOptions?.hover?.backgroundColor,
          subTextColor: mobileOptions?.hover?.subTextColor,
          transform: mobileOptions?.hover?.transform,
          offset: mobileOptions?.hover?.offset,
        }}
        subTextStyles={{
          color: options.subTextColor,
          fontSize: options.subTextFontSize,
          fontFamily: options.subTextFontFamily,
          fontStyle: options.subTextFontStyle,
          fontWeight: options.subTextFontWeight,
        }}
        subTextMobileStyles={{
          color: mobileOptions.subTextColor,
          fontSize: mobileOptions.subTextFontSize,
          fontFamily: mobileOptions.subTextFontFamily,
          fontStyle: mobileOptions.subTextFontStyle,
          fontWeight: mobileOptions.subTextFontWeight,
        }}
        href={file ? file.path : ''}
        iconClassNameBefore={entity.options.iconClassNameBefore}
        iconClassNameAfter={entity.options.iconClassNameAfter}
        target={'_blank'}
        asLink
      >
        {entity.options.text}
      </CommonLink>
    </StyleWrapper>
  )
}

DownloadFileButton.propTypes = {
  entity: PropTypes.shape({
    options: PropTypes.shape({
      srcFileId: PropTypes.number,
      attrId: PropTypes.string,
      text: PropTypes.string,
      borderType: PropTypes.oneOf(Object.values(borderTypeKeys)),
      appearance: PropTypes.shape({
        desktop: PropTypes.bool.isRequired,
        mobile: PropTypes.bool.isRequired,
      }).isRequired,
      subText: PropTypes.string,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      iconClassNameBefore: PropTypes.string,
      iconClassNameAfter: PropTypes.string,
    }),
    mobileOptions: PropTypes.shape({
      borderType: PropTypes.string,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      subTextFontWeight: PropTypes.string,
    }),
    mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
    styles: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
}

export default DownloadFileButton
