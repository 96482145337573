import React, { useEffect } from 'react'
import WebinarRegistrationTimeOptions from './WebinarRegistrationTimeOptions'
import {
  formatDate,
  getOptionsByDateAndTimes,
  getTomorrowDate,
} from './webinarRegistrationDateUtils'

export function getTodayOrTomorrowDateByScheduledHours(
  scheduledHours: number[],
) {
  const todayDate = new Date()
  const currentDateHours = todayDate.getHours()

  if (scheduledHours.some(hour => hour > currentDateHours)) {
    return todayDate
  }

  return getTomorrowDate()
}

const isToday = (someDate: Date) => {
  const today = new Date()
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  )
}

export function getAvailableScheduledHours(
  selectedDate: Date,
  scheduledHours: number[],
) {
  const isDateToday = isToday(selectedDate)
  const selectedDateHours = selectedDate.getHours()
  //if we choose 00:00 on the next day it will be displayed because of >=
  return scheduledHours.filter(scheduledHour =>
    isDateToday
      ? scheduledHour > selectedDateHours
      : scheduledHour >= selectedDateHours,
  )
}

export function getAvailableScheduledHoursOptions(
  selectedDate: Date,
  scheduledHours: number[],
  timeFormat: number,
) {
  const preparedHours = scheduledHours.map(scheduledHour => ({
    hours: scheduledHour,
    minutes: 0,
  }))

  return getOptionsByDateAndTimes(selectedDate, preparedHours, timeFormat)
}

type WebinarRegistrationDateByScheduledTimesProps = {
  scheduledHours: number[]
  locale: string
  changeTime: (value: string | number) => void
  time?: number
  timeFormat: number
}

function WebinarRegistrationDateByScheduledTimes({
  scheduledHours,
  locale,
  changeTime,
  time,
  timeFormat,
}: WebinarRegistrationDateByScheduledTimesProps) {
  const date = getTodayOrTomorrowDateByScheduledHours(scheduledHours)
  const availableScheduledHours = getAvailableScheduledHours(
    date,
    scheduledHours,
  )

  const availableHoursOptions = getAvailableScheduledHoursOptions(
    date,
    availableScheduledHours,
    timeFormat,
  )

  useEffect(() => {
    if (Object.keys(availableHoursOptions).length > 0) {
      changeTime(Object.keys(availableHoursOptions)[0])
    }
  }, [])

  const formattedDate = formatDate(date, locale)
  return (
    <>
      <WebinarRegistrationTimeOptions
        value={formattedDate}
        handleChange={changeTime}
        options={{ '': formattedDate }}
      />
      <WebinarRegistrationTimeOptions
        dataTestId="webinar-registration-date-by-scheduled-times"
        value={time ? String(time) : undefined}
        handleChange={changeTime}
        options={availableHoursOptions}
      />
    </>
  )
}

export default WebinarRegistrationDateByScheduledTimes
