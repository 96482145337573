import styled, { css } from 'styled-components'
import { mobilePaddingsCss, paddingsCss } from 'common/utils/styleUtilsNew'

const CheckboxInputUi = styled.input`
  ${paddingsCss}

  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobilePaddingsCss}
    `}
`

export default CheckboxInputUi
