import React from 'react'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import {
  BlogPost,
  blogPostListingImageInterface,
} from '../../../types/entities/BlogPostListingInterface'
import BlogPostImageUi from './ui/BlogPostImageUi'
import BlogPostLinkImageWrapperUi from './ui/BlogPostLinkImageWrapperUi'

type BlogPostImageProps = {
  post: BlogPost
  imagePlacement?: ImagePlacementVariants
  mobileImagePlacement?: ImagePlacementVariants
} & blogPostListingImageInterface

function BlogPostImage({
  post,
  imageBorder,
  mobileImageBorder,
  imageBoxShadow,
  mobileImageBoxShadow,
  imageMargin,
  mobileImageMargin,
  imagePlacement,
  mobileImagePlacement,
}: BlogPostImageProps) {
  return (
    <>
      {post.image ? (
        <BlogPostLinkImageWrapperUi
          margin={imageMargin}
          mobileMargin={mobileImageMargin}
          href={post.path}
          key={post.path}
          imagePlacement={imagePlacement}
          mobileImagePlacement={mobileImagePlacement}
        >
          <BlogPostImageUi
            src={post.image}
            alt={post.name}
            boxShadow={imageBoxShadow}
            border={imageBorder}
            mobileBorder={mobileImageBorder}
            mobileBoxShadow={mobileImageBoxShadow}
          />
        </BlogPostLinkImageWrapperUi>
      ) : (
        <div />
      )}
    </>
  )
}

export default BlogPostImage
