/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react'
import { CSSProp } from 'styled-components'
import { defaultCountries } from '../../constants/defaultCountries'
import { DEFAULT_COUNTRY } from '../../constants/defaultCountry'
import { usePhoneInput, UsePhoneInputConfig } from '../../hooks/usePhoneInput'
import { CountryIso2 } from '../../types'
import { getCountry } from '../../utils'
import {
  CountrySelector,
  CountrySelectorProps,
  CountrySelectorStyleProps,
} from '../CountrySelector'
import { DialCodePreview, DialCodePreviewStyleProps } from '../DialCodePreview'
import { PhoneInputInputUi } from './ui/PhoneInputInputUi'
import { PhoneInputWrapperUi } from './ui/PhoneInputWrapperUi'

export interface PhoneInputStyleProps {
  style?: CSSProp
  inputStyle?: CSSProp
  countrySelectorStyleProps?: CountrySelectorStyleProps
  dialCodePreviewStyleProps?: DialCodePreviewStyleProps
}

export interface PhoneInputProps
  extends Omit<UsePhoneInputConfig, 'onChange'>,
    PhoneInputStyleProps {
  className?: string

  phoneInputInputProps?: React.ComponentProps<typeof PhoneInputInputUi>

  countrySelectorProps?: Partial<CountrySelectorProps>

  /**
   * @description Hide the dropdown icon. Make country selection not accessible.
   * @default false
   */
  hideDropdown?: CountrySelectorProps['hideDropdown']

  /**
   * @description Input's placeholder
   * @default undefined
   */
  placeholder?: React.InputHTMLAttributes<HTMLInputElement>['placeholder']

  /**
   * @description Disable phone input and country selector.
   * @default false
   */
  disabled?: boolean

  /**
   * @description
   * Show prefix and dial code between country selector and phone input.
   * Works only when *disableDialCodeAndPrefix* is *true*
   * @default false
   */
  showDisabledDialCodeAndPrefix?: boolean

  /**
   * @description Default input component props
   * @default undefined
   */
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>

  /**
   * @description Callback that calls on phone change
   * @params `phone` - new phone value, `country` - country iso2 value
   * @default undefined
   */
  onChange?: (data: {
    phone: string
    unformatedPhone: string
    country: CountryIso2
    prefix: string
  }) => void
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  countrySelectorProps,
  className,
  hideDropdown,
  placeholder,
  disabled,
  showDisabledDialCodeAndPrefix,
  inputProps,
  onChange,
  style,
  inputStyle,
  countrySelectorStyleProps,
  dialCodePreviewStyleProps,
  value,
  defaultCountry = DEFAULT_COUNTRY,
  initialCountry = DEFAULT_COUNTRY,
  countries = defaultCountries,
  phoneInputInputProps,
  ...usePhoneInputConfig
}) => {
  const {
    phone,
    inputRef,
    country,
    setCountry,
    handlePhoneValueChange,
    onFocus,
  } = usePhoneInput({
    value,
    countries,
    defaultCountry,
    initialCountry,
    ...usePhoneInputConfig,
    onChange: data => {
      onChange?.(data)
    },
  })

  const fullCountry = useMemo(() => {
    if (!country) return
    return getCountry({
      value: country,
      field: 'iso2',
      countries,
      defaultCountry,
    })
  }, [countries, country, defaultCountry])

  const showDialCodePreview =
    usePhoneInputConfig.disableDialCodeAndPrefix &&
    showDisabledDialCodeAndPrefix &&
    fullCountry?.dialCode

  return (
    <PhoneInputWrapperUi className={className} styles={style}>
      <CountrySelector
        onSelect={country => setCountry(country.iso2)}
        defaultCountry={defaultCountry}
        selectedCountry={country}
        countries={countries}
        disabled={disabled}
        hideDropdown={hideDropdown}
        {...countrySelectorProps}
        {...countrySelectorStyleProps}
      />

      {showDialCodePreview && (
        <DialCodePreview
          dialCode={fullCountry.dialCode}
          prefix={usePhoneInputConfig.prefix ?? '+'}
          disabled={disabled}
          {...dialCodePreviewStyleProps}
        />
      )}

      <PhoneInputInputUi
        onChange={handlePhoneValueChange}
        onFocus={onFocus}
        value={phone}
        type="tel"
        ref={inputRef}
        placeholder={placeholder}
        disabled={disabled}
        styles={inputStyle}
        {...inputProps}
        {...phoneInputInputProps}
      />
    </PhoneInputWrapperUi>
  )
}
