import styled, { CSSProp } from 'styled-components'

export const CountrySelectorDropDownArrowUi = styled.div<{
  disabled?: boolean
  showDropdown?: boolean
  styles?: CSSProp
}>`
  border-top: 4px solid #777;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-right: 4px;
  transition: all 100ms ease-out;

  ${({ showDropdown }) => showDropdown && `transform: rotateX(180deg);`}
  ${({ disabled }) => disabled && `border-top-color: #999;`}
  ${({ styles }) => styles}
`
