import styled, { css } from 'styled-components'
import { AlignSelfType } from '../../../../types/styleTypes'

const ImageWrapperUi = styled.div<{
  alignSelf: AlignSelfType
  mobileAlignSelf?: AlignSelfType
  mobileWidth?: string | number
  width?: string | number
  ratio?: number
}>`
  align-self: ${p => p.alignSelf};
  ${p => p.theme.desktop} {
    ${p => typeof p.width === 'string' && css`
      width: ${p.width};
      aspect-ratio: ${p.ratio};
  `}
  }
  ${p => p.theme.phone} {
    ${p => typeof p.mobileWidth === 'string' && css`
        width: ${p.width};
        aspect-ratio: ${p.ratio};
    `}

    align-self: ${p => p.mobileAlignSelf};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      align-self: ${p.mobileAlignSelf};
    `}
`

export default ImageWrapperUi
