import React, { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonContactUs, {
  ContactUsSuccessMessageUi,
} from 'common/components/entities/ContactUs'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { ContactUsInterface } from 'common/types/entities/ContactUsInterface'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'
import { usePage, pageSelectors } from 'publisher/store'

export const getContactUsError = (
  errors: ErrorsInterface,
  slug: string,
  isPreview?: boolean,
) => {
  let calculatedError: string[] = []
  // todo make a normal fix
  if (!errors || !errors.fields) {
    return []
  }

  if (slug === FieldSlugEnum.FirstName && errors?.fields?.first_name) {
    calculatedError = errors.fields.first_name
  }
  if (slug === FieldSlugEnum.Email && errors?.fields?.email) {
    calculatedError = errors.fields.email
  }
  if (slug === FieldSlugEnum.Message && errors?.fields?.message) {
    calculatedError = errors.fields.message
  }
  if (slug === FieldSlugEnum.Subject && errors?.fields?.subject) {
    calculatedError = errors.fields.subject
  }
  if (
    slug === FieldSlugEnum.Recaptcha &&
    errors?.fields?.recaptcha &&
    !isPreview
  ) {
    calculatedError = errors.fields.recaptcha
  }
  return calculatedError
}

interface ErrorsInterface {
  common: string[]
  fields: {
    [FieldSlugEnum.FirstName]?: string[]
    [FieldSlugEnum.Message]?: string[]
    [FieldSlugEnum.Email]?: string[]
    [FieldSlugEnum.Subject]?: string[]
    [FieldSlugEnum.Recaptcha]?: string[]
  }
  checkbox?: string[]
}

export interface ContextFieldsInterface {
  [FieldSlugEnum.FirstName]: string
  [FieldSlugEnum.Email]: string
  [FieldSlugEnum.Message]: string
  [FieldSlugEnum.Recaptcha]: string
  [FieldSlugEnum.Subject]: string
  [FieldSlugEnum.Attachments]: File | null
}

type ShowSuccess = {
  showSuccess: () => void
  isDisabled: boolean
  setIsDisabled: (value: boolean) => void
  isCaptcha: boolean
  errors: ErrorsInterface
  setErrors: (value: ErrorsInterface) => void
  fields: ContextFieldsInterface
  setFields: React.Dispatch<React.SetStateAction<ContextFieldsInterface>>
}

const ShowContactUsSuccessContext = createContext({} as ShowSuccess)

export const useShowContactUsSuccess = () =>
  useContext(ShowContactUsSuccessContext)

function ContactUs({ entity }: { entity: ContactUsInterface }) {
  const [isSuccess, showSuccess] = useState(false)
  const [fields, setFields] = useState<ContextFieldsInterface>({
    [FieldSlugEnum.Email]: '',
    [FieldSlugEnum.Message]: '',
    [FieldSlugEnum.FirstName]: '',
    [FieldSlugEnum.Recaptcha]: '',
    [FieldSlugEnum.Subject]: '',
    [FieldSlugEnum.Attachments]: null,
  })

  const { t } = useTranslation()

  const desecendantCaptcha = usePage(page =>
    pageSelectors.findEntityDescendantsCaptcha(page, entity),
  )[0]

  const [isDisabled, setIsDisabled] = useState(false)
  const [errors, setErrors] = useState<ErrorsInterface>({
    common: [],
    fields: {},
  })

  return (
    <StyleWrapper
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
      mobile={entity.appearance.mobile}
      desktop={entity.appearance.desktop}
    >
      <ShowContactUsSuccessContext.Provider
        value={{
          showSuccess: () => showSuccess(true),
          isDisabled,
          setIsDisabled: (value: boolean) => setIsDisabled(value),
          isCaptcha: desecendantCaptcha ? desecendantCaptcha.show : false,
          errors,
          setErrors: (value: ErrorsInterface) => setErrors(value),
          fields,
          setFields,
        }}
      >
        <CommonContactUs
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          id={entity.htmlAttrId}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          backgroundColor={entity.backgroundColor}
          mobileBackgroundColor={entity.mobileBackgroundColor}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
        >
          {isSuccess ? (
            <ContactUsSuccessMessageUi
              color={entity.successMessageTextColor}
              mobileColor={entity.mobileSuccessMessageTextColor}
            >
              {entity.successMessageText ||
                t('components.contact_us.success_message')}
            </ContactUsSuccessMessageUi>
          ) : (
            <BaseNodeChildren childIds={entity.childIds} />
          )}
        </CommonContactUs>
      </ShowContactUsSuccessContext.Provider>
    </StyleWrapper>
  )
}

export default ContactUs
