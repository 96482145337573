import styled from 'styled-components'

const ContentBoxInnerOldUi = styled.div`
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles }) => styles || {}}
`

export default ContentBoxInnerOldUi
