import React, { useEffect } from 'react'
import { IntervalTypes } from './WebinarRegistrationDate'
import WebinarRegistrationTimeOptions from './WebinarRegistrationTimeOptions'
import {
  formatDate,
  getCurrentDate,
  getFormattedTimeObject,
  getOptionsByDateAndTimes,
} from './webinarRegistrationDateUtils'

export function getClosestInterval(interval: IntervalTypes) {
  const date = new Date()
  const minutesInterval = calculateInterval(
    date.getMinutes(),
    interval,
    interval,
  )

  // if current minutes is 60 we should show next hour
  if (minutesInterval === 60) {
    return getFormattedTimeObject(date.getHours() + 1)
  }

  if (
    interval === IntervalTypes.hour &&
    date.getHours() === 24 &&
    minutesInterval > 0
  ) {
    date.setHours(date.getHours() + 1)
    return getFormattedTimeObject(date.getHours() + 1)
  }

  // if current time 22:01 we should provide 23:00 for the next webinar session
  if (interval === IntervalTypes.hour && date.getMinutes() > 0) {
    return getFormattedTimeObject(date.getHours() + 1)
  }

  return getFormattedTimeObject(date.getHours(), minutesInterval)
}

export function calculateInterval(
  currentMinutes: number,
  initialInterval: number,
  resultInterval = 0,
): number {
  if (initialInterval === 60) {
    return 0
  }

  return currentMinutes >= resultInterval
    ? calculateInterval(
        currentMinutes,
        initialInterval,
        initialInterval + resultInterval,
      )
    : resultInterval
}

function getAvailableIntervalTimeOptions(
  interval: IntervalTypes,
  timeFormat: number,
) {
  const closestInterval = getClosestInterval(interval)

  return getOptionsByDateAndTimes(
    getCurrentDate(),
    [closestInterval],
    timeFormat,
  )
}

type WebinarRegistrationDateByIntervalProps = {
  interval: IntervalTypes
  locale: string
  changeTime: (value: string) => void
  time?: number
  timeFormat?: number
}

function WebinarRegistrationDateByInterval({
  interval,
  time,
  locale,
  changeTime,
  timeFormat = 24,
}: WebinarRegistrationDateByIntervalProps) {
  const timeOptions = getAvailableIntervalTimeOptions(interval, timeFormat)

  useEffect(() => {
    if (Object.keys(timeOptions).length > 0) {
      changeTime(Object.keys(timeOptions)[0])
    }
  }, [])

  const formattedDate = formatDate(getCurrentDate(), locale)

  return (
    <>
      <WebinarRegistrationTimeOptions
        value={formattedDate}
        handleChange={changeTime}
        options={{ '': formattedDate }}
      />
      <WebinarRegistrationTimeOptions
        value={time ? String(time) : undefined}
        handleChange={changeTime}
        options={timeOptions}
      />
    </>
  )
}

export default WebinarRegistrationDateByInterval
