const webinarSessionTimeStampPlaceholder = '%WEBINAR_SESSION_TIMESTAMP%'

const defaultState = {
  timeStamp: webinarSessionTimeStampPlaceholder,
}

export default function(state = defaultState) {
  return state
}

export const getDateTime = state => {
  return state.timeStamp === webinarSessionTimeStampPlaceholder
    ? new Date(Date.now() + 10 * 1000)
    : new Date(state.timeStamp * 1000)
}

export const selectors = {
  getDateTime,
}
