import styled, { css } from 'styled-components'
import { AlignSelfType } from 'common/types/styleTypes'

type SideMenuUiProps = {
  opened: boolean
  forceVisible: boolean
  alignSelf: AlignSelfType
  mobileAlignSelf?: AlignSelfType
  sideMenuBackgroundColor: string
}
const SideMenuUi = styled.div<SideMenuUiProps>`
  ${p => p.theme.phone} {
    display: block;
    justify-content: ${p => p.mobileAlignSelf || 'center'};
  }
  background-color: ${p => p.sideMenuBackgroundColor};
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: ${p => (p.opened ? '40%' : 0)};
  z-index: 1;
  transition: width 0.2s ease-in-out;
  justify-content: ${p => p.alignSelf || 'center'};
  /* emulation property */
  ${p =>
    p.forceVisible &&
    css`
      display: flex;
      z-index: 10;
    `};
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
    justify-content: ${p.mobileAlignSelf || 'center'};
      li {
        display: ${p.opened ? 'block' : 'none'};
      }
    `}
`

export default SideMenuUi
