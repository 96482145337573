import styled, { css } from 'styled-components'
import { AlignSelfType, PaddingType } from 'common/types/styleTypes'
import {
  alignItemsCss,
  mobilePaddingsCss,
  paddingsCss,
} from 'common/utils/styleUtilsNew'

type BurgerProps = {
  fixed: boolean
  opened: boolean
  forceVisible: boolean
  padding: PaddingType
  mobilePadding: PaddingType
  alignItems: AlignSelfType
}
const Burger = styled.div<BurgerProps>`
  ${p => p.theme.phone} {
    display: flex;
    ${p =>
      p.opened
        ? css`
            padding: 0 0 0 0;
          `
        : mobilePaddingsCss}
  }
  display: none;
  position: relative;
  ${paddingsCss}
  ${alignItemsCss}
  ${p =>
    p.fixed &&
    css`
      position: fixed;
      top: 20px;
      right: 10px;
    `};
  ${p => p.theme.isEditorMobileMode && mobilePaddingsCss}
  z-index: ${p => (p.opened ? 2 : 0)};
  justify-content: flex-end;
  flex-direction: column;
  span:first-child {
    transform-origin: 0 0;
  }
  span:nth-last-child(1) {
    transform-origin: 0 100%;
  }
  ${p =>
    p.opened &&
    css`
      span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
      }
      span:nth-last-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
      span:nth-last-child(1) {
        transform: rotate(-45deg) translate(-1px, 1px);
      }
    `}
  /* emulation property */
  ${p =>
    p.forceVisible &&
    css`
      display: flex;
      z-index: 11;
    `};
`

export default Burger
