import React from 'react'
import {
  backgroundPositionKeys,
  backgroundPositionValues as oldBackgroundPositionValues,
} from 'common/constants/settings'
import {
  BackgroundPositionTypeEnum,
  BackgroundTypeEnum,
} from 'common/enums/BackgroundEnum'
import { Background } from 'common/types/BackgroundType'
import { DataFile } from 'client/store/files/filesReducer'

export const backGroundPositionNames = {
  [BackgroundTypeEnum.Default]:
    'components.core.settings.background_position.default.title',
  [BackgroundTypeEnum.Fixed]: 'Full Center (Fixed)',
  [BackgroundTypeEnum.Repeat]: 'Repeat',
  [BackgroundTypeEnum.Width100]: 'Fill 100% Width',
  [BackgroundTypeEnum.Width100Height100]: 'Fill 100% Width & Height',
  [BackgroundTypeEnum.ScalesWithoutCropping]:
    'components.core.settings.background_position.scales_without_cropping.title',
}

export const backgroundPositionValues: Record<
  BackgroundPositionTypeEnum,
  string
> = {
  [BackgroundPositionTypeEnum.TopLeft]: '0% 0%',
  [BackgroundPositionTypeEnum.TopCenter]: '50% 0%',
  [BackgroundPositionTypeEnum.TopRight]: '100% 0%',
  [BackgroundPositionTypeEnum.CenterLeft]: '0% 50%',
  [BackgroundPositionTypeEnum.CenterCenter]: '50% 50%',
  [BackgroundPositionTypeEnum.CenterRight]: '100% 50%',
  [BackgroundPositionTypeEnum.BottomLeft]: '0% 100%',
  [BackgroundPositionTypeEnum.BottomCenter]: '50% 100%',
  [BackgroundPositionTypeEnum.BottomRight]: '100% 100%',
}

export const backgroundPositionStyles: Record<BackgroundTypeEnum, Background> =
  {
    [BackgroundTypeEnum.Default]: {
      backgroundSize: 'initial',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'initial',
    },
    [BackgroundTypeEnum.Fixed]: {
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
    },
    [BackgroundTypeEnum.Repeat]: {
      backgroundSize: 'initial',
      backgroundPosition: '0% 0%',
      backgroundRepeat: 'repeat',
      backgroundAttachment: 'initial',
    },
    [BackgroundTypeEnum.Width100]: {
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'initial',
    },
    [BackgroundTypeEnum.Width100Height100]: {
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'initial',
    },
    [BackgroundTypeEnum.ScalesWithoutCropping]: {
      backgroundSize: 'contain',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'initial',
    },
  }

export function getFileName(file: DataFile | null) {
  if (!file) {
    return ''
  }

  return file.path
    .replace(/^.*[/]/, '')
    .split('_')
    .slice(1)
    .join('_')
}

export const detectBackgroundType = (
  position?: string | Background,
  isLecture?: boolean,
): BackgroundTypeEnum => {
  if (position) {
    if (typeof position === 'object') {
      if (position.backgroundAttachment === 'fixed') {
        /*
         * NOTE: the iframe in the user-domain project (course lectures) doesn't support "background-attachment: fixed"
         */
        return isLecture
          ? BackgroundTypeEnum.Width100Height100
          : BackgroundTypeEnum.Fixed
      }
      if (
        position.backgroundRepeat === 'repeat' ||
        position.backgroundRepeat !== 'no-repeat'
      ) {
        return BackgroundTypeEnum.Repeat
      }
      if (position.backgroundSize === '100%') {
        return BackgroundTypeEnum.Width100
      }
      if (position.backgroundSize === 'cover') {
        return BackgroundTypeEnum.Width100Height100
      }
      if (position.backgroundSize === 'contain') {
        return BackgroundTypeEnum.ScalesWithoutCropping
      }
      return BackgroundTypeEnum.Default
    } else {
      //will be removed
      switch (position) {
        case backgroundPositionKeys.cover:
          /*
           * NOTE: the iframe in the user-domain project doesn't support "background-attachment: fixed"
           */
          return isLecture
            ? BackgroundTypeEnum.Width100Height100
            : BackgroundTypeEnum.Fixed
        case backgroundPositionKeys.cover100Width:
          return BackgroundTypeEnum.Width100
        case backgroundPositionKeys.cover100WidthHeight:
          return BackgroundTypeEnum.Width100Height100
        case backgroundPositionKeys.repeat:
          return BackgroundTypeEnum.Repeat
        case backgroundPositionKeys.repeatX:
          return BackgroundTypeEnum.Repeat
        case backgroundPositionKeys.repeatY:
          return BackgroundTypeEnum.Repeat
        case backgroundPositionKeys.repeatXTop:
          return BackgroundTypeEnum.Repeat
        case backgroundPositionKeys.repeatXBottom:
          return BackgroundTypeEnum.Repeat
        case backgroundPositionKeys.noRepeat:
          return BackgroundTypeEnum.Default
        case backgroundPositionKeys.center:
          return BackgroundTypeEnum.Default
        default:
          return BackgroundTypeEnum.Default
      }
    }
  }
  // fallback
  return BackgroundTypeEnum.Default
}

export const detectBackgroundPositionType = (
  position?: string | Background,
): BackgroundPositionTypeEnum => {
  if (position && typeof position === 'object') {
    switch (position.backgroundPosition) {
      case backgroundPositionValues[BackgroundPositionTypeEnum.TopLeft]:
        return BackgroundPositionTypeEnum.TopLeft
      case backgroundPositionValues[BackgroundPositionTypeEnum.TopCenter]:
        return BackgroundPositionTypeEnum.TopCenter
      case backgroundPositionValues[BackgroundPositionTypeEnum.TopRight]:
        return BackgroundPositionTypeEnum.TopRight
      case backgroundPositionValues[BackgroundPositionTypeEnum.CenterLeft]:
        return BackgroundPositionTypeEnum.CenterLeft
      case backgroundPositionValues[BackgroundPositionTypeEnum.CenterCenter]:
        return BackgroundPositionTypeEnum.CenterCenter
      case backgroundPositionValues[BackgroundPositionTypeEnum.CenterRight]:
        return BackgroundPositionTypeEnum.CenterRight
      case backgroundPositionValues[BackgroundPositionTypeEnum.BottomLeft]:
        return BackgroundPositionTypeEnum.BottomLeft
      case backgroundPositionValues[BackgroundPositionTypeEnum.BottomCenter]:
        return BackgroundPositionTypeEnum.BottomCenter
      case backgroundPositionValues[BackgroundPositionTypeEnum.BottomRight]:
        return BackgroundPositionTypeEnum.BottomRight
      default:
        return BackgroundPositionTypeEnum.CenterCenter
    }
  }
  return BackgroundPositionTypeEnum.CenterCenter
}

//will be removed
export const convertOldPositionType = (position: string) => {
  const convertedPositionValue = oldBackgroundPositionValues[position]
  switch (convertedPositionValue.backgroundPosition) {
    case 'initial':
      convertedPositionValue.backgroundPosition = '0% 0%'
      break
    case 'center center':
      convertedPositionValue.backgroundPosition = '50% 50%'
      break
    default:
      break
  }
  return convertedPositionValue
}

export const circlesPositionRenderer = (
  onCircleClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void,
  currentPositionType?: BackgroundPositionTypeEnum,
  currentBackgroundType?: BackgroundTypeEnum,
) => {
  return (
    <svg
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={e => onCircleClick(e)}
    >
      <rect
        x="6.44"
        y="6.44"
        width="65.12"
        height="65.12"
        stroke="#7C8A93"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      {!(
        currentBackgroundType === BackgroundTypeEnum.Width100Height100 ||
        currentBackgroundType === BackgroundTypeEnum.Width100 ||
        currentBackgroundType === BackgroundTypeEnum.ScalesWithoutCropping
      ) && (
        <circle
          style={{ cursor: 'pointer' }}
          cx="6"
          cy="6"
          r="5"
          fill={
            currentPositionType === BackgroundPositionTypeEnum.TopLeft
              ? '#399EFC'
              : '#7C8A93'
          }
          stroke="white"
          id={BackgroundPositionTypeEnum.TopLeft}
        />
      )}
      {!(
        currentBackgroundType === BackgroundTypeEnum.Width100Height100 ||
        currentBackgroundType === BackgroundTypeEnum.Width100 ||
        currentBackgroundType === BackgroundTypeEnum.ScalesWithoutCropping
      ) && (
        <circle
          style={{ cursor: 'pointer' }}
          cx="6"
          cy="72"
          r="5"
          fill={
            currentPositionType === BackgroundPositionTypeEnum.BottomLeft
              ? '#399EFC'
              : '#7C8A93'
          }
          stroke="white"
          id={BackgroundPositionTypeEnum.BottomLeft}
        />
      )}
      {!(currentBackgroundType === BackgroundTypeEnum.Width100) && (
        <circle
          style={{ cursor: 'pointer' }}
          cx="6"
          cy="38"
          r="5"
          fill={
            currentPositionType === BackgroundPositionTypeEnum.CenterLeft
              ? '#399EFC'
              : '#7C8A93'
          }
          stroke="white"
          id={BackgroundPositionTypeEnum.CenterLeft}
        />
      )}
      {currentBackgroundType !== BackgroundTypeEnum.Width100Height100 && (
        <circle
          style={{ cursor: 'pointer' }}
          cx="39"
          cy="6"
          r="5"
          fill={
            currentPositionType === BackgroundPositionTypeEnum.TopCenter
              ? '#399EFC'
              : '#7C8A93'
          }
          stroke="white"
          id={BackgroundPositionTypeEnum.TopCenter}
        />
      )}
      {currentBackgroundType !== BackgroundTypeEnum.Width100Height100 && (
        <circle
          style={{ cursor: 'pointer' }}
          cx="39"
          cy="72"
          r="5"
          fill={
            currentPositionType === BackgroundPositionTypeEnum.BottomCenter
              ? '#399EFC'
              : '#7C8A93'
          }
          stroke="white"
          id={BackgroundPositionTypeEnum.BottomCenter}
        />
      )}
      <circle
        style={{ cursor: 'pointer' }}
        cx="39"
        cy="38"
        r="5"
        fill={
          currentPositionType === BackgroundPositionTypeEnum.CenterCenter
            ? '#399EFC'
            : '#7C8A93'
        }
        stroke="white"
        id={BackgroundPositionTypeEnum.CenterCenter}
      />
      {!(
        currentBackgroundType === BackgroundTypeEnum.Width100Height100 ||
        currentBackgroundType === BackgroundTypeEnum.Width100 ||
        currentBackgroundType === BackgroundTypeEnum.ScalesWithoutCropping
      ) && (
        <circle
          style={{ cursor: 'pointer' }}
          cx="72"
          cy="6"
          r="5"
          fill={
            currentPositionType === BackgroundPositionTypeEnum.TopRight
              ? '#399EFC'
              : '#7C8A93'
          }
          stroke="white"
          id={BackgroundPositionTypeEnum.TopRight}
        />
      )}
      {!(
        currentBackgroundType === BackgroundTypeEnum.Width100Height100 ||
        currentBackgroundType === BackgroundTypeEnum.Width100 ||
        currentBackgroundType === BackgroundTypeEnum.ScalesWithoutCropping
      ) && (
        <circle
          style={{ cursor: 'pointer' }}
          cx="72"
          cy="72"
          r="5"
          fill={
            currentPositionType === BackgroundPositionTypeEnum.BottomRight
              ? '#399EFC'
              : '#7C8A93'
          }
          stroke="white"
          id={BackgroundPositionTypeEnum.BottomRight}
        />
      )}
      {!(currentBackgroundType === BackgroundTypeEnum.Width100) && (
        <circle
          style={{ cursor: 'pointer' }}
          cx="72"
          cy="38"
          r="5"
          fill={
            currentPositionType === BackgroundPositionTypeEnum.CenterRight
              ? '#399EFC'
              : '#7C8A93'
          }
          stroke="white"
          id={BackgroundPositionTypeEnum.CenterRight}
        />
      )}
    </svg>
  )
}
