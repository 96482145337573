import styled, { CSSProp } from 'styled-components'

export const PhoneInputInputUi = styled.input<{
  styles?: CSSProp
  disabled?: boolean
}>`
  overflow: visible;
  height: 36px;
  box-sizing: border-box;
  padding: 0 8px;
  border: 1px solid gainsboro;
  border-radius: 4px;
  margin: 0;
  background-color: white;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: #222;
  font-family: inherit;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
    background-color: whitesmoke;
    color: #666;`}

  ${({ styles }) => styles}
`
