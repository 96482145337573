import styled from 'styled-components'
import { BorderType } from 'common/types/styleTypes'
import { borderCss, mobileBorderCss } from 'common/utils/styleUtilsNew'

type SurveyAnswerUiProps = {
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  borderColor: string
  fontSize: number
  mobileFontSize?: number
  backgroundColor: string
}

const SurveyAnswerUi = styled.div<SurveyAnswerUiProps>`
  font-size: ${p => p.fontSize}px;
  color: ${p => p.color};
  background-color: ${p => p.backgroundColor};
  border: 1px solid transparent;
  padding: 10px;
  margin-bottom: 10px;
  ${borderCss}
  &:hover {
    border: 1px solid ${p => p.borderColor};
  }
  ${p => p.theme.phone} {
    ${mobileBorderCss};
    ${p => p.mobileFontSize && `font-size: ${p.mobileFontSize}px;`};
  }
  ${p => p.theme.isEditorMobileMode && mobileBorderCss}
  ${p => p.theme.isEditorMobileMode && `font-size: ${p.mobileFontSize}px;`}
`

export default SurveyAnswerUi
