import styled from 'styled-components'
import { AlignType } from '../../../../types/styleTypes'
import {
  mobileTextAlignCss,
  textAlignCss,
} from '../../../../utils/styleUtilsNew'

type RawHtmlUiProps = {
  attrId: string
  textAlign: AlignType
  mobileTextAlign?: AlignType
}

const RawHtmlUi = styled.div.attrs<RawHtmlUiProps>(p => ({
  id: p.attrId,
}))<RawHtmlUiProps>`
  min-height: 20px;
  ${textAlignCss}
  ${p => p.theme.phone} {
    ${mobileTextAlignCss}
  }
  ${p => p.theme.isEditorMobileMode && textAlignCss}
`
export default RawHtmlUi
