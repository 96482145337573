import styled from 'styled-components'

type WebinarRegistrationDateUi = {
  attrId: string
  spaceBetween: number
}

const WebinarRegistrationDateUi = styled.div.attrs<WebinarRegistrationDateUi>(
  p => ({
    id: p.attrId,
    ...(process.env.NODE_ENV === 'test'
      ? { 'data-testid': 'webinar-registration-date' }
      : {}),
  }),
)<WebinarRegistrationDateUi>`
  display: flex;
  flex-direction: column;
  gap: ${p => p.spaceBetween}px;
`

export default WebinarRegistrationDateUi
