export const countdownTypes = {
  date: 'date',
  minute: 'minute',
  daily: 'daily',
  deadline: 'deadline',
}

export const countdownActions = {
  nothing: 'nothing',
  redirection: 'redirection',
}

export const countdownActionNames = {
  nothing: 'entity_settings.countdown.actions.nothing',
  redirection: 'entity_settings.countdown.actions.redirection',
}

export const countdownTypeNames = {
  date: 'components.countdown.types.date',
  minute: 'components.countdown.types.minute',
  daily: 'components.countdown.types.daily',
  deadline: 'components.countdown.types.deadline',
}
