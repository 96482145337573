import { pageTypes } from './pageTypes'

export const commonMediaQueries = {
  phone: '@media only screen and (max-width: 800px)',
  desktop: '@media only screen and (min-width: 801px)',
}

export const inlineMediaQueries = {
  phone: '@media only screen and (max-width: 480px)',
  desktop: '@media only screen and (min-width: 481px)',
}

export function getMediaQueryByPageType(pageType) {
  switch (pageType) {
    case pageTypes.inline:
      return inlineMediaQueries
    default:
      return commonMediaQueries
  }
}
