import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { LayoutType } from 'client/components/core/Sidebar/components/Settings/components/PostListingLayoutSetting/utils'
import { AlignType, BorderType, MarginType, PaddingType } from '../styleTypes'
import EntityInterface, { CommonContainerInterface } from './EntityInterface'
import { ImagePlacementVariants } from './LatestBlogPostsCarouselInterface'

export type BlogPostCategory = {
  name: string
  path: string
}

export interface BlogPost {
  name: string
  description: string
  categories: BlogPostCategory[]
  image?: string
  path: string
  dateTs: number
}

export interface BlogPostListingInterface
  extends EntityInterface,
    CommonBlogPostListingProps {
  type: EntityTypeEnum.BlogPostListing
  maxPosts: number
  category: string //number?
  layoutType?: LayoutType
  imagePlacement?: ImagePlacementVariants
  mobileImagePlacement?: ImagePlacementVariants
}

export type CommonBlogPostListingProps = {
  showDate: boolean
  displayHorizontally: boolean
  maxPostsPerRow: number
  rowsGap: number
  columnsGap: number
  mobileRowsGap?: number
  padding: PaddingType
  mobilePadding?: PaddingType
  postCard?: PostCardInterface
} & blogPostListingImageInterface &
  blogPostListingTitleInterface &
  blogPostListingDescriptionInterface &
  blogPostListingCategoriesInterface &
  blogPostListingDateInterface &
  blogPostListingPaginationInterface

export interface PostCardInterface extends CommonContainerInterface {
  foregroundColor?: string
  mobileForegroundColor?: string
  verticalAlignment?: VerticalAlignmentVariants
  mobileVerticalAlignment?: VerticalAlignmentVariants
}
export interface blogPostListingImageInterface {
  imageBorder?: Partial<BorderType>
  mobileImageBorder?: Partial<BorderType>
  imageBoxShadow?: string
  mobileImageBoxShadow?: string
  imageMargin: MarginType
  mobileImageMargin: Partial<MarginType>
}
export interface blogPostListingTitleInterface {
  titleFontSize: number
  mobileTitleFontSize?: number
  titleLineHeight?: number
  mobileTitleLineHeight?: number
  titleFontFamily?: string
  titleFontWeight?: string
  titleFontStyle?: string
  mobileTitleFontFamily?: string
  mobileTitleFontWeight?: string
  mobileTitleFontStyle?: string
  titleLetterSpacing?: number
  mobileTitleLetterSpacing?: number
  titleAlign: AlignType
  mobileTitleAlign?: AlignType
  titleColor: string
  mobileTitleColor?: string
  titleFontFileId?: number
  mobileTitleFontFileId?: number
  titleMargin: MarginType
  mobileTitleMargin: Partial<MarginType>
}

export interface blogPostListingDescriptionInterface {
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionLineHeight?: number
  mobileDescriptionLineHeight?: number
  descriptionFontFamily?: string
  descriptionFontWeight?: string
  descriptionFontStyle?: string
  mobileDescriptionFontFamily?: string
  mobileDescriptionFontWeight?: string
  mobileDescriptionFontStyle?: string
  descriptionAlign: AlignType
  mobileDescriptionAlign?: AlignType
  descriptionColor: string
  mobileDescriptionColor?: string
  descriptionFontFileId?: number
  mobileDescriptionFontFileId?: number
  descriptionMargin: MarginType
  mobileDescriptionMargin: Partial<MarginType>
}

export interface blogPostListingCategoriesInterface {
  categoriesFontSize: number
  mobileCategoriesFontSize?: number
  categoriesFontFamily?: string
  categoriesFontWeight?: string
  categoriesFontStyle?: string
  mobileCategoriesFontFamily?: string
  mobileCategoriesFontWeight?: string
  mobileCategoriesFontStyle?: string
  categoriesAlign: AlignType
  mobileCategoriesAlign?: AlignType
  categoriesColor: string
  mobileCategoriesColor?: string
  categoriesFontFileId?: number
  mobileCategoriesFontFileId?: number
  categoriesMargin: MarginType
  mobileCategoriesMargin: Partial<MarginType>
}

export interface blogPostListingDateInterface {
  dateFontSize: number
  mobileDateFontSize?: number
  dateFontFamily?: string
  dateFontWeight?: string
  dateFontStyle?: string
  mobileDateFontFamily?: string
  mobileDateFontWeight?: string
  mobileDateFontStyle?: string
  dateAlign: AlignType
  mobileDateAlign?: AlignType
  dateColor: string
  mobileDateColor?: string
  dateFontFileId?: number
  mobileDateFontFileId?: number
  dateMargin: MarginType
  mobileDateMargin: Partial<MarginType>
}

export interface blogPostListingPaginationInterface {
  paginationActiveColor: string
  mobilePaginationActiveColor?: string
  paginationActiveBackgroundColor: string
  mobilePaginationActiveBackgroundColor?: string
  paginationInactiveColor: string
  mobilePaginationInactiveColor?: string
  paginationInactiveBackgroundColor: string
  mobilePaginationInactiveBackgroundColor?: string
}

export enum VerticalAlignmentVariants {
  Top = 'Top',
  Center = 'Center',
  Bottom = 'Bottom',
}
