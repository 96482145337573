import styled, { css } from 'styled-components'
import { fontCss, FontProps } from 'common/utils/styleUtilsNew'

export const DayPickerWrapperUi = styled.div<FontProps>`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(181, 195, 213, 0.5);
  margin-top: 20px;
  overflow: hidden;

  & .rdp-chevron {
    fill: #393939;
  }

  ${fontCss}

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      width: 100%;
      min-height: 420px;
      font-size: 16px;

      & .rdp-month_grid {
        border-spacing: 3px;
      }
    `}
  ${p => p.theme.phone} {
    width: 100%;
    min-height: 420px;
    font-size: 16px;

    & .rdp-month_grid {
      border-spacing: 3px;
    }
  }

  @media (max-width: 400px) {
    .rdp-day {
      width: 30px !important;
      max-width: 30px;
    }
  }
`
