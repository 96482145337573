import styled from 'styled-components'

interface MobileSubMenuChevronUiProps {
  opened: boolean
  color: string
}

const MobileSubMenuChevronUi = styled.i<MobileSubMenuChevronUiProps>`
  ${p => (p.opened ? `transform: rotate(180deg);` : ``)}
  transition: transform 0.3s ease-in-out;
  color: ${p => p.color};
`

export default MobileSubMenuChevronUi
