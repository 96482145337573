import styled from 'styled-components'

const CloseUi = styled.i`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 24px;
`

export default CloseUi
