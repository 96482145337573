import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export const QueryKeys = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
}

export const Query = {
  [QueryKeys.MOBILE]: '(max-width: 700px)',
  [QueryKeys.DESKTOP]: '(min-width: 701px)',
}

export const useMatchMedia = query => {
  const matchMedia = window.matchMedia(query)

  const [isMatching, setIsMatching] = useState(matchMedia.matches)

  const updateMatching = () => setIsMatching(matchMedia.matches)

  useEffect(() => {
    try {
      matchMedia.addEventListener('change', updateMatching)
    } catch {
      // safari
      matchMedia.addListener(updateMatching)
    }
    return () => {
      try {
        matchMedia.removeEventListener('change', updateMatching)
      } catch {
        matchMedia.removeListener(updateMatching)
      }
    }
  })

  return isMatching
}

export const MatchMedia = ({ query, children, not }) => {
  const matchQuery = useMatchMedia(Query[query] || query)
  const isMatching = not ? !matchQuery : matchQuery
  return <React.Fragment>{isMatching ? children : <div />}</React.Fragment>
}

MatchMedia.propTypes = {
  query: PropTypes.oneOf([QueryKeys.DESKTOP, QueryKeys.MOBILE]).isRequired,
  children: PropTypes.any.isRequired,
  not: PropTypes.bool,
}

MatchMedia.defaultProps = {
  not: false,
}

export const IsDesktop = ({ children }) =>
  typeof window === 'undefined' ? (
    children
  ) : (
    <MatchMedia query={QueryKeys.DESKTOP}>{children}</MatchMedia>
  )

IsDesktop.propTypes = {
  children: PropTypes.any.isRequired,
}

export const IsMobile = ({ children }) =>
  typeof window === 'undefined' ? (
    children
  ) : (
    <MatchMedia query={QueryKeys.MOBILE}>{children}</MatchMedia>
  )

IsMobile.propTypes = {
  children: PropTypes.any.isRequired,
}
