import {
  FIELD_CHANGE,
  BOOKING_FIELD_CHANGE,
  FIELD_CHANGE_INVALID,
  FINISH_SURVEY,
  SUBMIT_FAIL,
  SUBMIT_SUCCESS,
  TOGGLE_EXPLICIT_CONSENT_CONFIRM,
} from 'publisher/actionTypes'
import {
  SUBMIT_RECAPTCHA,
  OPT_IN_RECAPTCHA_VALIDATION_FAILED,
} from 'publisher/store/optIn/optInActionTypes'

export const customerCountryPlaceholder = '%CUSTOMER_COUNTRY%'
const nextStepUrlPlaceholder = '%NEXT_STEP_URL%'
const customerFieldsPlaceholder = '%CUSTOMER_FIELDS%'
const availableCountriesPlaceholder = '%AVAILABLE_COUNTRIES%'
const csrfTokenPlaceholder = '%CSRF_TOKEN%'

export const defaultState = {
  customerFields: customerFieldsPlaceholder,
  fields: {},
  availableCountries: availableCountriesPlaceholder,
  errors: {
    common: [],
    fields: {},
    bookingFields: {},
  },
  bookingFields: {},
  success: false,
  submitted: false,
  customerCountry: customerCountryPlaceholder,
  nextStepUrl: nextStepUrlPlaceholder,
  surveyResults: null,
  surveysResults: undefined,
  csrfToken: csrfTokenPlaceholder,
  submitedRecaptchas: [],
  optInRecaptchaError: {},
}

const updateSubmitedRecaptchas = (state, newRecaptcha) => {
  const foundRecaptcha = state.find(
    recaptcha => recaptcha.id === newRecaptcha.id,
  )
  if (foundRecaptcha) {
    return state.reduce((acc, curr) => {
      if (curr.id === newRecaptcha.id) {
        acc.push(newRecaptcha)
      } else acc.push(curr)
      return acc
    }, [])
  }

  return [...state, newRecaptcha]
}

export default function (state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case SUBMIT_SUCCESS:
      return {
        ...state,
        fields: {
          ...Object.keys(state.fields).reduce(
            (prev, slug) => ({ ...prev, [slug]: '' }),
            {},
          ),
          country: state.fields.country, // avoid price plan flash (vat)
        },
        bookingFields: Object.keys(state.bookingFields ?? {}).reduce(
          (prev, slug) => {
            prev[slug] = ''
            return prev
          },
          {},
        ),
        success: true,
        submitted: true,
      }
    case SUBMIT_FAIL:
      return {
        ...state,
        errors: {
          common: payload.common || [],
          fields: {
            ...payload.fields,
          },
          bookingFields: payload.bookingFields || {},
        },
        success: false,
        submitted: true,
      }
    case FIELD_CHANGE: {
      const fields = { ...state.errors.fields }
      delete fields[payload.slug]

      return {
        ...state,
        fields: {
          ...state.fields,
          [payload.slug]: payload.value,
        },
        errors: {
          ...state.errors,
          common: [],
          fields,
        },
        success: false,
      }
    }
    case BOOKING_FIELD_CHANGE: {
      const bookingFields = { ...state.errors.bookingFields }
      delete bookingFields[payload.slug]

      return {
        ...state,
        bookingFields: {
          ...state.bookingFields,
          [payload.slug]: payload.value,
        },
        errors: {
          ...state.errors,
          common: [],
          bookingFields,
        },
        success: false,
      }
    }
    case FIELD_CHANGE_INVALID:
      return {
        ...state,
        errors: {
          ...state.errors,
          fields: {
            ...state.fields,
            [payload.slug]: payload.value,
          },
        },
      }
    case TOGGLE_EXPLICIT_CONSENT_CONFIRM:
      return {
        ...state,
        errors: {
          ...state.errors,
          common: [],
        },
        submitted: false,
      }
    case FINISH_SURVEY:
      return {
        ...state,
        surveyResults: {
          ...state.surveyResults,
          ...payload,
        },
        surveysResults: {
          ...state.surveysResults,
          ...payload,
        },
      }
    case SUBMIT_RECAPTCHA: {
      return {
        ...state,
        submitedRecaptchas: updateSubmitedRecaptchas(
          state.submitedRecaptchas,
          payload,
        ),
        optInRecaptchaError:
          state.optInRecaptchaError.id === payload.id
            ? {}
            : state.optInRecaptchaError,
      }
    }
    case OPT_IN_RECAPTCHA_VALIDATION_FAILED:
      return {
        ...state,
        optInRecaptchaError: payload,
      }
    default:
      return state
  }
}

export const getFieldValue = (state, slug) =>
  state.fields && state.fields[slug] ? state.fields[slug] : ''

export const getBookingFieldValue = (state, slug) =>
  state.bookingFields && state.bookingFields[slug] !== undefined
    ? state.bookingFields[slug]
    : ''

export const getCustomerFields = ({ customerFields }) => {
  try {
    return JSON.parse(customerFields)
  } catch (e) {
    return {}
  }
}

export const getCustomerFieldValue = (state, slug) =>
  getCustomerFields(state)[slug]

export const getFieldErrors = (state, slug) => state.errors.fields[slug] || []

const getBookingFieldErrors = (state, slug) => state.errors.bookingFields[slug] || []

export const getErrors = state => state.errors

export const getCommonErrors = ({ errors }) => errors.common

export const getCustomerCountry = ({ customerCountry }) =>
  customerCountry !== customerCountryPlaceholder ? customerCountry : null

export const getAvailableCountries = ({ availableCountries }) => {
  try {
    return JSON.parse(availableCountries)
  } catch (e) {
    return {}
  }

  return availableCountries
}

export const isSubmitted = ({ submitted }) => submitted

export const isSubmitSuccessful = ({ success }) => success

export const getNextStepUrl = ({ nextStepUrl }) => nextStepUrl

export const getCsrfToken = ({ csrfToken }) => csrfToken

export const getFields = ({ fields }) => fields

export const getCustomerOptInFields = ({ fields }) => {
  const { datetime, timerDatetime, ...customerFields } = fields
  return customerFields
}

export const getBookingOptInFields = ({ bookingFields }) => {
  return bookingFields || {}
}

export const getFieldsBySlugs = ({ fields }, slugs) =>
  slugs.reduce((acc, slug) => ({ ...acc, [slug]: fields[slug] }), {})

export const getDateTimeField = ({ fields }) =>
  fields.datetime || fields.timerDatetime // todo could it be one?

export const getSurveyResults = state => state.surveyResults

export const selectors = {
  getFieldErrors,
  getBookingFieldErrors,
  getFieldValue,
  getFields,
  getNextStepUrl,
  getCustomerFields,
  getCustomerCountry,
  getCustomerOptInFields,
  getDateTimeField,
  getSurveyResults,
  getAvailableCountries,
  getCsrfToken,
  getBookingOptInFields,
  getBookingFieldValue,
}
