import styled, { css } from 'styled-components'

const beforeStyles = css`
  &::before {
    content: '⌄';
    vertical-align: 60%;
    padding: 5px;
    cursor: pointer;
    ${({ styles = {} }) => styles}
  }
`

const LanguageSwitcherDropdownUi = styled.span`
  ${p => p.theme.phone} {
    ${beforeStyles}
  }
  ${({ styles = {} }) => styles}
  ${beforeStyles}
`

export default LanguageSwitcherDropdownUi
