import styled from 'styled-components'

const TooltipArrowUi = styled.div`
  width: 50px;
  height: 25px;
  position: absolute;
  top: 100%;
  left: ${props => `${props.left}px` || '50%'};
  transform: translateX(-50%);
  overflow: hidden;
  &::after {
   content: "";
   position: absolute;
   width: 10px;
   height: 10px;
   background: white;
   transform: translateX(-50%) translateY(-50%) rotate(45deg);
   top: 0;
   left: 50%;
   box-shadow: 1px 1px 20px 0 rgba(0,0,0,0.6);
  }
`

export default TooltipArrowUi
