import React from 'react'

type AudioNewProps = {
  src?: string
  htmlAttrId: string
}

function AudioNew({
  src = 'https://d1yei2z3i6k35z.cloudfront.net/common/audio-sample.ogg',
  htmlAttrId,
}: AudioNewProps) {
  return (
    <audio
      id={htmlAttrId}
      src={src}
      style={{ width: '100%', height: '32px' }}
      controlsList="nodownload"
      controls
    />
  )
}

export default AudioNew
