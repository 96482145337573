import styled, { css } from 'styled-components'

const columnDirectionCss = css`
  display: flex;
  flex-direction: ${p => (p.reverseColumns ? 'column-reverse' : 'column')};
  grid-template-columns: auto;
`

const RowOldUi = styled.div.attrs(p => ({
  id: p.attrId,
}))`
  //width: 100%; confirmed, it stretches the width alongside with margins
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  ${p =>
    p.theme.isEditorMobileMode && !p.disableStacking && columnDirectionCss};
  ${p => p.theme.phone} {
    ${p => !p.disableStacking && columnDirectionCss};
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${p =>
    p.disableColumnGap &&
    css`
      column-gap: unset !important;
    `}
  ${p => p.styles}
  ${p => p.theme.isEditorMobileMode && p.mobileStyles}
`

export default RowOldUi
