export const slugs = {
  EMAIL: 'email',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm_password',
  FIRST_NAME: 'first_name',
  SURNAME: 'surname',
  PHONE_NUMBER: 'phone_number',
  COUNTRY: 'country',
  STREET_ADDRESS: 'street_address',
  CITY: 'city',
  POSTCODE: 'postcode',
  COMPANY_NAME: 'company_name',
  TAX_NUMBER: 'tax_number',
  STATE: 'state',
  DLOCAL_DOCUMENT_NUMBER: 'dlocal_document_number',
}

export const types = {
  COUNTRY: 'country',
  TEXT: 'text',
}
