export default function(state = {}) {
  return state
}

export const getFile = (files, fileId) => {
  if (!fileId) {
    return null
  }
  return files[fileId]
}

export const getByFileIds = (files, fileIds) =>
  fileIds.map(fileId => files[fileId])

export const selectors = {
  getFile,
}
