import styled from 'styled-components'
import { AlignType, MarginType } from 'common/types/styleTypes'
import {
  textCss,
  mobileTextCss,
  marginCss,
  mobileMarginCss,
} from 'common/utils/styleUtilsNew'

type BlogPostAnnounceDescriptionUiProps = {
  fontSize: number
  color: string
  fontFamily?: string
  fontWeight?: string
  fontStyle?: string
  textAlign: AlignType
  lineHeight?: number
  mobileFontSize?: number
  mobileColor?: string
  mobileFontFamily?: string
  mobileFontWeight?: string
  mobileFontStyle?: string
  mobileLineHeight?: number
  mobileTextAlign?: AlignType
  margin: MarginType
  mobileMargin: Partial<MarginType>
}
const BlogPostAnnounceDescriptionUi = styled.p<BlogPostAnnounceDescriptionUiProps>`
  font-size: 18px;
  ${textCss};
  ${marginCss}
  ${p => p.theme.isEditorMobileMode && mobileTextCss}
  ${p => p.theme.isEditorMobileMode && mobileMarginCss}
  ${p => p.theme.phone} {
    ${mobileMarginCss}
    ${mobileTextCss}
  }
`

export default BlogPostAnnounceDescriptionUi
