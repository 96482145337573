import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import TimerItemDelimiterUi from './ui/TimerItemDelimiterUi'
import TimerItemUi from './ui/TimerItemUi'
import TimerUi from './ui/TimerUi'

interface TimeProps {
  id: string
  days?: number
  hours: number
  minutes: number
  seconds: number
  fontFamily?: string
  fontWeight?: string
  fontStyle?: string
  mobileFontFamily?: string
  mobileFontWeight?: string
  mobileFontStyle?: string
  labelStyles: CountdownInterface['labelStyles']
  timeStyles: CountdownInterface['timeStyles']
  hideDays: boolean
  locale: PageLocaleEnum
}

function Timer(props: TimeProps) {
  const {
    id,
    hideDays,
    days = 0,
    //for old entity
    hours = 0,
    minutes = 0,
    seconds = 0,
    fontWeight,
    fontStyle,
    fontFamily,
    mobileFontFamily,
    mobileFontStyle,
    mobileFontWeight,
    timeStyles,
    labelStyles,
    locale,
  } = props
  const { t, i18n } = useTranslation('common')
  const translate = locale ? i18n.getFixedT(locale, 'common') : t
  return (
    <TimerUi
      fontFamily={fontFamily}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      mobileFontFamily={mobileFontFamily}
      mobileFontStyle={mobileFontStyle}
      mobileFontWeight={mobileFontWeight}
      id={id}
    >
      {!hideDays && (
        <React.Fragment>
          <TimerItemUi
            label={translate('components.timer.days')}
            time={days}
            timeStyles={timeStyles}
            labelStyles={labelStyles}
          />
          <TimerItemDelimiterUi {...timeStyles}> : </TimerItemDelimiterUi>
        </React.Fragment>
      )}
      <TimerItemUi
        label={translate('components.timer.hours')}
        time={hours}
        timeStyles={timeStyles}
        labelStyles={labelStyles}
      />
      <TimerItemDelimiterUi {...timeStyles}> : </TimerItemDelimiterUi>
      <TimerItemUi
        label={translate('components.timer.minutes')}
        time={minutes}
        timeStyles={timeStyles}
        labelStyles={labelStyles}
      />
      <TimerItemDelimiterUi {...timeStyles}> : </TimerItemDelimiterUi>
      <TimerItemUi
        label={translate('components.timer.seconds')}
        time={seconds}
        timeStyles={timeStyles}
        labelStyles={labelStyles}
      />
    </TimerUi>
  )
}

export default Timer
