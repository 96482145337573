import pageSelectors from 'publisher/store/page/pageSelectors'
import React from 'react'
import { useDispatch } from 'react-redux'
import CommonPricePlanCalculator from 'common/components/entities/PricePlanCalculator'
import PricePlanCalculatorInterface from 'common/types/entities/PricePlanCalculatorInterface'
import { showPopup } from '../../actionsManagement'
import { usePage, useOptIn, optInSelectors } from '../../store'

type PricePlanSliderProps = {
  entity: PricePlanCalculatorInterface
}

function PricePlanCalculator({ entity }: PricePlanSliderProps) {
  const dispatch = useDispatch()
  const countryCode = useOptIn(optInSelectors.getCustomerCountry)
  const pageLocale = usePage(pageSelectors.getLocale)

  function handleOpenPopup() {
    dispatch(showPopup(entity.popupId))
  }

  return (
    <CommonPricePlanCalculator
      openPopup={handleOpenPopup}
      locale={pageLocale}
      countryCode={countryCode}
    />
  )
}

export default PricePlanCalculator
