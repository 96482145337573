import styled, { css } from 'styled-components'
import {
  colorCss,
  lineHeightCss,
  mobileColorCss,
  mobileLineHeightCss,
  mobileTextCss,
  textCss,
} from 'common/utils/styleUtilsNew'

export type CheckboxLabelUiProps = {
  fontSize?: number
  mobileFontSize?: number
  lineHeight?: number
  mobileLineHeight?: number
  fontFamily?: string
  mobileFontFamily?: string
  color?: string
  mobileColor?: string
}

const CheckboxLabelUi = styled.label<CheckboxLabelUiProps>`
  display: flex;
  align-items: baseline;
  flex-basis: 100%;
  ${textCss}
  ${colorCss}
  ${lineHeightCss}

  ${p => p.theme.phone} {
    ${mobileTextCss}
    ${mobileColorCss}
    ${mobileLineHeightCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileTextCss}
      ${mobileColorCss}
      ${mobileLineHeightCss}
    `}
`

export default CheckboxLabelUi
