import styled, { css } from 'styled-components'

const BodyBackgroundUi = styled.div`
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  ${p => p.theme.phone} {
    ${props =>
      props.styles &&
      props.styles.backgroundAttachment === 'fixed' &&
      props.styles.backgroundImage &&
      css`
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        background-position: center center;
      `}
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
  // for ipad fixed background
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    ${props =>
      props.styles &&
      props.styles.backgroundAttachment === 'fixed' &&
      props.styles.backgroundImage &&
      css`
        &:before {
          content: '';
          background-image: inherit;
          position: fixed;
          top: 0;
          left: 0;
          height: 100vmax;
          width: 100%;
          -webkit-background-size: cover !important;
          -moz-background-size: cover !important;
          -o-background-size: cover;
          background-size: cover !important;
          background-position: center center;
          z-index: -1;
        }
      `}
  }
`

export default BodyBackgroundUi
