import styled, { css } from 'styled-components'
import { ColumnBaseInterface } from 'common/components/entities/Column/types/ColumnInterface'
import { AlignSelfType } from 'common/types/styleTypes'

interface ColumnUiInterface extends ColumnBaseInterface {
  gridColumnStart: number
  disableStacking?: boolean
  alignSelf: AlignSelfType
}

const disableStackingUi = css`
  align-self: unset;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 12;
  padding-left: 0;
  padding-right: 0;
`

const ColumnUi = styled.div<ColumnUiInterface>`
  display: flex;
  flex-direction: column;
  grid-column-start: ${p => p.gridColumnStart};
  grid-column-end: ${p => p.gridColumnStart + p.size};
  align-self: ${p => p.alignSelf};
  ${p => p.theme.phone} {
    ${p => !p.disableStacking && disableStackingUi}
  }
  ${p => p.theme.isEditorMobileMode && !p.disableStacking && disableStackingUi}
`

export default ColumnUi
