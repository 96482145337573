import PropTypes from 'prop-types'
import React, { memo } from 'react'
import * as types from 'common/types'
import FieldErrors from 'publisher/components/FieldErrors'
import useCustomLoginSubmit from 'publisher/hooks/useCustomLoginSubmit'
import BaseButton from './BaseButton'

function CustomLoginButton({ entity }) {
  const { styles, mobileStyles, options, mobileOptions } = entity
  const [isLoading, errors, submit] = useCustomLoginSubmit(entity)

  return (
    <React.Fragment>
      <BaseButton
        handleClick={submit}
        disabled={isLoading}
        styles={styles}
        mobileStyles={mobileStyles}
        attrId={options.attrId}
        text={options.text}
        subText={options.subText}
        subTextColor={options.subTextColor}
        subTextFontSize={options.subTextFontSize}
        subTextFontWeight={options.subTextFontWeight}
        subTextFontStyle={options.subTextFontStyle}
        subTextFontFamily={options.subTextFontFamily}
        mobileSubTextColor={mobileOptions.subTextColor}
        mobileSubTextFontSize={mobileOptions.subTextFontSize}
        mobileSubTextFontWeight={mobileOptions.subTextFontWeight}
        mobileSubTextFontStyle={mobileOptions.subTextFontStyle}
        mobileSubTextFontFamily={mobileOptions.subTextFontFamily}
        iconClassNameBefore={options.iconClassNameBefore}
        iconClassNameAfter={options.iconClassNameAfter}
        borderType={options.borderType}
        mobileBorderType={mobileOptions.borderType}
        appearance={options.appearance}
        hoverStyles={options?.hover}
        hoverMobileStyles={mobileOptions?.hover}
      />
      <FieldErrors errors={errors} align="center" />
    </React.Fragment>
  )
}

CustomLoginButton.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(CustomLoginButton)
