import styled, { css } from 'styled-components'
import { BorderType } from 'common/types/styleTypes'
import {
  backgroundColorCss,
  mobileBackgroundColorCss,
  mobileTextCss,
  textCss,
  TextProps,
} from 'common/utils/styleUtilsNew'

interface ContentBoxHeaderUiProps extends TextProps {
  backgroundColor?: string
  mobileBackgroundColor?: string
  borderRadius?: BorderType['radius']
  mobileBorderRadius?: BorderType['radius']
}

const ContentBoxHeaderUi = styled.div<ContentBoxHeaderUiProps>`
  padding: 10px;
  text-align: ${p => p.textAlign || 'start'};
  min-height: 50px;
  ${textCss}
  ${backgroundColorCss}
  ${p => p.theme.phone} {
    ${mobileTextCss}
    ${mobileBackgroundColorCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileTextCss}
      ${mobileBackgroundColorCss}
    `}
`

export default ContentBoxHeaderUi
