import styled from 'styled-components'
import {
  ActiveMobilePaginationArrowCss,
  ActivePaginationArrowCss,
} from 'common/utils/styleUtilsNew'

type PaginationCurrentPageUiProps = {
  paginationActiveColor: string
  mobilePaginationActiveColor?: string
  paginationActiveBackgroundColor: string
  mobilePaginationActiveBackgroundColor?: string
}

const PaginationCurrentPageUi = styled.span<PaginationCurrentPageUiProps>`
  font-size: 14px;
  padding: 5px 10px;
  text-align: center;
  border-radius: 50%;
  padding: 0em 0.5em;
  height: 40px;
  line-height: 40px;
  width: 40px;
  margin-left: 8px;
  margin-right: 8px;
  ${ActivePaginationArrowCss}
  ${p => p.theme.isEditorMobileMode && ActiveMobilePaginationArrowCss};
  ${p => p.theme.phone} {
    ${ActiveMobilePaginationArrowCss}
    margin-top: 5px;
  }
`

export default PaginationCurrentPageUi
