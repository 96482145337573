import styled, { css } from 'styled-components'

interface HorizontalMenuDropdownUiProps {
  spacing?: number
}

const HorizontalMenuDropdownUi = styled.div<HorizontalMenuDropdownUiProps>`
  display: flex;
  flex-direction: column;
  ${p =>
    p.spacing &&
    css`
      gap: ${p.spacing}px;
    `}
  height: fit-content;
  background-color: white;
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: max-content;
  border-radius: 5px;
`

export default HorizontalMenuDropdownUi
