import React from 'react'
import { Helmet } from 'react-helmet'
import { useFiles, usePage } from 'publisher/store'
import { useManagement } from 'publisher/store'
import filesSelectors from 'publisher/store/files/filesSelectors'
import managementSelectors from 'publisher/store/management/managementSelectors'
import pageSelectors from 'publisher/store/page/pageSelectors'

function MetaTags() {
  const seo = usePage(pageSelectors.getSeo)
  const url = useManagement(managementSelectors.getUrl)
  const file = useFiles(files =>
    filesSelectors.getFile(
      files,
      seo.socialImage ? parseInt(seo.socialImage) : undefined,
    ),
  )
  const ogImagePath = file ? file.path : ''
  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="title" content={seo.title} />
      <meta name="description" content={seo.description} />
      <meta name="author" content={seo.author} />
      <meta name="keywords" content={seo.keywords} />
      <meta property="og:image" content={ogImagePath} />
      {ogImagePath && <meta property="og:image:alt" content={seo.title} />}
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={url} />
      {seo.index === false && <meta name="robots" content="noindex" />}
    </Helmet>
  )
}

export default MetaTags
