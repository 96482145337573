import { PageLocaleEnum } from '../enums/PageLocaleEnum'

export function getCurrencySignByLocale(
  locale: PageLocaleEnum,
  countryCode?: string,
) {
  switch (locale) {
    case PageLocaleEnum.ENGLISH:
    case PageLocaleEnum.SPANISH:
      return 'USD'
    case PageLocaleEnum.PORTUGUESE:
      // if (countryCode && countryCode === 'BR') {
      //   return 'BRL'
      // }

      return 'BRL'
    case PageLocaleEnum.RUSSIAN:
      return 'RUB'
    default:
      return 'EUR'
  }
}
