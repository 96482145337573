import React from 'react'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import {
  blogPostListingTitleInterface,
  BlogPost,
  blogPostListingImageInterface,
  blogPostListingDescriptionInterface,
  blogPostListingCategoriesInterface,
  blogPostListingDateInterface,
  PostCardInterface,
} from 'common/types/entities/BlogPostListingInterface'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import BlogPostCategories from './BlogPostCategories'
import BlogPostImage from './BlogPostImage'
import BlogPostsDates from './BlogPostsDates'
import BlogPostsDescriptions from './BlogPostsDescriptions'
import BlogPostsTitles from './BlogPostsTitles'
import BlogPostCardContentUi from './ui/BlogPostCardContentUi'
import BlogPostCardUi from './ui/BlogPostCardUi'

type BlogPostCardProps = {
  blogPath?: string
  post: BlogPost
  rowSize?: number
  mobileRowsGap?: number
  locale: PageLocaleEnum
  showDate: boolean
  postCard?: PostCardInterface
  imagePlacement?: ImagePlacementVariants
  mobileImagePlacement?: ImagePlacementVariants
  layoutType?: number
  isCategoryPage: boolean
  displayHorizontally: boolean
  isMobile?: boolean
} & blogPostListingImageInterface &
  blogPostListingTitleInterface &
  blogPostListingDescriptionInterface &
  blogPostListingCategoriesInterface &
  blogPostListingDateInterface

function BlogPostCard(props: BlogPostCardProps) {
  return (
    <BlogPostCardUi
      imagePlacement={props.imagePlacement}
      mobileImagePlacement={props.mobileImagePlacement}
      imageSrc={props.post.image}
      boxShadow={props.postCard?.boxShadow}
      backgroundColor={props.postCard?.backgroundColor}
      foregroundColor={props.postCard?.foregroundColor}
      mobileForegroundColor={props.postCard?.mobileForegroundColor}
      border={props.postCard?.border}
      mobileBorder={props.postCard?.mobileBorder}
      mobileBackgroundColor={props.postCard?.mobileBackgroundColor}
      mobileBoxShadow={props.postCard?.mobileBoxShadow}
      verticalAlignment={props.postCard?.verticalAlignment}
      mobileVerticalAlignment={props.postCard?.mobileVerticalAlignment}
    >
      {!props.isMobile &&
        props.imagePlacement !== ImagePlacementVariants.Background && (
          <BlogPostImage
            post={props.post}
            imageBorder={props.imageBorder}
            mobileImageBorder={props.mobileImageBorder}
            imageBoxShadow={props.imageBoxShadow}
            mobileImageBoxShadow={props.mobileImageBoxShadow}
            imageMargin={props.imageMargin}
            mobileImageMargin={props.mobileImageMargin}
          />
        )}
      {props.isMobile &&
        props.mobileImagePlacement !== ImagePlacementVariants.Background && (
          <BlogPostImage
            post={props.post}
            imageBorder={props.imageBorder}
            mobileImageBorder={props.mobileImageBorder}
            imageBoxShadow={props.imageBoxShadow}
            mobileImageBoxShadow={props.mobileImageBoxShadow}
            imageMargin={props.imageMargin}
            mobileImageMargin={props.mobileImageMargin}
          />
        )}
      <BlogPostCardContentUi
        padding={props.postCard?.padding}
        mobilePadding={props.postCard?.mobilePadding}
      >
        <BlogPostCategories
          post={props.post}
          isCategoryPage={props.isCategoryPage}
          categoriesFontSize={props.categoriesFontSize}
          mobileCategoriesFontSize={props.mobileCategoriesFontSize}
          categoriesFontFamily={props.categoriesFontFamily}
          categoriesFontWeight={props.categoriesFontWeight}
          categoriesFontStyle={props.categoriesFontStyle}
          mobileCategoriesFontFamily={props.mobileCategoriesFontFamily}
          mobileCategoriesFontWeight={props.mobileCategoriesFontWeight}
          mobileCategoriesFontStyle={props.mobileCategoriesFontStyle}
          categoriesAlign={props.categoriesAlign}
          mobileCategoriesAlign={props.mobileCategoriesAlign}
          categoriesColor={props.categoriesColor}
          mobileCategoriesColor={props.mobileCategoriesColor}
          categoriesMargin={props.categoriesMargin}
          mobileCategoriesMargin={props.mobileCategoriesMargin}
          blogPath={props.blogPath}
        />
        <BlogPostsTitles
          post={props.post}
          titleFontSize={props.titleFontSize}
          mobileTitleFontSize={props.mobileTitleFontSize}
          titleLineHeight={props.titleLineHeight}
          mobileTitleLineHeight={props.mobileTitleLineHeight}
          titleFontFamily={props.titleFontFamily}
          titleFontWeight={props.titleFontWeight}
          titleFontStyle={props.titleFontStyle}
          mobileTitleFontFamily={props.mobileTitleFontFamily}
          mobileTitleFontWeight={props.mobileTitleFontWeight}
          mobileTitleFontStyle={props.mobileTitleFontStyle}
          titleLetterSpacing={props.titleLetterSpacing}
          mobileTitleLetterSpacing={props.mobileTitleLetterSpacing}
          titleAlign={props.titleAlign}
          mobileTitleAlign={props.mobileTitleAlign}
          titleColor={props.titleColor}
          mobileTitleColor={props.mobileTitleColor}
          titleMargin={props.titleMargin}
          mobileTitleMargin={props.mobileTitleMargin}
        />
        {props.showDate && (
          <BlogPostsDates
            post={props.post}
            dateFontSize={props.dateFontSize}
            mobileDateFontSize={props.mobileDateFontSize}
            dateFontFamily={props.dateFontFamily}
            dateFontWeight={props.dateFontWeight}
            dateFontStyle={props.dateFontStyle}
            mobileDateFontFamily={props.mobileDateFontFamily}
            mobileDateFontWeight={props.mobileDateFontWeight}
            mobileDateFontStyle={props.mobileDateFontStyle}
            dateAlign={props.dateAlign}
            mobileDateAlign={props.mobileDateAlign}
            dateColor={props.dateColor}
            mobileDateColor={props.mobileDateColor}
            dateMargin={props.dateMargin}
            mobileDateMargin={props.mobileDateMargin}
            locale={props.locale}
          />
        )}
        <BlogPostsDescriptions
          post={props.post}
          descriptionFontSize={props.descriptionFontSize}
          mobileDescriptionFontSize={props.mobileDescriptionFontSize}
          descriptionLineHeight={props.descriptionLineHeight}
          mobileDescriptionLineHeight={props.mobileDescriptionLineHeight}
          descriptionFontFamily={props.descriptionFontFamily}
          descriptionFontWeight={props.descriptionFontWeight}
          descriptionFontStyle={props.descriptionFontStyle}
          mobileDescriptionFontFamily={props.mobileDescriptionFontFamily}
          mobileDescriptionFontWeight={props.mobileDescriptionFontWeight}
          mobileDescriptionFontStyle={props.mobileDescriptionFontStyle}
          descriptionAlign={props.descriptionAlign}
          mobileDescriptionAlign={props.mobileDescriptionAlign}
          descriptionColor={props.descriptionColor}
          mobileDescriptionColor={props.mobileDescriptionColor}
          descriptionMargin={props.descriptionMargin}
          mobileDescriptionMargin={props.mobileDescriptionMargin}
        />
      </BlogPostCardContentUi>
    </BlogPostCardUi>
  )
}

export default BlogPostCard
