import { useCallback } from 'react'
import structureTypes from 'common/constants/structureTypes'
import { useActions } from 'common/hooks/useActions'
import { toggleActivePopup } from '../actionsManagement'
import { dispatchPopupOpenedCustomEvent } from '../utils/popupUtils'
import usePage, { selectors as pageSelectors } from './usePage'

const hasPopupId = node => node && node.hasAttribute('popupId')

export function usePopupOpenerRef() {
  const togglePopup = useActions(toggleActivePopup)
  const popups = usePage(page =>
    pageSelectors.getByType(page, structureTypes.POPUP),
  )

  return useCallback(
    node => {
      if (node !== null) {
        const openPopup = (node, level = 0) => {
          if (level === 5) {
            return
          }
          if (hasPopupId(node)) {
            const popup = popups.find(
              popup => popup.id === node.getAttribute('popupId'),
            )
            if (popup) {
              togglePopup(popup.id)
              dispatchPopupOpenedCustomEvent(popup.options.attrId)
            }
          } else {
            level++
            openPopup(node.parentNode, level)
          }
        }

        node.addEventListener(
          'click',
          ({ target }) => {
            openPopup(target)
          },
          false,
        )
      }
    },
    [togglePopup],
  )
}
