import React, { RefObject, useEffect, useRef } from 'react'
import { CarouselSlideUi } from 'common/components/entities/Carousel'
import { AlignSelfType } from 'common/types/styleTypes'
import { alignSelfVariants } from 'client/constants/alignSelfOptions'

type CarouselSlideProps = {
  active: boolean
  children: React.ReactElement
  setActive: () => void
  needScroll: boolean
  carouselRef: RefObject<HTMLDivElement>
  alignSelf?: AlignSelfType
  mobileAlignSelf?: AlignSelfType
}

function CarouselSlide({
  active,
  needScroll,
  children,
  setActive,
  carouselRef,
  alignSelf = alignSelfVariants.center,
  mobileAlignSelf,
}: CarouselSlideProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && active && needScroll) {
      ref.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      })
    }
  }, [active])

  useEffect(() => {
    const observerParams = { threshold: 0.5, root: carouselRef.current }
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries
        if (entry.isIntersecting) {
          setActive()
        }
      },
      observerParams,
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => observer.disconnect()
  }, [])

  return (
    <CarouselSlideUi
      ref={ref}
      active={active}
      alignSelf={alignSelf}
      mobileAlignSelf={mobileAlignSelf}
    >
      {children}
    </CarouselSlideUi>
  )
}

export default CarouselSlide
