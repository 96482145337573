export const SET_DLOCAL_DOCUMENT_NUMBER = 'SET_DLOCAL_DOCUMENT_NUMBER'
export const SET_ACTIVE_PRICE_PLAN_ID = 'SET_ACTIVE_PRICE_PLAN_ID'
export const TOGGLE_CHECKED_BUMP = 'TOGGLE_CHECKED_BUMP'

export const INCREASE_PRODUCT_QUANTITY = 'INCREASE_PRODUCT_QUANTITY'
export const DECREASE_PRODUCT_QUANTITY = 'DECREASE_PRODUCT_QUANTITY'

export const SET_ACTIVE_PRODUCT_VARIANT = 'SET_ACTIVE_PRODUCT_VARIANT'

export const INITIALIZE_PRODUCT_QUANTITY = 'INITIALIZE_PRODUCT_QUANTITY'

export const SET_SELECTED_SHIPPING = 'SET_SELECTED_SHIPPING'

export const SET_OFFER_PRICING = 'SET_OFFER_PRICING'
