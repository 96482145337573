export function getQueryString(params) {
  return Object.keys(params)
    .filter(k => params[k])
    .map(k => {
      if (Array.isArray(params[k])) {
        return params[k]
          .filter(val => val !== null)
          .map(val => `${encodeURIComponent(k)}[]=${encodeURIComponent(val)}`)
          .join('&')
      }

      return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
    })
    .join('&')
}
