import styled from 'styled-components'

type BlogPostContentTableHeaderIconUiProps = {
  color: string
  fontSize: number
  opened: boolean
  closedIcon: boolean
}

const ContentTableHeaderIconUi = styled.i<BlogPostContentTableHeaderIconUiProps>`
  color: ${p => p.color};
  cursor: pointer;
  font-size: ${p => p.fontSize - 5}px;
  transform: ${p => (!p.opened || p.closedIcon ? 'none' : 'rotate(180deg)')};
  transition: transform 0.3s;
`

export default ContentTableHeaderIconUi
