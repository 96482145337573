import styled, { css } from 'styled-components'
import { colorCss, mobileColorCss } from 'common/utils/styleUtilsNew'

interface ContactUsSuccessMessageUiiProps {
  color?: string
  mobileColor?: string
}

const ContactUsSuccessMessageUi = styled.div<ContactUsSuccessMessageUiiProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  ${colorCss}
  ${p => p.theme.phone} {
    ${mobileColorCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileColorCss}
    `}
`

export default ContactUsSuccessMessageUi
