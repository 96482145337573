import React, { PropsWithChildren } from 'react'
import { ColumnInterface } from 'common/components/entities/Column/types/ColumnInterface'
import ColumnUi from 'common/components/entities/Column/ui/ColumnUi'

interface CommonColumnProps {
  entity: ColumnInterface
  gridColumnStart: number
  disableStacking?: boolean
}

const CommonColumn = ({
  entity,
  gridColumnStart,
  children,
  disableStacking,
}: PropsWithChildren<CommonColumnProps>) => (
  <ColumnUi
    gridColumnStart={gridColumnStart}
    disableStacking={disableStacking}
    size={entity.size}
    alignSelf={entity.alignSelf}
  >
    {children}
  </ColumnUi>
)

export default CommonColumn
