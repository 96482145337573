/* eslint-disable no-underscore-dangle */
import React from 'react'
import { hydrate } from 'react-dom'
import 'common/utils/domNormalizeHack'
import { Query, QueryKeys } from 'publisher/components/core/MatchMedia'
import configureStore from 'publisher/entrypoints/simple/configureStore'
import { selectors } from 'publisher/reducers/optInReducer'
import { selectors as pageSelectors } from 'publisher/reducers/pageReducer'
import { initialiseAxiosInterceptors } from 'publisher/utils/registerAxiosInterceptors'
import { normalizeStringBoolean } from '../../store'
import BaseApp from '../base-app'
import createEntityElement from './createEntityElement'
import createPage from './createPage'

initialiseAxiosInterceptors()

const rollbarReportLevel = window.__ROLLBAR_LEVEL__
const preloadedState = window.__PRELOADED_STATE__
delete window.__PRELOADED_STATE__
// Allow the passed state to be garbage-collected
const isDesktop = window.matchMedia(Query[QueryKeys.DESKTOP]).matches
preloadedState.management.isDesktop = isDesktop
preloadedState.management.isMobile = normalizeStringBoolean(
  preloadedState.management.isMobile,
)
const store = configureStore(preloadedState)
const rootElement = document.getElementById('app')

if (pageSelectors.getCountryFieldId(preloadedState.page)) {
  preloadedState.optIn.fields.country = selectors.getCustomerCountry(
    preloadedState.optIn,
  )
}

hydrate(
  <BaseApp
    createEntityElement={createEntityElement}
    createPage={createPage}
    store={store}
    rollbarReportLevel={rollbarReportLevel}
  />,
  rootElement,
)
