import styled, { css } from 'styled-components'
import { AlignType, PaddingType } from 'common/types/styleTypes'
import {
  fontCss,
  FontProps,
  lineHeightCss,
  mobileFontCss,
  mobileLineHeightCss,
} from 'common/utils/styleUtilsNew'

type BlogPostDateUiNewProps = {
  lineHeight?: number
  mobileLineHeight?: number
  backgroundColor?: string
  mobileBackgroundColor?: string
  alignSelf: AlignType
  mobileAlignSelf?: AlignType
  padding?: PaddingType
  mobilePadding?: PaddingType
} & FontProps

const BlogPostDateUiNew = styled.div<BlogPostDateUiNewProps>`
  text-align: ${p => p.alignSelf};
  ${fontCss}
  ${lineHeightCss}
${p => p.theme.phone} {
    text-align: ${p => p.mobileAlignSelf || 'center'};
    ${mobileFontCss}
    ${mobileLineHeightCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
      ${mobileLineHeightCss}
      text-align: ${p.mobileAlignSelf || 'center'};
    `}
`

export default BlogPostDateUiNew
