import styled from 'styled-components'
import { VerticalAlignmentVariants } from 'common/types/entities/BlogPostListingInterface'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { BorderType } from 'common/types/styleTypes'
import { containerCss } from 'common/utils/styleUtilsNew'
import {
  imageLayerCss,
  imagePlacementCss,
  mobileImageLayerCss,
  mobileImagePlacementCss,
  mobileVerticalAlignmentCss,
  verticalAlignmentCss,
} from '../uiUtils'

const BlogPostCardUi = styled.div<{
  boxShadow?: string
  backgroundColor?: string
  color?: string
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  mobileColor?: string
  mobileBackgroundColor?: string
  mobileBoxShadow?: string
  imagePlacement?: ImagePlacementVariants
  mobileImagePlacement?: ImagePlacementVariants
  imageSrc?: string
  foregroundColor?: string
  mobileForegroundColor?: string
  verticalAlignment?: VerticalAlignmentVariants
  mobileVerticalAlignment?: VerticalAlignmentVariants
}>`
  display: grid;
  grid-template-rows: repeat(5, max-content);
  ${containerCss}
  ${imagePlacementCss}
  ${verticalAlignmentCss}
  ${imageLayerCss}
  overflow: hidden;

  ${p => p.theme.isEditorMobileMode && mobileImagePlacementCss}
  ${p => p.theme.isEditorMobileMode && mobileVerticalAlignmentCss}
  ${p => p.theme.isEditorMobileMode && mobileImageLayerCss}

  ${p => p.theme.phone} {
    ${mobileImagePlacementCss}
    ${mobileVerticalAlignmentCss}
    ${mobileImageLayerCss}
  }
`

export default BlogPostCardUi
