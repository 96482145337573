import styled, { css } from 'styled-components'
import { fontCss, mobileFontCss } from 'common/utils/styleUtilsNew'

type FaqItemTitleUiProps = {
  color: string
  mobileColor?: string
  fontSize: number
  mobileFontSize: number
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
  expanded: boolean
}

const FaqItemTitleUi = styled.div<FaqItemTitleUiProps>`
  ${p =>
    p.expanded &&
    css`
      margin-bottom: 5px;
    `}
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${fontCss}
  cursor: pointer;
  ${p => p.theme.phone} {
    ${mobileFontCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
    `}
`

export default FaqItemTitleUi
