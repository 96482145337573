import styled from 'styled-components'
import { MarginType } from 'common/types/styleTypes'
import { marginCss, mobileMarginCss } from 'common/utils/styleUtilsNew'

type LanguageSwitcherUiProps = {
  margin: MarginType
  mobileMargin?: Partial<MarginType>
}

const LanguageSwitcherUi = styled.div<LanguageSwitcherUiProps>`
  position: relative;
  display: flex;
  align-items: center;
  ${marginCss}
  ${p => p.theme.isEditorMobileMode && mobileMarginCss}
  ${p => p.theme.phone} {
    ${mobileMarginCss}
  }
`

export default LanguageSwitcherUi
