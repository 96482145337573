import React from 'react'
import { BlogPostListingInterface } from 'common/types/entities/BlogPostListingInterface'

export enum LayoutType {
  Grid3x3 = 'grid3x3',
  GridLongShort3ShortLong = 'gridLongShort3ShortLong',
  Grid1Big3Under = 'grid1Big3Under',
  GridRows3x2x3 = 'gridRows3x2x3',
  Grid1Big3Side = 'grid1Big3Side',
}

export const addLeftPaddingToPostCard = (
  entity: BlogPostListingInterface,
  value: number,
) => {
  if (entity.postCard) {
    entity.postCard = {
      ...entity.postCard,
      padding: {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: value,
      },
    }
  } else {
    entity.postCard = {
      padding: {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: value,
      },
    }
  }
}

export const clearPostCardPaddings = (entity: BlogPostListingInterface) => {
  if (entity.postCard) {
    entity.postCard = {
      ...entity.postCard,
      padding: {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
      },
    }
  } else {
    entity.postCard = {
      padding: {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
      },
    }
  }
}

export const firstLayoutRenderer = (isActive: boolean) => {
  return (
    <svg
      width="143"
      height="142"
      viewBox="0 0 143 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="143"
        height="142"
        rx="6"
        fill={isActive ? '#399EFC' : '#EBEDF0'}
      />
      <rect x="10" y="10" width="123" height="122" rx="4" fill="white" />
      <rect
        x="10.5"
        y="10.5"
        width="122"
        height="121"
        rx="3.5"
        stroke="#6B7C93"
        strokeOpacity="0.4"
      />
      <rect x="20" y="56" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="20" y="20" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="57" y="56" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="57" y="92" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="93" y="20" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="57" y="20" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="93" y="56" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="20" y="92" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="93" y="92" width="30" height="30" rx="4" fill="#EBEDF0" />
    </svg>
  )
}

export const secondLayoutRenderer = (isActive: boolean) => {
  return (
    <svg
      width="143"
      height="142"
      viewBox="0 0 143 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="143"
        height="142"
        rx="6"
        fill={isActive ? '#399EFC' : '#EBEDF0'}
      />
      <rect
        x="10.5"
        y="10.5"
        width="122"
        height="121"
        rx="3.5"
        fill="white"
        stroke="#6B7C93"
        strokeOpacity="0.4"
      />
      <rect x="20" y="92" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="93" y="20" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="93" y="56" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="57" y="56" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="20" y="56" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="20" y="20" width="67" height="30" rx="4" fill="#EBEDF0" />
      <rect x="56" y="92" width="67" height="30" rx="4" fill="#EBEDF0" />
    </svg>
  )
}

export const thirdLayoutRenderer = (isActive: boolean) => {
  return (
    <svg
      width="143"
      height="142"
      viewBox="0 0 143 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="143"
        height="142"
        rx="6"
        fill={isActive ? '#399EFC' : '#EBEDF0'}
      />
      <rect x="10" y="10" width="123" height="122" rx="4" fill="white" />
      <rect
        x="10.5"
        y="10.5"
        width="122"
        height="121"
        rx="3.5"
        stroke="#6B7C93"
        strokeOpacity="0.4"
      />
      <rect x="20" y="20" width="103" height="66" rx="4" fill="#EBEDF0" />
      <rect x="20" y="92" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="93" y="92" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="56" y="92" width="30" height="30" rx="4" fill="#EBEDF0" />
    </svg>
  )
}

export const fourthLayoutRenderer = (isActive: boolean) => {
  return (
    <svg
      width="143"
      height="142"
      viewBox="0 0 143 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="143"
        height="142"
        rx="6"
        fill={isActive ? '#399EFC' : '#EBEDF0'}
      />
      <rect x="10" y="10" width="123" height="122" rx="4" fill="white" />
      <rect
        x="10.5"
        y="10.5"
        width="122"
        height="121"
        rx="3.5"
        stroke="#6B7C93"
        strokeOpacity="0.4"
      />
      <rect x="20" y="56" width="48" height="30" rx="4" fill="#EBEDF0" />
      <rect x="75" y="56" width="48" height="30" rx="4" fill="#EBEDF0" />
      <rect x="57" y="92" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="93" y="92" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="20" y="92" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="57" y="20" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="93" y="20" width="30" height="30" rx="4" fill="#EBEDF0" />
      <rect x="20" y="20" width="30" height="30" rx="4" fill="#EBEDF0" />
    </svg>
  )
}

export const fifthLayoutRenderer = (isActive: boolean) => {
  return (
    <svg
      width="301"
      height="130"
      viewBox="0 0 301 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="301"
        height="130"
        rx="6"
        fill={isActive ? '#399EFC' : '#EBEDF0'}
      />
      <rect x="12" y="11" width="277" height="108" rx="4" fill="white" />
      <rect
        x="12.5"
        y="11.5"
        width="276"
        height="107"
        rx="3.5"
        stroke="#6B7C93"
        strokeOpacity="0.4"
      />
      <rect x="28" y="26" width="78" height="78" rx="4" fill="#EBEDF0" />
      <rect x="123" y="55" width="146" height="20" rx="4" fill="#EBEDF0" />
      <rect x="123" y="26" width="146" height="20" rx="4" fill="#EBEDF0" />
      <rect x="123" y="84" width="146" height="20" rx="4" fill="#EBEDF0" />
    </svg>
  )
}
