// Case font-family: Slabo 13px / 8-bitLimitBRK8632298, it won't work, we need to wrap it with quotes
import fontFamilies from 'common/constants/fonts'

const fontTypes = {
  inherited: 'inherited',
  google: 'google',
  safe: 'safe',
}

const detectFontType = font =>
  Object.keys(fontFamilies).find(type =>
    fontFamilies[type].some(f => font === f),
  ) || fontTypes.inherited

export const wrapFontFamilyByStyles = styles =>
  Object.keys(styles).reduce(
    (acc, styleKey) => ({
      ...acc,
      [styleKey]:
        styleKey === 'fontFamily' &&
        detectFontType(styles[styleKey]) !== fontTypes.safe &&
        styles[styleKey] &&
        (styles[styleKey].match(/\s/) || styles[styleKey].match(/^\d/))
          ? `"${styles[styleKey]}"`
          : styles[styleKey],
    }),
    {},
  )

export const wrapFontFamily = font =>
  font &&
  detectFontType(font) !== fontTypes.safe &&
  (font.match(/\s/) || font.match(/^\d/))
    ? `"${font}"`
    : font
