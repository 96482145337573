import React from 'react'
import { PaddingType } from 'common/types/styleTypes'
import FaqUi from './ui/FaqUi'
import FaqItem from './FaqItem'

type FaqProps = {
  children: React.ReactElement[]
  padding: PaddingType
  mobilePadding: PaddingType
  htmlAttrId: string
  spaceBetweenItems: number
}

function Faq({
  padding,
  mobilePadding,
  htmlAttrId,
  children,
  spaceBetweenItems,
}: FaqProps) {
  return (
    <FaqUi
      padding={padding}
      mobilePadding={mobilePadding}
      id={htmlAttrId}
      spaceBetweenItems={spaceBetweenItems}
    >
      {children}
    </FaqUi>
  )
}

export { FaqItem }

export default Faq
