import styled, { css } from 'styled-components'

type LayoutGridRowUiProps = {
  rowStart?: number
  rowEnd?: number
}

const LayoutGridRowUi = styled.div<LayoutGridRowUiProps>`
  display: grid;
  ${p =>
    p.rowStart &&
    css`
      grid-row-start: ${p.rowStart};
    `}
  ${p =>
    p.rowEnd &&
    css`
      grid-row-end: ${p.rowEnd};
    `}
`

export default LayoutGridRowUi
