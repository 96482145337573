import React, { FC } from 'react'
import { CSSObject } from 'styled-components'
import { Appearance } from 'common/types/entities/EntityInterface'
import { MarginType } from 'common/types/styleTypes'
import StyleWrapperUi from 'publisher/components/core/StyleWrapperUi'
import { useManagement } from 'publisher/store'
import managementSelectors from 'publisher/store/management/managementSelectors'

export type StyleWrapperProps = {
  id?: string
  margin: MarginType
  mobileMargin: Partial<MarginType>
  appearance: Appearance
  flex?: boolean
  flexColumn?: boolean
  textAlign?: string
  mobileTextAlign?: string
  additionalCss?: CSSObject
  mobileAdditionalCss?: CSSObject
}

const StyleWrapper: FC<StyleWrapperProps> = props => {
  const isScreenshotMode = useManagement(managementSelectors.isScreenshotMode)
  return (
    <StyleWrapperUi
      {...props}
      appearance={{
        desktop: isScreenshotMode || props.appearance.desktop,
        mobile: isScreenshotMode || props.appearance.mobile,
      }}
    />
  )
}

export default StyleWrapper
