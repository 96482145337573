/* eslint-disable react/prop-types */
import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CSSProp } from 'styled-components'
import { defaultCountries } from '../../constants/defaultCountries'
import { DEFAULT_COUNTRY } from '../../constants/defaultCountry'
import { CountryData, CountryIso2 } from '../../types'
import { getCountry } from '../../utils'
import {
  CountrySelectorDropdown,
  CountrySelectorDropdownProps,
  CountrySelectorDropdownStyleProps,
} from '../Dropdown'
import { CountrySelectorFlagEmojiUi } from './ui/CountrySelecorFlagEmojiUi'
import { CountrySelectorButtonContentUi } from './ui/CountrySelectorButtonContentUi'
import { CountrySelectorDropDownArrowUi } from './ui/CountrySelectorDropDownArrowUi'
import { CountrySelectorButtonUi } from './ui/CountrySelectorUi'
import { CountrySelectorWrapperUi } from './ui/CountrySelectorWrapperUi'

export interface CountrySelectorStyleProps {
  className?: string
  styles?: CSSProp
  buttonStyle?: CSSProp
  buttonContentWrapperStyle?: CSSProp
  flagStyle?: CSSProp
  dropdownArrowStyle?: CSSProp
  dropdownStyleProps?: CountrySelectorDropdownStyleProps
}

type RenderButtonWrapperRootProps = {
  // Omit the event argument to prevent errors on event mistype
  onClick: () => void
} & Pick<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  | 'onKeyDown'
  | 'onMouseDown'
  | 'title'
  | 'disabled'
  | 'role'
  | 'aria-label'
  | 'aria-haspopup'
  | 'aria-expanded'
>

export interface CountrySelectorProps extends CountrySelectorStyleProps {
  language?: Intl.Locale['language']
  selectedCountry: CountryIso2
  onSelect?: CountrySelectorDropdownProps['onSelect']
  disabled?: boolean
  hideDropdown?: boolean
  defaultCountry?: CountryIso2
  countries?: CountryData[]
  buttonProps?: React.ComponentPropsWithoutRef<'button'>
  renderButtonWrapper?: (props: {
    children: React.ReactNode
    rootProps: RenderButtonWrapperRootProps
  }) => React.ReactNode
}

export const CountrySelector: React.FC<CountrySelectorProps> = ({
  className,
  language = 'en',
  selectedCountry,
  onSelect,
  disabled,
  hideDropdown,
  defaultCountry = DEFAULT_COUNTRY,
  countries = defaultCountries,
  renderButtonWrapper,
  buttonContentWrapperStyle,
  flagStyle,
  dropdownStyleProps,
  dropdownArrowStyle,
  styles,
  buttonStyle,
  buttonProps,
  ...restProps
}) => {
  const { t } = useTranslation()

  const [showDropdown, setShowDropdown] = useState(false)

  const fullSelectedCountry = useMemo(() => {
    if (!selectedCountry) return undefined
    return getCountry({
      value: selectedCountry,
      field: 'iso2',
      countries: countries,
      defaultCountry,
    })
  }, [countries, defaultCountry, selectedCountry])

  const countrySelectorRef = useRef<HTMLDivElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!e.key) return

    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault()
      setShowDropdown(true)
    }
  }

  const renderSelectorButton = () => {
    const rootProps: RenderButtonWrapperRootProps = {
      title: fullSelectedCountry?.name,
      onClick: () => setShowDropdown(v => !v),
      // Need this to close dropdown on selector button click
      // https://stackoverflow.com/a/28963938
      onMouseDown: e => e.preventDefault(),
      onKeyDown: handleKeyDown,
      disabled: hideDropdown || disabled,
      role: 'combobox',
      'aria-label': 'Country selector',
      'aria-haspopup': 'listbox',
      'aria-expanded': showDropdown,
    }

    const buttonContent = (
      <CountrySelectorButtonContentUi styles={buttonContentWrapperStyle}>
        <CountrySelectorFlagEmojiUi
          iso2={selectedCountry}
          selectedCountry={!!selectedCountry}
          disabled={disabled}
          styles={flagStyle}
        />
        {!hideDropdown && (
          <CountrySelectorDropDownArrowUi
            disabled={disabled}
            showDropdown={showDropdown}
            styles={dropdownArrowStyle}
          />
        )}
      </CountrySelectorButtonContentUi>
    )
    if (renderButtonWrapper) {
      return renderButtonWrapper({
        children: buttonContent,
        rootProps: rootProps,
      })
    }
    return (
      <CountrySelectorButtonUi
        {...rootProps}
        type="button"
        disabled={disabled}
        hideDropdown={hideDropdown}
        data-country={selectedCountry}
        styles={buttonStyle}
        {...buttonProps}
      >
        {buttonContent}
      </CountrySelectorButtonUi>
    )
  }

  return (
    <CountrySelectorWrapperUi
      className={className}
      styles={styles}
      ref={countrySelectorRef}
      {...restProps}
    >
      {renderSelectorButton()}
      <CountrySelectorDropdown
        show={showDropdown}
        countries={countries}
        language={language}
        onSelect={country => {
          setShowDropdown(false)
          onSelect?.(country)
        }}
        selectedCountry={selectedCountry}
        onClose={() => {
          setShowDropdown(false)
        }}
        searchInputPlaceholder={t('global.search')}
        {...dropdownStyleProps}
      />
    </CountrySelectorWrapperUi>
  )
}
