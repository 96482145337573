import PropTypes from 'prop-types'
import React from 'react'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import Meta from '../components/core/Meta'
import OnExitPopup from './components/OnExitPopup'
import OnloadPopups from './components/OnloadPopups'
import TriggeredPopup from './components/TriggeredPopup'
import PageUi from './ui/PageUi'

function CommonPage({ children, noIndex }) {
  const globalTextFontProperties = usePage(
    pageSelectors.getGlobalFontProperties,
  )
  return (
    <PageUi
      textFontFamily={wrapFontFamily(globalTextFontProperties.fontFamily)}
      textFontWeight={globalTextFontProperties.fontWeight}
      textFontStyle={globalTextFontProperties.fontStyle}
    >
      <Meta noIndex={noIndex} />
      {children}
      <TriggeredPopup />
      <OnExitPopup />
      <OnloadPopups />
    </PageUi>
  )
}

CommonPage.propTypes = {
  children: PropTypes.node.isRequired,
  noIndex: PropTypes.bool,
}

CommonPage.propTypes = {
  noIndex: false,
}

export default CommonPage
