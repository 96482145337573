import styled, { css } from 'styled-components'

const PricePlanActionUi = styled.div`
  flex-basis: 218px;
  margin: 45px;
  ${p => p.theme.phone} {
    justify-content: center;
    text-align: center;
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      justify-content: center;
      text-align: center;
    `}
`

export default PricePlanActionUi
