import { useEffect, useState } from 'react'

export function useDelay(delay = 0) {
  // possible string value
  const delayInt = +delay
  const [isVisible, setVisible] = useState(delayInt === 0)

  useEffect(() => {
    if (delayInt > 0) {
      setTimeout(() => setVisible(true), delayInt * 1000)
    } else {
      setVisible(true)
    }
  }, [delay])

  return isVisible
}
