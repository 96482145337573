import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonBlogPostCategories from 'common/components/entities//BlogPostCategories/BlogPostCategoriesNew'
import { pageTypes } from 'common/constants/pageTypes'
import { BlogPostCategoriesInterface } from 'common/types/entities/BlogPostCategoriesInterface'
import useBlog, { selectors as blogSelectors } from 'publisher/hooks/useBlog'
import useManagement, {
  selectors as managementSelectors,
} from 'publisher/hooks/useManagement'
import { typedPageSelectors, usePage } from 'publisher/store'

function BlogPostCategoriesNew({
  entity,
}: {
  entity: BlogPostCategoriesInterface
}) {
  const categories = useBlog(blogSelectors.getBlogPostCategories)
  const blogPath = useBlog(blogSelectors.getBlogPath)
  const isScreenshotMode = useManagement(managementSelectors.isScreenshotMode)
  const isPreview = useManagement(managementSelectors.isPreviewMode)
  const pageType = usePage(typedPageSelectors.getPageType)

  const calculatedCategories =
    isScreenshotMode || (isPreview && pageType === pageTypes.blogPostLayout)
      ? ['Lorem', 'Ipsum']
      : categories

  return (
    <StyleWrapper
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonBlogPostCategories
        categories={calculatedCategories}
        blogPath={blogPath}
        attrId={entity.htmlAttrId}
        fontSize={entity.fontSize}
        fontFamily={entity.fontFamily}
        fontStyle={entity.fontStyle}
        fontWeight={entity.fontWeight}
        mobileFontSize={entity.mobileFontSize}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontWeight={entity.mobileFontWeight}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        color={entity.color}
        mobileColor={entity.mobileColor}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
      />
    </StyleWrapper>
  )
}

export default BlogPostCategoriesNew
