import styled, { css } from 'styled-components'
import { PaddingType, BorderType } from 'common/types/styleTypes'
import {
  borderCss,
  mobileBorderCss,
  paddingsCss,
  mobilePaddingsCss,
  fontCss,
  backgroundColorCss,
  mobileBackgroundColorCss,
  mobileFontCss,
  FontProps,
} from 'common/utils/styleUtilsNew'

type BlogPostContentTableUiProps = {
  backgroundColor?: string
  mobileBackgroundColor?: string
  padding: PaddingType
  mobilePadding: PaddingType
  border: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
} & FontProps

const ContentTableUi = styled.div<BlogPostContentTableUiProps>`
  ${backgroundColorCss}
  ${paddingsCss}
  ${fontCss}
  ${borderCss}
  ${p => p.theme.phone} {
    ${mobileBackgroundColorCss}
    ${mobilePaddingsCss}
    ${mobileBorderCss}
    ${mobileFontCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileBackgroundColorCss}
      ${mobilePaddingsCss}
      ${mobileBorderCss}
      ${mobileFontCss}
    `}
`

export default ContentTableUi
