import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonCountdown from 'common/components/entities/Countdown'
import Timer from 'common/components/entities/Timer/TimerOld'
import {
  countdownActions,
  countdownTypes,
} from 'common/constants/countdownTypes'
import * as types from 'common/types'
import { addUrlSchemaToTheLink, isUrlCurrent } from 'common/utils/urlUtils'
import {
  getCounterEndTimeCookie,
  setCounterEndTimeCookie,
} from 'publisher/utils/cookie'
import { isPreviewMode } from '../../reducers/managementReducer'
import {
  getDeadlineExpirationUrl,
  getDeadlineTime,
} from '../../reducers/paymentReducer'

class CountdownOld extends PureComponent {
  constructor(props) {
    super(props)
    const {
      pageId,
      entity: {
        options: { type },
      },
    } = props
    const postfix = this.getPostfixByType(type)
    // if the timer is completed for a current user
    // we need to redirect the user if the url provided
    if (this.wasCompletedForCurrentUser(type, pageId, postfix)) {
      this.timeEndHandler()
    } else if (
      type === countdownTypes.daily ||
      type === countdownTypes.minute
    ) {
      // check if there is a stored not expired time
      if (!this.getNotExpiredStoredStartTime(pageId, postfix)) {
        // store cookie for minute and daily timers
        // example of daily timer
        const currentTime = new Date().getTime()
        const time = this.getTimeByType(type)
        // 22 pm
        if (!time || currentTime > time) {
          // now is 23pm - redirect!
          this.timeEndHandler()
        } else {
          // now is 21pm - set cookie with 22pm
          this.storeEndTime(pageId, time, postfix)
        }
      }
    }
  }

  getNotExpiredStoredStartTime = (pageId, postFix) => {
    const endTime = getCounterEndTimeCookie(pageId, postFix)
    return endTime > Date.now() && +endTime
  }

  getTimeToRender = () => {
    const { pageId, entity } = this.props
    const {
      options: { type, dateOptions, dailyOptions, minuteOptions },
    } = entity
    let countdownTime = null
    if (type === countdownTypes.daily) {
      const endDate = this.generateEndDate(
        dailyOptions.hours,
        dailyOptions.minutes,
        dailyOptions.seconds,
      )

      if (endDate.getDate() === new Date().getDate()) {
        countdownTime = endDate.getTime()
      }
      // wait for the next day
    }

    if (type === countdownTypes.date) {
      countdownTime = new Date(dateOptions.date).getTime()
    }

    if (type === countdownTypes.minute) {
      countdownTime = this.getNotExpiredStoredStartTime(
        pageId,
        minuteOptions.createdTime,
      )
    }

    if (type === countdownTypes.deadline) {
      countdownTime = new Date(this.props.deadlineTime)
    }

    return countdownTime > new Date().getTime() ? countdownTime : null
  }

  getTimeByType = type => {
    const {
      options: { dateOptions, dailyOptions, minuteOptions },
    } = this.props.entity
    let countdownTime = null
    if (type === countdownTypes.daily) {
      const endDate = this.generateEndDate(
        dailyOptions.hours,
        dailyOptions.minutes,
        dailyOptions.seconds,
      )

      if (endDate.getDate() === new Date().getDate()) {
        countdownTime = endDate.getTime()
      }
      // wait for the next day
    }

    if (type === countdownTypes.date) {
      countdownTime = new Date(dateOptions.date).getTime()
    }

    if (type === countdownTypes.minute) {
      countdownTime = this.generateStartTime(
        minuteOptions.days,
        minuteOptions.hours,
        minuteOptions.minutes,
        minuteOptions.seconds,
      )
    }

    return countdownTime
  }

  getPostfixByType = type => {
    if (type === countdownTypes.minute) {
      return this.props.entity.options.minuteOptions.createdTime
    }

    if (type === countdownTypes.daily) {
      return this.props.entity.options.dailyOptions.createdTime
    }

    return null
  }

  timeEndHandler = () => {
    const { redirectUrl, action, type } = this.props.entity.options
    if (typeof window === 'undefined') {
      return
    }
    let isUrlNotSameAsCurrent
    if (redirectUrl) {
      isUrlNotSameAsCurrent = !isUrlCurrent(redirectUrl, window.location.href)
    }
    if (action === countdownActions.redirection && redirectUrl) {
      // hotfix, see constructor
      if (typeof window !== 'undefined' && isUrlNotSameAsCurrent) {
        window.location.href = addUrlSchemaToTheLink(redirectUrl)
      }
    }

    if (type === countdownTypes.deadline) {
      if (this.props.deadlineExpirationUrl && isUrlNotSameAsCurrent) {
        window.location.assign(
          addUrlSchemaToTheLink(this.props.deadlineExpirationUrl),
        )
      }
    }
  }

  storeEndTime = (pageId, time, postFix) => {
    setCounterEndTimeCookie(pageId, time, postFix)
  }

  generateStartTime = (days = 0, hours = 0, minutes = 0, seconds = 0) => {
    const startDate = new Date()
    startDate.setDate(startDate.getDate() + Number(days))
    startDate.setHours(startDate.getHours() + Number(hours))
    startDate.setMinutes(startDate.getMinutes() + Number(minutes))
    startDate.setSeconds(startDate.getSeconds() + Number(seconds))

    return startDate.getTime()
  }

  generateEndDate = (hours, minutes, seconds = 0) => {
    const currentDate = new Date()
    const endDate = new Date()
    endDate.setHours(Number(hours))
    endDate.setMinutes(Number(minutes))
    endDate.setSeconds(Number(seconds))

    // for example current hour is 22 and end date hour is 20
    // so we need to set +1 day
    if (currentDate.getTime() > endDate.getTime()) {
      endDate.setDate(currentDate.getDate() + 1)
    }

    return endDate
  }

  wasCompletedForCurrentUser = (type, pageId, postFix) => {
    let endTime = null
    if (type === countdownTypes.daily || type === countdownTypes.minute) {
      const cookieTime = Number.parseInt(
        getCounterEndTimeCookie(pageId, postFix),
      )
      // it may be null if not found
      if (Number.isInteger(cookieTime)) {
        endTime = cookieTime
      }
    } else if (type === countdownTypes.deadline) {
      endTime = this.props.deadlineTime
    } else {
      endTime = new Date(this.props.entity.options.dateOptions.date).getTime()
    }

    return endTime && endTime < Date.now()
  }

  render() {
    const {
      entity: {
        options: { type, labelStyles, timeStyles, appearance },
        mobileOptions: {
          labelStyles: mobileLabelStyles = {},
          timeStyles: mobileTimeStyles = {},
        },
        styles,
      },
    } = this.props
    const time = this.getTimeToRender()

    return (
      <StyleWrapper desktop={appearance.desktop} mobile={appearance.mobile}>
        <CommonCountdown
          time={time}
          onTimeEndHandler={time ? this.timeEndHandler : () => {}}
        >
          {({ days, hours, minutes, seconds }) => (
            <Timer
              wrapperStyles={styles}
              labelStyles={labelStyles}
              timeStyles={timeStyles}
              mobileTimeStyles={mobileTimeStyles}
              mobileLabelStyles={mobileLabelStyles}
              days={days}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
              hideDays={type === countdownTypes.daily}
            />
          )}
        </CommonCountdown>
      </StyleWrapper>
    )
  }
}

CountdownOld.propTypes = {
  entity: types.entity.isRequired,
  pageId: PropTypes.number.isRequired,
  deadlineTime: PropTypes.number,
  deadlineExpirationUrl: PropTypes.string,
  isPreview: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ page: { id: pageId }, payment, management }) => ({
  pageId,
  deadlineTime: payment ? getDeadlineTime(payment) : 0,
  deadlineExpirationUrl: payment ? getDeadlineExpirationUrl(payment) : 0,
  isPreview: isPreviewMode(management),
})

export default connect(mapStateToProps)(CountdownOld)
