import React, { memo } from 'react'
import CommonSection from 'common/components/entities/Section'
import { SectionInterface } from 'common/components/entities/Section/types/SectionInterface'
import { getStickyCss } from 'common/components/entities/Section/utils/getStickyCss'
import { commonWidthValues } from 'common/constants/commonWidth'
import ChildrenEntities from 'publisher/components/ChildrenEntities'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { useBackgroundImage } from 'publisher/hooks/useBackgroundImage'
import { useDelay } from '../../../hooks/useDelay'
import { useScreenshotStyles } from './hooks/useScreenshotStyles'

interface SectionProps {
  entity: SectionInterface
}

const Section = ({ entity }: SectionProps) => {
  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })
  const isVisible = useDelay(entity.delay)
  const screenshotStyles = useScreenshotStyles(entity)

  return isVisible ? (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
      additionalCss={{ ...getStickyCss(entity.sticky), ...screenshotStyles }}
      mobileAdditionalCss={getStickyCss(entity.mobileSticky)}
    >
      <CommonSection
        backgroundImage={backgroundImage}
        mobileBackgroundImage={mobileBackgroundImage}
        htmlAttrId={entity.htmlAttrId}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        width={commonWidthValues[entity.contentWidth]}
        containerBackgroundColor={entity.containerBackgroundColor}
        mobileContainerBackgroundColor={entity.mobileContainerBackgroundColor}
        background={entity.background}
      >
        <ChildrenEntities childIds={entity.childIds} />
      </CommonSection>
    </StyleWrapper>
  ) : (
    <div />
  )
}

export default memo(Section)
