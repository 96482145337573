import styled from 'styled-components'
import { PaddingType } from 'common/types/styleTypes'
import { mobilePaddingsCss, paddingsCss } from 'common/utils/styleUtilsNew'

const BlogPostCardContentUi = styled.div<{
  padding?: PaddingType
  mobilePadding?: PaddingType
}>`
  ${paddingsCss}
  ${p => p.theme.isEditorMobileMode && mobilePaddingsCss}
  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
  }
`

export default BlogPostCardContentUi
