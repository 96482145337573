import styled, { css } from 'styled-components'

type CarouselArrowRightUiProps = {
  showArrows: boolean
  mobileShowArrows?: boolean
}

const CarouselArrowRightUi = styled.i<CarouselArrowRightUiProps>`
  display: ${p => (p.showArrows ? 'block' : 'none')};
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      display: ${p.mobileShowArrows ? 'block' : 'none'};
    `};
  ${p => p.theme.phone} {
    display: ${p => (p.mobileShowArrows ? 'block' : 'none')};
  }
  color: ${p => p.color};
  position: absolute;
  right: 10px;
  top: 50%;
  padding: 10px;
  font-size: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
`

export default CarouselArrowRightUi
