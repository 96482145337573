import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { closeCookieExists } from 'publisher/utils/cookie'
import { closePopup, showPopup } from '../../actionsManagement'
import useManagement from '../../hooks/useManagement'
import usePage from '../../hooks/usePage'
import { getTriggeredPopupId } from '../../reducers/managementReducer'
import { getFirstOnExitPopup, getPageId } from '../../reducers/pageReducer'
import { setCloseCookie } from '../../utils/cookie'

export function OnExitPopup() {
  const popup = usePage(getFirstOnExitPopup)
  const triggeredPopupId = useManagement(getTriggeredPopupId)
  const pageId = usePage(getPageId)
  const [wasOpened, setWasOpened] = useState(false)
  const dispatch = useDispatch()

  const notEqualsTriggeredPopup = popup?.id !== triggeredPopupId

  const onLeaveHandler = useCallback(
    event => {
      if (closeCookieExists(pageId, popup.id)) {
        return
      }
      if (
        event.clientY < 50 &&
        event.relatedTarget == null &&
        event.target.nodeName.toLowerCase() !== 'select'
      ) {
        dispatch(showPopup(popup.id))
        setWasOpened(true)
        document.removeEventListener('mouseout', onLeaveHandler)
      }
    },
    [dispatch, pageId, popup],
  )

  useEffect(() => {
    if (popup && !closeCookieExists(pageId, popup.id)) {
      document.addEventListener('mouseout', onLeaveHandler)
    }
  }, [onLeaveHandler, pageId, popup])

  const handleClose = useCallback(() => {
    if (!popup?.id) return

    setCloseCookie(pageId, popup.id, popup.options.closedDays)
    dispatch(closePopup)
  }, [popup?.id, popup?.options.closedDays])

  useEffect(() => {
    if (wasOpened && notEqualsTriggeredPopup) {
      handleClose()
    }
  }, [wasOpened, notEqualsTriggeredPopup, handleClose])

  // I guess the popup will not be rendered, it renders in TriggeredPopup component
  return null
}

export default OnExitPopup
