import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type FieldErrorUiProps = {
  align: string
  dataTestId: string
}

const FieldErrorUi = styled.p.attrs<FieldErrorUiProps>(props => {
  const additionalAttrs: Record<string, string> = {}
  if (process.env.NODE_ENV === 'test') {
    additionalAttrs['data-testid'] = props.dataTestId
  }
  return additionalAttrs
})<FieldErrorUiProps>`
  color: red;
  align-self: ${({ align }) => align};
  font-size: 13px;
  margin-top: 5px;
`

export const TEST_ID = 'send-form-error'

type FieldErrorsProps = {
  errors?: string[]
  align?: string
}

function FieldErrors({ errors = [], align = 'left' }: FieldErrorsProps) {
  const { t } = useTranslation()
  return (
    <>
      {errors.map((error, index) => (
        <FieldErrorUi
          key={`err-${error}`}
          align={align}
          dataTestId={`${TEST_ID}-${index}`}
        >
          {t(error)}
        </FieldErrorUi>
      ))}
    </>
  )
}

export default FieldErrors
