import React from 'react'
import { Breadcrumb } from 'common/types/entities/BreadcrumbsInterface'
import { FontProps } from 'common/utils/styleUtilsNew'
import BreadcrumbsItemUi from './ui/BreadcrumbsItemUi'
import BreadcrumbsUi from './ui/BreadcrumbsUi'

type BreadcrumbsProps = {
  items: Breadcrumb[]
  itemColor: string
  lastItemColor: string
} & FontProps

function Breadcrumbs({
  items,
  itemColor,
  lastItemColor,
  fontSize,
  fontStyle,
  fontWeight,
  fontFamily,
  mobileFontSize,
  mobileFontStyle,
  mobileFontWeight,
  mobileFontFamily,
}: BreadcrumbsProps) {
  return (
    <BreadcrumbsUi>
      {items.map((item, index) => (
        <BreadcrumbsItemUi
          key={item.name}
          color={itemColor}
          lastItemColor={lastItemColor}
          fontSize={fontSize}
          fontStyle={fontStyle}
          fontWeight={fontWeight}
          fontFamily={fontFamily}
          mobileFontSize={mobileFontSize}
          mobileFontStyle={mobileFontStyle}
          mobileFontWeight={mobileFontWeight}
          mobileFontFamily={mobileFontFamily}
        >
          {index === items.length - 1 ? (
            <span color={lastItemColor}>{item.name}</span>
          ) : (
            <a href={item.url}>{item.name}</a>
          )}
        </BreadcrumbsItemUi>
      ))}
    </BreadcrumbsUi>
  )
}

export default Breadcrumbs
