import React, { PropsWithChildren } from 'react'
import CheckboxInputUi from 'common/components/entities/Checkbox/ui/CheckboxInputUi'
import CheckboxLabelUi from 'common/components/entities/Checkbox/ui/CheckboxLabelUi'
import CheckboxUi from 'common/components/entities/Checkbox/ui/CheckboxUi'
import { CheckboxInterface } from 'common/types/entities/CheckboxInterface'

export interface CheckboxProps {
  entity: CheckboxInterface
  checked: boolean
  onChangeHandler: React.ChangeEventHandler<HTMLInputElement>
}

const Checkbox = ({
  entity,
  checked,
  onChangeHandler,
  children,
}: PropsWithChildren<CheckboxProps>) => (
  <CheckboxUi
    id={entity.htmlAttrId}
    textAlign={entity.textAlign}
  >
    <CheckboxLabelUi
      fontSize={entity.fontSize}
      mobileFontSize={entity.mobileFontSize}
      color={entity.color}
      mobileColor={entity.mobileColor}
      fontFamily={entity.fontFamily}
      mobileFontFamily={entity.mobileFontFamily}
      lineHeight={entity.lineHeight}
      mobileLineHeight={entity.mobileLineHeight}
    >
      <CheckboxInputUi
        type="checkbox"
        onChange={onChangeHandler}
        defaultChecked={checked}
        disabled={entity.isCheckboxDisabled}
      />
      {children}
    </CheckboxLabelUi>
  </CheckboxUi>
)

export default Checkbox
