import styled, { css } from 'styled-components'

const DropDownMenuNavUi = styled.ul<{ spacing?: number }>`
  & li {
    ${({ spacing }) =>
      spacing &&
      css`
        margin-bottom: ${spacing}px;
      `}
  }
  & li:last-of-type {
    margin-bottom: 0;
  }
`

export default DropDownMenuNavUi
