import React from 'react'
import { useCreateEntityElement } from 'publisher/context/create-entity-element-context'
import { usePage } from 'publisher/store'
import { getChildrenEntities } from 'publisher/store/page/pageSelectors'

interface ChildrenEntitiesProps {
  childIds: string[]
}

function ChildrenEntities({ childIds }: ChildrenEntitiesProps) {
  const createEntityElement = useCreateEntityElement()
  const entities = usePage(page => getChildrenEntities(page, childIds))

  return <>{entities.map(entity => createEntityElement(entity))}</>
}

export default ChildrenEntities
