import styled from 'styled-components'
import { fontCss, FontProps } from 'common/utils/styleUtilsNew'

export const CommonCalendarButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: center;
  align-items: center;
`

interface CommonCalendarButtonUiProps {
  backgroundColor: string
}

export const CommonCalendarButtonUi = styled.button<
  CommonCalendarButtonUiProps & FontProps
>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: ${p => p.backgroundColor};
  padding: 10px 0;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 10px 90px;
  border-radius: 5px;

  ${fontCss}

  color: #fff
`
