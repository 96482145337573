import styled, { css } from 'styled-components'

const SurveyAnswerUi = styled.div`
  font-size: ${p => p.fontSize};
  color: ${p => (p.checked ? p.borderColor : p.color)};
  background-color: ${p => (p.checked ? '#D7F1FF' : p.backgroundColor)};
  border: 1px solid transparent;
  padding: 10px;
  border-top-left-radius: ${p => p.borderRadius.borderTopLeftRadius}px;
  border-top-right-radius: ${p => p.borderRadius.borderTopRightRadius}px;
  border-bottom-right-radius: ${p => p.borderRadius.borderBottomRightRadius}px;
  border-bottom-left-radius: ${p => p.borderRadius.borderBottomLeftRadius}px;
  margin-bottom: 10px;
  &:hover {
    border: 1px solid ${p => p.borderColor};
  }

  &:hover .answer-radio {
    ${p =>
      !p.checked &&
      css`
        background-image: url(${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/check_hovered.png);
        background-color: ${p => p.borderColor};
        border: none;
      `}
  }
  ${p => p.theme.phone} {
    border-top-left-radius: ${p => p.mobileBorderRadius.borderTopLeftRadius}px;
    border-top-right-radius: ${p =>
      p.mobileBorderRadius.borderTopRightRadius}px;
    border-bottom-right-radius: ${p =>
      p.mobileBorderRadius.borderBottomRightRadius}px;
    border-bottom-left-radius: ${p =>
      p.mobileBorderRadius.borderBottomLeftRadius}px;
  }
`

export default SurveyAnswerUi
