import styled, { css } from 'styled-components'

const SurveyAnswerRadioButtonUi = styled.div`
  width: 25px;
  height: 25px;
  background-size: cover;
  border-radius: 12.5px;
  ${p =>
    p.checked
      ? css`
          background-image: url(${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/checked.png);
          background-color: ${p.borderColor};
        `
      : css`
          border: 1px solid rgba(216, 217, 217, 1);
          background-color: rgba(255, 255, 255, 1);
        `}
`

export default SurveyAnswerRadioButtonUi
