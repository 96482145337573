import React, { memo } from 'react'
import CommonColumn from 'common/components/entities/Column'
import { ColumnInterface } from 'common/components/entities/Column/types/ColumnInterface'
import ChildrenEntities from 'publisher/components/ChildrenEntities'

interface ColumnProps {
  entity: ColumnInterface
  gridColumnStart: number
  disableStacking?: boolean
}

const Column = ({ entity, gridColumnStart, disableStacking }: ColumnProps) => (
  <CommonColumn
    entity={entity}
    gridColumnStart={gridColumnStart}
    disableStacking={disableStacking}
  >
    <ChildrenEntities childIds={entity.childIds} />
  </CommonColumn>
)

export default memo(Column)
