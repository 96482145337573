import styled, { CSSProp } from 'styled-components'

export const DropDownInputUi = styled.input<{
  styles?: CSSProp
}>`
  font-size: 14px;

  display: block;
  width: 100%;
  min-height: 2em;
  padding: 0.4em 0.6em;
  border: 0;
  border-bottom: 1px solid gainsboro;

  &:focus {
    outline: none;
  }

  ${({ styles }) => styles}
`
