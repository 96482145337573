import styled from 'styled-components'

const SubTextUi = styled.small`
  flex-basis: 100%;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles};
  }
  ${({ styles = {} }) => styles}
`

export default SubTextUi
