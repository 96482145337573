import { CSSObject } from 'styled-components'
import { StickyPositionEnum } from 'common/components/entities/Section/enums/StickyPositionEnum'
import { StickyTypeEnum } from 'common/components/entities/Section/enums/StickyTypeEnum'
import { StickyInterface } from 'common/components/entities/Section/types/SectionInterface'

export const getStickyCss = (sticky?: StickyInterface): CSSObject => {
  if (!sticky) return {}
  if (sticky.type === StickyTypeEnum.Static)
    return { position: StickyTypeEnum.Static }
  switch (sticky.position) {
    case StickyPositionEnum.top:
      return {
        position: 'sticky',
        top: `${sticky.indent}px`,
        bottom: 'auto',
        zIndex: 2,
      }
    case StickyPositionEnum.bottom:
      return {
        position: 'sticky',
        top: 'auto',
        bottom: `${sticky.indent}px`,
        zIndex: 2,
      }
    default:
      return {}
  }
}
