import { FilesState } from '../index'

function getFile(state: FilesState, fileId?: number) {
  if (!fileId) {
    return null
  }
  return state[fileId]
}

const filesSelectors = {
  getFile,
}

export default filesSelectors
