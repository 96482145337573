import React from 'react'
import ContentBoxHeaderUi from 'common/components/entities/ContentBox/ui/ContentBoxHeaderUi'
import ContentBoxInnerUi from 'common/components/entities/ContentBox/ui/ContentBoxInnerUi'
import ContentBoxWrapperUi from 'common/components/entities/ContentBox/ui/ContentBoxWrapperUi'
import { ContentBoxInterface } from 'common/types/entities/ContentBoxInterface'

interface ContentBoxProps {
  entity: ContentBoxInterface
  children: React.ReactNode
}

const ContentBox = ({ entity, children }: ContentBoxProps) => (
  <ContentBoxWrapperUi
    htmlAttrId={entity.htmlAttrId}
    backgroundColor={entity.backgroundColor}
    mobileBackgroundColor={entity.mobileBackgroundColor}
    border={entity.border}
    mobileBorder={entity.mobileBorder}
    boxShadow={entity.boxShadow}
    mobileBoxShadow={entity.mobileBoxShadow}
  >
    {entity.header.visibility && (
      <ContentBoxHeaderUi
        backgroundColor={entity.header.backgroundColor}
        mobileBackgroundColor={entity.header.mobileBackgroundColor}
        textAlign={entity.header.textAlign}
        mobileTextAlign={entity.header.mobileTextAlign}
        fontSize={entity.header.fontSize}
        mobileFontSize={entity.header.mobileFontSize}
        fontWeight={entity.header.fontWeight}
        mobileFontWeight={entity.header.mobileFontWeight}
        color={entity.header.color}
        mobileColor={entity.header.mobileColor}
        fontStyle={entity.header.fontStyle}
        mobileFontStyle={entity.header.mobileFontStyle}
        fontFamily={entity.header.fontFamily}
        mobileFontFamily={entity.header.mobileFontFamily}
        borderRadius={entity.border?.radius}
        mobileBorderRadius={entity.mobileBorder?.radius}
      >
        {entity.header.title}
      </ContentBoxHeaderUi>
    )}
    <ContentBoxInnerUi
      padding={entity.padding}
      mobilePadding={entity.mobilePadding}
    >
      {children}
    </ContentBoxInnerUi>
  </ContentBoxWrapperUi>
)
export default ContentBox
