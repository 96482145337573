import styled, { css } from 'styled-components'
import { PaddingType, AlignSelfType, BorderType } from 'common/types/styleTypes'
import {
  backgroundColorCss,
  fontCss,
  FontProps,
  mobileBackgroundColorCss,
  mobileFontCss,
  mobilePaddingsCss,
  paddingsCss,
  borderCss,
  mobileBorderCss,
  boxShadowCss,
  mobileBoxShadowCss,
} from 'common/utils/styleUtilsNew'

type AttachmentsUiProps = {
  alignSelf?: AlignSelfType
  mobileAlignSelf?: AlignSelfType
  padding: PaddingType
  mobilePadding?: PaddingType
  backgroundColor: string
  mobileBackgroundColor?: string
  boxShadow?: string
  mobileBoxShadow?: string
  border: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
} & FontProps

const AttachmentsUi = styled.div<AttachmentsUiProps>`
  cursor: pointer;
  width: auto;
  align-self: ${p => p.alignSelf || 'center'};
  ${backgroundColorCss}
  ${paddingsCss}
  ${fontCss}
  ${borderCss}
  ${boxShadowCss}
  ${p => p.theme.phone} {
    align-self: ${p => p.mobileAlignSelf || 'center'};
    ${mobileBackgroundColorCss}
    ${mobilePaddingsCss}
    ${mobileFontCss}
    ${mobileBorderCss};
    ${mobileBoxShadowCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileBackgroundColorCss}
      ${mobilePaddingsCss}
      ${mobileBorderCss}
      ${mobileFontCss}
      ${mobileBorderCss};
      ${mobileBoxShadowCss}
      align-self: ${p.mobileAlignSelf || 'center'};
    `}
`

export default AttachmentsUi
