import styled, { css } from 'styled-components'
import { addOpacityToRGBA } from 'common/utils/colorUtils'

interface TimeSlotButtonUiProps {
  selectedBackgroundColor: string
  isSelected: boolean
}

const TIME_SLOT_OPACITY = 0.1

export const TimeSlotButtonUi = styled.button<TimeSlotButtonUiProps>`
  padding: 13px 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;
  border: 1px solid #b5c3d580;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  ${p =>
    p.isSelected &&
    css`
      color: ${p.selectedBackgroundColor};
      background-color: ${addOpacityToRGBA(
        p.selectedBackgroundColor,
        TIME_SLOT_OPACITY,
      )};
      border-color: ${p.selectedBackgroundColor};
    `}

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      padding: 10px;
    `}

  ${p => p.theme.phone} {
    padding: 10px;
  }
`
