import styled, { css } from 'styled-components'
import {
  deprecatedTextCss,
  deprecatedMobileTextCss,
} from 'common/utils/styleUtilsNew'

type FieldIconUiProps = {
  color?: string
  mobileColor?: string
  fontSize?: string
  mobileFontSize?: string
}
const FieldIconUi = styled.i<FieldIconUiProps>`
  ${deprecatedTextCss};
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  ${p => p.theme.phone} {
    right: 10px;
    ${deprecatedMobileTextCss};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      left: 10px;
      ${deprecatedMobileTextCss};
    `}
`

export default FieldIconUi
