import React from 'react'
import DropDownMenuNavUi from 'common/components/entities/Menu/components/Mobile/DropDown/ui/DropDownMenuNavUi'
import DropDownUi from 'common/components/entities/Menu/components/Mobile/DropDown/ui/DropDownUi'
import MobileMenuItem from 'common/components/entities/Menu/components/Mobile/components/MobileMenuItem'
import { MenuItemInterface } from 'common/components/entities/Menu/types/MenuInterface'
import { AlignSelfType } from 'common/types/styleTypes'
import { isBlogReplacementPlaceholder } from 'common/utils/blogReplacementsUtils'
import { FontProps } from 'common/utils/styleUtilsNew'

type DropDownMenuProps = {
  attrId: string
  items: MenuItemInterface[]
  itemUnderlineColor?: string
  mobileItemUnderlineColor?: string
  forceVisible?: boolean
  alignSelf: AlignSelfType
  mobileAlignSelf?: AlignSelfType
  lineHeight?: number
  mobileLineHeight?: number
  activeColor: string
  mobileActiveColor?: string
  spacing?: number
  subMenuSpacing?: number
  isEditing?: boolean
} & FontProps

function DropDownMenu({
  attrId,
  items,
  activeColor,
  mobileActiveColor,
  itemUnderlineColor,
  mobileItemUnderlineColor,
  forceVisible = false,
  alignSelf,
  mobileAlignSelf,
  fontSize,
  mobileFontSize,
  fontFamily,
  fontWeight,
  fontStyle,
  mobileFontFamily,
  mobileFontWeight,
  mobileFontStyle,
  lineHeight,
  mobileLineHeight,
  color,
  mobileColor,
  spacing,
  subMenuSpacing,
  isEditing,
}: DropDownMenuProps) {
  return (
    <DropDownUi
      id={attrId}
      alignSelf={alignSelf}
      mobileAlignSelf={mobileAlignSelf}
      forceVisible={forceVisible}
    >
      <DropDownMenuNavUi spacing={spacing}>
        {items
          .filter(item => !isBlogReplacementPlaceholder(item.link))
          .map((item, index) => (
            <MobileMenuItem
              key={`${attrId}-dropdown-item-${index}-${item.link || item.text}`}
              attrId={attrId}
              item={item}
              index={index}
              activeColor={activeColor}
              mobileActiveColor={mobileActiveColor}
              mobileUnderlineColor={mobileItemUnderlineColor}
              underlineColor={itemUnderlineColor}
              fontSize={fontSize}
              mobileFontSize={mobileFontSize}
              fontFamily={fontFamily}
              fontWeight={fontWeight}
              fontStyle={fontStyle}
              mobileFontFamily={mobileFontFamily}
              mobileFontWeight={mobileFontWeight}
              mobileFontStyle={mobileFontStyle}
              lineHeight={lineHeight}
              mobileLineHeight={mobileLineHeight}
              color={color}
              mobileColor={mobileColor}
              spacing={subMenuSpacing}
              isEditing={isEditing}
            />
          ))}
      </DropDownMenuNavUi>
    </DropDownUi>
  )
}

export default DropDownMenu
