import styled, { css } from 'styled-components'
import { BorderType } from 'common/types/styleTypes'
import {
  borderCss,
  boxShadowCss,
  mobileBorderCss,
  mobileBoxShadowCss,
} from 'common/utils/styleUtilsNew'

type VideoUiProps = {
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  boxShadow?: string
  mobileBoxShadow?: string
  disabledPointerEvents: boolean
}

const VideoUi = styled.div<VideoUiProps>`
  'video::-internal-media-controls-download-button' {
    display: none;
  }
  'video::-webkit-media-controls-enclosure' {
    overflow: hidden;
  }
  'video::-webkit-media-controls-panel' {
    width: calc(100% + 30px);
  }

  position: relative;
  padding-top: 56.25%;
  ${p => p.disabledPointerEvents && `pointer-events: none;`};
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    ${borderCss};
    ${boxShadowCss};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      .react-player {
        ${mobileBorderCss};
        ${mobileBoxShadowCss};
      }
    `}
  ${p => p.theme.phone} {
    .react-player {
      ${mobileBorderCss};
      ${mobileBoxShadowCss};
    }
  }
`

export default VideoUi
