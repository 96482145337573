import React, { useEffect, useState } from 'react'
import CommonCountdown from 'common/components/entities/Countdown'
import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import { getNotExpiredStoredStartTime } from 'common/components/entities/Countdown/utils/get-not-expired-stored-start-time'
import { getPostfixByType } from 'common/components/entities/Countdown/utils/get-postfix-by-type'
import { getTimeByType } from 'common/components/entities/Countdown/utils/get-time-by-type'
import { getTimeToRender } from 'common/components/entities/Countdown/utils/get-time-to-render'
import { timeEndHandler } from 'common/components/entities/Countdown/utils/time-end-handler'
import { wasCompletedForCurrentUser } from 'common/components/entities/Countdown/utils/was-completed-for-current-user'
import Timer from 'common/components/entities/Timer'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { usePage, usePayment } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'
import { setCounterEndTimeCookie } from 'publisher/utils/cookie'

const Countdown = ({ entity }: { entity: CountdownInterface }) => {
  const pageId = usePage(pageSelectors.getPageId)
  const postfix = getPostfixByType(entity)
  const deadlineTime = usePayment(paymentSelectors.getDeadlineTime)
  const deadlineExpirationUrl = usePayment(
    paymentSelectors.getDeadlineExpirationUrl,
  )
  const [countdownTime, setCountdownTime] = useState(
    getTimeToRender({ entity, pageId, deadlineTime }),
  )

  useEffect(() => {
    setCountdownTime(getTimeToRender({ entity, pageId, deadlineTime }))
  }, [deadlineTime, entity, pageId])

  const locale = usePage(pageSelectors.getLocale)

  useEffect(() => {
    // if the timer is completed for a current user
    // we need to redirect the user if the url provided
    if (wasCompletedForCurrentUser({ entity, pageId, postfix, deadlineTime })) {
      timeEndHandler({ entity, deadlineExpirationUrl })
    } else if (
      entity.countdownType === CountdownTypesEnum.daily ||
      entity.countdownType === CountdownTypesEnum.delay
    ) {
      // check if there is a stored not expired time
      if (!getNotExpiredStoredStartTime(pageId, postfix)) {
        // store cookie for minute and daily timers
        // example of daily timer
        const currentTime = new Date().getTime()
        const time = getTimeByType(entity)
        // 22 pm
        if (!time || currentTime > time) {
          // now is 23pm - redirect!
          timeEndHandler({ entity, deadlineExpirationUrl })
        } else {
          // now is 21pm - set cookie with 22pm
          setCounterEndTimeCookie(pageId, time, postfix)
          setCountdownTime(time)
        }
      }
    }
  }, [deadlineExpirationUrl, deadlineTime, entity, pageId, postfix])
  return (
    <StyleWrapper
      appearance={entity.appearance}
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
    >
      <CommonCountdown
        time={countdownTime}
        onTimeEndHandler={() =>
          timeEndHandler({ entity, deadlineExpirationUrl })
        }
      >
        {({ days, hours, minutes, seconds }) => (
          <Timer
            locale={locale}
            labelStyles={entity.labelStyles}
            timeStyles={entity.timeStyles}
            id={entity.htmlAttrId}
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            fontFamily={entity.fontFamily}
            fontStyle={entity.fontStyle}
            fontWeight={entity.fontWeight}
            mobileFontFamily={entity.mobileFontFamily}
            mobileFontStyle={entity.mobileFontStyle}
            mobileFontWeight={entity.mobileFontWeight}
            hideDays={entity.countdownType === CountdownTypesEnum.daily}
          />
        )}
      </CommonCountdown>
    </StyleWrapper>
  )
}

export default Countdown
