import React from 'react'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import {
  BlogPost,
  blogPostListingDateInterface,
} from 'common/types/entities/BlogPostListingInterface'
import BlogPostAnnounceDateUi from './ui/BlogPostAnnounceDateUi'

type BlogPostsDatesProps = {
  post: BlogPost
  locale: PageLocaleEnum
} & blogPostListingDateInterface

const formatDate = (date: Date, locale: PageLocaleEnum) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  // @ts-ignore
  return new Intl.DateTimeFormat(locale, options).format(date)
}

function BlogPostsDates({
  post,
  locale,
  dateFontSize,
  mobileDateFontSize,
  dateFontFamily,
  dateFontWeight,
  dateFontStyle,
  mobileDateFontFamily,
  mobileDateFontWeight,
  mobileDateFontStyle,
  dateAlign,
  mobileDateAlign,
  dateColor,
  mobileDateColor,
  dateMargin,
  mobileDateMargin,
}: BlogPostsDatesProps) {
  return (
    <BlogPostAnnounceDateUi
      key={post.path}
      fontSize={dateFontSize}
      color={dateColor}
      fontFamily={dateFontFamily}
      fontWeight={dateFontWeight}
      fontStyle={dateFontStyle}
      textAlign={dateAlign}
      mobileFontSize={mobileDateFontSize}
      mobileColor={mobileDateColor}
      mobileFontFamily={mobileDateFontFamily}
      mobileFontWeight={mobileDateFontWeight}
      mobileFontStyle={mobileDateFontStyle}
      mobileTextAlign={mobileDateAlign}
      margin={dateMargin}
      mobileMargin={mobileDateMargin}
    >
      {formatDate(new Date(post.dateTs * 1000), locale)}
    </BlogPostAnnounceDateUi>
  )
}

export default BlogPostsDates
