import { useEffect, useState } from 'react'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import useManagement, {
  selectors as managementSelectors,
} from 'publisher/hooks/useManagement'
import { usePage } from 'publisher/store/index'
import pageSelectors from 'publisher/store/page/pageSelectors'

export default function (entity: VideoInterface) {
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const hasAllVisibleAscendants = usePage(page =>
    pageSelectors.hasAllVisibleAscendants(page, entity, isDesktop),
  )
  const [autoPlay, setAutoPlay] = useState(false)

  useEffect(() => {
    const autoPlay =
      hasAllVisibleAscendants &&
      entity.autoplay &&
      ((isDesktop && entity.appearance.desktop) ||
        (!isDesktop && entity.appearance.mobile))

    setAutoPlay(autoPlay)
  }, [isDesktop, hasAllVisibleAscendants])

  return autoPlay
}
