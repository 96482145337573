import { CustomEventTypesEnum } from '../constants/customEventTypesEnum'

export function dispatchPopupOpenedCustomEvent(popupAttrId: string) {
  setTimeout(() => {
    const openPopupEvent = new CustomEvent(CustomEventTypesEnum.open_popup, {
      detail: { popupAttrId },
    })
    document.dispatchEvent(openPopupEvent)
  }, 500)
}
