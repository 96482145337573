import styled, { css } from 'styled-components'

export const CalendarWrapperUi = styled.div<{ isDateSelected: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 100%;
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      width: 100%;
    `}
  ${p => p.theme.phone} {
    width: 100%;
  }
`
