import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import { getDailyTime } from 'common/components/entities/Countdown/utils/get-daily-time'
import { getDelayTime } from 'common/components/entities/Countdown/utils/get-delay-time'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import {getFixedTime} from "./get-fixed-time";

export const getTimeByType = (entity: CountdownInterface) => {
  switch (entity.countdownType) {
    case CountdownTypesEnum.daily: {
      return getDailyTime(entity[CountdownTypesEnum.daily])
    }
    case CountdownTypesEnum.fixed: {
      return getFixedTime(entity[CountdownTypesEnum.fixed])
    }
    case CountdownTypesEnum.delay: {
      return getDelayTime(entity[CountdownTypesEnum.delay])
    }
    default: {
      return null
    }
  }
}
