import React from 'react'
import {
  DayPicker as ReactDayPicker,
  Styles as DayPickerStyles,
} from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { AvailabilitySlot } from '../calendar-interface'
import { CalendarDaySlot } from './calendar-day-slot'
import { MonthCaption } from './month-caption'
import { CalendarWrapperUi } from './ui/calendar-wrapper-ui'
import { DayPickerWrapperUi } from './ui/day-picker-wrapper-ui'
import { WeekdayUi } from './ui/weekday-ui'

interface DayPickerProps {
  selectedDate?: Date
  onDateSelect?: (date: Date) => void
  selectedMonth?: Date
  onMonthSelect?: (month: Date) => void
  isLoading?: boolean

  availabilitySlots: AvailabilitySlot[]
  availableSlotBackgroundColor: string
  selectedSlotBackgroundColor: string
  titlesColor?: string
  mobileTitlesColor?: string
  color?: string
  mobileColor?: string
  displayTimezone?: string
}

export const DayPicker = ({
  selectedDate,
  onDateSelect,
  selectedMonth = new Date(),
  onMonthSelect,
  isLoading,

  availabilitySlots,
  displayTimezone,

  availableSlotBackgroundColor,
  selectedSlotBackgroundColor,
  titlesColor,
  mobileTitlesColor,
  color,
  mobileColor,
}: DayPickerProps) => {
  const { t } = useTranslation()
  const { isMobile } = useDeviceModeContext()

  return (
    <CalendarWrapperUi isDateSelected={!!selectedDate}>
      <span style={{ color: titlesColor }}>
        {t('components.core.calendar.select_date')}
      </span>
      <DayPickerWrapperUi>
        <ReactDayPicker
          showOutsideDays
          timeZone={displayTimezone}
          disableNavigation={!onMonthSelect}
          defaultMonth={selectedMonth}
          onMonthChange={month => onMonthSelect?.(month)}
          mode="single"
          selected={selectedDate}
          styles={getStyles({
            titlesColor: isMobile ? mobileTitlesColor : titlesColor,
            color: isMobile ? mobileColor : color,
          })}
          labels={{
            labelWeekday: date => String(date.getDay()),
          }}
          components={{
            Weekday: WeekdayUi,
            // eslint-disable-next-line react/display-name
            Day: props => (
              <CalendarDaySlot
                selectedDate={selectedDate}
                availableSlotBackgroundColor={availableSlotBackgroundColor}
                selectedSlotBackgroundColor={selectedSlotBackgroundColor}
                onDateSelect={onDateSelect}
                isLoading={isLoading}
                {...props}
              />
            ),
            MonthCaption,
          }}
          modifiers={{
            isDateAvailable: availabilitySlots.map(
              ({ date }) => new Date(date),
            ),
          }}
          modifiersStyles={{
            today: {
              color: isMobile ? mobileColor : color,
            },
          }}
        />
      </DayPickerWrapperUi>
    </CalendarWrapperUi>
  )
}

interface GetStylesArgs {
  titlesColor?: string
  color?: string
}

function getStyles({
  titlesColor,
  color,
}: GetStylesArgs): Partial<DayPickerStyles> {
  return {
    month_grid: {
      width: '100%',
      minHeight: '400px',
    },
    month: {
      width: '100%',
    },
    months: {
      maxWidth: '100%',
    },
    weekday: {
      color: titlesColor,
      fontWeight: 500,
      height: '60px',
    },
    day_button: {
      width: '100%',
    },
    month_caption: {
      color: titlesColor,
      borderBottom: '1px solid rgba(181, 195, 213, 0.5)',
      padding: '0 15px',
      height: '70px',
      alignItems: 'center',
      fontWeight: 400,
    },
    nav: {
      top: '13px',
      right: '5px',
    },
    day: {
      color,
    },
  }
}
