import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import useManagement from 'publisher/hooks/useManagement'
import { selectors } from 'publisher/reducers/managementReducer'

const StyledWrapper = styled.div`
  position: relative; //position relative needs to make the same stacking context as editor
  ${props =>
    props.mobile === false &&
    css`
      ${p => p.theme.phone} {
        display: none;
      }
    `}
  ${props =>
    props.desktop === false &&
    css`
      ${p => p.theme.desktop} {
        display: none;
      }
    `}
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

function StyleWrapper(props) {
  const {
    children,
    forwardedRef,
    catchClick,
    mobile,
    desktop,
    styles,
    mobileStyles,
    ...restProps
  } = props
  const isScreenshotMode = useManagement(selectors.isScreenshotMode)

  return (
    <StyledWrapper
      ref={forwardedRef}
      styles={{
        ...restProps,
        ...styles,
      }}
      mobileStyles={mobileStyles}
      catchClick={catchClick}
      mobile={isScreenshotMode || mobile}
      desktop={isScreenshotMode || desktop}
    >
      {children}
    </StyledWrapper>
  )
}

StyleWrapper.propTypes = {
  children: PropTypes.node,
  forwardedRef: PropTypes.func,
  mobile: PropTypes.bool,
  desktop: PropTypes.bool,
  styles: PropTypes.objectOf(PropTypes.any),
  mobileStyles: PropTypes.objectOf(PropTypes.any),
  catchClick: PropTypes.bool,
}

StyleWrapper.defaultProps = {
  children: '',
  forwardedRef: () => {},
  mobile: true,
  desktop: true,
  catchClick: false,
  styles: {},
  mobileStyles: {},
  dropProps: {},
}

export default StyleWrapper
