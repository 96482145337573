import React, { ReactNode, useEffect, useState } from 'react'
import ContentItemUi from 'common/components/entities/ContentTable/ui/ContentItemUi'
import ContentItemWrapperUi from 'common/components/entities/ContentTable/ui/ContentItemWrapperUi'
import ContentTableContentUi from 'common/components/entities/ContentTable/ui/ContentTableContentUi'
import ContentTableHeaderIconUi from 'common/components/entities/ContentTable/ui/ContentTableHeaderIconUi'
import ContentTableHeaderIconWrapperUi from 'common/components/entities/ContentTable/ui/ContentTableHeaderIconWrapperUi'
import ContentTableHeaderTitleUi from 'common/components/entities/ContentTable/ui/ContentTableHeaderTitleUi'
import ContentTableHeaderUi from 'common/components/entities/ContentTable/ui/ContentTableHeaderUi'
import ContentTableUi from 'common/components/entities/ContentTable/ui/ContentTableUi'
import { HeadingTypeEnum } from 'common/enums/HeadingTypeEnum'
import { HeadlineInterface } from 'common/types/entities/HeadlineInterface'
import { OldTextEntityInterface } from 'common/types/entities/OldEntityInterface'
import { BorderType, PaddingType } from 'common/types/styleTypes'

export type ProcessedHeadline = {
  headline: HeadlineInterface | OldTextEntityInterface
  headlineType: HeadingTypeEnum
  content: ReactNode
}

type ContentTableProps = {
  padding: PaddingType
  mobilePadding: PaddingType
  iconClassName: string
  processedHeadlines: ProcessedHeadline[]
  closedIconClassName?: string
  iconColor: string
  headerTitle: string
  headerFontSize: number
  headerColor: string
  backgroundColor?: string
  headerFontFamily?: string
  headerFontStyle?: string
  headerFontWeight?: string
  mobileBackgroundColor?: string
  border: Partial<BorderType>
  contentColor: string
  contentFontSize: number
  contentFontFamily?: string
  contentFontStyle?: string
  contentFontWeight?: string
  contentItemGap?: number
  mobileBorder?: Partial<BorderType>
  defaultState: boolean
}

const headersOffsetMap = {
  [HeadingTypeEnum.HeaderOne]: 0,
  [HeadingTypeEnum.HeaderTwo]: 30,
  [HeadingTypeEnum.HeaderThree]: 60,
  [HeadingTypeEnum.HeaderFour]: 90,
  [HeadingTypeEnum.HeaderFive]: 120,
  [HeadingTypeEnum.HeaderSix]: 150,
}

function getOffset(
  processedHeadline: ProcessedHeadline,
  rootHeaderType: HeadingTypeEnum,
) {
  if (
    Object.values(HeadingTypeEnum).includes(processedHeadline.headlineType) ===
    false
  ) {
    return 0
  }

  return (
    headersOffsetMap[processedHeadline.headlineType] -
    headersOffsetMap[rootHeaderType]
  )
}

function compareHeaderTypes(first: HeadingTypeEnum, second: HeadingTypeEnum) {
  if (headersOffsetMap[first] < headersOffsetMap[second]) {
    return -1
  }

  return 1
}

function ContentTable({
  iconClassName,
  iconColor,
  backgroundColor,
  mobileBackgroundColor,
  padding,
  mobilePadding,
  contentColor,
  processedHeadlines,
  contentFontSize,
  border,
  mobileBorder,
  headerTitle,
  headerFontSize,
  headerColor,
  headerFontFamily,
  headerFontStyle,
  headerFontWeight,
  contentFontFamily,
  contentFontStyle,
  contentFontWeight,
  contentItemGap,
  closedIconClassName,
  defaultState,
}: ContentTableProps) {
  const [opened, setOpened] = useState(false)
  const rootHeaderType = processedHeadlines
    .map(processedHeadline => processedHeadline.headlineType)
    .sort(compareHeaderTypes)[0]

  useEffect(() => {
    setOpened(defaultState)
  }, [defaultState])

  return (
    <ContentTableUi
      padding={padding}
      mobilePadding={mobilePadding}
      mobileBackgroundColor={mobileBackgroundColor}
      backgroundColor={backgroundColor}
      border={border}
      mobileBorder={mobileBorder}
      fontFamily={contentFontFamily}
      fontStyle={contentFontStyle}
      fontWeight={contentFontWeight}
    >
      <ContentTableHeaderUi>
        <ContentTableHeaderTitleUi
          fontSize={headerFontSize}
          color={headerColor}
          fontFamily={headerFontFamily}
          fontStyle={headerFontStyle}
          fontWeight={headerFontWeight}
        >
          {headerTitle}
        </ContentTableHeaderTitleUi>
        <ContentTableHeaderIconWrapperUi>
          <ContentTableHeaderIconUi
            className={
              closedIconClassName
                ? opened
                  ? closedIconClassName
                  : iconClassName
                : iconClassName
            }
            color={iconColor}
            onClick={() => setOpened(!opened)}
            closedIcon={!!closedIconClassName}
            fontSize={headerFontSize}
            opened={opened}
          />
        </ContentTableHeaderIconWrapperUi>
      </ContentTableHeaderUi>
      <ContentTableContentUi collapsed={opened} fontSize={contentFontSize}>
        {processedHeadlines.map(processedHeadline => (
          <ContentItemWrapperUi
            key={processedHeadline.headline.id}
            marginBottom={contentItemGap}
            marginLeft={getOffset(processedHeadline, rootHeaderType)}
          >
            <ContentItemUi
              color={contentColor}
              href={`#${
                'rawContentState' in processedHeadline.headline
                  ? processedHeadline.headline.htmlAttrId
                  : processedHeadline.headline.options.attrId
              }`}
            >
              {processedHeadline.content}
            </ContentItemUi>
          </ContentItemWrapperUi>
        ))}
      </ContentTableContentUi>
    </ContentTableUi>
  )
}

export default ContentTable
