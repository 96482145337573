import React from 'react'
import { BlogPost } from 'common/types/entities/BlogPostListingInterface'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { BlogPostCardProps } from '.'
import BlogPostCard from './BlogPostCard'
import LayoutGridColumnUi from './ui/LayoutGridColumnUi'
import LayoutGridRowUi from './ui/LayoutGridRowUi'
import LayoutGridUi from './ui/LayoutGridUi'

type OneBackgroundThreeSideLayoutProps = {
  blogPostCardProps: BlogPostCardProps
  posts: BlogPost[]
  oneElementInColumn: boolean
}

function OneBackgroundThreeSideLayout({
  posts,
  blogPostCardProps,
  oneElementInColumn,
}: OneBackgroundThreeSideLayoutProps) {
  return (
    <LayoutGridUi
      gridTemplateColumns="1.75fr 1fr"
      gridTemplateRows="1fr 1fr 1fr"
    >
      {posts.map((post: BlogPost, index: number) => (
        <>
          {index === 0 ? (
            <LayoutGridRowUi rowStart={1} rowEnd={4}>
              <BlogPostCard
                key={index}
                post={post}
                {...blogPostCardProps}
                imagePlacement={ImagePlacementVariants.Background}
              />
            </LayoutGridRowUi>
          ) : (
            <LayoutGridColumnUi columnStart={2} columnEnd={2}>
              <BlogPostCard
                key={index}
                post={post}
                {...blogPostCardProps}
                imagePlacement={ImagePlacementVariants.Left}
              />
            </LayoutGridColumnUi>
          )}
        </>
      ))}
    </LayoutGridUi>
  )
}
export default OneBackgroundThreeSideLayout
