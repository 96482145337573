import { ColumnInterface } from 'common/components/entities/Column/types/ColumnInterface'
import { RowInterface } from 'common/components/entities/Row/types/RowInterface'
import { usePage } from 'publisher/store'

export const useColumnEntities = (entity: RowInterface) => {
  return usePage(state => {
    const columnIds = entity.childIds
    return (columnIds || [])
      .filter(Boolean)
      .map(childId => state.entities[childId])
      .filter(Boolean) as ColumnInterface[]
  })
}
