import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonBlogPostListing from 'common/components/entities/BlogPostListing/BlogPostListingsNew'
import {
  publisherBlogPostTs,
  publisherImagePlaceholderSrc,
  rangePosts,
} from 'common/components/entities/BlogPostsNew/utils'
import { BlogPostListingInterface } from 'common/types/entities/BlogPostListingInterface'
import useBlog, { selectors as blogSelectors } from 'publisher/hooks/useBlog'
import { managementSelectors, useManagement } from 'publisher/store'
import { usePage, typedPageSelectors } from 'publisher/store/index'

function BlogPostListingNew({ entity }: { entity: BlogPostListingInterface }) {
  const blogListing = useBlog(blogSelectors.getBlogPostListing)
  const blogPath = useBlog(blogSelectors.getBlogPath)
  const locale = usePage(typedPageSelectors.getLocale)
  const isPreview = useManagement(managementSelectors.isPreview)
  const isDesktop = useManagement(managementSelectors.isDesktop)

  let calculatedDisplayHorizontally = entity.displayHorizontally
  let isMobile = false
  if (typeof window !== 'undefined' && !isDesktop) {
    isMobile = true
    calculatedDisplayHorizontally = false
  }

  let paginationCurrent
  let paginationTotal
  let posts
  if (isPreview) {
    paginationCurrent = 0
    paginationTotal = 0
    posts = rangePosts(
      entity.maxPosts,
      publisherBlogPostTs,
      publisherImagePlaceholderSrc,
    )
  } else {
    paginationCurrent = blogListing.pagination.current
    paginationTotal = blogListing.pagination.total
    posts = blogListing.posts
  }

  return (
    <StyleWrapper
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonBlogPostListing
        htmlAttrId={entity.htmlAttrId}
        displayHorizontally={calculatedDisplayHorizontally}
        imagePlacement={entity.imagePlacement}
        isMobile={isMobile}
        mobileImagePlacement={entity.mobileImagePlacement}
        layoutType={entity.layoutType}
        locale={locale}
        blogPath={blogPath}
        paginationCurrent={paginationCurrent}
        paginationTotal={paginationTotal}
        posts={posts}
        rowsGap={entity.rowsGap}
        columnsGap={entity.columnsGap}
        maxPostsPerRow={entity.maxPostsPerRow}
        showDate={entity.showDate}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        imageMargin={entity.imageMargin}
        mobileImageMargin={entity.mobileImageMargin}
        imageBorder={entity.imageBorder}
        mobileImageBorder={entity.mobileImageBorder}
        imageBoxShadow={entity.imageBoxShadow}
        mobileImageBoxShadow={entity.mobileImageBoxShadow}
        titleMargin={entity.titleMargin}
        mobileTitleMargin={entity.mobileTitleMargin}
        titleFontSize={entity.titleFontSize}
        mobileTitleFontSize={entity.mobileTitleFontSize}
        titleLineHeight={entity.titleLineHeight}
        mobileTitleLineHeight={entity.mobileTitleLineHeight}
        titleFontFamily={entity.titleFontFamily}
        titleFontWeight={entity.titleFontWeight}
        titleFontStyle={entity.titleFontStyle}
        mobileTitleFontFamily={entity.mobileTitleFontFamily}
        mobileTitleFontWeight={entity.mobileTitleFontWeight}
        mobileTitleFontStyle={entity.mobileTitleFontStyle}
        titleLetterSpacing={entity.titleLetterSpacing}
        mobileTitleLetterSpacing={entity.mobileTitleLetterSpacing}
        titleAlign={entity.titleAlign}
        mobileTitleAlign={entity.mobileTitleAlign}
        titleColor={entity.titleColor}
        mobileTitleColor={entity.mobileTitleColor}
        descriptionMargin={entity.descriptionMargin}
        mobileDescriptionMargin={entity.mobileDescriptionMargin}
        descriptionFontSize={entity.descriptionFontSize}
        mobileDescriptionFontSize={entity.mobileDescriptionFontSize}
        descriptionLineHeight={entity.descriptionLineHeight}
        mobileDescriptionLineHeight={entity.mobileDescriptionLineHeight}
        descriptionFontFamily={entity.descriptionFontFamily}
        descriptionFontWeight={entity.descriptionFontWeight}
        descriptionFontStyle={entity.descriptionFontStyle}
        mobileDescriptionFontFamily={entity.mobileDescriptionFontFamily}
        mobileDescriptionFontWeight={entity.mobileDescriptionFontWeight}
        mobileDescriptionFontStyle={entity.mobileDescriptionFontStyle}
        descriptionAlign={entity.descriptionAlign}
        mobileDescriptionAlign={entity.mobileDescriptionAlign}
        descriptionColor={entity.descriptionColor}
        mobileDescriptionColor={entity.mobileDescriptionColor}
        categoriesMargin={entity.categoriesMargin}
        mobileCategoriesMargin={entity.mobileCategoriesMargin}
        categoriesFontSize={entity.categoriesFontSize}
        mobileCategoriesFontSize={entity.mobileCategoriesFontSize}
        categoriesFontFamily={entity.categoriesFontFamily}
        categoriesFontWeight={entity.categoriesFontWeight}
        categoriesFontStyle={entity.categoriesFontStyle}
        mobileCategoriesFontFamily={entity.mobileCategoriesFontFamily}
        mobileCategoriesFontWeight={entity.mobileCategoriesFontWeight}
        mobileCategoriesFontStyle={entity.mobileCategoriesFontStyle}
        categoriesAlign={entity.categoriesAlign}
        mobileCategoriesAlign={entity.mobileCategoriesAlign}
        categoriesColor={entity.categoriesColor}
        mobileCategoriesColor={entity.mobileCategoriesColor}
        dateMargin={entity.dateMargin}
        mobileDateMargin={entity.mobileDateMargin}
        dateFontSize={entity.dateFontSize}
        mobileDateFontSize={entity.mobileDateFontSize}
        dateFontFamily={entity.dateFontFamily}
        dateFontWeight={entity.dateFontWeight}
        dateFontStyle={entity.dateFontStyle}
        mobileDateFontFamily={entity.mobileDateFontFamily}
        mobileDateFontWeight={entity.mobileDateFontWeight}
        mobileDateFontStyle={entity.mobileDateFontStyle}
        dateAlign={entity.dateAlign}
        mobileDateAlign={entity.mobileDateAlign}
        dateColor={entity.dateColor}
        mobileDateColor={entity.mobileDateColor}
        paginationActiveColor={entity.paginationActiveColor}
        mobilePaginationActiveColor={entity.mobilePaginationActiveColor}
        paginationActiveBackgroundColor={entity.paginationActiveBackgroundColor}
        mobilePaginationActiveBackgroundColor={
          entity.mobilePaginationActiveBackgroundColor
        }
        paginationInactiveColor={entity.paginationInactiveColor}
        mobilePaginationInactiveColor={entity.mobilePaginationInactiveColor}
        paginationInactiveBackgroundColor={
          entity.paginationInactiveBackgroundColor
        }
        mobilePaginationInactiveBackgroundColor={
          entity.mobilePaginationInactiveBackgroundColor
        }
        postCard={entity.postCard}
      />
    </StyleWrapper>
  )
}

export default BlogPostListingNew
