import React from 'react'
import WebinarRegistrationDateByInterval from './WebinarRegistrationDateByInterval'
import WebinarRegistrationDateByScheduledTimes from './WebinarRegistrationDateByScheduledTimes'
import WebinarRegistrationDateUi from './uiNew/WebinarRegistrationDateUi'

export enum IntervalTypes {
  quarter = 15,
  half = 30,
  hour = 60,
}

type WebinarRegistrationDateProps = {
  attrId: string
  changeTime: (value: string | number) => void
  scheduledHours: number[]
  interval: number | null
  locale: string
  spaceBetween: number
  time?: number
  timeFormat?: number
}

function WebinarRegistrationDate({
  attrId,
  locale,
  scheduledHours,
  interval,
  spaceBetween,
  changeTime,
  time,
  timeFormat = 24,
}: WebinarRegistrationDateProps): JSX.Element {
  return (
    <WebinarRegistrationDateUi attrId={attrId} spaceBetween={spaceBetween}>
      {interval ? (
        <WebinarRegistrationDateByInterval
          interval={interval}
          locale={locale}
          timeFormat={timeFormat}
          changeTime={changeTime}
          time={time}
        />
      ) : (
        <WebinarRegistrationDateByScheduledTimes
          scheduledHours={scheduledHours}
          timeFormat={timeFormat}
          locale={locale}
          changeTime={changeTime}
          time={time}
        />
      )}
    </WebinarRegistrationDateUi>
  )
}

export default WebinarRegistrationDate
