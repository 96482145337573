import PropTypes from 'prop-types'
import React, { useLayoutEffect } from 'react'
import CommonInline from 'common/components/entities/Inline'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import { useBackgroundImage } from 'publisher/hooks/useBackgroundImage'
import * as types from '../../../common/types'
import BaseNodeChildren from '../ChildrenEntities'

const Inline = ({ entity, globalSettings }) => {
  const { mobileStyles, options, childIds } = entity
  const styles =
    globalSettings && globalSettings.fontFamily
      ? {
          ...entity.styles,
          fontFamily: wrapFontFamily(globalSettings.fontFamily),
        }
      : entity.styles

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      window.parent.postMessage({
        sender: window.location.hash.slice(1), // iframe's hash
        width: '100%',
        height: document.getElementById('app')?.offsetHeight,
      })
    })

    resizeObserver.observe(document.body)
  }, [])

  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.options.backgroundFileId,
    mobileBackgroundFileId: entity.mobileOptions.backgroundFileId,
  })

  return (
    <CommonInline
      styles={{ ...styles, backgroundImage }}
      mobileStyles={{ ...mobileStyles, backgroundImage: mobileBackgroundImage }}
      options={options}
    >
      <BaseNodeChildren childIds={childIds} />
    </CommonInline>
  )
}

Inline.propTypes = {
  entity: types.entity.isRequired,
  globalSettings: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Inline
