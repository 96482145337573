import styled from 'styled-components'


const NumberUi = styled.p`
  width: 100%;
  height: 85%;
  background-color: #fff;
  margin: -25px 0 0;
  padding-top: 100px;
  font-size: 96px;
  text-align: center;
  color: #353535;
  ${p => p.theme.phone} {
    padding-top: 0;
    margin-top: 0;
    font-size: 50px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
`

export default NumberUi
