import PropTypes from 'prop-types'
import React from 'react'
import { wrapFontFamilyByStyles } from 'common/components/entities/Text/utils'
import { defaultTextFontSize } from 'common/constants/settings'
import TextUi from './ui/TextUi'

const Text = props => {
  return (
    <TextUi
      attrId={props.htmlAttrId}
      styles={wrapFontFamilyByStyles(props.styles)}
      mobileStyles={wrapFontFamilyByStyles(props.mobileStyles)}
      listItemIconColor={props.listItemIconColor}
      mobileListItemIconColor={props.mobileListItemIconColor}
      listItemIconSize={props.listItemIconSize}
      linkColor={props.linkColor}
      fontSize={props.fontSize}
      mobileFontSize={props.mobileFontSize}
      lineHeight={props.lineHeight}
      mobileLineHeight={props.mobileLineHeight}
      textColor={props.textColor}
      flexBasis={props.flexBasis}
      listItemClassName={props.listItemClassName}
      verticalSpacing={props.verticalSpacing}
      mobileVerticalSpacing={props.mobileVerticalSpacing}
    >
      {props.children}
    </TextUi>
  )
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  mobileStyles: PropTypes.any,
  listItemIconColor: PropTypes.string,
  mobileListItemIconColor: PropTypes.string,
  listItemIconSize: PropTypes.string,
  linkColor: PropTypes.string,
  textColor: PropTypes.string,
  fontSize: PropTypes.string,
  mobileFontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  mobileLineHeight: PropTypes.string,
  htmlAttrId: PropTypes.string,
  flexBasis: PropTypes.string,
  listItemClassName: PropTypes.string,
  iconList: PropTypes.objectOf(PropTypes.string),
}

Text.defaultProps = {
  listItemIconColor: '',
  listItemIconSize: defaultTextFontSize,
  linkColor: null,
  fontSize: null,
  mobileFontSize: null,
  lineHeight: null,
  mobileLineHeight: null,
  listItemClassName: '',
}

export default Text
