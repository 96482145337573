import React from 'react'
import PropTypes from 'prop-types'
import SurveyQuestion, { QuestionPropType } from './SurveyQuestion'
import { AnswerPropType } from './SurveyAnswer'
import SurveyUi from './ui/SurveyUi'

function Survey({
  question,
  questionTitleFontSize,
  mobileQuestionTitleFontSize,
  questionSubTitleFontSize,
  mobileQuestionSubTitleFontSize,
  answerFontSize,
  mobileAnswerFontSize,
  answerColor,
  answerBackgroundColor,
  answerOutlineColor,
  questionTitleColor,
  questionSubTitleColor,
  choose,
  children,
  showResult,
  styles,
  mobileStyles,
  answerBorderRadius,
  mobileAnswerBorderRadius,
}) {
  return (
    <SurveyUi styles={styles} mobileStyles={mobileStyles}>
      {showResult ? (
        children
      ) : (
        <SurveyQuestion
          key={question.id}
          question={question}
          questionTitleFontSize={questionTitleFontSize}
          mobileQuestionTitleFontSize={mobileQuestionTitleFontSize}
          questionSubTitleFontSize={questionSubTitleFontSize}
          mobileQuestionSubTitleFontSize={mobileQuestionSubTitleFontSize}
          answerFontSize={answerFontSize}
          mobileAnswerFontSize={mobileAnswerFontSize}
          answerBorderRadius={answerBorderRadius}
          mobileAnswerBorderRadius={mobileAnswerBorderRadius}
          answerColor={answerColor}
          answerOutlineColor={answerOutlineColor}
          answerBackgroundColor={answerBackgroundColor}
          questionTitleColor={questionTitleColor}
          questionSubTitleColor={questionSubTitleColor}
          choose={choose}
        />
      )}
    </SurveyUi>
  )
}

Survey.propTypes = {
  question: QuestionPropType,
  questionTitleFontSize: PropTypes.string.isRequired,
  mobileQuestionTitleFontSize: PropTypes.string,
  questionSubTitleFontSize: PropTypes.string.isRequired,
  mobileQuestionSubTitleFontSize: PropTypes.string,
  answerFontSize: PropTypes.string.isRequired,
  mobileAnswerFontSize: PropTypes.string,
  answerOutlineColor: PropTypes.string,
  answerBackgroundColor: PropTypes.string,
  questionTitleColor: PropTypes.string.isRequired,
  questionSubTitleColor: PropTypes.string.isRequired,
  answerColor: PropTypes.string.isRequired,
  choose: PropTypes.func.isRequired,
  children: PropTypes.node,
  showResult: PropTypes.bool.isRequired,
  styles: PropTypes.shape({
    fontFamily: PropTypes.string,
    padding: PropTypes.string.isRequired,
    marginTop: PropTypes.string.isRequired,
    marginRight: PropTypes.string.isRequired,
    marginBottom: PropTypes.string.isRequired,
    marginLeft: PropTypes.string.isRequired,
  }),
  mobileStyles: PropTypes.shape({
    fontFamily: PropTypes.string,
    padding: PropTypes.string.isRequired,
    marginTop: PropTypes.string.isRequired,
    marginRight: PropTypes.string.isRequired,
    marginBottom: PropTypes.string.isRequired,
    marginLeft: PropTypes.string.isRequired,
  }),
  answerBorderRadius: PropTypes.shape({
    borderTopLeftRadius: PropTypes.number,
    borderTopRightRadius: PropTypes.number,
    borderBottomRightRadius: PropTypes.number,
    borderBottomLeftRadius: PropTypes.number,
  }).isRequired,
  mobileAnswerBorderRadius: PropTypes.shape({
    borderTopLeftRadius: PropTypes.number,
    borderTopRightRadius: PropTypes.number,
    borderBottomRightRadius: PropTypes.number,
    borderBottomLeftRadius: PropTypes.number,
  }),
}

export const actions = {
  redirect: 'redirect',
  nothing: 'nothing',
  openPopup: 'openPopup',
}

export const actionsNames = {
  [actions.redirect]: 'components.survey.actions.redirect',
  [actions.openPopup]: 'components.survey.actions.open_popup',
  [actions.nothing]: 'components.survey.actions.nothing',
}

export { QuestionPropType, AnswerPropType }

export default Survey
