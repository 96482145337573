import React from 'react'
import { blogPostListingPaginationInterface } from 'common/types/entities/BlogPostListingInterface'
import PaginationArrow from './PaginationArrow'
import PaginationCurrentPageUi from './ui/PaginationCurrentPageUi'
import PaginationLinkUi from './ui/PaginationLinkUi'
import PaginationUi from './ui/PaginationUi'

type PaginationProps = {
  current: number
  total: number
} & blogPostListingPaginationInterface

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'
const DOTS = 'DOTS'

const range = (from: number, to: number, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range as (string | number)[]
}

function Pagination({
  current,
  total,
  paginationActiveColor,
  mobilePaginationActiveColor,
  paginationActiveBackgroundColor,
  mobilePaginationActiveBackgroundColor,
  paginationInactiveColor,
  mobilePaginationInactiveColor,
  paginationInactiveBackgroundColor,
  mobilePaginationInactiveBackgroundColor,
}: PaginationProps) {
  const url =
    typeof window === 'undefined' ? null : new URL(window.location.href)

  const fetchPageNumbers = () => {
    const totalPages = total
    const currentPage = current
    const pageNeighbours = 1

    const totalNumbers = 1 * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const leftBound = currentPage - pageNeighbours
      const rightBound = currentPage + pageNeighbours
      const beforeLastPage = totalPages - 1

      const startPage = leftBound > 2 ? leftBound : 2
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage

      let pages = range(startPage, endPage)

      const pagesCount = pages.length
      const singleSpillOffset = totalNumbers - pagesCount - 1

      const leftSpill = startPage > 2
      const rightSpill = endPage < beforeLastPage

      const leftSpillPage = LEFT_PAGE
      const rightSpillPage = RIGHT_PAGE

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1)
        pages = [leftSpillPage, 1, ...extraPages, ...pages, totalPages]
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset)
        pages = [1, ...pages, ...extraPages, DOTS, totalPages, rightSpillPage]
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, 1, ...pages, DOTS, totalPages, rightSpillPage]
      }

      return pages
    }

    return range(1, totalPages)
  }

  const getUrl = (page: number | string) => {
    if (!url) {
      return ''
    }
    url.searchParams.set('page', `${page}`)
    return url.href
  }

  if (!total || total === 1) {
    return <PaginationUi />
  }
  const pages = fetchPageNumbers()

  return (
    <PaginationUi>
      {pages.map(page => {
        if (page === DOTS) {
          return (
            <span style={{ alignSelf: 'end', fontSize: '16px' }}>. . .</span>
          )
        }

        if (page === LEFT_PAGE) {
          return (
            <PaginationArrow
              key={page}
              href={getUrl(current - 1)}
              left
              paginationActiveColor={paginationActiveColor}
              mobilePaginationActiveColor={mobilePaginationActiveColor}
              paginationActiveBackgroundColor={paginationActiveBackgroundColor}
              mobilePaginationActiveBackgroundColor={
                mobilePaginationActiveBackgroundColor
              }
              paginationInactiveColor={paginationInactiveColor}
              mobilePaginationInactiveColor={mobilePaginationInactiveColor}
              paginationInactiveBackgroundColor={
                paginationInactiveBackgroundColor
              }
              mobilePaginationInactiveBackgroundColor={
                mobilePaginationInactiveBackgroundColor
              }
            />
          )
        }

        if (page === RIGHT_PAGE) {
          return (
            <PaginationArrow
              key={page}
              href={getUrl(current + 1)}
              right
              paginationActiveColor={paginationActiveColor}
              mobilePaginationActiveColor={mobilePaginationActiveColor}
              paginationActiveBackgroundColor={paginationActiveBackgroundColor}
              mobilePaginationActiveBackgroundColor={
                mobilePaginationActiveBackgroundColor
              }
              paginationInactiveColor={paginationInactiveColor}
              mobilePaginationInactiveColor={mobilePaginationInactiveColor}
              paginationInactiveBackgroundColor={
                paginationInactiveBackgroundColor
              }
              mobilePaginationInactiveBackgroundColor={
                mobilePaginationInactiveBackgroundColor
              }
            />
          )
        }

        return current === page ? (
          <PaginationCurrentPageUi
            key={page}
            paginationActiveColor={paginationActiveColor}
            mobilePaginationActiveColor={mobilePaginationActiveColor}
            paginationActiveBackgroundColor={paginationActiveBackgroundColor}
            mobilePaginationActiveBackgroundColor={
              mobilePaginationActiveBackgroundColor
            }
          >
            {page}
          </PaginationCurrentPageUi>
        ) : (
          <PaginationLinkUi
            key={page}
            href={getUrl(page)}
            paginationActiveColor={paginationActiveColor}
            mobilePaginationActiveColor={mobilePaginationActiveColor}
            paginationActiveBackgroundColor={paginationActiveBackgroundColor}
            mobilePaginationActiveBackgroundColor={
              mobilePaginationActiveBackgroundColor
            }
            paginationInactiveColor={paginationInactiveColor}
            mobilePaginationInactiveColor={mobilePaginationInactiveColor}
            paginationInactiveBackgroundColor={
              paginationInactiveBackgroundColor
            }
            mobilePaginationInactiveBackgroundColor={
              mobilePaginationInactiveBackgroundColor
            }
          >
            {page}
          </PaginationLinkUi>
        )
      })}
    </PaginationUi>
  )
}

export default Pagination
