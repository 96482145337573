import styled, { css } from 'styled-components'
import { AlignSelfType, PaddingType } from 'common/types/styleTypes'
import { mobilePaddingsCss, paddingsCss } from 'common/utils/styleUtilsNew'

type HorizontalMenuUiProps = {
  forceHide?: boolean
  disablePointerEvents?: boolean
  isVisibleOnMobile: boolean
  alignSelf: AlignSelfType
  mobileAlignSelf?: AlignSelfType
  padding: PaddingType
  mobilePadding: PaddingType
}
const HorizontalMenuUi = styled.div<HorizontalMenuUiProps>`
  min-height: 20px;
  display: ${p => (p.forceHide ? 'none' : 'flex')};
  justify-content: ${p => p.alignSelf || 'center'};
  ${paddingsCss}
  ${p => p.theme.phone} {
    display: ${p => (p.isVisibleOnMobile ? 'flex' : 'none')};
    justify-content: ${p => p.mobileAlignSelf || 'center'};
    ${mobilePaddingsCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      display: ${p.isVisibleOnMobile ? 'flex' : 'none'};
      justify-content: ${p.mobileAlignSelf || 'center'};
      ${mobilePaddingsCss}
    `}
  ${p =>
    p.disablePointerEvents &&
    css`
      pointer-events: none;
    `}
`

export default HorizontalMenuUi
