const videoTypes = {
  YOUTUBE: 'youtube',
  CUSTOM: 'custom',
  FILE: 'file',
  URL: 'url',
}

export const videoTypesNames = {
  [videoTypes.YOUTUBE]: 'entity_settings.video.source_type.youtube',
  [videoTypes.CUSTOM]: 'entity_settings.video.source_type.custom_embed',
  [videoTypes.FILE]: 'entity_settings.video.source_type.upload_file',
  [videoTypes.URL]: 'entity_settings.video.source_type.direct_link',
}

export default videoTypes
