import React from 'react'
import useManagement, {
  selectors as managementSelectors,
} from '../../hooks/useManagement'
import useOnLoadVisibility from '../../hooks/useOnloadVisibility'
import usePage from '../../hooks/usePage'
import {
  getMobileOnloadPopups,
  getOnloadPopups,
  getPageId,
} from '../../reducers/pageReducer'

function OnloadPopups() {
  const desktopOnloadPopups = usePage(getOnloadPopups)
  // mobile onload it is a "mobile on exit" option actually
  const mobileOnloadPopups = usePage(getMobileOnloadPopups)
  const pageId = usePage(getPageId)
  const isMobile = useManagement(managementSelectors.isMobile)

  const renderedDesktopOnLoadPopups = desktopOnloadPopups.map(popup => (
    <OnloadPopup pageId={pageId} key={popup.id} popup={popup} />
  ))

  if (typeof window !== 'undefined' && isMobile) {
    return mobileOnloadPopups
      .map(popup => (
        <MobileOnloadPopup pageId={pageId} key={popup.id} popup={popup} />
      ))
      .concat(renderedDesktopOnLoadPopups)
  } else {
    return renderedDesktopOnLoadPopups
  }
}

export function OnloadPopup({ popup, pageId }) {
  useOnLoadVisibility(pageId, popup.id, popup.options.delay)

  return null
}

export function MobileOnloadPopup({ popup, pageId }) {
  useOnLoadVisibility(pageId, popup.id, popup.options.mobileDelay)

  return null
}

export default OnloadPopups
