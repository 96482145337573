import { css } from 'styled-components'

type menuItemMixinProps = {
  isActive: boolean
  activeColor?: string
  underlineColor?: string
}
type MobileMenuItemMixinProps = {
  isActive: boolean
  mobileActiveColor?: string
  mobileUnderlineColor?: string
  mobileColor?: string
}
export const menuItemMixin = css<menuItemMixinProps>`
  ${p =>
    p.isActive &&
    p.activeColor &&
    css`
      color: ${p.activeColor};
    `}
  ${p =>
    p.isActive &&
    p.underlineColor &&
    css`
      text-decoration-color: ${p.underlineColor};
      text-decoration-line: underline;
      text-decoration-thickness: 3px;
      text-underline-offset: 10px;
    `}
  transition: box-shadow 0.1s ease-in-out;
  &:hover {
    color: ${p => p.activeColor};
    text-decoration-color: ${p => p.underlineColor};
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 10px;
  }
`
export const mobileMenuItemMixin = css<MobileMenuItemMixinProps>`
  ${p =>
    p.mobileColor &&
    css`
      color: ${p.mobileColor};
      &:hover {
        color: ${p.mobileColor};
      }
    `};
  ${p =>
    p.isActive &&
    p.mobileActiveColor &&
    css`
      color: ${p.mobileActiveColor};
      &:hover {
        color: ${p.mobileActiveColor};
      }
    `}
  ${p =>
    p.isActive &&
    p.mobileUnderlineColor &&
    css`
      text-decoration-color: ${p.mobileUnderlineColor};
      text-decoration-line: underline;
      text-decoration-thickness: 3px;
      text-underline-offset: 10px;
    `}
  transition: box-shadow 0.1s ease-in-out;
`
