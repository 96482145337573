import styled, { css } from 'styled-components'
import { fontSizeCss, mobileFontSizeCss } from 'common/utils/styleUtilsNew'

const SurveyQuestionTitleUi = styled.div<{
  fontSize: number
  mobileFontSize: number
}>`
  ${fontSizeCss};
  color: ${p => p.color};
  text-align: center;
  margin-bottom: 15px;
  ${p => p.theme.phone} {
    ${mobileFontSizeCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontSizeCss}
    `}
`

export default SurveyQuestionTitleUi
