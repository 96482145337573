import styled from 'styled-components'

const PricePlanActionButtonUi = styled.div`
  background: #1ba0ff;
  color: #ffffff;
  font-size: 23px;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 218px;
  text-align: center;
  border-radius: 3px;
  margin-bottom: 15px;
  cursor: pointer;
`

export default PricePlanActionButtonUi
