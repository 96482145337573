import styled from 'styled-components'


const PopupScrollContentUi = styled.div`
  overflow-y: auto; // auto to prevent always showing scroll bar
  max-height: inherit;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}    
  }
  ${({ styles = {} }) => styles}
`

export default PopupScrollContentUi
