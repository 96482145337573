export enum PageLocaleEnum {
  FRENCH = 'fr',
  ENGLISH = 'en',
  SPANISH = 'es',
  ITALIAN = 'it',
  PORTUGUESE = 'pt',
  DEUTSCH = 'de',
  DUTCH = 'nl',
  RUSSIAN = 'ru',
  JAPANESE = 'jp',
  ARABIC = 'ar',
  TURKISH = 'tr',
  CHINESE = 'zh',
  SWEDISH = 'sv',
  ROMANIAN = 'ro',
  CZECH = 'cs',
  HUNGARIAN = 'hu',
  SLOVAK = 'sk',
  DANISH = 'dk',
  INDONESIAN = 'id',
  POLISH = 'pl',
  GREEK = 'el',
  SERBIAN = 'sr',
  NORWEGIAN = 'no',
  THAI = 'th',
  SLOVENIAN = 'sl',
  UKRAINIAN = 'ua',
  ALBANIA = 'sq',
  HINDI = 'hi',
}
