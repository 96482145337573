import PropTypes from 'prop-types'
import React, { memo } from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonText from 'common/components/entities/Text'
import { useDelay } from '../../hooks/useDelay'
import usePage, { selectors } from '../../hooks/usePage'
import { usePopupOpenerRef } from '../../hooks/usePopupOpenerRef'

function BulletList({ entity }) {
  const ref = usePopupOpenerRef()
  const isVisible = useDelay(entity.options.delay)
  const linkColor = usePage(selectors.getGlobalLinkColor)
  const textFontSize = usePage(selectors.getGlobalTextFontSize)
  const mobileTextFontSize = usePage(selectors.getGlobalMobileTextFontSize)
  const textLineHeight = usePage(selectors.getGlobalTextLineHeight)
  const textColor = usePage(selectors.getGlobalTextColor)
  const mobileTextLineHeight = usePage(selectors.getGlobalMobileTextLineHeight)
  const { options, mobileOptions, styles, mobileStyles } = entity

  return isVisible ? (
    <StyleWrapper
      textAlign={styles.textAlign}
      desktop={options.appearance.desktop}
      mobile={options.appearance.mobile}
    >
      <CommonText
        htmlAttrId={options.attrId}
        styles={styles}
        mobileStyles={mobileStyles}
        linkColor={linkColor}
        textColor={textColor}
        fontSize={textFontSize}
        mobileFontSize={mobileTextFontSize}
        lineHeight={textLineHeight}
        mobileLineHeight={mobileTextLineHeight}
        listItemIconColor={options.listItemIconColor}
        mobileListItemIconColor={mobileOptions.listItemIconColor}
        listItemIconSize={options.listItemIconSize}
        listItemClassName={options.listItemClassName}
        verticalSpacing={options.verticalSpacing}
        mobileVerticalSpacing={mobileOptions.verticalSpacing}
      >
        <div dangerouslySetInnerHTML={{ __html: options.html }} ref={ref} />
      </CommonText>
    </StyleWrapper>
  ) : (
    <div />
  )
}

BulletList.propTypes = {
  entity: PropTypes.objectOf(PropTypes.any),
}

export default memo(BulletList)
