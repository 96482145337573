import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import HorizontalLineUi from 'common/components/entities/HorizontalLine/HorizontalLineUi'
import { HorizontalLineInterface } from 'common/types/entities/HorizontalLineInterface'

type HorizontalLineProps = {
  entity: HorizontalLineInterface
}

function NewHorizontalLine({ entity }: HorizontalLineProps) {
  return (
    <StyleWrapper
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
    >
      <HorizontalLineUi
        id={entity.htmlAttrId}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
      />
    </StyleWrapper>
  )
}
export default NewHorizontalLine
