import React, { useLayoutEffect } from 'react'
import CommonInlineBody from 'common/components/entities/inline-body'
import { InlineBodyInterface } from 'common/types/inline-body-interface'
import { useBackgroundImage } from 'publisher/hooks/useBackgroundImage'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import BaseNodeChildren from '../ChildrenEntities'

interface InlineBodyProps {
  entity: InlineBodyInterface
}

const InlineBody = ({ entity }: InlineBodyProps) => {
  const { childIds = [] } = entity

  const {
    background,
    backgroundColor,
    mobileBackgroundColor,
    boxShadow,
    maxWidth,
    mobilePadding,
    mobileWidth,
    padding,
    width,
    blur,
    border,
    mobileBorder,
    mobileBoxShadow,
    mobileBackground,
  } = entity

  const textColor = usePage(pageSelectors.getGlobalTextColor)
  const fontFamily = usePage(pageSelectors.getGlobalTextFontFamily)
  const fontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const mobileFontSize = usePage(pageSelectors.getGlobalMobileTextFontSize)

  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      window.parent.postMessage({
        sender: window.location.hash.slice(1), // iframe's hash
        width: '100%',
        height: document.getElementById('app')?.offsetHeight,
      })
    })

    resizeObserver.observe(document.body)
  }, [])

  return (
    <CommonInlineBody
      width={width}
      mobileWidth={mobileWidth}
      maxWidth={maxWidth}
      padding={padding}
      mobilePadding={mobilePadding}
      background={background}
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      backgroundImage={backgroundImage}
      mobileBackgroundImage={mobileBackgroundImage}
      mobileBackground={mobileBackground}
      border={border}
      mobileBorder={mobileBorder}
      boxShadow={boxShadow}
      mobileBoxShadow={mobileBoxShadow}
      blur={blur}
      color={textColor}
      fontFamily={fontFamily}
      fontSize={fontSize}
      mobileFontSize={mobileFontSize}
    >
      <BaseNodeChildren childIds={childIds} />
    </CommonInlineBody>
  )
}

export default InlineBody
