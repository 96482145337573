import styled from 'styled-components'

const TimerItemDelimiterUi = styled.section`
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default TimerItemDelimiterUi
