import React from 'react'
import { AlignType, PaddingType } from 'common/types/styleTypes'
import { FontProps } from 'common/utils/styleUtilsNew'
import BlogPostTitleUi from './ui/BlogPostTitleUiNew'

type BlogPostTitleProps = {
  title: string
  attrId: string
  mobileLineHeight?: number
  lineHeight?: number
  alignSelf: AlignType
  mobileAlignSelf?: AlignType
} & FontProps

function BlogPostTitleNew({
  title,
  attrId,
  fontSize,
  fontFamily,
  fontStyle,
  fontWeight,
  mobileLineHeight,
  mobileFontSize,
  mobileFontFamily,
  mobileFontStyle,
  mobileFontWeight,
  lineHeight,
  color,
  mobileColor,
  alignSelf,
  mobileAlignSelf,
}: BlogPostTitleProps) {
  return (
    <BlogPostTitleUi
      id={attrId}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      mobileFontSize={mobileFontSize}
      mobileFontFamily={mobileFontFamily}
      mobileFontStyle={mobileFontStyle}
      mobileFontWeight={mobileFontWeight}
      lineHeight={lineHeight}
      mobileLineHeight={mobileLineHeight}
      color={color}
      mobileColor={mobileColor}
      alignSelf={alignSelf}
      mobileAlignSelf={mobileAlignSelf}
    >
      {title}
    </BlogPostTitleUi>
  )
}

export default BlogPostTitleNew
