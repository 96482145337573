import styled from 'styled-components'
import { leftToRightDisplay } from '../../../../utils/mixins'

const Select = styled.select`
  font-size: 16px;
  text-indent: 10px;
  padding: 12px 0;
  outline: none;
  border: 1px solid #b0baca;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
    & option {
      ${({ mobileStyles = {} }) => leftToRightDisplay(mobileStyles.textAlign)}
    }
  }
  ${({ styles = {} }) => styles}
  & option {
    ${({ styles = {} }) => leftToRightDisplay(styles.textAlign)}
  }
`

export default Select
