import PropTypes from 'prop-types'
import React from 'react'
import {
  backgroundPositionValues,
  borderTypeValues,
} from 'common/constants/settings'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import provideRef from 'common/hoc/provideRef'
import RowOldUi from './ui/RowOldUi'

const getBackgroundImage = file => (file ? `url("${file.path}")` : 'none')

const RowOld = ({
  children,
  styles,
  options,
  mobileOptions,
  mobileStyles,
  forwardedRef,
  disableColumnGap,
  backgroundFile,
  mobileBackgroundFile,
  ...restProps
}) => {
  const backgroundImage = getBackgroundImage(backgroundFile)

  const calculatedMobileStyles = {
    ...mobileStyles,
    ...borderTypeValues[mobileOptions.borderType],
    ...backgroundPositionValues[mobileOptions.backgroundPosition],
  }

  if (mobileBackgroundFile) {
    calculatedMobileStyles.backgroundImage = `url("${mobileBackgroundFile.path}")`
  }

  return (
    <RowOldUi
      attrId={options.attrId}
      {...restProps}
      ref={forwardedRef}
      mobileStyles={calculatedMobileStyles}
      disableColumnGap={disableColumnGap}
      disableStacking={options.disableStacking}
      reverseColumns={options.reverseColumns}
      styles={{
        ...styles,
        backgroundImage,
        ...borderTypeValues[options.borderType],
        ...backgroundPositionValues[options.backgroundPosition],
      }}
    >
      {React.Children.map(children, child =>
        React.cloneElement(child, { disableStacking: options.disableStacking }),
      )}
    </RowOldUi>
  )
}

RowOld.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.objectOf(PropTypes.any),
  mobileOptions: PropTypes.objectOf(PropTypes.any),
  styles: PropTypes.objectOf(PropTypes.string),
  mobileStyles: PropTypes.objectOf(PropTypes.string),
  disableColumnGap: PropTypes.bool,
  mobileBackgroundFile: PropTypes.any,
  backgroundFile: PropTypes.any,
}

RowOld.defaultProps = {
  styles: {},
  options: {},
  mobileOptions: {},
  mobileStyles: {},
  disableColumnGap: false,
}

// provideRef need for row resizing
export default provideRef(provideMobileStyles(RowOld))
