import styled from 'styled-components'

type BlogPostContentTableContentUiProps = {
  fontSize: number
  collapsed: boolean
}

const ContentTableContentUi = styled.div<BlogPostContentTableContentUiProps>`
  height: ${p => (p.collapsed ? '0' : '100%')};
  font-size: ${p => p.fontSize}px;
  line-height: ${p => p.fontSize * 1.3}px;
  overflow: hidden;
  transition: all 0.3s ease-out;
`

export default ContentTableContentUi
