import styled, { css } from 'styled-components'
import {
  paddingsCss,
  mobilePaddingsCss,
  boxShadowCss,
  mobileBoxShadowCss,
  backgroundColorCss,
  mobileBackgroundColorCss,
} from 'common/utils/styleUtilsNew'
import { PaddingType } from '../../../../types/styleTypes'

type FaqItemUiProps = {
  backgroundColor: string
  mobileBackgroundColor?: string
  borderRadius: number
  padding: PaddingType
  mobilePadding: PaddingType
  boxShadow?: string
  mobileBoxShadow?: string
}

const FaqItemUi = styled.div<FaqItemUiProps>`
  border-radius: ${p => p.borderRadius}px;
  ${backgroundColorCss}
  ${paddingsCss}
  ${boxShadowCss}
  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
    ${mobileBoxShadowCss}
    ${mobileBackgroundColorCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobilePaddingsCss}
      ${mobileBoxShadowCss}
      ${mobileBackgroundColorCss}
    `}
`

export default FaqItemUi
