import axios from 'axios'
import { AvailabilitySlot, EventInterface } from 'common/components/entities/Calendar/calendar-interface'
import { getQueryString } from 'common/utils/fetchHelper';

export function getEvent(eventId: EventInterface['id']) {
  return axios.get<EventInterface>(`/api/booking-calendar/event/${eventId}`)
}

export interface GetTimeSlotsArgs {
  ownerId: number
  eventId: EventInterface['id']
  startDate: string //2024-10-20,
  endDate: string // 2024-12-05
  timezone: string
}

export function getTimeSlots(args: GetTimeSlotsArgs) {
  const searchQuery = getQueryString(args)
  return axios.get<AvailabilitySlot[]>(`/api/booking-calendar/event/timeSlots?${searchQuery}`)
}
