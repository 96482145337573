import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'

export const getPostfixByType = (entity: CountdownInterface) => {
  switch (entity.countdownType) {
    case CountdownTypesEnum.delay: {
      // the actual data is different from the interface, @see https://app.shortcut.com/systeme/story/27582/fix-countdown-screenshots
      return (
        entity[CountdownTypesEnum.delay] &&
        entity[CountdownTypesEnum.delay].createdAt
      )
    }
    case CountdownTypesEnum.daily: {
      // the actual data is different from the interface, @see https://app.shortcut.com/systeme/story/27582/fix-countdown-screenshots
      return (
        entity[CountdownTypesEnum.daily] &&
        entity[CountdownTypesEnum.daily].createdAt
      )
    }
    default: {
      return null
    }
  }
}
