import styled, { css } from 'styled-components'
import { showFaqIconCss } from 'common/utils/styleUtilsNew'

type FaqItemArrowUiProps = {
  expanded: boolean
  iconColor: string
  mobileIconColor?: string
  showIcons: boolean
  mobileShowIcons: boolean
}

const FaqItemArrowUi = styled.i<FaqItemArrowUiProps>`
  color: ${p => p.iconColor};
  transform: ${p => (p.expanded ? 'none' : 'rotate(180deg)')};
  transition: transform 0.3s;

  ${showFaqIconCss}
  ${p => p.theme.phone} {
    ${p => p.mobileIconColor && `color: ${p.mobileIconColor};`}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${p.mobileIconColor && `color: ${p.mobileIconColor};`}
    `}
`

export default FaqItemArrowUi
