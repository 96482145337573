export default function (url: string, id: string, onload: () => void) {
  const script = document.createElement('script')
  script.onload = onload
  script.id = id
  script.src = url

  document.head.appendChild(script)
}

export function loadScriptAsync(scriptUrl: string, doc: Document) {
  return new Promise((resolve, reject) => {
    const calculatedDocument = doc || document
    const script = calculatedDocument.createElement('script')
    script.src = scriptUrl
    script.onload = ev => resolve(ev)
    script.onerror = err => reject(err)
    calculatedDocument.head.appendChild(script)
  })
}
