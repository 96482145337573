import React from 'react'
import CommonBookingCalendar from 'common/components/entities/Calendar'
import {
  BookingCalendarSteps,
  EventTimeZoneTypeEnum,
} from 'common/components/entities/Calendar/constants'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import ChildrenEntities from 'publisher/components/ChildrenEntities'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { useClosePopup } from 'publisher/hooks/use-close-popup'
import useBlog, { selectors as blogSelectors } from 'publisher/hooks/useBlog'
import { useBookingCalendarOptIn } from './use-booking-calendar-opt-in'
import { useBookingCalendarParams } from './use-booking-calendar-params'
import { useCalendarErrors } from './use-calendar-step-errors'

interface BookingCalendarProps {
  entity: BookingCalendarInterface
}

function BookingCalendar({ entity }: BookingCalendarProps) {
  const {
    event,
    timeSlots,
    isEventFetching,
    isTimeSlotsFetching,
    activeStep,
    setActiveStep,
    selectedDate,
    selectedTimeZone,
    selectedTimeSlot,
    selectedLocationId,
    handleChangeField,
    onMonthSelect,
    globalFontFamily,
    globalMobileFontSize,
    globalFontSize,
  } = useBookingCalendarParams(entity)

  const blogLayoutPageId = useBlog(blogSelectors.getBlogLayoutPageId)
  const closePopup = useClosePopup(entity, blogLayoutPageId)

  const {
    isLoading,
    errors: formStepErrors,
    submit,
  } = useBookingCalendarOptIn({
    optInButtonEntity: entity,
    closePopup,
  })

  const {
    errors: calendarStepErrors,
    isErrorsVisible,
    setIsErrorsVisible,
  } = useCalendarErrors()

  return (
    <StyleWrapper
      mobileMargin={entity.mobileMargin}
      margin={entity.margin}
      appearance={entity.appearance}
    >
      <CommonBookingCalendar
        entity={entity}
        selectedDate={selectedDate}
        onDateSelect={date => {
          handleChangeField('date', date)
          handleChangeField('timeSlot', '')
        }}
        selectedTimeSlot={selectedTimeSlot}
        onTimeSlotSelect={timeSlot => handleChangeField('timeSlot', timeSlot)}
        selectedTimeZone={selectedTimeZone}
        onTimeZoneSelect={tz => {
          if (event?.displayTimezoneType !== EventTimeZoneTypeEnum.Fixed) {
            handleChangeField('timeZone', tz)
          }
        }}
        selectedLocationId={selectedLocationId}
        onLocationSelect={locationId => {
          handleChangeField('eventLocationId', locationId)
        }}
        onMonthSelect={onMonthSelect}
        onNextStepClick={() => {
          if (!calendarStepErrors.length) {
            setActiveStep(BookingCalendarSteps.FormStep)
            return
          }
          if (!isErrorsVisible) setIsErrorsVisible(true)
        }}
        onFormSubmit={submit}
        formStepErrors={formStepErrors}
        isFormSubmitDisabled={isLoading}
        calendarStepErrors={isErrorsVisible ? calendarStepErrors : []}
        onPrevStepClick={() => {
          setActiveStep(BookingCalendarSteps.CalendarStep)
        }}
        activeStep={activeStep}
        availabilitySlots={timeSlots}
        event={event}
        fontSize={globalFontSize}
        mobileFontSize={globalMobileFontSize}
        fontFamily={globalFontFamily}
        mobileFontFamily={globalFontFamily}
        isLoading={isEventFetching || isTimeSlotsFetching}
        formStepChildren={<ChildrenEntities childIds={entity.childIds} />}
      />
    </StyleWrapper>
  )
}

export default BookingCalendar
