import styled, { css } from 'styled-components'

const PricePlanCalculatorUi = styled.div`
  display: flex;
  border: 10px solid #1ba0ff;
  border-radius: 5px;
  ${p => p.theme.phone} {
    flex-direction: column;
    justify-content: center;
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      flex-direction: column;
      justify-content: center;
    `}
`

export default PricePlanCalculatorUi
