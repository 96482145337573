import styled from 'styled-components'


const ClockUi = styled.div`
  display: flex;
  justify-content: center;
  ${p => p.theme.phone} {
    flex-direction: column;
    width: 70%;
    margin: auto;
  }
`

export default ClockUi
