export enum PageTypeEnum {
  Inline = 'inline',
  Popup = 'popup',
  Squeeze = 'squeeze',
  SalesPage = 'sales_page',
  Lecture = 'lecture',
  WebinarSession = 'webinar_session',
  WebinarRegistration = 'webinar_registration',
  WebinarThankYou = 'webinar_thank_you_page',
  InfoPage = 'info_page',
  OptInThankYou = 'opt_in_thank_you',
  OfferPage = 'offer_page',
  OfferUpSellPage = 'offer_upsell_page',
  OfferDownSellPage = 'offer_downsell_page',
  InactiveFunnelPage = 'inactive_funnel_page',
  OfferThankYouPage = 'offer_thank_you_page',
  OfferInner = 'offer_inner',
  CustomLogin = 'custom_login',
  BlogPostLayout = 'blog_post_layout',
  BlogPostBody = 'blog_post_body',
  BlogLayout = 'blog_layout',
  BlogStatic = 'blog_static',
  BlogHome = 'blog_home',
  CourseIntroduction = 'course_introduction',
  FunnelContactUs = 'funnel_contact_us',
  NotFound = 'not_found',
  LinkInBio = 'link_in_bio',

  // used only for migrated pages backward compatibility
  InlineBody = 'inline_body',
}
