import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { PaddingType } from '../styleTypes'
import {
  blogPostListingCategoriesInterface,
  blogPostListingDateInterface,
  blogPostListingDescriptionInterface,
  blogPostListingImageInterface,
  blogPostListingTitleInterface,
} from './BlogPostListingInterface'
import EntityInterface from './EntityInterface'

export interface LatestBlogPostsCarouselInterface
  extends EntityInterface,
    CommonLatestBlogPostsCarouselProps {
  type: EntityTypeEnum.LatestBlogPostsCarousel
  maxPosts: number
  postsReplacement: string
  showArrows: boolean
  mobileShowArrows?: boolean
  controlsColor: string
  arrowsColor: string
  imagePlacement: ImagePlacementVariants
  mobileImagePlacement?: ImagePlacementVariants
}

export type CommonLatestBlogPostsCarouselProps = {
  maxPostsPerRow: number
  columnsGap: number
  showDate: boolean
  padding: PaddingType
  mobilePadding: PaddingType
  postBackgroundColor?: string
  mobilePostBackgroundColor?: string
} & blogPostListingImageInterface &
  blogPostListingTitleInterface &
  blogPostListingDescriptionInterface &
  blogPostListingCategoriesInterface &
  blogPostListingDateInterface

export enum ImagePlacementVariants {
  Up = 'up',
  Left = 'left',
  Background = 'background',
}
