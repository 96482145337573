import { useEffect, useRef, useState } from 'react'

export const useFloatHover = () => {
  const [show, setShow] = useState(false)
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  const open = () => {
    if (timer.current !== null) {
      clearTimeout(timer.current)
      timer.current = null
    }
    setShow(true)
  }

  useEffect(() => {
    if (show && timer.current) {
      setShow(false)
    }
  }, [show])

  const delayOpen = () => {
    if (timer.current !== null) {
      clearTimeout(timer.current)
      timer.current = null
    }
    setTimeout(() => {
      setShow(true)
    }, 100)
  }

  const close = () => {
    setShow(false)
  }

  const delayClose = () => {
    timer.current = setTimeout(() => {
      setShow(false)
    }, 100)
  }
  return { show, open, close, delayClose, delayOpen }
}
