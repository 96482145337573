import styled, { css } from 'styled-components'
import * as mixins from 'common/utils/mixins'

const TextUi = styled.div.attrs(p => ({
  id: p.attrId,
}))`
  font-display: swap;
  word-break: break-word;
  & a {
    &:hover {
      box-shadow: inset 0 -1px 0;
    }
  }
  & a > span {
    &:hover {
      box-shadow: inset 0 -1px 0;
    }
  }
  & ul {
    ${mixins.textUl()}
  }
  & li {
    ${mixins.textLi()}
    ${({ verticalSpacing }) =>
      verticalSpacing &&
      css`
        margin-bottom: ${verticalSpacing}px;
      `}
  }
  & li:last-of-type {
    margin-bottom: 0;
  }
  & li div {
    display: inline;
  }
  ${p => p.styles.fontSize && mixins.headingFontSize(p.styles.fontSize)}
  ${p => p.styles.fontStyle && mixins.headingFontStyle(p.styles.fontStyle)}
  ${p => p.styles.fontWeight && mixins.headingFontWeight(p.styles.fontWeight)}
  ${p => p.styles.fontFamily && mixins.headingFontFamily(p.styles.fontFamily)}
  ${p => p.linkColor && mixins.textLinkColor(p.linkColor)}
  ${p => p.textColor && mixins.textColor(p.textColor)}
  ${p => p.fontSize && mixins.textFontSize(p.fontSize)}
  ${p => p.fontStyle && mixins.textFontStyle(p.fontStyle)}
  ${p => p.lineHeight && mixins.textLineHeight(p.lineHeight)}
  ${p => p.flexBasis && mixins.textFlexBasis(p.flexBasis)}
  ${mixins.headingMargins()}
  ${p =>
    p.styles &&
    p.styles.fontFamily &&
    css`
      & li {
        font-family: ${p.styles.fontFamily};
      }
    `};
  ${({ styles = {} }) => styles}
  ${p => p.theme.phone} {
    ${p => p.mobileFontSize && mixins.headingFontSize(p.mobileFontSize)}
    ${p =>
      p.mobileStyles.fontSize &&
      mixins.headingFontSize(p.mobileStyles.fontSize)}
    ${p =>
      p.mobileStyles.fontStyle &&
      mixins.headingFontStyle(p.mobileStyles.fontStyle)}
    ${p =>
      p.mobileStyles.fontWeight &&
      mixins.headingFontWeight(p.mobileStyles.fontWeight)}
    ${p => p.mobileFontSize && mixins.textFontSize(p.mobileFontSize)}
    ${p => p.lineHeight && mixins.textLineHeight(p.lineHeight)}
      & li > i {
      ${({ mobileListItemIconColor }) =>
        mobileListItemIconColor &&
        css`
          color: ${mobileListItemIconColor} !important;
          margin-left: -25px;
          margin-right: 10px;
        `}
    }
    & li {
      ${({ mobileVerticalSpacing }) =>
        mobileVerticalSpacing
          ? css`
              margin-bottom: ${mobileVerticalSpacing}px;
            `
          : css`
              margin-bottom: 0px;
            `}
    }
    ${({ mobileStyles = {} }) => mobileStyles}
  }
`

export default TextUi
