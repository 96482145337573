import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import * as actions from 'publisher/actionsManagement'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import { dispatchPopupOpenedCustomEvent } from 'publisher/utils/popupUtils'
import BaseButton from '../BaseButton'

export const TEST_ID = 'show-popup-button'

function ShowPopupButton({ entity }) {
  const dispatch = useDispatch()
  const popupEntity = usePage(page =>
    pageSelectors.getEntityById(page, entity.options.popup),
  )

  const openPopup = e => {
    e.preventDefault()
    dispatch(actions.showPopup(entity.options.popup))
    // use small timeout in order to render popup
    if (popupEntity) {
      dispatchPopupOpenedCustomEvent(popupEntity.options.attrId)
    }
  }

  return (
    <BaseButton
      dataTestId={TEST_ID}
      handleClick={openPopup}
      borderType={entity.options.borderType}
      mobileBorderType={entity.mobileOptions.borderType}
      attrId={entity.options.attrId}
      styles={entity.styles}
      mobileStyles={entity.mobileStyles}
      text={entity.options.text}
      subText={entity.options.subText}
      subTextColor={entity.options.subTextColor}
      subTextFontSize={entity.options.subTextFontSize}
      subTextFontWeight={entity.options.subTextFontWeight}
      subTextFontStyle={entity.options.subTextFontStyle}
      subTextFontFamily={entity.options.subTextFontFamily}
      mobileSubTextColor={entity.mobileOptions.subTextColor}
      mobileSubTextFontSize={entity.mobileOptions.subTextFontSize}
      mobileSubTextFontWeight={entity.mobileOptions.subTextFontWeight}
      mobileSubTextFontStyle={entity.mobileOptions.subTextFontStyle}
      mobileSubTextFontFamily={entity.mobileOptions.subTextFontFamily}
      iconClassNameBefore={entity.options.iconClassNameBefore}
      iconClassNameAfter={entity.options.iconClassNameAfter}
      appearance={entity.options.appearance}
      hoverStyles={entity.options?.hover}
      hoverMobileStyles={entity.mobileOptions?.hover}
    />
  )
}

ShowPopupButton.propTypes = {
  entity: PropTypes.shape({
    options: PropTypes.shape({
      borderType: PropTypes.string.isRequired,
      attrId: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      popup: PropTypes.string.isRequired,
      appearance: PropTypes.shape({
        desktop: PropTypes.bool.isRequired,
        mobile: PropTypes.bool.isRequired,
      }).isRequired,
      subText: PropTypes.string,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      iconClassNameBefore: PropTypes.string,
      iconClassNameAfter: PropTypes.string,
      hover: PropTypes.shape({
        textColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        subTextColor: PropTypes.string,
        transform: PropTypes.string,
        offset: PropTypes.number,
      }),
    }),
    mobileOptions: PropTypes.shape({
      borderType: PropTypes.string.isRequired,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      hover: PropTypes.shape({
        textColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        subTextColor: PropTypes.string,
        transform: PropTypes.string,
        offset: PropTypes.number,
      }),
    }),
    mobileStyles: PropTypes.objectOf(PropTypes.string),
    styles: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default ShowPopupButton
