import styled, { css } from 'styled-components'
import { marginCss, mobileMarginCss } from 'common/utils/styleUtilsNew'
import { StyleWrapperProps } from 'publisher/components/core/StyleWrapper'

const StyleWrapperUi = styled.div<StyleWrapperProps>`
  position: relative; // position relative needs to make the same stacking context as editor
  ${p => `text-align: ${p.textAlign ? p.textAlign : 'initial'}`};
  ${p => p.flex && `display: flex`};
  ${p => p.flexColumn && `flex-direction: column`};
  ${p => p.additionalCss && p.additionalCss};
  ${p =>
    !p.appearance.mobile &&
    css`
      ${p => p.theme.phone} {
        display: none;
      }
    `}
  ${p =>
    !p.appearance.desktop &&
    css`
      ${p => p.theme.desktop} {
        display: none;
      }
    `}
  ${p => p.theme.phone} {
    ${mobileMarginCss};
    ${p => p.mobileTextAlign && `text-align: ${p.mobileTextAlign};`}
    ${p => p.mobileAdditionalCss && p.mobileAdditionalCss}
  }
  ${marginCss};
`

export default StyleWrapperUi
