import styled, { css } from 'styled-components'

type HorizontalMenuSpacerUiProps = {
  maxWidth: number
  mobileMaxWidth?: number
}
const HorizontalMenuSpacerUi = styled.div<HorizontalMenuSpacerUiProps>`
  width: 100%;
  max-width: ${p => p.maxWidth}px;
  ${p => p.theme.phone} {
    max-width: ${p => p.mobileMaxWidth}px;
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      max-width: ${p.mobileMaxWidth}px;
    `}
`

export default HorizontalMenuSpacerUi
