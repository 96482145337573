import React from 'react'
import { Translation } from 'react-i18next'
import styled from 'styled-components'
import { PaddingType } from 'common/types/styleTypes'
import { mobilePaddingsCss, paddingsCss } from 'common/utils/styleUtilsNew'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'
import { BlogPostContentPlaceholderInterface } from '../../../common/types/entities/BlogPostContentPlaceholderInterface'

const BlogPostContentPlaceholderUi = styled.div`
  color: #acc9de;
  font-size: 80px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`
type BlogPostContentPlaceholderContainerUiProps = {
  padding: PaddingType
  mobilePadding: PaddingType
}
const BlogPostContentPlaceholderContainerUi = styled.div<BlogPostContentPlaceholderContainerUiProps>`
  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
  }
  ${paddingsCss}
`

const BlogPostContentPlaceholderNew = ({
  entity,
}: {
  entity: BlogPostContentPlaceholderInterface
}) =>
  entity.childIds.length > 0 ? (
    <BlogPostContentPlaceholderContainerUi
      padding={entity.padding}
      mobilePadding={entity.mobilePadding}
    >
      <BaseNodeChildren childIds={entity.childIds} />
    </BlogPostContentPlaceholderContainerUi>
  ) : (
    <BlogPostContentPlaceholderUi id="blog-post-content-placeholder">
      <Translation>
        {t =>
          t('components.entities.blog_post_content_placeholder.placeholder')
        }
      </Translation>
    </BlogPostContentPlaceholderUi>
  )

export default BlogPostContentPlaceholderNew
