import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import CommonBody from 'common/components/entities/Body'
import * as types from 'common/types'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'
import {
  getBlogPath,
  getBlogPostCategories,
} from 'publisher/reducers/blogReducer'
import { getFile } from '../../reducers/filesReducer'

class BlogPostBody extends PureComponent {
  render() {
    const {
      backgroundFile,
      videoFile,
      blogPostCategories,
      blogPath,
      ...restProps
    } = this.props
    const { styles, mobileStyles, options = {}, childIds } = this.props.entity

    return (
      <CommonBody
        {...restProps}
        styles={styles}
        mobileStyles={mobileStyles}
        backgroundStyles={{ zIndex: -1 }}
        options={{
          ...options,
          backgroundFile,
          videoFile,
        }}
      >
        <BaseNodeChildren childIds={childIds} />
        {/* todo create separate component with own settings */}
        {/* <BlogPostCategories */}
        {/*  categories={blogPostCategories} */}
        {/*  blogPath={blogPath} */}
        {/* /> */}
      </CommonBody>
    )
  }
}

BlogPostBody.propTypes = {
  backgroundFile: PropTypes.objectOf(PropTypes.any),
  videoFile: PropTypes.objectOf(PropTypes.any),
  entity: types.entity.isRequired,
  blogPostCategories: PropTypes.arrayOf(PropTypes.string),
  blogPath: PropTypes.string,
}

BlogPostBody.defaultProps = {
  backgroundFile: null,
  videoFile: null,
  blogPostCategories: [],
  blogPath: '',
}

const mapStateToProps = ({ files, blog }, { entity: { options = {} } }) => ({
  backgroundFile: getFile(files, options.backgroundFileId),
  videoFile: getFile(files, options.videoFileId),
  blogPostCategories: getBlogPostCategories(blog),
  blogPath: getBlogPath(blog),
})

export default connect(mapStateToProps)(BlogPostBody)
