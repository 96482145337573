import PropTypes from 'prop-types'
import React from 'react'
import { backgroundPositionValues } from 'common/constants/settings'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import SectionContainerOldUi from './ui/SectionContainerOldUi'
import SectionOldUi from './ui/SectionOldUi'

const getBackgroundImage = file => (file ? `url("${file.path}")` : 'none')

const SectionOld = ({
  styles,
  mobileStyles,
  options,
  mobileOptions,
  children,
  backgroundFile,
  mobileBackgroundFile,
}) => {
  const { backgroundPosition, containerBackgroundColor, width } = options
  const { containerBackgroundColor: containerMobileBackgroundColor } =
    mobileOptions

  // const stretchStyles = options.stretch ? { height: '100%' } : {}
  const backgroundImage = getBackgroundImage(backgroundFile)
  const mobileBackgroundImage = getBackgroundImage(mobileBackgroundFile)

  return (
    <SectionOldUi
      attrId={options.attrId}
      styles={{
        ...styles,
        backgroundImage,
        ...backgroundPositionValues[backgroundPosition],
        // ...stretchStyles,
      }}
      mobileStyles={{
        ...mobileStyles,
        backgroundImage: mobileBackgroundImage,
        ...backgroundPositionValues[mobileOptions.backgroundPosition],
      }}
      id={options.attrId}
    >
      <SectionContainerOldUi
        width={width}
        backgroundColor={containerBackgroundColor}
        mobileBackgroundColor={containerMobileBackgroundColor}
      >
        {children}
      </SectionContainerOldUi>
    </SectionOldUi>
  )
}

SectionOld.propTypes = {
  children: PropTypes.node.isRequired,
  mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  options: PropTypes.shape({
    attrId: PropTypes.string,
    width: PropTypes.string,
    containerBackgroundColor: PropTypes.string,
    backgroundPosition: PropTypes.string,
  }).isRequired,
  mobileOptions: PropTypes.shape({
    containerBackgroundColor: PropTypes.string,
    backgroundPosition: PropTypes.string,
  }),
  mobileBackgroundFile: PropTypes.any,
  backgroundFile: PropTypes.any,
}

SectionOld.defaultProps = {
  options: {},
}

export default provideMobileStyles(SectionOld)
