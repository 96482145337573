import {
  LOADED_SCRIPT,
  TOGGLE_ACTIVE_POPUP,
  TOGGLE_EXPLICIT_CONSENT_CONFIRM,
} from 'publisher/actionTypes'
import { pageTypes } from 'common/constants/pageTypes'
import { getFunnelStepId } from 'publisher/reducers/managementReducer'

export const showPopup = id => ({
  type: TOGGLE_ACTIVE_POPUP,
  payload: { id },
})

export const closePopup = {
  type: TOGGLE_ACTIVE_POPUP,
  payload: { id: null },
}

export const toggleActivePopup = id => dispatch => {
  dispatch({
    type: TOGGLE_ACTIVE_POPUP,
    payload: { id },
  })
}

export const closeRemotePopup = (popupId, closedDays) => (
  dispatch,
  getState,
) => {
  const { page, management } = getState()
  const funnelStepId = getFunnelStepId(management)

  if (page.type === pageTypes.popup) {
    window.parent.postMessage(
      {
        type: `funnel_step_${funnelStepId}_popup_close`,
        expires: closedDays,
      },
      '*',
    )
  }

  toggleActivePopup(null)(dispatch)
}

export const toggleExplicitConsent = value => ({
  type: TOGGLE_EXPLICIT_CONSENT_CONFIRM,
  payload: value,
})
