import styled from 'styled-components'
import { TwitterTweetButtonInterface } from 'common/types/entities/TwitterTweetButtonInterface'

const TwitterTweetButtonUi = styled.a<
  Pick<TwitterTweetButtonInterface, 'alignSelf' | 'mobileAlignSelf'>
>`
  display: flex;
  align-items: center;
  background-color: #1b95e0;
  color: #ffffff;
  padding: 5px 8px 5px 6px;
  border-radius: 3px;
  font-size: 13px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  text-decoration: none;
  > span {
    margin-left: 4px;
  }
  &:hover {
    background-color: #0c7abf;
  }

  ${({ alignSelf }) => `align-self: ${alignSelf};`}
  ${p => p.theme.phone} {
    width: 100%;
    ${({ mobileAlignSelf }) => `align-self: ${mobileAlignSelf}`}
  }
`

export default TwitterTweetButtonUi
