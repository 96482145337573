import styled from 'styled-components'

const AttachmentsContentUi = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

export default AttachmentsContentUi
