import styled from 'styled-components'
import { PaddingType } from 'common/types/styleTypes'
import { mobilePaddingsCss, paddingsCss } from 'common/utils/styleUtilsNew'

type LatestBlogPostsCarouselUiProps = {
  padding: PaddingType
  mobilePadding?: PaddingType
}
const LatestBlogPostsCarouselUi = styled.div<LatestBlogPostsCarouselUiProps>`
  ${paddingsCss};
  ${p => p.theme.isEditorMobileMode && mobilePaddingsCss}
  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
  }
`

export default LatestBlogPostsCarouselUi
