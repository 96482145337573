import styled from 'styled-components'

const TimerUi = styled.div.attrs(p => ({
  id: p.attrId,
}))`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default TimerUi
