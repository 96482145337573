import React from 'react'
import {
  BlogPost,
  blogPostListingCategoriesInterface,
} from 'common/types/entities/BlogPostListingInterface'
import BlogPostCategoriesUi from './ui/BlogPostCategoriesUi'
import BlogPostCategoryLinkUi from './ui/BlogPostCategoryLinkUi'

type BlogPostCategoriesProps = {
  post: BlogPost
  blogPath?: string
  isCategoryPage: boolean
} & blogPostListingCategoriesInterface

function BlogPostCategories({
  post,
  blogPath,
  categoriesFontSize,
  mobileCategoriesFontSize,
  categoriesFontFamily,
  categoriesFontWeight,
  categoriesFontStyle,
  mobileCategoriesFontFamily,
  mobileCategoriesFontWeight,
  mobileCategoriesFontStyle,
  categoriesAlign,
  mobileCategoriesAlign,
  categoriesColor,
  mobileCategoriesColor,
  isCategoryPage,
  categoriesMargin,
  mobileCategoriesMargin,
}: BlogPostCategoriesProps) {
  return (
    <BlogPostCategoriesUi
      key={post.path}
      fontSize={categoriesFontSize}
      color={categoriesColor}
      fontFamily={categoriesFontFamily}
      fontWeight={categoriesFontWeight}
      fontStyle={categoriesFontStyle}
      textAlign={categoriesAlign}
      mobileColor={mobileCategoriesColor}
      mobileFontSize={mobileCategoriesFontSize}
      mobileFontFamily={mobileCategoriesFontFamily}
      mobileFontWeight={mobileCategoriesFontWeight}
      mobileFontStyle={mobileCategoriesFontStyle}
      mobileTextAlign={mobileCategoriesAlign}
      margin={categoriesMargin}
      mobileMargin={mobileCategoriesMargin}
    >
      {post.categories.map((category, index, arr) => (
        <>
          <BlogPostCategoryLinkUi
            key={category.path}
            href={
              isCategoryPage
                ? post.path
                : `${blogPath}/category/${category.path}`
            }
            color={categoriesColor}
            mobileColor={mobileCategoriesColor}
          >
            {category.name}
          </BlogPostCategoryLinkUi>
          {arr.length > 1 && index + 1 !== arr.length && ' | '}
        </>
      ))}
    </BlogPostCategoriesUi>
  )
}

export default BlogPostCategories
