import styled, { css } from 'styled-components'

type PictureUiProps = {
  ratio?: number
}

const PictureUi = styled.picture<PictureUiProps>`
  position: relative;
  ${p =>
    p.ratio
      ? css`
          display: block;
          //position: relative; Why it commented: it increases the z-index layer. In general it used to make absolute image work properly but we don't use absolute image.
          height: 0;
          overflow: visible;
          padding-bottom: ${(100 / p.ratio).toFixed(2)}%;
        `
      : css`
          display: contents;
        `}
`

export default PictureUi
