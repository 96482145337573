import React from 'react'
import { SurveyAnswer } from '../../../types/entities/SurveyInterface'
import { BorderType } from '../../../types/styleTypes'
import SurveyAnswerRadioButtonUi from './ui/SurveyAnswerRadioButtonUi'
import SurveyTextAreaUi from './ui/SurveyTextAreaUi'
import SurveyTextAreaWrapperUi from './ui/SurveyTextAriaWrapper'

type SurveyAnswerProps = {
  answer: SurveyAnswer
  fontSize: number
  mobileFontSize?: number
  color: string
  select: () => void
  backgroundColor: string
  outlineColor: string
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  setOpenAnswer: (value: React.SetStateAction<string>) => void
  isEdit?: boolean
  openAnswer: string
}

function SurveyTextArea({
  answer,
  fontSize,
  mobileFontSize,
  color,
  select,
  backgroundColor,
  outlineColor,
  border,
  mobileBorder,
  setOpenAnswer,
  isEdit,
  openAnswer,
}: SurveyAnswerProps) {
  return (
    <SurveyTextAreaWrapperUi>
      <SurveyTextAreaUi
        readOnly={isEdit}
        isEdit={isEdit}
        fontSize={fontSize}
        mobileFontSize={mobileFontSize}
        color={color}
        backgroundColor={backgroundColor}
        borderColor={outlineColor}
        border={border}
        mobileBorder={mobileBorder}
        placeholder={answer.title}
        onChange={e => setOpenAnswer(e.target.value)}
      />
      {openAnswer && (
        <SurveyAnswerRadioButtonUi
          isTextArea
          className="answer-radio"
          outlineColor={outlineColor}
          onClick={select}
        />
      )}
    </SurveyTextAreaWrapperUi>
  )
}

export default SurveyTextArea
