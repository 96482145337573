import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonBlogPostTitle from 'common/components/entities/BlogPostTitle/BlogPostTItleNew'
import { BlogPostTitleInterface } from 'common/types/entities/BlogPostTitleInterface'
import useBlog, { selectors as blogSelectors } from '../../../hooks/useBlog'

function BlogPostTitleNew({ entity }: { entity: BlogPostTitleInterface }) {
  const blogPostTitle = useBlog(blogSelectors.getBlogPostTitle)

  return (
    <StyleWrapper
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonBlogPostTitle
        title={blogPostTitle}
        attrId={entity.htmlAttrId}
        fontSize={entity.fontSize}
        fontFamily={entity.fontFamily}
        fontStyle={entity.fontStyle}
        fontWeight={entity.fontWeight}
        mobileFontSize={entity.mobileFontSize}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontWeight={entity.mobileFontWeight}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        color={entity.color}
        mobileColor={entity.mobileColor}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
      />
    </StyleWrapper>
  )
}

export default BlogPostTitleNew
