import React, { useEffect, useState } from 'react'
import CommonTwitterTweetButtonOld from 'common/components/entities/TwitterTweetButtonOld'
import { wrapperStyles } from 'common/components/entities/Button'
import StyleWrapper from 'common/components/StyleWrapper'
import { splitMarginsAndRestStyles } from 'common/utils/styleUtils'
import * as types from 'common/types'
import { isValidUrl } from 'common/utils/urlUtils'
import useManagement, {
  selectors as managementSelectors,
} from '../../hooks/useManagement'

function TwitterTweetButtonOld({ entity }) {
  const affiliateId = useManagement(managementSelectors.getAffiliateId)
  const [textWithAffiliateId, setTextWithAffiliateId] = useState(
    entity.options.text,
  )
  const [margins, restStyles] = splitMarginsAndRestStyles(entity.styles)
  const [mobileMargins, mobileRestStyles] = splitMarginsAndRestStyles(
    entity.mobileStyles,
  )

  useEffect(() => {
    if (affiliateId) {
      const textParts = entity.options.text
        ? entity.options.text.split(' ')
        : []
      const text = textParts
        .map(textPart => {
          return isValidUrl(textPart)
            ? `${textPart}?sa=${affiliateId}`
            : textPart
        })
        .join(' ')

      setTextWithAffiliateId(text)
    }
  }, [affiliateId])

  return (
    <StyleWrapper
      styles={{ ...margins, ...wrapperStyles }}
      mobileStyles={mobileMargins}
      desktop={entity.options.appearance.desktop}
      mobile={entity.options.appearance.mobile}
    >
      <CommonTwitterTweetButtonOld
        text={textWithAffiliateId}
        styles={restStyles}
        mobileStyles={mobileRestStyles}
      />
    </StyleWrapper>
  )
}

TwitterTweetButtonOld.propTypes = {
  entity: types.entity.isRequired,
}

export default TwitterTweetButtonOld