import styled from 'styled-components'

const CarouselSliderUi = styled.div<{ disableOverflow: boolean }>`
  display: flex;
  flex-direction: row;
  overflow-x: ${p =>
    p.disableOverflow
      ? 'visible'
      : 'scroll'}; // in the editor we need visible to show entity actions
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  //for firefox
  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 0;
  }
`

export default CarouselSliderUi
