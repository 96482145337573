import styled from 'styled-components'

const CarouselNavigationItemsUi = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
`

export default CarouselNavigationItemsUi
