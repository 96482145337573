export const splitMarginsAndRestStyles = styles => {
  const { marginTop, marginLeft, marginBottom, marginRight, ...restStyles } =
    styles
  return [
    {
      marginTop,
      marginLeft,
      marginBottom,
      marginRight,
    },
    restStyles,
  ]
}

export const splitMarginsAndPaddingAndRestStyles = styles => {
  const {
    marginTop,
    marginLeft,
    marginBottom,
    marginRight,
    padding,
    ...restStyles
  } = styles
  return [
    {
      marginTop,
      marginLeft,
      marginBottom,
      marginRight,
      padding,
    },
    restStyles,
  ]
}
