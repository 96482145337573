import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonContentBox from 'common/components/entities/ContentBox/ContentBoxOld'
import * as types from 'common/types'
import provideDelay from '../hoc/provideDelay'
import BaseNodeChildren from './ChildrenEntities'

const ContentBoxOld = ({ entity }) => {
  const {
    options: { appearance = {}, ...restOptions },
    styles,
    mobileOptions,
    mobileStyles,
    childIds,
  } = entity
  const { marginLeft, marginRight, ...restStyles } = styles
  const { marginLeft: mobileMarginLeft, ...restMobileStyles } = mobileStyles
  const { mobile = true, desktop = true } = appearance

  return (
    <StyleWrapper
      position="relative"
      styles={{ marginLeft, marginRight }}
      mobileStyles={{
        marginLeft: mobileStyles.marginLeft,
        marginRight: mobileStyles.marginRight,
      }}
      mobile={mobile}
      desktop={desktop}
    >
      <CommonContentBox
        options={restOptions}
        mobileStyles={restMobileStyles}
        mobileOptions={mobileOptions}
        styles={restStyles}
      >
        <BaseNodeChildren childIds={childIds} />
      </CommonContentBox>
    </StyleWrapper>
  )
}

ContentBoxOld.propTypes = {
  entity: types.entity.isRequired,
}

export default provideDelay(ContentBoxOld)
