/* eslint-disable react/prop-types */
import React from 'react'
import { CSSProp } from 'styled-components'
import { DialCodePreviewUi } from './ui/DialCodePreviewUi'

export interface DialCodePreviewStyleProps {
  style?: CSSProp
  className?: string
}

export interface DialCodePreviewProps extends DialCodePreviewStyleProps {
  dialCode: string
  prefix: string
  disabled?: boolean
}

export const DialCodePreview: React.FC<DialCodePreviewProps> = ({
  dialCode,
  prefix,
  disabled,
  style,
  className,
}) => {
  return (
    <DialCodePreviewUi
      className={className}
      disabled={disabled}
      styles={style}
    >{`${prefix}${dialCode}`}</DialCodePreviewUi>
  )
}
