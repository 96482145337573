import React from 'react'
import { BorderType } from 'common/types/styleTypes'
import ImageBackgroundUi from '../ImageNew/ui/ImageBackgroundUi'
import BlogPostImageUi from './ui/BlogPostImageUi'

type BlogPostImageProps = {
  src: string
  border: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  boxShadow?: string
  mobileBoxShadow?: string
  background?: string
  mobileBackground?: string
}

function BlogPostImage({
  src,
  border,
  mobileBorder,
  boxShadow,
  mobileBoxShadow,
  background,
  mobileBackground,
}: BlogPostImageProps) {
  const isBackgroundSet = background || mobileBackground
  return (
    <div>
      {isBackgroundSet ? (
        <>
          <BlogPostImageUi
            borderRadius={border.radius}
            mobileBorderRadius={mobileBorder?.radius}
            boxShadow={boxShadow}
            mobileBoxShadow={mobileBoxShadow}
            src={src}
          />
          <ImageBackgroundUi
            border={border}
            background={background}
            mobileBackground={mobileBackground}
          />
        </>
      ) : (
        <>
          <BlogPostImageUi
            border={border}
            mobileBorder={mobileBorder}
            boxShadow={boxShadow}
            mobileBoxShadow={mobileBoxShadow}
            src={src}
          />
        </>
      )}
    </div>
  )
}

export default BlogPostImage
