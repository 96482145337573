import styled, { CSSProp } from 'styled-components'
import { FlagEmoji } from '../../FlagEmoji/FlagEmoji'

export const DropDownFlagEmojiUi = styled(FlagEmoji)<{
  styles?: CSSProp
}>`
  margin-right: 0.6em;

  ${({ styles }) => styles}
`
