import styled, { css } from 'styled-components'
import { AlignType, PaddingType } from 'common/types/styleTypes'
import {
  fontCss,
  FontProps,
  lineHeightCss,
  mobileFontCss,
  mobileLineHeightCss,
} from 'common/utils/styleUtilsNew'

type BlogPostCategoriesUiNewProps = {
  lineHeight?: number
  mobileLineHeight?: number
  backgroundColor?: string
  mobileBackgroundColor?: string
  alignSelf: AlignType
  mobileAlignSelf?: AlignType
  padding?: PaddingType
  mobilePadding?: PaddingType
} & FontProps

const BlogPostCategoriesUiNew = styled.div<BlogPostCategoriesUiNewProps>`
  text-align: ${p => p.alignSelf};
  ${lineHeightCss}
  > a {
    ${fontCss}
  }
  ${p => p.theme.phone} {
    text-align: ${p => p.mobileAlignSelf || 'center'};
    > a {
      ${mobileFontCss}
    }
    ${mobileLineHeightCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      > a {
        ${mobileFontCss}
      }
      ${mobileLineHeightCss}
      text-align: ${p.mobileAlignSelf || 'center'};
    `}
`

export default BlogPostCategoriesUiNew
