import { BlogPost } from 'common/types/entities/BlogPostListingInterface'

const DEFAULT_POSTS_SIZE = 6

export const clientImagePlaceholderSrc =
  '/assets/images/blog_post_list_image_placeholder.jpg'
export const publisherImagePlaceholderSrc = `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/blog_post_list_image_placeholder.jpg`

export const clientBlogPostTs = Date.now() / 1000
export const publisherBlogPostTs = Date.now()

export const rangePosts = (
  number = DEFAULT_POSTS_SIZE,
  blogPostTs = Date.now(),
  imageSrc: string,
) =>
  [...Array(number).keys()].reduce(
    (prev, id) => [
      ...prev,
      {
        id,
        name: 'Lorem ipsum',
        path: 'lorem',
        dateTs: blogPostTs,
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        image: imageSrc,
        categories: [
          {
            name: 'Lorem',
            path: 'lorem',
          },
        ],
      },
    ],
    [] as BlogPost[],
  )
