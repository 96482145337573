import { pageTypes } from 'common/constants/pageTypes'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import CommonNoIndexPage from 'publisher/pages/CommonNoIndexPage'
import CommonPage from 'publisher/pages/CommonPage'
import InlineWrapper from 'publisher/pages/InlineForm'
import PopupForm from 'publisher/pages/PopupForm'
import WebinarSessionPage from 'publisher/pages/WebinarSessionPage'
import WebinarThankYouPage from 'publisher/pages/WebinarThankYouPage'
import CommonBlogPage from 'publisher/pages/blog'
import BlogPage from 'publisher/pages/blog-page/BlogPage'
import LecturePage from '../../pages/LecturePage'

export default function createPage(type) {
  switch (type) {
    case pageTypes.webinarSession:
      return WebinarSessionPage
    case pageTypes.webinarThankYou:
      return WebinarThankYouPage
    case pageTypes.inline:
      return InlineWrapper
    case pageTypes.lecture:
      return LecturePage
    case pageTypes.popup:
      return PopupForm
    case pageTypes.blogPostBody:
      return CommonBlogPage
    case pageTypes.blogStatic:
    case pageTypes.blogHome:
      return BlogPage
    case pageTypes.squeeze:
    case pageTypes.webinarRegistration:
    case pageTypes.infoPage:
    case pageTypes.blogPostLayout: // post layout save
    case pageTypes.blogLayout: // blog layout save
    case PageTypeEnum.FunnelContactUs:
    case pageTypes.optInThankYou:
    case PageTypeEnum.LinkInBio:
      return CommonPage
    case pageTypes.inactiveFunnelPage:
    case pageTypes.courseIntroduction:
    case PageTypeEnum.NotFound:
      return CommonNoIndexPage
    default:
      Rollbar.error(`unknown page type ${type}`)
      return null
  }
}
