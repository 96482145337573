import React from 'react'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import CommonRecaptcha from 'publisher/components/core/CommonRecaptcha'
import useManagement from 'publisher/hooks/useManagement'
import { selectors as managementSelectors } from 'publisher/reducers/managementReducer'
import {
  ContextFieldsInterface,
  getContactUsError,
  useShowContactUsSuccess,
} from './ContactUs'

type RecaptchaProps = {
  entity: RecaptchaInterface
}

function Recaptcha({ entity }: RecaptchaProps) {
  const isPreview = useManagement(managementSelectors.isPreviewMode)
  const { setIsDisabled, errors, setFields } = useShowContactUsSuccess()
  const contactUsError = getContactUsError(
    errors,
    entity.slug as string,
    isPreview,
  )

  function onSubmit(token: string) {
    setFields((prev: ContextFieldsInterface) => {
      return {
        ...prev,
        [FieldSlugEnum.Recaptcha]: token,
      }
    })
    setIsDisabled(false)
  }

  return (
    <CommonRecaptcha
      entity={entity}
      errors={contactUsError}
      onSubmit={onSubmit}
    />
  )
}

export default Recaptcha
