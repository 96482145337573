import React from 'react'
import RawHtmlUi from './ui/RawHtmlUi'
import { AlignType } from '../../../types/styleTypes'

interface CommonRawHtmlInterfaceProps {
  textAlign: AlignType
  mobileTextAlign?: AlignType
  html?: string
  htmlAttrId: string
}

const RawHtml = ({
  html,
  htmlAttrId,
  textAlign,
  mobileTextAlign,
}: CommonRawHtmlInterfaceProps) => (
  <RawHtmlUi
    textAlign={textAlign}
    mobileTextAlign={mobileTextAlign}
    attrId={htmlAttrId}
    dangerouslySetInnerHTML={{ __html: html ?? '' }}
  />
)

export default RawHtml
