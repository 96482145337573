import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import { getCounterEndTimeCookie } from 'publisher/utils/cookie'

const getEndTime = ({
  deadlineTime,
  postfix,
  pageId,
  entity,
}: {
  entity: CountdownInterface
  pageId: number
  postfix: number | null
  deadlineTime: number | null
}) => {
  switch (entity.countdownType) {
    case CountdownTypesEnum.daily:
    case CountdownTypesEnum.delay: {
      const cookieTime = Number.parseInt(
        getCounterEndTimeCookie(pageId, postfix),
      )
      // it may be null if not found
      if (Number.isInteger(cookieTime)) {
        return cookieTime
      }
      return null
    }
    case CountdownTypesEnum.deadline: {
      return deadlineTime
    }
    case CountdownTypesEnum.fixed: {
      return new Date(entity[CountdownTypesEnum.fixed].date).getTime()
    }
  }
}

export const wasCompletedForCurrentUser = ({
  entity,
  postfix,
  pageId,
  deadlineTime,
}: {
  entity: CountdownInterface
  pageId: number
  postfix: number | null
  deadlineTime: number | null
}) => {
  const endTime = getEndTime({ deadlineTime, postfix, pageId, entity })

  return endTime ? endTime < Date.now() : false
}
