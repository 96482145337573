import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import {
  backgroundColorCss,
  mobileBackgroundColorCss,
} from '../../../../utils/styleUtilsNew'

const beforeAfter = css`
  position: absolute;
  left: 14px;
  content: ' ';
  height: 30px;
  width: 2px;
  background-color: #333;
  box-shadow: 0px 0px 1px #fff;
`

const CloseButtonUi = styled.a.attrs(() => ({
  'data-testid': 'popup-close-icon',
}))`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  z-index: 1;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.7);
  transition: opacity 250ms ease-in-out;
  &:hover {
    opacity: 0.5;
  }
  &:before {
    ${beforeAfter};
    ${backgroundColorCss};
    ${mobileBackgroundColorCss};

    transform: rotate(45deg);
  }
  &:after {
    ${beforeAfter};
    transform: rotate(-45deg);
    ${backgroundColorCss}
    ${mobileBackgroundColorCss};
  }
`

const PopupClose = ({ close, color }) => {
  return (
    <CloseButtonUi
      backgroundColor={color}
      href="#"
      onClick={e => {
        e.preventDefault()
        close()
      }}
    />
  )
}

PopupClose.propTypes = {
  close: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
}

export default PopupClose
