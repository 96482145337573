import React from 'react'
import { BlogPost } from 'common/types/entities/BlogPostListingInterface'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { BlogPostCardProps } from '.'
import BlogPostCard from './BlogPostCard'
import LayoutGridColumnUi from './ui/LayoutGridColumnUi'
import LayoutGridUi from './ui/LayoutGridUi'

export enum RowsAlignment {
  doubleLongShort = 'doubleLongShort',
  default = 'default',
  shortDoubleLong = 'shortDoubleLong',
}

type TwoThreePostsPerRowLayoutProps = {
  blogPostCardProps: BlogPostCardProps
  posts: BlogPost[]
  alignment: RowsAlignment
}

function TwoThreePostsPerRowLayout({
  posts,
  blogPostCardProps,
  alignment,
}: TwoThreePostsPerRowLayoutProps) {
  return (
    <>
      {alignment === RowsAlignment.doubleLongShort ? (
        <OneLongOneShortRowRenderer
          posts={posts}
          blogPostCardProps={blogPostCardProps}
        />
      ) : alignment === RowsAlignment.shortDoubleLong ? (
        <OneShortOneLongRowRenderer
          posts={posts}
          blogPostCardProps={blogPostCardProps}
        />
      ) : (
        <LayoutGridUi gridTemplateColumns="1fr 1fr 1fr">
          {posts.map((post: BlogPost, index: number) => (
            <BlogPostCard
              key={index}
              post={post}
              {...blogPostCardProps}
              imagePlacement={ImagePlacementVariants.Background}
            />
          ))}
        </LayoutGridUi>
      )}
    </>
  )
}

export default TwoThreePostsPerRowLayout

function OneLongOneShortRowRenderer({
  posts,
  blogPostCardProps,
}: {
  posts: BlogPost[]
  blogPostCardProps: BlogPostCardProps
}) {
  return (
    <LayoutGridUi gridTemplateColumns="1fr 1fr 1fr">
      {posts.map((post: BlogPost, index: number) => (
        <>
          {index === 0 ? (
            <LayoutGridColumnUi columnStart={1} columnEnd={3}>
              <BlogPostCard
                key={index}
                post={post}
                {...blogPostCardProps}
                imagePlacement={ImagePlacementVariants.Background}
              />
            </LayoutGridColumnUi>
          ) : (
            <LayoutGridColumnUi columnStart={3} columnEnd={4}>
              <BlogPostCard
                key={index}
                post={post}
                {...blogPostCardProps}
                imagePlacement={ImagePlacementVariants.Background}
              />
            </LayoutGridColumnUi>
          )}
        </>
      ))}
    </LayoutGridUi>
  )
}

function OneShortOneLongRowRenderer({
  posts,
  blogPostCardProps,
}: {
  posts: BlogPost[]
  blogPostCardProps: BlogPostCardProps
}) {
  return (
    <LayoutGridUi gridTemplateColumns="1fr 1fr 1fr">
      {posts.map((post: BlogPost, index: number) => (
        <>
          {index === 0 ? (
            <LayoutGridColumnUi columnStart={1} columnEnd={2}>
              <BlogPostCard
                key={index}
                post={post}
                {...blogPostCardProps}
                imagePlacement={ImagePlacementVariants.Background}
              />
            </LayoutGridColumnUi>
          ) : (
            <LayoutGridColumnUi columnStart={2} columnEnd={4}>
              <BlogPostCard
                key={index}
                post={post}
                {...blogPostCardProps}
                imagePlacement={ImagePlacementVariants.Background}
              />
            </LayoutGridColumnUi>
          )}
        </>
      ))}
    </LayoutGridUi>
  )
}
