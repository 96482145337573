import styled from 'styled-components'

const ImageWrapperUi = styled.div.attrs(p => ({
  id: p.attrId,
}))`
  ${({ styles = {} }) => styles}
  ${p => p.theme.phone} {
    // display: flex; // justify-content could be passed
    // width: 100% !important; // disable overflowing
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${p => p.theme.isEditorMobileMode && p.mobileStyles}
`

export default ImageWrapperUi
