import CloseIcon from 'common/icons/CloseIcon'
import styled, { CSSProp } from 'styled-components'

export const DropDownClearInputIconUi = styled(CloseIcon)<{
  styles?: CSSProp
}>`
  position: absolute;
  top: 0.6em;
  right: 0.6em;

  cursor: pointer;
  
  ${({ styles }) => styles}
`
