import React from 'react'
import { AlignType } from '../../../types/styleTypes'
import WebinarSessionLinkUiNew from './ui/WebinarSessionLinkUiNew'
import WrapperWebinarSessionLinkUi from './ui/WrapperWrapperWebinarSessionLinkUi'

type WebinarSessionLinkNewProps = {
  redirectUrl?: string
  color: string
  mobileColor?: string
  fontSize: number
  mobileFontSize?: number
  htmlAttrId: string
  text?: string
  textAlign?: AlignType
  mobileTextAlign?: AlignType
}

const WebinarSessionLinkNew = ({
  htmlAttrId,
  redirectUrl,
  color,
  mobileColor,
  fontSize,
  mobileFontSize,
  text = '',
  textAlign,
  mobileTextAlign,
}: WebinarSessionLinkNewProps) => (
  <WrapperWebinarSessionLinkUi
    textAlign={textAlign}
    mobileTextAlign={mobileTextAlign}
  >
    <WebinarSessionLinkUiNew
      id={htmlAttrId}
      href={redirectUrl}
      color={color}
      mobileColor={mobileColor}
      fontSize={fontSize}
      mobileFontSize={mobileFontSize}
    >
      {text ? text : redirectUrl}
    </WebinarSessionLinkUiNew>
  </WrapperWebinarSessionLinkUi>
)

export default WebinarSessionLinkNew
