import styled, { css } from 'styled-components'
import {
  borderCss,
  borderRadiusCss,
  boxShadowCss,
  mobileBorderCss,
  mobileBorderRadiusCss,
  mobileBoxShadowCss,
} from 'common/utils/styleUtilsNew'
import {
  BorderRadiusType,
  BorderType,
  FullBorderRadiusType,
} from '../../../../types/styleTypes'

type ImageUiProps = {
  blur?: number
  width?: number | string
  mobileWidth?: number | string
  boxShadow?: string
  mobileBoxShadow?: string
  border?: Partial<BorderType>
  borderRadius?: BorderRadiusType | FullBorderRadiusType
  mobileBorderRadius?: BorderRadiusType | FullBorderRadiusType
  mobileBorder?: Partial<BorderType>
  fullHeight: boolean
  pointer: boolean
}

const ImageUi = styled.img<ImageUiProps>`
  display: block; // fixes https://stackoverflow.com/questions/5804256/image-inside-div-has-extra-space-below-the-image
  max-width: 100%;
  object-fit: contain; // keep proportions
  width: ${p => (typeof p.width === 'string' ? p.width : `${p.width}px`)};
  ${p => p.pointer && `cursor: pointer;`}
  ${p => !!p.blur && `filter: blur(${p.blur}px);`}
  ${p => p.fullHeight && `height: 100%;`}
  ${borderRadiusCss};
  ${borderCss};
  ${boxShadowCss};
  ${p => p.theme.phone} {
    ${mobileBorderRadiusCss};
    ${mobileBorderCss}
    ${mobileBoxShadowCss}
    ${p =>
      p.mobileWidth &&
      `width: ${
        typeof p.mobileWidth === 'string' ? p.mobileWidth : `${p.mobileWidth}px`
      }`}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileBorderRadiusCss};
      ${mobileBorderCss}
      ${mobileBoxShadowCss}
      ${p.mobileWidth &&
      `width: ${
        typeof p.mobileWidth === 'string' ? p.mobileWidth : `${p.mobileWidth}px`
      }`}
    `}
`

export default ImageUi
