import React from 'react'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import { BlogPost } from 'common/types/entities/BlogPostListingInterface'
import { CommonBlogLatestPostsProps } from 'common/types/entities/LatestBlogPostsInterface'
import BlogPosts from '../BlogPostsNew'
import BlogLatestPostsUiNew from './ui/BlogLatestPostsUiNew'

type BlogPostListingsProps = {
  blogPath?: string
  htmlAttrId: string
  posts: BlogPost[]
  locale: PageLocaleEnum
  maxPosts: number
} & CommonBlogLatestPostsProps

function LatestBlogPostsNew(props: BlogPostListingsProps) {
  const allowedPosts = props.posts.filter(
    (post, index) => index < +props.maxPosts,
  )

  return (
    <BlogLatestPostsUiNew
      id={props.htmlAttrId}
      padding={props.padding}
      mobilePadding={props.mobilePadding}
    >
      <BlogPosts
        displayHorizontally={props.displayHorizontally}
        showDate={props.showDate}
        imageMargin={props.imageMargin}
        mobileImageMargin={props.mobileImageMargin}
        imageBorder={props.imageBorder}
        mobileImageBorder={props.mobileImageBorder}
        imageBoxShadow={props.imageBoxShadow}
        mobileImageBoxShadow={props.mobileImageBoxShadow}
        titleMargin={props.titleMargin}
        mobileTitleMargin={props.mobileTitleMargin}
        titleFontSize={props.titleFontSize}
        mobileTitleFontSize={props.mobileTitleFontSize}
        titleLineHeight={props.titleLineHeight}
        mobileTitleLineHeight={props.mobileTitleLineHeight}
        titleFontFamily={props.titleFontFamily}
        titleFontWeight={props.titleFontWeight}
        titleFontStyle={props.titleFontStyle}
        mobileTitleFontFamily={props.mobileTitleFontFamily}
        mobileTitleFontWeight={props.mobileTitleFontWeight}
        mobileTitleFontStyle={props.mobileTitleFontStyle}
        titleLetterSpacing={props.titleLetterSpacing}
        mobileTitleLetterSpacing={props.mobileTitleLetterSpacing}
        titleAlign={props.titleAlign}
        mobileTitleAlign={props.mobileTitleAlign}
        titleColor={props.titleColor}
        mobileTitleColor={props.mobileTitleColor}
        descriptionMargin={props.descriptionMargin}
        mobileDescriptionMargin={props.mobileDescriptionMargin}
        descriptionFontSize={props.descriptionFontSize}
        mobileDescriptionFontSize={props.mobileDescriptionFontSize}
        descriptionLineHeight={props.descriptionLineHeight}
        mobileDescriptionLineHeight={props.mobileDescriptionLineHeight}
        descriptionFontFamily={props.descriptionFontFamily}
        descriptionFontWeight={props.descriptionFontWeight}
        descriptionFontStyle={props.descriptionFontStyle}
        mobileDescriptionFontFamily={props.mobileDescriptionFontFamily}
        mobileDescriptionFontWeight={props.mobileDescriptionFontWeight}
        mobileDescriptionFontStyle={props.mobileDescriptionFontStyle}
        descriptionAlign={props.descriptionAlign}
        mobileDescriptionAlign={props.mobileDescriptionAlign}
        descriptionColor={props.descriptionColor}
        mobileDescriptionColor={props.mobileDescriptionColor}
        categoriesMargin={props.categoriesMargin}
        mobileCategoriesMargin={props.mobileCategoriesMargin}
        categoriesFontSize={props.categoriesFontSize}
        mobileCategoriesFontSize={props.mobileCategoriesFontSize}
        categoriesFontFamily={props.categoriesFontFamily}
        categoriesFontWeight={props.categoriesFontWeight}
        categoriesFontStyle={props.categoriesFontStyle}
        mobileCategoriesFontFamily={props.mobileCategoriesFontFamily}
        mobileCategoriesFontWeight={props.mobileCategoriesFontWeight}
        mobileCategoriesFontStyle={props.mobileCategoriesFontStyle}
        categoriesAlign={props.categoriesAlign}
        mobileCategoriesAlign={props.mobileCategoriesAlign}
        categoriesColor={props.categoriesColor}
        mobileCategoriesColor={props.mobileCategoriesColor}
        dateMargin={props.dateMargin}
        mobileDateMargin={props.mobileDateMargin}
        dateFontSize={props.dateFontSize}
        mobileDateFontSize={props.mobileDateFontSize}
        dateFontFamily={props.dateFontFamily}
        dateFontWeight={props.dateFontWeight}
        dateFontStyle={props.dateFontStyle}
        mobileDateFontFamily={props.mobileDateFontFamily}
        mobileDateFontWeight={props.mobileDateFontWeight}
        mobileDateFontStyle={props.mobileDateFontStyle}
        dateAlign={props.dateAlign}
        mobileDateAlign={props.mobileDateAlign}
        dateColor={props.dateColor}
        mobileDateColor={props.mobileDateColor}
        posts={allowedPosts}
        rowSize={props.maxPostsPerRow}
        blogPath={props.blogPath}
        locale={props.locale}
        rowsGap={props.rowsGap}
        mobileRowsGap={props.mobileRowsGap}
        columnsGap={props.columnsGap}
      />
    </BlogLatestPostsUiNew>
  )
}

export default LatestBlogPostsNew
