import styled from 'styled-components'

type ContentItemWrapperUiProps = {
  marginLeft: number
  marginBottom?: number
}

const ContentItemWrapperUi = styled.p<ContentItemWrapperUiProps>`
  ${p => p.marginBottom && `margin-bottom: ${p.marginBottom}px;`}
  margin-left: ${p => p.marginLeft}px;
`

export default ContentItemWrapperUi
