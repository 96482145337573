import styled from 'styled-components'

const SurveyQuestionSubTitleUi = styled.div`
  font-size: ${p => p.fontSize};
  color: ${p => p.color};
  text-align: center;
  margin-top: 20px;
  margin-bottom: 25px;
  ${p => p.theme.phone} {
    font-size: ${p => p.mobileFontSize};
  }
`

export default SurveyQuestionSubTitleUi
