import styled from 'styled-components'

const ImageUi = styled.img`
  display: block; // fixes https://stackoverflow.com/questions/5804256/image-inside-div-has-extra-space-below-the-image
  width: 100%;
  max-width: 100%;
  object-fit: contain; // keep proportions
  // height: 100%; // real mobile phones display fix (chrome emulation worked well)
  ${p => p.styles}
  ${p => p.height100 && `height: 100%;`}
  //height: min-intrinsic; // real mobile phones display fix (chrome emulation worked well)
  ${p => p.theme.phone} {
    ${p => ({
      ...p.mobileStyles,
      ...(p.mobileStyles.width ? { width: p.mobileStyles.width } : {}),
    })}
  }
  ${p =>
    p.theme.isEditorMobileMode && {
      ...p.mobileStyles,
      ...(p.mobileStyles.width ? { width: p.mobileStyles.width } : {}),
    }}
  ${p => p.filter && `filter:${p.filter}`}
`

export default ImageUi
