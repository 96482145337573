import React from 'react'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import {
  BlogPost,
  blogPostListingCategoriesInterface,
  blogPostListingDateInterface,
  blogPostListingDescriptionInterface,
  blogPostListingImageInterface,
  blogPostListingTitleInterface,
} from 'common/types/entities/BlogPostListingInterface'
import { ImagePlacementVariants } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import BlogPostCategories from './BlogPostCategories'
import BlogPostImage from './BlogPostImage'
import BlogPostsDates from './BlogPostsDates'
import BlogPostsDescriptions from './BlogPostsDescriptions'
import BlogPostsTitles from './BlogPostsTitles'
import BlogPostContentContainerUi from './ui/BlogPostContentContainerUi'
import BlogPostsPostItemRowUi from './ui/BlogPostsPostItemRowUi'
import CarouselBlogPostsRowUi from './ui/CarouselBlogPostsRowUi'

const ROW_SIZE = 1

type CarouselBlogPostsProps = {
  blogPath?: string
  posts: BlogPost[][]
  rowSize?: number
  columnsGap: number
  locale: PageLocaleEnum
  showDate: boolean
  imagePlacement: ImagePlacementVariants
  mobileImagePlacement?: ImagePlacementVariants
  postBackgroundColor?: string
  mobilePostBackgroundColor?: string
} & blogPostListingImageInterface &
  blogPostListingTitleInterface &
  blogPostListingDescriptionInterface &
  blogPostListingCategoriesInterface &
  blogPostListingDateInterface

function CarouselBlogPosts({
  blogPath,
  posts,
  rowSize = ROW_SIZE,
  locale,
  imageBorder,
  mobileImageBorder,
  imageBoxShadow,
  mobileImageBoxShadow,
  titleFontSize,
  mobileTitleFontSize,
  titleLineHeight,
  mobileTitleLineHeight,
  titleFontFamily,
  titleFontWeight,
  titleFontStyle,
  mobileTitleFontFamily,
  mobileTitleFontWeight,
  mobileTitleFontStyle,
  titleLetterSpacing,
  mobileTitleLetterSpacing,
  titleAlign,
  mobileTitleAlign,
  titleColor,
  mobileTitleColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionFontFamily,
  descriptionFontWeight,
  descriptionFontStyle,
  mobileDescriptionFontFamily,
  mobileDescriptionFontWeight,
  mobileDescriptionFontStyle,
  descriptionAlign,
  mobileDescriptionAlign,
  descriptionColor,
  mobileDescriptionColor,
  categoriesFontSize,
  mobileCategoriesFontSize,
  categoriesFontFamily,
  categoriesFontWeight,
  categoriesFontStyle,
  mobileCategoriesFontFamily,
  mobileCategoriesFontWeight,
  mobileCategoriesFontStyle,
  categoriesAlign,
  mobileCategoriesAlign,
  categoriesColor,
  mobileCategoriesColor,
  showDate,
  dateFontSize,
  mobileDateFontSize,
  dateFontFamily,
  dateFontWeight,
  dateFontStyle,
  mobileDateFontFamily,
  mobileDateFontWeight,
  mobileDateFontStyle,
  dateAlign,
  mobileDateAlign,
  dateColor,
  mobileDateColor,
  columnsGap,
  imageMargin,
  mobileImageMargin,
  titleMargin,
  mobileTitleMargin,
  categoriesMargin,
  mobileCategoriesMargin,
  descriptionMargin,
  mobileDescriptionMargin,
  dateMargin,
  mobileDateMargin,
  imagePlacement,
  mobileImagePlacement,
  postBackgroundColor,
  mobilePostBackgroundColor,
}: CarouselBlogPostsProps) {
  let isCategoryPage = false
  if (typeof window !== 'undefined') {
    isCategoryPage = /\/category\/.*/.test(window.location.href)
  }

  const renderPostsGrid = (posts: BlogPost[], index: number) => (
    <BlogPostsPostItemRowUi columnsCount={rowSize} gap={columnsGap}>
      {posts.map((post: BlogPost, index: number) => (
        <CarouselBlogPostsRowUi
          imagePlacement={imagePlacement}
          mobileImagePlacement={mobileImagePlacement}
          imageSrc={post.image}
          key={index}
        >
          <BlogPostImage
            post={post}
            imagePlacement={imagePlacement}
            mobileImagePlacement={mobileImagePlacement}
            imageBorder={imageBorder}
            mobileImageBorder={mobileImageBorder}
            imageBoxShadow={imageBoxShadow}
            mobileImageBoxShadow={mobileImageBoxShadow}
            imageMargin={imageMargin}
            mobileImageMargin={mobileImageMargin}
          />
          <BlogPostContentContainerUi
            backgroundColor={postBackgroundColor}
            mobileBackgroundColor={mobilePostBackgroundColor}
          >
            <BlogPostCategories
              post={post}
              isCategoryPage={isCategoryPage}
              categoriesFontSize={categoriesFontSize}
              mobileCategoriesFontSize={mobileCategoriesFontSize}
              categoriesFontFamily={categoriesFontFamily}
              categoriesFontWeight={categoriesFontWeight}
              categoriesFontStyle={categoriesFontStyle}
              mobileCategoriesFontFamily={mobileCategoriesFontFamily}
              mobileCategoriesFontWeight={mobileCategoriesFontWeight}
              mobileCategoriesFontStyle={mobileCategoriesFontStyle}
              categoriesAlign={categoriesAlign}
              mobileCategoriesAlign={mobileCategoriesAlign}
              categoriesColor={categoriesColor}
              mobileCategoriesColor={mobileCategoriesColor}
              categoriesMargin={categoriesMargin}
              mobileCategoriesMargin={mobileCategoriesMargin}
              blogPath={blogPath}
            />
            <BlogPostsTitles
              post={post}
              titleFontSize={titleFontSize}
              mobileTitleFontSize={mobileTitleFontSize}
              titleLineHeight={titleLineHeight}
              mobileTitleLineHeight={mobileTitleLineHeight}
              titleFontFamily={titleFontFamily}
              titleFontWeight={titleFontWeight}
              titleFontStyle={titleFontStyle}
              mobileTitleFontFamily={mobileTitleFontFamily}
              mobileTitleFontWeight={mobileTitleFontWeight}
              mobileTitleFontStyle={mobileTitleFontStyle}
              titleLetterSpacing={titleLetterSpacing}
              mobileTitleLetterSpacing={mobileTitleLetterSpacing}
              titleAlign={titleAlign}
              mobileTitleAlign={mobileTitleAlign}
              titleColor={titleColor}
              mobileTitleColor={mobileTitleColor}
              titleMargin={titleMargin}
              mobileTitleMargin={mobileTitleMargin}
            />
            {showDate && (
              <BlogPostsDates
                post={post}
                dateFontSize={dateFontSize}
                mobileDateFontSize={mobileDateFontSize}
                dateFontFamily={dateFontFamily}
                dateFontWeight={dateFontWeight}
                dateFontStyle={dateFontStyle}
                mobileDateFontFamily={mobileDateFontFamily}
                mobileDateFontWeight={mobileDateFontWeight}
                mobileDateFontStyle={mobileDateFontStyle}
                dateAlign={dateAlign}
                mobileDateAlign={mobileDateAlign}
                dateColor={dateColor}
                mobileDateColor={mobileDateColor}
                dateMargin={dateMargin}
                mobileDateMargin={mobileDateMargin}
                locale={locale}
              />
            )}
            <BlogPostsDescriptions
              post={post}
              descriptionFontSize={descriptionFontSize}
              mobileDescriptionFontSize={mobileDescriptionFontSize}
              descriptionLineHeight={descriptionLineHeight}
              mobileDescriptionLineHeight={mobileDescriptionLineHeight}
              descriptionFontFamily={descriptionFontFamily}
              descriptionFontWeight={descriptionFontWeight}
              descriptionFontStyle={descriptionFontStyle}
              mobileDescriptionFontFamily={mobileDescriptionFontFamily}
              mobileDescriptionFontWeight={mobileDescriptionFontWeight}
              mobileDescriptionFontStyle={mobileDescriptionFontStyle}
              descriptionAlign={descriptionAlign}
              mobileDescriptionAlign={mobileDescriptionAlign}
              descriptionColor={descriptionColor}
              mobileDescriptionColor={mobileDescriptionColor}
              descriptionMargin={descriptionMargin}
              mobileDescriptionMargin={mobileDescriptionMargin}
            />
          </BlogPostContentContainerUi>
        </CarouselBlogPostsRowUi>
      ))}
    </BlogPostsPostItemRowUi>
  )

  return <>{posts.map((el, index) => renderPostsGrid(el, index))}</>
}

export default CarouselBlogPosts
