import styled, { css } from 'styled-components'
import { AlignSelfType } from 'common/types/styleTypes'
import { fontCss, FontProps, mobileFontCss } from 'common/utils/styleUtilsNew'

type AttachmentsFileNameUiProps = {
  alignSelf?: AlignSelfType
  mobileAlignSelf?: AlignSelfType
} & FontProps

const AttachmentsFileNameUi = styled.div<AttachmentsFileNameUiProps>`
  align-self: ${p => p.alignSelf || 'center'};
  ${fontCss}
  ${p => p.theme.phone} {
    align-self: ${p => p.mobileAlignSelf || 'center'};
    ${mobileFontCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
      align-self: ${p.mobileAlignSelf || 'center'};
    `}
`

export default AttachmentsFileNameUi
