import {
  SUBMIT_SUCCESS,
  TOGGLE_ACTIVE_POPUP,
  TOGGLE_EXPLICIT_CONSENT_CONFIRM,
} from 'publisher/actionTypes'

const previewModePlaceholder = '%PREVIEW_MODE%'
const funnelStepIdPlaceholder = '%FUNNEL_STEP_ID%'
const urlPlaceholder = '%URL%'
const affiliateIdPlaceholder = '%CONTACT_AFFILIATE_ID%'
const isMobilePlaceholder = '%IS_MOBILE%'
const affiliateLinkPlaceholder = '%AFFILIATE_LINK%'
const agreementPlaceholder = '%AGREEMENT%'
const isFreemiumUserPlaceholder = '%FREEMIUM_USER%'

export const captchaPlaceholder = '%CAPTCHA_OPEN_KEY%'

export const defaultReducerState = {
  activePopupId: null,
  previewMode: previewModePlaceholder,
  screenshotMode: false,
  funnelStepId: funnelStepIdPlaceholder,
  url: urlPlaceholder,
  confirmedExplicitConsents: [],
  isDesktop: null,
  affiliateId: affiliateIdPlaceholder,
  isMobile: isMobilePlaceholder,
  affiliateLink: affiliateLinkPlaceholder,
  agreement: agreementPlaceholder,
  isFreemiumUser: isFreemiumUserPlaceholder,
  captcha: captchaPlaceholder,
}

export default function (state = defaultReducerState, action) {
  const { type, payload } = action

  switch (type) {
    case TOGGLE_ACTIVE_POPUP:
      return {
        ...state,
        activePopupId: payload.id,
      }
    case SUBMIT_SUCCESS:
      return {
        ...state,
        activePopupId: null,
      }
    case TOGGLE_EXPLICIT_CONSENT_CONFIRM:
      return {
        ...state,
        confirmedExplicitConsents: state.confirmedExplicitConsents.includes(
          payload,
        )
          ? state.confirmedExplicitConsents.filter(id => id !== payload)
          : [...state.confirmedExplicitConsents, payload],
      }
    default:
      return state
  }
}

export const hasActivePopup = management => management.activePopupId !== null

export const isPopupActive = ({ activePopupId }, popupId) =>
  activePopupId === popupId

export const getTriggeredPopupId = ({ activePopupId }) => activePopupId

export const isPreviewMode = ({ previewMode }) =>
  previewMode === 'true' || previewMode === '1' || previewMode === true

export const isExplicitConsentConfirmed = (state, checkboxEntityId) => {
  state.confirmedExplicitConsents.includes(checkboxEntityId)
}

export const getConfirmedExplicitConsentsIds = state =>
  state.confirmedExplicitConsents

export const getFunnelStepId = ({ funnelStepId }) => funnelStepId

export const isScreenshotMode = ({ screenshotMode }) => screenshotMode

export const isFreemiumUser = ({ isFreemiumUser }) =>
  isFreemiumUser === 'true' || isFreemiumUser === '1'

export const isDesktop = state => state.isDesktop

export const getUrl = ({ url }) => {
  if (typeof window !== 'undefined' && url === urlPlaceholder) {
    return ''
  }

  return url
}

export const getHost = ({ url }) => {
  if (url === urlPlaceholder) {
    return ''
  }

  return url ? new URL(url).host : ''
}

export const getAffiliateId = state => {
  if (state.affiliateId === affiliateIdPlaceholder) {
    return null
  }

  return state.affiliateId
}

const isMobile = state => state.isMobile

const getAffiliateLink = ({ affiliateLink }) => affiliateLink

const getAgreement = ({ agreement }) => agreement

const getCaptcha = ({ captcha }) =>
  captcha === captchaPlaceholder ? null : captcha

export const selectors = {
  getAgreement,
  getAffiliateLink,
  getUrl,
  isMobile,
  isScreenshotMode,
  isDesktop,
  isPreviewMode,
  getFunnelStepId,
  isPopupActive,
  getTriggeredPopupId,
  getActivePopup: getTriggeredPopupId,
  isExplicitConsentConfirmed,
  getConfirmedExplicitConsentsIds,
  getAffiliateId,
  isFreemiumUser,
  getCaptcha,
}
