import styled from 'styled-components'


const LabelUi = styled.p`
  color: rgba(255, 255, 255, 1);
  background: rgba(20, 45, 99, 1);
  font-size: 26px;
  width: 100%;
  height: auto;
  padding: 30px 0;
  margin: 0;
  text-align: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  ${p => p.theme.phone} {
    font-size: 35px;
    padding: 0;
  }
`

export default LabelUi
