import debounce from 'lodash.debounce'
import React, { useRef, useState } from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonCarousel from 'common/components/entities/Carousel'
import CarouselInterface, {
  CarouselItemInterface,
} from 'common/types/entities/CarouselInterface'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'
import { pageSelectors, usePage } from '../../../store'
import CarouselSlide from './CarouselSlide'

type CarouselProps = {
  entity: CarouselInterface
}

function Carousel({ entity }: CarouselProps) {
  const entities: CarouselItemInterface[] = usePage(page =>
    pageSelectors.getChildrenEntities(page, entity.childIds),
  )
  const [needScroll, setNeedScroll] = useState(false)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const carouselRef = useRef<HTMLDivElement>(null)

  function updateActiveSlideIndex(index: number) {
    setActiveSlideIndex(prevIndex => {
      setNeedScroll(prevIndex !== index)
      return index
    })
  }

  const updateActive = debounce(updateActiveSlideIndex, 500)

  return (
    <StyleWrapper
      mobile={entity.appearance.mobile}
      desktop={entity.appearance.desktop}
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
    >
      <CommonCarousel
        arrowsColor={entity.arrowsColor}
        controlsColor={entity.controlsColor}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        totalItems={entity.childIds.length}
        htmlAttrId={entity.htmlAttrId}
        showArrows={entity.showArrows}
        mobileShowArrows={entity.mobileShowArrows}
        switchActiveSlideNumber={updateActiveSlideIndex}
        activeSlideIndex={activeSlideIndex}
        carouselRef={carouselRef}
      >
        {entities.map((carouselSlideEntity, index) => (
          <CarouselSlide
            key={carouselSlideEntity.id}
            active={index === activeSlideIndex}
            setActive={() => updateActive(index)}
            alignSelf={entity.verticalAlignment}
            mobileAlignSelf={entity.mobileVerticalAlignment}
            carouselRef={carouselRef}
            needScroll={needScroll}
          >
            <BaseNodeChildren childIds={carouselSlideEntity.childIds} />
          </CarouselSlide>
        ))}
      </CommonCarousel>
    </StyleWrapper>
  )
}

export default Carousel
