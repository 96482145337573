export const TOGGLE_ACTIVE_POPUP = 'TOGGLE_ACTIVE_POPUP'
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS'
export const SUBMIT_FAIL = 'SUBMIT_FAIL'
export const FIELD_CHANGE = 'FIELD_CHANGE'
export const BOOKING_FIELD_CHANGE = 'BOOKING_FIELD_CHANGE'
export const TOGGLE_CUSTOMER_TYPE = 'TOGGLE_CUSTOMER_TYPE'
export const TOGGLE_PAYMENT_METHOD = 'TOGGLE_PAYMENT_METHOD'
export const FIELD_CHANGE_INVALID = 'FIELD_CHANGE_INVALID'
export const TOGGLE_OFFER_PRICE_PLAN = 'TOGGLE_OFFER_PRICE_PLAN'
export const TOGGLE_BUMP_PRICE_PLAN = 'TOGGLE_BUMP_PRICE_PLAN'
export const TOGGLE_CHECKED_BUMP = 'TOGGLE_CHECKED_BUMP'
export const CHANGE_COUNTRY_VAT = 'CHANGE_COUNTRY_VAT'
export const TOGGLE_EXPLICIT_CONSENT_CONFIRM = 'TOGGLE_EXPLICIT_CONSENT_CONFIRM'
export const CHANGE_LECTURE_SESSION_ID = 'CHANGE_LECTURE_SESSION_ID'
export const CHECK_COUPON = 'CHECK_COUPON'
export const UPDATE_COUPON = 'UPDATE_COUPON'
export const SHOW_TWO_STEP_PAYMENT_FORM_STEP_PAYMENT =
  'SHOW_TWO_STEP_PAYMENT_FORM_STEP_PAYMENT'
export const FINISH_SURVEY = 'FINISH_SURVEY'
export const SUBMIT_RECAPTCHA = 'SUBMIT_RECAPTCHA'
export const OPT_IN_RECAPTCHA_VALIDATION_FAILED =
  'OPT_IN_RECAPTCHA_VALIDATION_FAILED'
export const TURN_OFF_SESSION_LOGGING = 'TURN_OFF_SESSION_LOGGING'
