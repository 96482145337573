import styled from 'styled-components'

const colors = {
  success: '#29A88E',
  error: '#C65F4A',
  warning: '#FFD035',
};

const AlertUi = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  padding: 10px;
  background-color: ${({ type }) => (type ? colors[type] : colors.gray)};
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  color: #444;
  width: 100%;
  height: 50px;
  font-size: 20px;
  z-index: 1;
`

export default AlertUi
