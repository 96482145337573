export const redirectionTypes = {
  nextStep: 'nextStep',
  customUrl: 'customUrl',
  login: 'login',
  none: 'none',
}

export const sendFormRedirectionChoices = {
  nextStep: 'entity_settings.button.send_form.redirection.choices.next_step',
  customUrl: 'entity_settings.button.send_form.redirection.choices.custom_url',
  none: 'entity_settings.button.send_form.redirection.choices.none',
}
