import styled, { css } from 'styled-components'
import { PaddingType } from 'common/types/styleTypes'
import { textCss, TextProps, mobileTextCss } from 'common/utils/styleUtilsNew'

type BaseTextEntityUiProps = {
  padding?: PaddingType
  mobilePadding?: PaddingType
} & TextProps

const BaseTextEntityUi = styled.p<BaseTextEntityUiProps>`
  ${textCss}
  ${p => p.theme.phone} {
    ${mobileTextCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileTextCss}
    `}
`

export default BaseTextEntityUi
