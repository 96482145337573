import { slugs } from 'common/constants/inputTypes'
import { checker, validator } from 'common/utils/validator'
import { isEmailValid, isNotEmpty } from 'publisher/utils/validators'

export default function fieldValidation(slug, value, optional) {
  if (optional) return []

  let validateField

  switch (slug) {
    case slugs.EMAIL:
      validateField = checker(
        validator('validation.email_invalid', isEmailValid),
      )
      break
    default:
      validateField = checker(validator('validation.not_empty', isNotEmpty))
      break
  }

  return validateField(value)
}

export function validateEmptyOptInFields(fieldProperties, t) {
  const slugs = Object.keys(fieldProperties)
  const emptyFieldErrors = slugs.reduce((acc, slug) => {
    let error = fieldValidation(
      slug,
      fieldProperties[slug].value,
      fieldProperties[slug].optional,
    )
    if (error.length > 0) {
      error = error.map(el => t(el))
      return { ...acc, [slug]: error }
    }
    return { ...acc }
  }, {})
  return emptyFieldErrors
}
