import styled, { css } from 'styled-components'
import { BorderType, PaddingType } from 'common/types/styleTypes'
import {
  backgroundColorCss,
  borderCss,
  boxShadowCss,
  fontCss,
  mobileBackgroundColorCss,
  mobileBorderCss,
  mobileBoxShadowCss,
  mobileFontCss,
  mobilePaddingsCss,
  paddingsCss,
} from 'common/utils/styleUtilsNew'

type ContactUsUiProps = {
  border?: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  padding: PaddingType
  mobilePadding?: PaddingType
  backgroundColor?: string
  mobileBackgroundColor?: string
  boxShadow?: string
  mobileBoxShadow?: string
}

const ContactUsUi = styled.div<ContactUsUiProps>`
  ${backgroundColorCss}
  ${paddingsCss}
  ${borderCss}
  ${boxShadowCss}
  ${fontCss}
  ${p => p.theme.phone} {
    ${mobileBackgroundColorCss}
    ${mobilePaddingsCss}
    ${mobileBorderCss}
    ${mobileFontCss}
    ${mobileBoxShadowCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileBackgroundColorCss}
      ${mobilePaddingsCss}
            ${mobileBorderCss}
            ${mobileFontCss}
            ${mobileBoxShadowCss}
    `}
`

export default ContactUsUi
