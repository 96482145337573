import React, { forwardRef } from 'react'

function provideRef(ComposedComponent) {
  const RefProvider = (props, ref) =>
    <ComposedComponent {...props} forwardedRef={ref} />

  const composedComponentName = ComposedComponent.displayName
    || ComposedComponent.name
    || 'Component'

  RefProvider.displayName = `provideRef(${composedComponentName})`

  return forwardRef(RefProvider);
}

export default provideRef
