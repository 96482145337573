import styled, { css } from 'styled-components'
import { CommonContainerInterface } from 'common/types/entities/EntityInterface'
import {
  containerCss,
  fontCss,
  mobileFontCss,
} from 'common/utils/styleUtilsNew'

interface SurveyUiProps extends CommonContainerInterface {
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
  mobileFontFamily?: string
  mobileFontStyle?: string
  mobileFontWeight?: string
}

const SurveyUi = styled.div<SurveyUiProps>`
  ${containerCss}
  ${fontCss}
  ${p => p.theme.phone} {
    ${mobileFontCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
    `}
`

export default SurveyUi
