import React from 'react'
import CommonWebinarSessionDateTime from 'common/components/entities/WebinarSessionDateTime'
import StyleWrapper from 'common/components/StyleWrapper'
import * as types from 'common/types'
import useManagement, {
  selectors as managementSelectors,
} from '../../hooks/useManagement'
import useWebinar, {
  selectors as webinarSelectors,
} from '../../hooks/useWebinar'

function WebinarSessionDateTime({ entity }) {
  const {
    appearance: { desktop, mobile },
    ...restOptions
  } = entity.options
  let timestamp = useWebinar(webinarSelectors.getWebinarTimestamp)
  const isScreenshotMode = useManagement(managementSelectors.isScreenshotMode)

  if (isScreenshotMode) {
    timestamp = new Date(Date.now() + 10 * 1000)
  }

  return (
    <StyleWrapper desktop={desktop} mobile={mobile}>
      {timestamp && (
        <CommonWebinarSessionDateTime
          options={restOptions}
          styles={entity.styles}
          mobileStyles={entity.mobileStyles}
          timestamp={timestamp}
        />
      )}
    </StyleWrapper>
  )
}

WebinarSessionDateTime.propTypes = {
  entity: types.entity.isRequired,
}

export default WebinarSessionDateTime
