import React from 'react'
import PropTypes from 'prop-types'
import OverlayUi from './ui/Overlay'
import PointlessDiv from './ui/PointlessDiv'

const Overlay = props => (
  <OverlayUi onClick={props.hide} dark={props.dark} zIndex={props.zIndex}>
    <PointlessDiv />
  </OverlayUi>
)

Overlay.propTypes = {
  hide: PropTypes.func,
  dark: PropTypes.bool,
  zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Overlay.defaultProps = {
  dark: false,
  zIndex: 1,
  hide: null,
}

export default Overlay
