import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import { CountdownTypesEnum } from '../enums/CountdownTypesEnum'
import { getDailyTime } from './get-daily-time'
import { getFixedTime } from './get-fixed-time'
import { getNotExpiredStoredStartTime } from './get-not-expired-stored-start-time'

export const getTimeToRender = ({
  entity,
  pageId,
  deadlineTime,
}: {
  entity: CountdownInterface
  pageId: number
  deadlineTime: number | null
}) => {
  let countdownTime = null
  switch (entity.countdownType) {
    case CountdownTypesEnum.daily: {
      countdownTime = getDailyTime(entity[CountdownTypesEnum.daily])
      break
    }
    case CountdownTypesEnum.fixed: {
      countdownTime = getFixedTime(entity[CountdownTypesEnum.fixed])
      break
    }
    case CountdownTypesEnum.delay: {
      if (entity[CountdownTypesEnum.delay]) {
        countdownTime = getNotExpiredStoredStartTime(
          pageId,
          entity[CountdownTypesEnum.delay].createdAt,
        )
      } else {
        countdownTime = null
      }
      break
    }
    case CountdownTypesEnum.deadline: {
      countdownTime = deadlineTime
      break
    }
  }

  return countdownTime && countdownTime > new Date().getTime()
    ? countdownTime
    : null
}
