import styled, { css } from 'styled-components'

const SelectPricePlanUi = styled.div`
  flex: 1;
  background: #f5fcff;
  padding: 45px;
  ${p => p.theme.phone} {
    padding: 34px 13px 24px;
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      padding: 34px 13px 24px;
    `}
`

export default SelectPricePlanUi
