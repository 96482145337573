import { FilesState } from '../index'

export default function (state: FilesState = {}) {
  return state
}

export const getFile = (files: FilesState, fileId?: number | null) => {
  if (!fileId) {
    return null
  }
  return files[fileId]
}

export const getByFileIds = (files: FilesState, fileIds: number[]) =>
  fileIds.map(fileId => files[fileId])

export const fileSelectors = {
  getFile,
}
