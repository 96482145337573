import styled, { css } from 'styled-components'

const LanguageSwitcherItemsUi = styled.div`
  display: ${p => (p.isOpened ? 'block' : 'none')};
  position: absolute;
  right: -8px;
  top: 25px;
  box-shadow: 0 1px 4px 0 #d5e0e8;
  z-index: 1;
  border-radius: 3px;
  background-color: #fff;
  ${p =>
    p.fontFamily &&
    css`
      font-family: ${p.fontFamily};
    `};
  padding: 22px 25px;
  &::before {
    border-width: 10px;
    border: solid transparent;
    border-bottom-color: #fff;
    position: absolute;
    top: -6px;
    z-index: 5;
    right: 15px;
    content: '\\0020';
    height: 0;
    width: 0;
    pointer-events: none;
  }
`

export default LanguageSwitcherItemsUi
