const blogPostListingPlaceholder = '%BLOG_POST_LISTING%'
const blogPostTitlePlaceholder = '%BLOG_POST_TITLE%'
const blogPostDescriptionPlaceholder = '%BLOG_POST_DESCRIPTION%'
const blogPostImageUrlPlaceholder = '%BLOG_POST_IMAGE_URL%'
const blogPostDateTsPlaceholder = '%BLOG_POST_DATE_TS%'
const blogPostCategoriesPlaceholder = '%BLOG_POST_CATEGORIES%'
const blogPathPlaceholder = '%BLOG_PATH%'
const blogLayoutPageIdPlaceholder = '%BLOG_LAYOUT_PAGE_ID%'
const blogCategoryTitlePlaceholder = '%BLOG_CATEGORY_TITLE%'
const blogCategoryDescriptionPlaceholder = '%BLOG_CATEGORY_DESCRIPTION%'

export const defaultState = {
  blogPostListing: blogPostListingPlaceholder,
  blogPostTitle: blogPostTitlePlaceholder,
  blogPostDescription: blogPostDescriptionPlaceholder,
  blogPostImageUrl: blogPostImageUrlPlaceholder,
  blogPostDateTs: blogPostDateTsPlaceholder,
  blogPostCategories: blogPostCategoriesPlaceholder,
  blogPath: blogPathPlaceholder,
  blogLayoutPageId: blogLayoutPageIdPlaceholder,
  blogCategoryTitle: blogCategoryTitlePlaceholder,
  blogCategoryDescription: blogCategoryDescriptionPlaceholder,
}

export default function (state = defaultState) {
  return state
}

export const getBlogCategoryTitle = state => {
  if (state.blogCategoryTitle === blogCategoryTitlePlaceholder) {
    return null
  }

  return state.blogCategoryTitle
}

export const getBlogCategoryDescription = state => {
  if (state.blogCategoryDescription === blogCategoryDescriptionPlaceholder) {
    return null
  }

  return state.blogCategoryDescription
}

export const getBlogPostTitle = ({ blogPostTitle }) => blogPostTitle

export const getBlogPostDescrition = ({ blogPostDescription }) =>
  blogPostDescription

export const getBlogPostImageUrl = ({ blogPostImageUrl }) => blogPostImageUrl

export const getBlogPostDate = ({ blogPostDateTs }) =>
  new Date(blogPostDateTs * 1000)

export const getBlogPostListing = ({ blogPostListing }) => {
  try {
    return JSON.parse(blogPostListing)
  } catch (e) {
    return {
      posts: [],
      pagination: {
        current: 0,
        total: 0,
      },
    }
  }
}

export const getBlogPostCategories = ({ blogPostCategories }) => {
  try {
    return JSON.parse(blogPostCategories)
  } catch (e) {
    return []
  }
}

export const getBlogPath = ({ blogPath }) => blogPath

const getBlogLayoutPageId = state => state.blogLayoutPageId

export const selectors = {
  getBlogLayoutPageId,
  getBlogPostImageUrl,
  getBlogPath,
  getBlogPostTitle,
  getBlogPostCategories,
  getBlogPostDate,
  getBlogPostListing,
  getBlogCategoryTitle,
  getBlogCategoryDescription,
}
