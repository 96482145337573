import React from 'react'
import CommonInlineBodyUi, {
  CommonInlineBodyUiProps,
} from './ui/common-inline-body-ui'

interface CommonInlineBodyProps extends CommonInlineBodyUiProps {
  children: React.ReactNode
}

const CommonInlineBody = ({ children, ...styles }: CommonInlineBodyProps) => {
  return <CommonInlineBodyUi {...styles}>{children}</CommonInlineBodyUi>
}

export default CommonInlineBody
