import { SectionInterface } from 'common/components/entities/Section/types/SectionInterface'
import { managementSelectors, useManagement } from 'publisher/store'

// otherwise, the empty section could be collapsed and screenshot won't be generated
export function useScreenshotStyles(entity: SectionInterface) {
  const isScreenshotMode = useManagement(managementSelectors.isScreenshotMode)

  if (isScreenshotMode && entity.childIds.length === 0) {
    return {
      padding: 20,
    }
  }
  return {}
}
