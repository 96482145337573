import PropTypes from 'prop-types'
import React from 'react'
import { imageActionsKeys } from 'common/constants/settings'
import ImageUi from './ui/ImageUi'
import ImageWrapperUi from './ui/ImageWrapperUi'

const Image = ({ src, styles, mobileStyles, options, showPopup, loading }) => {
  const { blur, alt } = options
  const filter = blur ? `blur(${blur || 0}px)` : undefined
  const {
    width,
    height,
    boxShadow,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    ...restStyles
  } = styles /* omit height, it works unstable now */
  const {
    boxShadow: mobileBoxShadow,
    borderTopLeftRadius: mobileBorderTopLeftRadius,
    borderTopRightRadius: mobileBorderTopRightRadius,
    borderBottomLeftRadius: mobileBorderBottomLeftRadius,
    borderBottomRightRadius: mobileBorderBottomRightRadius,
    maxHeight, // omit
    maxWidth, // omit
    width: mobileWidth,
    ...restMobileStyles
  } = mobileStyles
  const action =
    options.action || (options.link ? imageActionsKeys.openUrl : undefined)

  const renderedImage = (
    <ImageWrapperUi
      styles={restStyles}
      mobileStyles={{
        ...restMobileStyles,
        ...(mobileWidth === '100%' ? { width: '100%' } : {}),
      }}
      attrId={options.attrId}
    >
      <picture
        style={
          options.ratio
            ? {
                display: 'block',
                position: 'relative',
                height: 0,
                overflow: 'visible',
                paddingBottom: `${100 / +options.ratio}%`,
              }
            : { display: 'contents' }
        }
      >
        <ImageUi
          loading={loading}
          src={src}
          alt={alt}
          height100={Boolean(options.ratio) === false}
          filter={filter}
          styles={{
            width,
            boxShadow,
            borderTopLeftRadius,
            borderTopRightRadius,
            borderBottomRightRadius,
            borderBottomLeftRadius,
          }}
          mobileStyles={{
            boxShadow: mobileBoxShadow,
            borderTopLeftRadius: mobileBorderTopLeftRadius,
            borderTopRightRadius: mobileBorderTopRightRadius,
            borderBottomRightRadius: mobileBorderBottomLeftRadius,
            borderBottomLeftRadius: mobileBorderBottomRightRadius,
            width: mobileWidth,
            ...restMobileStyles,
          }}
        />
      </picture>
    </ImageWrapperUi>
  )

  switch (action) {
    case imageActionsKeys.openUrl:
      return (
        <a
          href={options.link}
          rel="noopener noreferrer"
          target={options.openNewTab ? '_blank' : '_self'}
        >
          {renderedImage}
        </a>
      )
    case imageActionsKeys.showPopup:
      return (
        <ImageWrapperUi
          styles={restStyles}
          mobileStyles={{
            ...restMobileStyles,
            ...(mobileWidth === '100%' ? { width: '100%' } : {}),
          }}
          attrId={options.attrId}
        >
          <ImageUi
            loading={loading}
            src={src}
            alt={alt}
            filter={filter}
            styles={{
              width,
              boxShadow,
              borderTopLeftRadius,
              borderTopRightRadius,
              borderBottomRightRadius,
              borderBottomLeftRadius,
            }}
            mobileStyles={{
              boxShadow: mobileBoxShadow,
              borderTopLeftRadius: mobileBorderTopLeftRadius,
              borderTopRightRadius: mobileBorderTopRightRadius,
              borderBottomRightRadius: mobileBorderBottomLeftRadius,
              borderBottomLeftRadius: mobileBorderBottomRightRadius,
              ...restMobileStyles,
            }}
            onClick={showPopup}
          />
        </ImageWrapperUi>
      )
    default:
      return renderedImage
  }
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  options: PropTypes.shape({
    action: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    alt: PropTypes.string,
    blur: PropTypes.string,
    openNewTab: PropTypes.bool,
    link: PropTypes.string,
    popup: PropTypes.string,
    attrId: PropTypes.string,
  }).isRequired,
  styles: PropTypes.shape({
    width: PropTypes.string.isRequired,
    borderTopLeftRadius: PropTypes.string,
    borderTopRightRadius: PropTypes.string,
    borderBottomRightRadius: PropTypes.string,
    borderBottomLeftRadius: PropTypes.string,
    marginTop: PropTypes.string,
    marginRight: PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    justifyContent: PropTypes.string,
    boxShadow: PropTypes.string,
  }).isRequired,
  mobileStyles: PropTypes.shape({
    borderTopLeftRadius: PropTypes.string,
    borderTopRightRadius: PropTypes.string,
    borderBottomRightRadius: PropTypes.string,
    borderBottomLeftRadius: PropTypes.string,
    marginTop: PropTypes.string,
    marginRight: PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    justifyContent: PropTypes.string,
    boxShadow: PropTypes.string,
  }).isRequired,
  showPopup: PropTypes.func,
  loading: PropTypes.oneOf(['lazy', 'eager']),
}

Image.defaultProps = {
  showPopup: () => {},
  width: 0,
  height: 0,
  alt: '',
  loading: 'lazy',
}

export default Image
