import React, { useEffect, useRef, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { getCurrencySignByLocale } from 'common/utils/currencyUtils'
import { PageLocaleEnum } from '../../../enums/PageLocaleEnum'
import { getReadablePrice } from '../../../utils/priceCalculator'
import PricePlanActionButtonUi from './ui/PricePlanActionButtonUi'
import PricePlanActionDescriptionUi from './ui/PricePlanActionDescriptionUi'
import PricePlanActionPriceUi from './ui/PricePlanActionPriceUi'
import PricePlanActionTitleUi from './ui/PricePlanActionTitleUi'
import PricePlanActionUi from './ui/PricePlanActionUi'
import PricePlanCalculatorUi from './ui/PricePlanCalculatorUi'
import PricePlanListButtonUi from './ui/PricePlanListButtonUi'
import PricePlanListUi from './ui/PricePlanListUi'
import PricePlanRangeUi, {
  PricePlanSliderUi,
  Tooltip,
} from './ui/PricePlanRangeUi'
import PricePlanSelectUi from './ui/PricePlanSelectUi'
import SelectPricePlanLabelUi from './ui/SelectPricePlanLabelUi'
import SelectPricePlanUi from './ui/SelectPricePlanUi'

type PricePlanCalculatorProps = {
  locale: PageLocaleEnum
  rangeMax?: number
  countryCode?: string
  openPopup: () => void
}

enum PricePlans {
  Startup = 'Startup',
  Webinar = 'Webinar',
  Enterprise = 'Enterprise',
  Custom_20 = 'Custom_20',
  Custom_30 = 'Custom_30',
  Custom_40 = 'Custom_40',
  Custom_50 = 'Custom_50',
  Custom_60 = 'Custom_60',
  Custom_70 = 'Custom_70',
  Custom_80 = 'Custom_80',
  Custom_90 = 'Custom_90',
  Custom_100 = 'Custom_100',
}

const pricePlansPricesEuro = {
  [PricePlans.Startup]: 27,
  [PricePlans.Webinar]: 47,
  [PricePlans.Enterprise]: 97,
  [PricePlans.Custom_20]: 147,
  [PricePlans.Custom_30]: 197,
  [PricePlans.Custom_40]: 247,
  [PricePlans.Custom_50]: 297,
  [PricePlans.Custom_60]: 347,
  [PricePlans.Custom_70]: 397,
  [PricePlans.Custom_80]: 447,
  [PricePlans.Custom_90]: 497,
  [PricePlans.Custom_100]: 547,
}

const pricePlansPricesRubble = {
  [PricePlans.Startup]: 1700,
  [PricePlans.Webinar]: 2800,
  [PricePlans.Enterprise]: 5750,
  [PricePlans.Custom_20]: 10900,
  [PricePlans.Custom_30]: 14600,
  [PricePlans.Custom_40]: 18300,
  [PricePlans.Custom_50]: 22000,
  [PricePlans.Custom_60]: 25700,
  [PricePlans.Custom_70]: 29400,
  [PricePlans.Custom_80]: 33100,
  [PricePlans.Custom_90]: 36800,
  [PricePlans.Custom_100]: 40500,
}

const pricePlansPricesReal = {
  [PricePlans.Startup]: 142.3,
  [PricePlans.Webinar]: 247.7,
  [PricePlans.Enterprise]: 511.2,
  [PricePlans.Custom_20]: 774.7,
  [PricePlans.Custom_30]: 1038.2,
  [PricePlans.Custom_40]: 1301.7,
  [PricePlans.Custom_50]: 1565.2,
  [PricePlans.Custom_60]: 1828.7,
  [PricePlans.Custom_70]: 2092.2,
  [PricePlans.Custom_80]: 2355.7,
  [PricePlans.Custom_90]: 2619.2,
  [PricePlans.Custom_100]: 2882.7,
}

const pricePlansTranslationKeys = {
  [PricePlans.Startup]: 'components.price_plan.startup',
  [PricePlans.Webinar]: 'components.price_plan.webinar',
  [PricePlans.Enterprise]: 'components.price_plan.enterprise',
  [PricePlans.Custom_20]: 'components.price_plan.custom',
  [PricePlans.Custom_30]: 'components.price_plan.custom',
  [PricePlans.Custom_40]: 'components.price_plan.custom',
  [PricePlans.Custom_50]: 'components.price_plan.custom',
  [PricePlans.Custom_60]: 'components.price_plan.custom',
  [PricePlans.Custom_70]: 'components.price_plan.custom',
  [PricePlans.Custom_80]: 'components.price_plan.custom',
  [PricePlans.Custom_90]: 'components.price_plan.custom',
  [PricePlans.Custom_100]: 'components.price_plan.custom',
}

const pricePlansContacts = {
  [PricePlans.Startup]: 5000,
  [PricePlans.Webinar]: 10000,
  [PricePlans.Enterprise]: 15000,
  [PricePlans.Custom_20]: 20000,
  [PricePlans.Custom_30]: 30000,
  [PricePlans.Custom_40]: 40000,
  [PricePlans.Custom_50]: 50000,
  [PricePlans.Custom_60]: 60000,
  [PricePlans.Custom_70]: 70000,
  [PricePlans.Custom_80]: 80000,
  [PricePlans.Custom_90]: 90000,
  [PricePlans.Custom_100]: 100000,
}

function getPricePlanPricesByLocale(
  locale: PageLocaleEnum,
  // countryCode?: string,
) {
  switch (locale) {
    case PageLocaleEnum.RUSSIAN:
      return pricePlansPricesRubble
    case PageLocaleEnum.PORTUGUESE:
      // if (countryCode && countryCode === 'BR') {
      //   return pricePlansPricesReal
      // }
      // return pricePlansPricesEuro
      return pricePlansPricesReal
    default:
      return pricePlansPricesEuro
  }
}

function getPlanByContactsAmount(inputContactsAmount: number) {
  const pricePlanContactsAmount = Object.values(pricePlansContacts).find(
    priceContactsAmount => inputContactsAmount <= priceContactsAmount,
  ) as number

  return Object.keys(pricePlansContacts).find(
    pricePlan =>
      pricePlansContacts[pricePlan as PricePlans] === pricePlanContactsAmount,
  ) as PricePlans
}

function PricePlanCalculator({
  locale,
  countryCode,
  rangeMax = 100000,
  openPopup,
}: PricePlanCalculatorProps) {
  const { t: tt, i18n } = useTranslation('common')
  const t = locale ? i18n.getFixedT(locale, 'common') : tt
  const initialContacts = pricePlansContacts[PricePlans.Startup]
  const tooltipRef = useRef<HTMLDivElement>(null)
  const rangeRef = useRef<HTMLInputElement>(null)
  const [activePricePlan, setActivePricePlan] = useState(PricePlans.Startup)
  const [rangeVal, setRangeVal] = useState(initialContacts)
  const [tooltipLeft, setTooltipLeft] = useState(0)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (tooltipRef.current && rangeRef.current) {
      calculateTooltipLeftPosition(
        rangeRef.current.offsetWidth,
        tooltipRef.current.offsetWidth,
        rangeVal,
        rangeMax,
      )
    }
    setActivePricePlan(getPlanByContactsAmount(rangeVal))
  }, [rangeVal, initialContacts])

  function handleRangeInput(e: React.ChangeEvent<HTMLInputElement>) {
    const newRangeValue = Number(e.target.value)
    if (newRangeValue > pricePlansContacts[PricePlans.Enterprise]) {
      const pricePlan = getPlanByContactsAmount(newRangeValue)
      setRangeVal(pricePlansContacts[pricePlan])
    } else {
      setRangeVal(newRangeValue)
    }
  }

  function calculateTooltipLeftPosition(
    rangeWidth: number,
    tooltipWidth: number,
    rangeValue: number,
    rangeMax: number,
  ) {
    setProgress((rangeValue * rangeWidth) / rangeMax)
    setTooltipLeft((rangeValue * rangeWidth) / rangeMax - tooltipWidth / 2)
  }

  function choosePlanByContactsAmount(contactsAmount: number) {
    return function () {
      setRangeVal(contactsAmount)
    }
  }

  function choosePlanFromSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    setRangeVal(Number(e.target.value))
  }

  return (
    <PricePlanCalculatorUi>
      <SelectPricePlanUi>
        <SelectPricePlanLabelUi>
          {t('components.price_plan.select_plan_title')}
        </SelectPricePlanLabelUi>
        <PricePlanListUi>
          <PricePlanListButtonUi
            onClick={choosePlanByContactsAmount(pricePlansContacts.Startup)}
            active={activePricePlan === PricePlans.Startup}
          >
            {t('components.price_plan.startup')}
          </PricePlanListButtonUi>
          <PricePlanListButtonUi
            onClick={choosePlanByContactsAmount(pricePlansContacts.Webinar)}
            active={activePricePlan === PricePlans.Webinar}
          >
            {t('components.price_plan.webinar')}
          </PricePlanListButtonUi>
          <PricePlanListButtonUi
            onClick={choosePlanByContactsAmount(pricePlansContacts.Enterprise)}
            active={activePricePlan === PricePlans.Enterprise}
          >
            {t('components.price_plan.enterprise')}
          </PricePlanListButtonUi>
        </PricePlanListUi>
        <SelectPricePlanLabelUi>
          {t('components.price_plan.question_main')}
        </SelectPricePlanLabelUi>
        <PricePlanSelectUi onChange={choosePlanFromSelect} value={rangeVal}>
          {Object.values(PricePlans).map(pricePlan => (
            <option key={pricePlan} value={pricePlansContacts[pricePlan]}>
              {t('components.price_plan.label_in_select_option', {
                amount: pricePlansContacts[pricePlan],
              })}
            </option>
          ))}
        </PricePlanSelectUi>
        {/*<p style={{ color: '#64657E' }}>*/}
        {/*  {t('components.price_plan.question_secondary')}*/}
        {/*</p>*/}
        <PricePlanSliderUi>
          <PricePlanRangeUi
            ref={rangeRef}
            type="range"
            onInput={handleRangeInput}
            value={rangeVal}
            progress={progress}
            min={0}
            max={100000}
            step={5000}
          />
          <Tooltip ref={tooltipRef} left={tooltipLeft}>
            <Trans
              i18nKey="components.price_plan.range_tooltip"
              t={t}
              values={{ amount: rangeVal }}
            >
              Up to <span>{{ rangeVal }}</span> contacts
            </Trans>
          </Tooltip>
        </PricePlanSliderUi>
      </SelectPricePlanUi>
      <PricePlanActionUi>
        <PricePlanActionTitleUi>
          {t(pricePlansTranslationKeys[activePricePlan])}
        </PricePlanActionTitleUi>
        <PricePlanActionPriceUi id="price-plan-calculator-value">
          {getReadablePrice(
            getPricePlanPricesByLocale(locale /*, countryCode*/)[
              activePricePlan
            ],
            getCurrencySignByLocale(locale, countryCode),
            locale,
          )}
          <small style={{ textTransform: 'lowercase' }}>/{t('month')}</small>
        </PricePlanActionPriceUi>
        <PricePlanActionButtonUi onClick={openPopup}>
          {t('components.price_plan.action.label')}
        </PricePlanActionButtonUi>
        <PricePlanActionDescriptionUi>
          {t('components.price_plan.action.description')}
        </PricePlanActionDescriptionUi>
      </PricePlanActionUi>
    </PricePlanCalculatorUi>
  )
}

export default PricePlanCalculator
