import styled, { css } from 'styled-components'

const PricePlanSelectUi = styled.select`
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding-top: 11px;
  padding-bottom: 11px;
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
  display: none;
  ${p => p.theme.phone} {
    display: inline;
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      display: inline;
    `}
`

export default PricePlanSelectUi
