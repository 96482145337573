import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Tooltip from 'client/components/core/Tooltip/index'
import Item from './Item'
import Label from './Label'
import Value from './Value'

function OptionWrapper(props) {
  const {
    labelText,
    labelIcon,
    directionRow,
    children,
    helpMessage,
    needTranslate,
    itemStyles,
    width,
  } = props
  const { t } = useTranslation()

  return (
    <Item itemStyle={itemStyles} width={width} directionRow={directionRow}>
      {labelText && (
        <Label directionRow={directionRow}>
          {labelText && helpMessage ? (
            <Tooltip message={needTranslate ? t(helpMessage) : helpMessage}>
              {needTranslate ? t(labelText) : labelText}
            </Tooltip>
          ) : needTranslate ? (
            t(labelText)
          ) : (
            labelText
          )}
          {labelIcon}
        </Label>
      )}
      <Value directionRow={directionRow}>{children}</Value>
    </Item>
  )
}

OptionWrapper.propTypes = {
  labelText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  directionRow: PropTypes.bool,
  labelIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  helpMessage: PropTypes.string,
  needTranslate: PropTypes.bool,
  itemStyles: PropTypes.object,
  width: PropTypes.number,
}

OptionWrapper.defaultProps = {
  value: '',
  firstOption: '',
  directionRow: false,
  labelText: null,
  labelIcon: null,
  helpMessage: '',
  needTranslate: true,
  itemStyles: {},
  width: undefined,
}

export { OptionWrapper as default, Value, Label, Item }
